import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import ShimmerCard from "../../common/ShimmerCard";
import Loadable from "../../common/Loadable";

//utils
import { GOOD_PERCENTAGE_BREAKPOINT } from "../../../utils/constants";

const RateLineWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing.small}px 0px;
    border-bottom: ${({ theme }) => theme.borderStyle.smallDivider};
    flex-shrink: 0;
    @media screen and (max-width: 1025px) {
        width: 55vw;
    }
    @media screen and (max-width: 963px) {
        width: 50vw;
    }
    @media screen and (max-width: 820px) {
        width: 43vw;
    }
    @media screen and (max-width: 790px) {
        width: 40vw;
    }
`;

const LineRateStats = styled.div`
    flex: 3;
`;

const LineRateText = styled(Text)`
    flex: 2;
    text-align: end;
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

const RateText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.small}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const LoadingWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.small}px;
    flex-shrink: 0;
    float: right;
`;

const StyledLoadingCard = () => {
    return (
        <LoadingWrapper>
            <ShimmerCard height={23} width={48}></ShimmerCard>
        </LoadingWrapper>
    );
};

export default function RateLine({ item }) {
    const { percentage, rateText } = item ? item : "";
    const key = item ? item.key : "";
    const { colors } = useTheme();
    let textColor = percentage
        ? percentage >= GOOD_PERCENTAGE_BREAKPOINT
            ? colors.GREEN_2
            : colors.DEEP_BLUE_SEA
        : colors.DEEP_BLUE_SEA;
    let textDescription = percentage
        ? `${(percentage * 100).toFixed(0)}%`
        : "–";

    return (
        <RateLineWrapper key={key}>
            <RateText type="label" size="small" color={colors.EXHAUST}>
                {rateText}
            </RateText>
            <LineRateStats>
                <Loadable
                    dataLoaded={item}
                    LoadingComponent={StyledLoadingCard}
                >
                    <LineRateText
                        percentage={percentage}
                        type="header"
                        size="small"
                        color={textColor}
                    >
                        {textDescription}
                    </LineRateText>
                </Loadable>
            </LineRateStats>
        </RateLineWrapper>
    );
}
