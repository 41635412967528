//style
import styled from "../../styling/styled-components";

//components
import RentalPhaseCard from "./RentalPhaseCard";
import VerticalLine from "../common/VerticalLine";
import Displayable from "../common/Displayable";

//types
import { FilteredAggregations, PhaseFilters } from "./common/RentalVisualTypes";

//enums
import { RentalPhase } from "../../API";

const PhaseGridCard = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
`;

const PhaseGridWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.large}px;
    padding-right: ${({ theme }) => theme.spacing.xxlarge}px;
    @media screen and (max-width: 1023px) {
        padding-right: ${({ theme }) => theme.spacing.small}px;
    }
`;

const LineDiv = styled.div`
    height: 30px;
`;

type Props = {
    filteredAggregations: FilteredAggregations[];
    phase: PhaseFilters;
    phaseFilters: PhaseFilters[];
};

export default function PhaseGridView({
    filteredAggregations,
    phase,
    phaseFilters,
}: Props) {
    const displayLine =
        phase.value == RentalPhase.FinedAutomatic ||
        phase.value == RentalPhase.FineError ||
        phase.value == RentalPhase.RefundRequested;

    return (
        <PhaseGridWrapper>
            <PhaseGridCard>
                <RentalPhaseCard
                    filteredAggregations={filteredAggregations}
                    phase={phase}
                    phaseFilters={phaseFilters}
                ></RentalPhaseCard>
            </PhaseGridCard>
            <Displayable displayPrimaryComponent={!displayLine}>
                <LineDiv>
                    <VerticalLine />
                </LineDiv>
            </Displayable>
        </PhaseGridWrapper>
    );
}
