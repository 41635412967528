//react
import React from "react";

//components
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

//style
import { useTheme } from "styled-components/macro";

//constants
import { GOOD_PERCENTAGE_BREAKPOINT } from "../../../utils/constants";

type Props = {
    percentage: number;
    loading: boolean;
};

export default function Component({ percentage, loading }: Props) {
    const { colors } = useTheme();

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        events: [],
        cutout: "90%",
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            filler: {
                propagate: false,
            },
        },
    };

    const textColor =
        percentage >= GOOD_PERCENTAGE_BREAKPOINT
            ? colors.GREEN_2
            : colors.DEEP_BLUE_SEA;

    const chartColor =
        percentage >= GOOD_PERCENTAGE_BREAKPOINT
            ? colors.GREEN_3
            : colors.DEEP_BLUE_SEA;

    const plugins = [
        {
            beforeDraw: function (chart) {
                const { width, height, ctx } = chart;
                ctx.restore();
                const fontSize = (height / 60).toFixed(2);
                ctx.font = fontSize + "em poppins";
                ctx.textBaseline = "middle";
                const text = loading
                        ? ""
                        : percentage
                        ? `${(percentage * 100).toFixed(0)}%`
                        : "–",
                    textX = Math.round(
                        (width - ctx.measureText(text).width) / 2
                    ),
                    // FIXIT: dynamic height centering

                    textY = Math.round(height / 2);
                ctx.fillStyle = percentage ? textColor : colors.DEEP_BLUE_SEA;
                ctx.fillText(text, textX, textY);
                ctx.save();
            },
        },
    ];

    percentage = percentage || 0;
    const data = {
        labels: ["Percentage", "InversePercentage"],
        datasets: [
            {
                data: [percentage * 100, 100 - percentage * 100],
                backgroundColor: [chartColor, colors.SMOG],
                borderColor: [chartColor, colors.SMOG],
            },
        ],
    };

    return (
        <Doughnut
            datasetIdKey={percentage}
            options={options}
            data={data}
            plugins={plugins}
        />
    );
}
