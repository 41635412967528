//react
import { useState, useEffect } from "react";

//style
import styled, { useTheme } from "../../styling/styled-components";

//components
import { RentalStatusWrapper, StatusText } from "./common/SharedRentalVisual";
import Loadable from "../common/Loadable";
import Text from "../common/Text";

//utils
import { filterDisplay } from "./utils/helpers";
import formatCount from "../../utils/formatCount";
import { totalUpdatedPhaseCounts } from "../../utils/rentalVisual/totalUpdatedPhaseCounts";

//types
import { FilteredAggregations, PhaseFilters } from "./common/RentalVisualTypes";

//enums
import { RentalPhase } from "../../API";

const PhaseLabelIconDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    gap: ${({ theme }) => theme.spacing.small}px;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const PhaseLabelWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const StyledPhaseText = styled(Text)`
    opacity: ${(props) => props.opacity};
    white-space: nowrap;
`;

type Props = {
    filteredAggregations: FilteredAggregations;
    phaseFilters: PhaseFilters[];
    phase: PhaseFilters;
};

export default function RentalPhaseCard({
    filteredAggregations,
    phaseFilters,
    phase,
}: Props) {
    const { colors, spacing } = useTheme();
    const [phaseFilteredShown, setPhaseFilteredShown] = useState(true);
    const [loading, setLoading] = useState(true);
    const [phaseCount, setPhaseCount] = useState(0);
    const failedStatusSelected =
        filteredAggregations?.length == 2 ? true : false;

    useEffect(() => {
        calculatePhaseCount();
        displaySelectedPhases();
    }, [phase, filteredAggregations, phaseFilters]);

    const displaySelectedPhases = () => {
        const phasesAreFiltered = phaseFilters.some(
            (thisPhase) => thisPhase.selected
        );
        const phaseInFilters =
            phasesAreFiltered &&
            phaseFilters.find((thisPhase) => thisPhase.value == phase.value)
                ?.selected;
        setPhaseFilteredShown(phaseInFilters || !phasesAreFiltered);
        setLoading(false);
    };

    const calculatePhaseCount = () => {
        //will need to be updated once we remove failed/fined and failed/fined pending phases
        //TEC-1861 notion ticket
        if (RentalPhase.AboutToExpire == phase.value) {
            const rentalPhaseKeys = [
                RentalPhase.FinePending,
                RentalPhase.FailedPending,
                RentalPhase.AboutToExpire,
            ];
            let totalAboutToExpireCount = totalUpdatedPhaseCounts(
                filteredAggregations,
                rentalPhaseKeys
            );
            setPhaseCount(totalAboutToExpireCount || 0);
            return;
        }
        if (RentalPhase.Expired == phase.value) {
            const rentalPhaseKeys = [
                RentalPhase.Fined,
                RentalPhase.Failed,
                RentalPhase.Expired,
            ];
            let totalExpiredCount = totalUpdatedPhaseCounts(
                filteredAggregations,
                rentalPhaseKeys
            );
            setPhaseCount(totalExpiredCount || 0);
            return;
        }
        if (failedStatusSelected) {
            const rentalPhaseKeys = [phase.value];
            let totalFailedExpiredCount = totalUpdatedPhaseCounts(
                filteredAggregations,
                rentalPhaseKeys
            );
            setPhaseCount(totalFailedExpiredCount || 0);
            return;
        } else {
            let phaseDocCount =
                (filteredAggregations &&
                    filteredAggregations[0].subtotals.find(
                        (sub) => sub.key == phase.value
                    )) ||
                {};
            setPhaseCount(phaseDocCount.docCount || 0);
            return;
        }
    };

    const showCorrectPhaseLabel = () => {
        if (phase.value == RentalPhase.FinedAutomatic) {
            return "Fined (automatic)";
        }
        if (phase.value == RentalPhase.FinedManual) {
            return "Fined (manual)";
        } else {
            return phase.label;
        }
    };

    return (
        <RentalStatusWrapper
            paddingLeft={spacing.xsmall}
            paddingTop={spacing.tiny}
        >
            <Loadable dataLoaded={!loading}>
                <PhaseLabelIconDiv>
                    <PhaseLabelWrapper>
                        <StyledPhaseText
                            type="label"
                            size="small"
                            color={colors.EXHAUST}
                            opacity={filterDisplay(
                                phaseFilteredShown,
                                "100%",
                                "20%"
                            )}
                        >
                            {showCorrectPhaseLabel()}
                        </StyledPhaseText>
                    </PhaseLabelWrapper>
                    <StatusText
                        type="header"
                        size="medium"
                        color={colors.ROCK_BOTTOM}
                        opacity={filterDisplay(
                            phaseFilteredShown,
                            "100%",
                            "20%"
                        )}
                    >
                        {filterDisplay(
                            phaseFilteredShown,
                            formatCount(phaseCount),
                            "—"
                        )}
                    </StatusText>
                </PhaseLabelIconDiv>
            </Loadable>
        </RentalStatusWrapper>
    );
}
