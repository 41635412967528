// react
import React from "react";

// style
import { TEXT_STYLES } from "../../styling";

// component
import Text from "../Text";

type Props = {
    count: number;
    type?: keyof typeof TEXT_STYLES;
    size?: keyof typeof TEXT_STYLES.body &
        keyof typeof TEXT_STYLES.header &
        keyof typeof TEXT_STYLES.other &
        keyof typeof TEXT_STYLES.link;
    style?: React.CSSProperties | null;
    children?: JSX.Element | string | string[] | null;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    color?: string;
};

export default function TextWithCounter({
    count,
    type,
    size,
    style = {},
    children = null,
    onClick,
    className,
    color,
}: Props) {
    return (
        <Text
            className={className}
            onClick={onClick && onClick}
            style={style}
            type={type}
            size={size}
            color={color}
        >
            {children} ({count})
        </Text>
    );
}
