import {
    Sidebar,
    Menu,
    MenuItem,
    useProSidebar,
    MenuItemStyles,
    SubMenu,
    menuClasses,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import { ReactComponent as Overview } from "../../assets/vectors/Overview.svg";
import { ReactComponent as Pulse } from "../../assets/vectors/Pulse.svg";
import { ReactComponent as Loops } from "../../assets/vectors/LoopsIcon.svg";
import { ReactComponent as Consumer } from "../../assets/vectors/Consumer.svg";
import { ReactComponent as Settings } from "../../assets/vectors/Settings.svg";
import { ReactComponent as Assets } from "../../assets/vectors/Assets.svg";
import { SidebarHeader } from "./SidebarHeader";
import { BrokenSidebarFooter, SidebarFooter } from "./SidebarFooter";
import { SIDEBAR } from "../../constants/sidebar";

export default function MobileSidebar() {
    const { collapsed, broken, toggleSidebar, toggled } = useProSidebar();
    const { colors, textStyles, spacing, isDesktop } = useTheme();
    const menuItemStyles = {
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: 400,
        fontFamily: "poppins",
        cursor: "pointer",
    };

    const location = useLocation();
    return (
        <div
            style={{
                backgroundColor: colors.DEEP_BLUE_SEA,
                width: "84px",
                zIndex: toggled ? 2 : 333,
            }}
            onClick={() => {
                toggleSidebar();
            }}
        >
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                }}
            >
                <SidebarHeader
                    style={{ paddingBottom: "24px", paddingTop: "16px" }}
                />
                <div style={{ flex: 1 }}>
                    <Menu
                        menuItemStyles={{
                            icon: {
                                [`&.${menuClasses.active}`]: {
                                    borderRadius: "50%",
                                    border: "1px solid",
                                },
                            },
                            button: ({ level, active, disabled }) => {
                                const menuStyle = {
                                    cursor: "pointer",
                                    color: active
                                        ? colors.LIME_SQUEEZE
                                        : colors.SUMMER_STORM,
                                    backgroundColor: colors.DEEP_BLUE_SEA,
                                    "&:hover": {
                                        backgroundColor: colors.DEEP_BLUE_SEA,
                                        color: colors.BLANK_SLATE,
                                    },
                                };
                                if (level === 0) {
                                    return menuStyle;
                                } else if (level === 1) {
                                    return {
                                        paddingLeft: collapsed ? 20 : 55,
                                        textDecoration: active
                                            ? "underline"
                                            : "none",
                                        ...menuStyle,
                                    };
                                }
                            },
                        }}
                    >
                        <MenuItem
                            rootStyles={menuItemStyles}
                            active={location.pathname.includes("overview")}
                            icon={<Overview />}
                        />
                        <MenuItem
                            active={location.pathname.includes("activity")}
                            rootStyles={menuItemStyles}
                            icon={<Pulse />}
                        />

                        <MenuItem
                            active={location.pathname.includes("assets")}
                            rootStyles={menuItemStyles}
                            icon={<Assets />}
                        />
                        <MenuItem
                            active={location.pathname.includes("consumers")}
                            rootStyles={menuItemStyles}
                            icon={<Consumer />}
                        />
                        <MenuItem
                            active={
                                location.pathname.includes("locations") ||
                                location.pathname.includes("scan-app")
                            }
                            rootStyles={menuItemStyles}
                            icon={<Settings />}
                        />
                    </Menu>
                </div>
                <BrokenSidebarFooter />
            </div>
        </div>
    );
}
