import React from "react";
import DeprecatedText from "./DeprecatedText";
import styled, { css, useTheme } from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";

const titles = {
    ASSET_TYPES: "Asset Types",
    ASSET_TYPES_DETAILS: "Back to all asset types",
    CONSUMERS: "Consumers",
    LOCATIONS: "Locations",
    LOOP_LIST: "Loop List",
    ACTIVITY: "Activity",
    OVERVIEW: "Overview",
    SCAN_APP_ACCOUNTS: "ScanApp Accounts",
    RENTALS: "Rentals",
};

const StyledScrollView = styled.div`
    background-color: ${({ theme }) => theme.colors.SMOG};
    display: flex;
    flex-direction: column;
    position: relative;
    padding: ${({ theme }) => theme.spacing.xlarge}px
        ${({ theme: { isMobile } }) =>
            isMobile &&
            css`
                margin-top: 72px;
            `};
    min-height: -webkit-fill-available;
`;

const ScreenHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
    backgroundcolor: "pink";
`;

const HeaderLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const HeaderRight = styled.div``;

const Body = styled.div`
    padding-bottom: 120px;
`;

const Title = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const Counter = styled.div`
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.small}px;
    margin-left: ${({ theme }) => theme.spacing.small}px;
`;

const CounterValue = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

type Props = {
    children?: JSX.Element | string | string[];
    counter: string;
    shouldShowHeader: boolean;
    shouldShowCounter?: boolean;
    screenName?: JSX.Element | string;
    headerRight?: JSX.Element;
    title: JSX.Element | string;
};

export default function ScreenViewTable({
    className,
    children,
    counter,
    screenName,
    headerRight,
    shouldShowHeader,
    shouldShowCounter,
    style,
    title: titleFromProps,
}: Props) {
    const defaultTitle = titles[screenName] || null;
    const { isMobile } = useTheme();
    return (
        <div>
            {shouldShowHeader && !isMobile ? (
                <ScreenHeader>
                    <HeaderLeft>
                        {defaultTitle && !titleFromProps ? (
                            <Title type="header" size="medium">
                                {defaultTitle}
                            </Title>
                        ) : null}
                        {titleFromProps}
                        {shouldShowCounter ? (
                            <Counter>
                                <CounterValue type="body" size="medium">
                                    {counter}
                                </CounterValue>
                            </Counter>
                        ) : null}
                    </HeaderLeft>
                    <HeaderRight>{headerRight}</HeaderRight>
                </ScreenHeader>
            ) : null}
            <Body>{children}</Body>
        </div>
    );
}

ScreenViewTable.ScreenHeader = ScreenHeader;

ScreenViewTable.defaultProps = {
    counter: "0",
    screenName: null,
    shouldShowHeader: true,
    shouldShowCounter: true,
    headerRight: null,
    title: null,
};
