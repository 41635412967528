//styles
import styled, { useTheme } from "../../../styling/styled-components";

//images
import { ReactComponent as GreyRental } from "../../../assets/vectors/GreyRental.svg";
import { ReactComponent as Complete } from "../../../assets/vectors/Complete.svg";
import { ReactComponent as ExpiredTimer } from "../../../assets/vectors/ExpiredTimer.svg";
import { ReactComponent as Canceled } from "../../../assets/vectors/Canceled.svg";

const StatusDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    right: 20px;
    top: -24px;
    height: 30px;
`;

export default function StatusIcon({ rental }) {
    const { spacing } = useTheme();

    const Icon = () => {
        if (rental.status == "IN_PROGRESS") {
            return (
                <GreyRental
                    style={{
                        width: 24,
                        height: 24,
                        paddingTop: spacing.xsmall,
                    }}
                />
            );
        } else if (rental.status == "COMPLETED") {
            return (
                <StatusDiv>
                    <Complete style={{ width: 80, height: 80 }}></Complete>
                </StatusDiv>
            );
        } else if (rental.status == "EXPIRED") {
            return (
                <StatusDiv>
                    <ExpiredTimer
                        style={{ width: 80, height: 80 }}
                    ></ExpiredTimer>
                </StatusDiv>
            );
        } else if (
            rental.status == "CANCELED" ||
            rental.status == "CANCELED_WITH_REFUND"
        ) {
            return (
                <StatusDiv>
                    <Canceled style={{ width: 80, height: 80 }}></Canceled>{" "}
                </StatusDiv>
            );
        }
    };

    return <Icon></Icon>;
}
