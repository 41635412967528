//react
import { useEffect } from "react";

//api
import { observation } from "../../../graphql/queries";
import { useQuery } from "../../../graphql/hooks/query";
import { ObservationTag } from "../../../API";

//components
import Overlay from "../../common/overlay/Overlay";
import Text from "../../common/Text";
import PageNotFound from "../../overlay/PageNotFound";
import ObservationTagPill from "./ObservationTagPill";
import ObservationImages from "./ObservationImages";

//styling
import styled from "../../../styling/styled-components";

//utils
import { parseISO, format } from "date-fns";
import { graphErrorDetails } from "../../../utils/helpers";
import { localizeWeightObservation } from "../helpers";
import { checkCostCurrencyAndDisplayDollars } from "../../../utils/streamline/checkCostCurrencyAndDisplayDollars";

const STRINGS = {
    LABEL_PENDING: "Label pending",
    CAPTURED: (date: string) =>
        `Captured on ${format(parseISO(date), "MM/dd/yy")} at ${format(
            parseISO(date),
            "h:mm aaaaa'm'"
        )} `,
    WEIGHT: "WEIGHT",
    NET_WEIGHT: "Weight (net)",
    VALUE: "Value",
    PAN_SIZE: "Pan size",
    GROSS_WEIGHT: "Gross weight",
    DETAILS: "DETAILS",
    DATE_LABELED: "Date labeled",
    LABELED_AT: (date: string) =>
        date ? format(parseISO(date), "MM/dd/yy h:mm aaaaa'm'") : "-",
    ITEM_ID: "Menu item ID / Ingredient ID",
    OID: "Observation ID",
    THING: "Unit",
};

const Container = styled.div`
    padding: 0px 36px 36px 36px;
    overflow: clip;
`;

const Headers = styled.div`
    margin-bottom: 30px;
`;

const HeaderText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const LocationText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    margin-top: 6px;
`;

const CapturedText = styled(Text)`
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

const TagsWrapper = styled.div`
    margin-top: 6px;
`;

const SectionHeaderText = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
    padding-bottom: 6px;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.MARINE_LAYER};
`;

const SectionRow = styled.div`
    display: flex;
    flex-direction: row;
    padding: 18px 0px 18px 0px;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.MARINE_LAYER};
`;

const Section = styled.div`
    margin-bottom: 30px;
`;

const SectionExtraDivider = styled.div`
    height: 18px;
`;

const Cell = styled.div`
    width: 216px;
`;

const CellHeaderText = styled(Text)`
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

const CellValueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

type TextCellProps = {
    header: string;
    value: string;
};

const TextCell = ({ header, value }: TextCellProps) => {
    return (
        <Cell>
            <CellHeaderText type="body" size="medium">
                {header}
            </CellHeaderText>
            <CellValueText type="label" size="medium">
                {value}
            </CellValueText>
        </Cell>
    );
};

type Props = {
    observationId: string;
    onClose: () => void;
    className?: string;
};

export default function ObservationOverlay({
    observationId,
    onClose,
    className,
}: Props) {
    const [getObservation, data, isLoading, errors] = useQuery(observation);

    useEffect(() => {
        if (observationId) {
            getObservation({ id: observationId });
        }
    }, [observationId]);

    const [observationDNE] = graphErrorDetails(
        errors?.errors,
        "object-does-not-exist"
    );

    if (!observationId) {
        return <></>;
    }
    return (
        <Overlay
            scroll={"scroll"}
            onClick={onClose}
            width={440}
            onClickedOutside={onClose}
            className={className}
        >
            {observationDNE ? (
                <PageNotFound />
            ) : (
                data && (
                    <Container>
                        <Headers>
                            <HeaderText type="header" size="large">
                                {data?.bestLabel?.name || STRINGS.LABEL_PENDING}
                            </HeaderText>
                            <LocationText type="header" size="small">
                                {data?.capturedBy?.location?.fullName}
                            </LocationText>
                            <CapturedText type="header" size="small">
                                {STRINGS.CAPTURED(data?.timestamp)}
                            </CapturedText>
                            <TagsWrapper>
                                {(data?.tags || []).some(
                                    (tag: ObservationTag) =>
                                        tag === ObservationTag.Donation
                                ) && (
                                    <ObservationTagPill
                                        label={ObservationTag.Donation}
                                    />
                                )}
                            </TagsWrapper>
                        </Headers>
                        <Section>
                            <SectionHeaderText type="label" size="small">
                                {STRINGS.WEIGHT}
                            </SectionHeaderText>
                            <SectionRow>
                                <TextCell
                                    header={STRINGS.NET_WEIGHT}
                                    value={localizeWeightObservation(
                                        data?.netWeight
                                    )}
                                />
                                {/*TODO: support currency conversion*/}
                                <TextCell
                                    header={STRINGS.VALUE}
                                    value={
                                        data?.value?.value >= 0
                                            ? checkCostCurrencyAndDisplayDollars(
                                                  data?.value?.value,
                                                  data?.value?.costCurrency
                                              )
                                            : "-"
                                    }
                                />
                            </SectionRow>
                            <SectionRow>
                                <TextCell
                                    header={STRINGS.PAN_SIZE}
                                    value={data?.container?.name || "Default"}
                                />
                                <TextCell
                                    header={STRINGS.GROSS_WEIGHT}
                                    value={localizeWeightObservation(
                                        data?.grossWeight
                                    )}
                                />
                            </SectionRow>
                        </Section>
                        <SectionExtraDivider />
                        <Section>
                            <SectionHeaderText type="label" size="small">
                                {STRINGS.DETAILS}
                            </SectionHeaderText>
                            <SectionRow>
                                <TextCell
                                    header={STRINGS.DATE_LABELED}
                                    value={STRINGS.LABELED_AT(
                                        data?.bestLabel?.updatedAt
                                    )}
                                />
                                <TextCell
                                    header={STRINGS.ITEM_ID}
                                    value={data?.bestLabel?.id || "-"}
                                />
                            </SectionRow>
                            <SectionRow>
                                <TextCell
                                    header={STRINGS.OID}
                                    value={data.id}
                                />
                                <TextCell
                                    header={STRINGS.THING}
                                    value={data?.capturedBy?.iotThingName}
                                />
                            </SectionRow>
                        </Section>
                        <ObservationImages
                            images={data?.images || []}
                            observationId={data.id}
                        />
                    </Container>
                )
            )}
        </Overlay>
    );
}
