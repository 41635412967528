//style
import styled from "../../../styling/styled-components";

//component
import Text from "../../common/Text";

const HeaderText = styled(Text)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
    position: relative;
    width: 100%;
`;

type Props = {
    children?: JSX.Element | string | string[] | null;
    color: string;
};

export default function SubHeader({ children, color }: Props) {
    return (
        <HeaderText type="header" size="smallest" color={color}>
            {children}
        </HeaderText>
    );
}
