import { toast } from "react-toastify";
import { ReactComponent as ToastSuccess } from "../assets/vectors/ToastSuccess.svg";
import { ReactComponent as ToastError } from "../assets/vectors/ToastError.svg";

export const toastSuccess = (message: string) =>
    toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        theme: "light",
        icon: <ToastSuccess />,
    });

export const toastError = (message: string) =>
    toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        theme: "light",
        icon: <ToastError />,
    });
