import React, { useEffect } from "react";
import DeprecatedText from "../common/DeprecatedText";
import styled, { useTheme } from "styled-components/macro";
import { parseISO, format } from "date-fns";
import { useReturnRateStatistics } from "../../hooks/useReturnRateStatistics";
import { GOOD_PERCENTAGE_BREAKPOINT } from "../../utils/constants";
import { findTextStyles } from "../../styling/styled-components";
import { RETURN_RATE_CARDS } from "../../constants/assets";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-left: ${({ theme }) => theme.spacing.medium}px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding: ${({ theme }) => theme.spacing.medium}px;
    height: 247px;
    justify-content: space-between;
`;

const CardsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Header = styled.div``;

const Footer = styled.div``;

const MarineText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.MARINE_LAYER};
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const Card = styled.div`
    flex: 1;
    text-align: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.xlarge}px 0;
`;

const Divider = styled.div`
    padding: 0 ${({ theme }) => theme.spacing.medium}px;
    margin: ${({ theme }) => theme.spacing.xlarge}px 0;
    border-right: 1px solid ${({ theme }) => theme.colors.SUMMER_STORM};
`;

const Chart = styled.div`
    align-self: center;
`;

const CardHeader = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const LineRateText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    padding-bottom: ${({ theme }) => theme.spacing.tiny}px;
    color: ${({ theme, percentage }) =>
        percentage
            ? percentage >= GOOD_PERCENTAGE_BREAKPOINT
                ? theme.colors.GREEN_2
                : theme.colors.DEEP_BLUE_SEA
            : theme.colors.MARINE_LAYER};
`;

const AshText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ASH};
`;

const UpdatedText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
`;

type Props = { assetTypeId: string };

export default function ReturnRateCards({ assetTypeId, style }: Props) {
    const { isDesktop } = useTheme();

    const [
        queryReturnRates,
        queryReturnRatesData,
        lastUpdated,
        queryReturnRatesLoading,
        queryReturnRatesErrors,
    ] = useReturnRateStatistics();

    useEffect(() => {
        queryReturnRates(assetTypeId);
    }, [assetTypeId]);

    const renderRateCard = (item) => {
        const { percentage, loopsText, rateText, loopDurationDays, key } = item;
        return (
            <Card key={key}>
                <Chart>
                    <LineRateText
                        percentage={percentage}
                        type="header"
                        size="large"
                    >
                        {percentage
                            ? `${(percentage * 100).toFixed(0)}%`
                            : "N/A"}
                    </LineRateText>
                </Chart>
                <CardHeader>
                    <AshText type="body" size="medium">
                        {rateText && rateText.toUpperCase()}
                    </AshText>
                    {isDesktop && (
                        <MarineText type="body" size="smallest">
                            {loopsText}
                        </MarineText>
                    )}
                </CardHeader>
            </Card>
        );
    };

    return queryReturnRatesData.length > 0 ? (
        <Container style={style}>
            <Header>
                <AshText type="label" size="small">
                    {RETURN_RATE_CARDS.HEADER}
                </AshText>
            </Header>
            <CardsWrapper>
                {renderRateCard(queryReturnRatesData[1])}
                <Divider />
                {renderRateCard(queryReturnRatesData[2])}
                <Divider />
                {renderRateCard(queryReturnRatesData[3])}
            </CardsWrapper>
            <Footer>
                <UpdatedText type="body" size="tiny">
                    {RETURN_RATE_CARDS.LAST_UPDATED(lastUpdated)}
                </UpdatedText>
            </Footer>
        </Container>
    ) : null;
}

ReturnRateCards.defaultProps = {};
