import { Currency } from "../../API";
import { displayDollars } from "../helpers";

type Props = {
    cents: number;
    costCurrency: string;
};

//TODO: update when we want to support different currency types
export function checkCostCurrencyAndDisplayDollars(cents, costCurrency) {
    if (costCurrency == Currency.Usd) {
        return displayDollars(cents);
    } else {
        console.error("Error with costCurrency (not USD)");
    }
}
