//style
import styled from "../../../styling/styled-components";

//components
import Button from "../../common/Button";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ContinueButton = styled(Button)`
    display: flex;
    width: 207px;
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-shrink: 0;
    height: 44px;
`;

export function NextButton({ children, type, onClick, label, status, style }) {
    return (
        <Container>
            <ContinueButton
                type={type}
                onClick={onClick}
                label={label}
                status={status}
                style={style}
            >
                {children}
            </ContinueButton>
        </Container>
    );
}
