//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";

//constants
import { MISSED_SCAN_SUMMARY } from "../../../constants/dashboard";

//utils
import { checkColorCardVariation } from "../../../utils/chart/checkColorCardVariation";

const AdditionalInfoText = styled(Text)`
padding-top: ${({ theme }) => theme.spacing.xsmall}px;'
  
`;

export default function StatsDescription({ missedScanRate }: Props) {
    const { colors } = useTheme();
    let cardColor = checkColorCardVariation(missedScanRate);

    let textColor = cardColor == "YELLOW" ? colors.YELLOW_1 : colors.RED_2;
    let textDescription =
        cardColor == "YELLOW"
            ? MISSED_SCAN_SUMMARY.IMPROVEMENT_DETAILS
            : MISSED_SCAN_SUMMARY.ATTENTION_DETAILS;

    const StaticDescriptionDetails = () => {
        if (cardColor == "GREEN" || !cardColor) {
            return <></>;
        } else {
            return (
                <AdditionalInfoText type="label" size="small" color={textColor}>
                    {textDescription}
                </AdditionalInfoText>
            );
        }
    };

    return (
        <div>
            <Text type="body" size="medium" color={colors.EXHAUST}>
                {MISSED_SCAN_SUMMARY.DESCRIPTION}
            </Text>
            <StaticDescriptionDetails />
        </div>
    );
}
