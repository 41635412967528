export function colorChange(statusBool, colors) {
    if (!statusBool) {
        return colors.BLANK_SLATE;
    } else return colors.SEAGLASS;
}

export function fontChange(statusBool, colors) {
    if (!statusBool) {
        return colors.EXHAUST;
    } else return colors.ROCK_BOTTOM;
}

export function fontChangeDate(statusBool, colors) {
    if (!statusBool) {
        return colors.EXHAUST;
    } else return colors.ASH;
}

export function buttonFontColor(action, colors) {
    if (action == "confirm") {
        return colors.BLANK_SLATE;
    } else return colors.WAVE_STORM;
}

export function removeDuplicateIds(array) {
    const uniqueIds = {};
    return array.filter((item) => {
        if (!uniqueIds[item.id]) {
            uniqueIds[item.id] = true;
            return true;
        }
        return false;
    });
}

export function findTriggerTime(array, phase, phase2 = "", phase3 = "") {
    let date = "";
    array.map((item, index) => {
        {
            if (
                item.rentalPhase == phase ||
                item.rentalPhase == phase2 ||
                item.rentalPhase == phase3
            ) {
                date = item.triggerAt;
            }
        }
    });
    return date;
}
