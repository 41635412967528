// react
import React from "react";

// style
import { theme } from "../../styling/styled-components";

// image
import { ReactComponent as BlueDottedCircleIcon } from "../../assets/vectors/BlueDottedCircleWhiteBorder.svg";
import { ReactComponent as GreenCheckIcon } from "../../assets/vectors/GreenCheckWhiteBorder.svg";
import { ReactComponent as GreyXIcon } from "../../assets/vectors/GreyXWhiteBorder.svg";
import { ReactComponent as RedHourglassIcon } from "../../assets/vectors/RedHourglassWhiteBorder.svg";
import { ReactComponent as ForgivenIcon } from "../../assets/vectors/ForgivenIcon.svg";
import { ReactComponent as WhiteXRefundIcon } from "../../assets/vectors/WhiteXRefundIcon.svg";

const shadowStyle = {
    borderRadius: "50%",
    boxShadow: theme.shadowStyle.containerDropShadow,
};

const shadowStyleSmall = {
    borderRadius: "50%",
    boxShadow: theme.shadowStyle.containerDropShadow,
    height: 24,
    width: 24,
};

export function BlueDottedCircle({}) {
    return <BlueDottedCircleIcon style={shadowStyle} />;
}

export function GreenCheck({}) {
    return <GreenCheckIcon style={shadowStyle} />;
}

export function GreyX({}) {
    return <GreyXIcon style={shadowStyle} />;
}

export function RedHourglass({}) {
    return <RedHourglassIcon style={shadowStyle} />;
}

export function ForgivenHeart({}) {
    return <ForgivenIcon style={shadowStyle} />;
}

export function GreyWhiteX({}) {
    return <WhiteXRefundIcon style={shadowStyle} />;
}

export function BlueDottedCircleSmall({}) {
    return <BlueDottedCircleIcon style={shadowStyleSmall} />;
}

export function GreenCheckSmall({}) {
    return <GreenCheckIcon style={shadowStyleSmall} />;
}

export function GreyXSmall({}) {
    return <GreyXIcon style={shadowStyleSmall} />;
}

export function RedHourglassSmall({}) {
    return <RedHourglassIcon style={shadowStyleSmall} />;
}

export function ForgivenHeartSmall({}) {
    return <ForgivenIcon style={shadowStyleSmall} />;
}

export function GreyWhiteXSmall({}) {
    return <WhiteXRefundIcon style={shadowStyleSmall} />;
}
