//style
import styled from "../../styling/styled-components";

//components
import RentalStatusCard from "./RentalStatusCard";
import StatusIcon from "./StatusIcon";
import Displayable from "../common/Displayable";

//images
import { ReactComponent as StatusLine } from "../../assets/vectors/StatusLine.svg";

//constants
import { STATUS_LIST } from "../../constants/statusList";

//types
import {
    FilteredAggregations,
    StatusItem,
    RentalVisualDetails,
} from "./common/RentalVisualTypes";

const CardDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const StatusCardsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
`;

const LineDiv = styled.div`
    position: relative;
    right: ${({ theme }) => theme.spacing.medium}px;
`;

type Props = {
    filteredAggregations: FilteredAggregations[];
    statusFilters: StatusItem[];
    visualDetails: RentalVisualDetails;
};

export default function StatusCardStatusView({
    filteredAggregations,
    statusFilters,
    visualDetails,
}: Props) {
    const showSelectedStatusDetailsStatusView = (item) => {
        const statusAreFiltered = statusFilters.some(
            (thisStatus) => thisStatus.selected
        );
        const statusInFilters =
            statusAreFiltered &&
            statusFilters.find((thisStatus) => thisStatus.value == item.key)
                ?.selected;
        item.displayStatus = statusInFilters || !statusAreFiltered;
        item.displayPercentage = true;
        item.totalVisualWidth = visualDetails.visualWidth;
    };

    return (
        <>
            {STATUS_LIST.map((item, index) => {
                showSelectedStatusDetailsStatusView(item);
                return (
                    <StatusCardsWrapper>
                        <Displayable displayPrimaryComponent={index !== 0}>
                            <LineDiv>
                                <StatusLine></StatusLine>
                            </LineDiv>
                        </Displayable>
                        <CardDiv key={index}>
                            <RentalStatusCard
                                filteredAggregations={filteredAggregations}
                                totalDocCount={visualDetails.totalDocCount}
                                status={item}
                            ></RentalStatusCard>
                            <StatusIcon
                                filteredAggregations={filteredAggregations}
                                statusItem={item}
                                statusFilters={statusFilters}
                            ></StatusIcon>
                        </CardDiv>
                    </StatusCardsWrapper>
                );
            })}
        </>
    );
}
