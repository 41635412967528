import React from "react";

//components
import Text from "./Text";
import Line from "../common/Line";

//styles
import styled, { useTheme } from "../../styling/styled-components";

const SecondaryNavDiv = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

const SecondaryNavHeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
`;

const TabDiv = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xlarge}px;
    flex: 1 0 0;
    cursor: pointer;
`;

const DescriptionDiv = styled.div`
    flex: 1 0 0;
`;

//TODO: Refactor secondary nav common component [TEC-1974]

export default function SecondaryNav({
    onClick,
    tabOptions,
    description,
    selectedTab,
}) {
    const { colors } = useTheme();
    return (
        <SecondaryNavDiv>
            <SecondaryNavHeaderDiv>
                <Text color={colors.EXHAUST} type="label" size="small"></Text>
                <TabDiv>
                    {tabOptions.map((item, index) => {
                        return (
                            <Text
                                type="header"
                                size="medium"
                                color={
                                    selectedTab == index
                                        ? colors.DEEP_BLUE_SEA
                                        : colors.EXHAUST
                                }
                                onClick={() => onClick(index)}
                                style={{
                                    height: 30,
                                    borderBottom:
                                        selectedTab == index
                                            ? "3px solid #162350"
                                            : "",
                                }}
                            >
                                {item}
                            </Text>
                        );
                    })}
                </TabDiv>
                <Line />
            </SecondaryNavHeaderDiv>
            <DescriptionDiv>
                <Text
                    type="body"
                    size="medium"
                    color={colors.ASH}
                    style={{ position: "relative", top: -36 }}
                >
                    {description}
                </Text>
            </DescriptionDiv>
        </SecondaryNavDiv>
    );
}
