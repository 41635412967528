//react
import React, { useState } from "react";

//style
import styled, { useTheme } from "styled-components/macro";

//components
import Text from "./Text";

type Props = {
    error: string;
    hintText: string;
    hintStyle: React.CSSProperties;
    onBlur?: () => void;
    onFocus?: () => void;
    style?: React.CSSProperties;
    rightIcon: React.ReactNode;
    type: string;
    width: string;
    maxLength?: number;
    placeHolder?: string;
    value: string;
    defaultValue: string;
    optional?: boolean;
    hasError?: boolean;
    infoText?: string;
    infoStyle?: React.CSSProperties;
    showCounter?: boolean;
};

const NUMBER_CHARACTERS_NAME = "characters remaining";

const Container = styled.div`
    width: fit-content;
`;

const WrapperTextInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0.5px solid ${({ theme }) => theme.colors.MARINE_LAYER};
    border-radius: 4px;
    width: ${(props) =>
        props.width === "large"
            ? "514"
            : props.width == "short"
            ? "386"
            : "444"}px;
    ${(props) =>
        props.status === "active" &&
        `border-color: ${props.theme.colors.WAVE_STORM};`};
    ${(props) =>
        props.hasError && `border-color: ${props.theme.colors.RED_2};`};
`;

const StyledTextInput = styled.input`
    flex: 1;
    font-family: poppins;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    letter-spacing: 0.01em;
    padding: ${({ theme }) => theme.spacing.xsmall}px;
    outline-style: none;
    border: none;
    background: none;
    height: 24px;
    ${(props) =>
        props.defaultValue &&
        `color: ${({ theme }) => theme.colors.EXHAUST};;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.13px;`};
    ::placeholder {
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.SUMMER_STORM};
    }
    ${(props) => props.hasError && `color: ${props.theme.colors.RED_2};`};
`;

const Hint = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
    ${(props) => props.hintStyle};
`;

const SubHint = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
    ${(props) => props.hintStyle};
`;

const ErrorMessage = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const LabelNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: ${(props) =>
        props.width === "large"
            ? "514"
            : props.width == "short"
            ? "386"
            : "444"}px;
`;

const InfoText = styled(Text)`
    height: ${({ theme }) => theme.spacing.small}px;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    width: ${(props) =>
        props.width === "large"
            ? "514"
            : props.width == "short"
            ? "386"
            : "444"}px;
`;

const OptionalText = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
    margin-left: ${({ theme }) => theme.spacing.huge}px;
    width: ${(props) =>
        props.width === "large"
            ? "514"
            : props.width == "short"
            ? "386"
            : "444"}px;
`;

const HintContainer = styled.div`
    display: inherit;
`;

export default function TextInput({
    error,
    hintText = "",
    hintStyle = {},
    subHintText = "",
    subHintStyle = {},
    onBlur,
    onFocus,
    rightIcon = null,
    style,
    infoStyle = {},
    width = "large",
    maxLength,
    value,
    optional = false,
    hasError = false,
    infoText,
    placeholder,
    defaultValue,
    showCounter = true,
    ...props
}: Props) {
    const [status, setStatus] = useState<string>("default");
    const { colors, textStyles } = useTheme();

    let textColor = hasError ? colors.RED_2 : colors.ASH;
    let subTitleTextColor = hasError ? colors.RED_2 : colors.EXHAUST;

    const underlineColor =
        status === "active" ? colors.WAVE_STORM : colors.MARINE_LAYER;

    const onBlurTextInput = () => {
        setStatus("default");
        onBlur && onBlur();
    };

    const onFocusTextInput = () => {
        setStatus("active");
        onFocus && onFocus();
    };
    return (
        <Container style={style}>
            <WrapperTextInput
                width={width}
                status={status}
                hasError={hasError && error}
            >
                <StyledTextInput
                    onBlur={onBlurTextInput}
                    onFocus={onFocusTextInput}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                    maxLength={maxLength}
                    {...props}
                />
                {rightIcon}
            </WrapperTextInput>
            <LabelNameWrapper>
                <HintContainer>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Hint
                            hasError={hasError && error}
                            hintStyle={hintStyle}
                            type="label"
                            size="medium"
                            color={textColor}
                        >
                            {hintText}
                        </Hint>
                        <SubHint
                            hasError={hasError && error}
                            hintStyle={subHintStyle}
                            type="label"
                            size="medium"
                            color={subTitleTextColor}
                        >
                            {subHintText}
                        </SubHint>
                    </div>
                </HintContainer>
                {infoText && (
                    <InfoText
                        style={infoStyle}
                        type="label"
                        size="medium"
                        color={textColor}
                    >
                        {infoText}
                    </InfoText>
                )}
                {maxLength && showCounter && width !== "short" && (
                    <OptionalText type="label" size="small" color={textColor}>
                        {!defaultValue
                            ? maxLength - value?.length
                            : maxLength - defaultValue?.length}
                        {"/"}
                        {maxLength} {NUMBER_CHARACTERS_NAME}
                    </OptionalText>
                )}
            </LabelNameWrapper>
            {hasError && error ? (
                <ErrorMessage type="label" size="medium" color={colors.RED_2}>
                    {error}
                </ErrorMessage>
            ) : null}
        </Container>
    );
}
