//components
import Text from "../../common/Text";
import ShimmerCard from "../../common/ShimmerCard";
import Loadable from "../../common/Loadable";

//styles
import styled, { useTheme } from "../../../styling/styled-components";

const FooterDiv = styled.div``;

const UpdatedText = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.medium}px;
`;

export default function Footer({ loading, lastUpdated }) {
    const { colors } = useTheme();

    const StyledShimmerCard = () => {
        return <ShimmerCard width={221} height={18}></ShimmerCard>;
    };

    return (
        <Loadable dataLoaded={!loading} LoadingComponent={StyledShimmerCard}>
            <FooterDiv>
                <UpdatedText type="body" size="small" color={colors.EXHAUST}>
                    {lastUpdated}
                </UpdatedText>
            </FooterDiv>
        </Loadable>
    );
}
