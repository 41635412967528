//images
import { ReactComponent as BlueProgressDot } from "../../../assets/vectors/BlueProgressDot.svg";
import { ReactComponent as WhiteProgressDot } from "../../../assets/vectors/WhiteProgressDot.svg";
import { ReactComponent as GreenCheck } from "../../../assets/vectors/GreenCheck.svg";
import { ReactComponent as CanceledRefundDot } from "../../../assets/vectors/CanceledRefundDot.svg";
import { ReactComponent as ExpiredDot } from "../../../assets/vectors/ExpiredDot.svg";
import { ReactComponent as CanceledDot } from "../../../assets/vectors/CanceledDot.svg";
import { ReactComponent as RedProgressDot } from "../../../assets/vectors/RedProgressDot.svg";
import { ReactComponent as ForgivenSmallHeart } from "../../../assets/vectors/ForgivenSmallHeart.svg";

//enums
import { RentalStatus, RentalPhase } from "../../../API";

//style
import styled from "../../../styling/styled-components";

const IconWrapper = styled.div`
    position: absolute;
    top: 26%;
    z-index: 10;
`;

const ReturnReceivedFailedWrapper = styled.div`
    position: absolute;
    top: 12%;
    z-index: 10;
`;

type Props = {
    status: RentalStatus;
    phase: RentalPhase;
    showRedPhaseDot: boolean;
};

export default function TimelineIcon({
    status,
    phase,
    showRedPhaseDot,
    returnReceivedFailed,
}: Props) {
    const TimelineIcon = () => {
        if (status == RentalStatus.Completed) {
            return (
                <IconWrapper>
                    <GreenCheck />
                </IconWrapper>
            );
        }
        if (status == RentalStatus.CanceledWithRefund) {
            return (
                <IconWrapper>
                    <CanceledRefundDot />
                </IconWrapper>
            );
        }
        if (status == RentalStatus.Canceled) {
            return (
                <IconWrapper>
                    <CanceledDot />
                </IconWrapper>
            );
        }
        if (status == RentalStatus.InProgress || status == "CHECK_FAILED") {
            return (
                <IconWrapper>
                    <BlueProgressDot />
                </IconWrapper>
            );
        }
        if (status == RentalStatus.Forgiven) {
            return (
                <IconWrapper>
                    <ForgivenSmallHeart />
                </IconWrapper>
            );
        }
        if (status == RentalStatus.Expired || status == RentalStatus.Failed) {
            if (returnReceivedFailed && phase !== RentalPhase.FineError) {
                return (
                    <ReturnReceivedFailedWrapper>
                        <ExpiredDot />
                    </ReturnReceivedFailedWrapper>
                );
            }
            if (showRedPhaseDot) {
                return (
                    <IconWrapper>
                        <RedProgressDot />
                    </IconWrapper>
                );
            } else if (!showRedPhaseDot) {
                return (
                    <IconWrapper>
                        <ExpiredDot />
                    </IconWrapper>
                );
            }
        } else {
            return (
                <IconWrapper>
                    <WhiteProgressDot />
                </IconWrapper>
            );
        }
    };
    return <TimelineIcon />;
}
