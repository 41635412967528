//react
import React, { useState, useRef, useEffect } from "react";

//components
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";

//custom chart elements
import { hoverOptions } from "../sharedLineChartElements/hoverOptions";
import { legendOptions } from "../sharedLineChartElements/legendOptions";
import { titleOptions } from "../sharedLineChartElements/titleOptions";
import { bluePointStyles } from "../sharedLineChartElements/bluePointStyles";
import { axisTicksExhaustStyles } from "../sharedLineChartElements/axisTicksExhaustStyles";
import { darkBlueTooltipStyles } from "../sharedLineChartElements/darkBlueTooltipStyles";
import { axisTitles } from "../sharedLineChartElements/axisTitles";

//utils
import { titleCallback } from "../../../utils/chart/titleCallback";
import { escapeHandleKeyDown } from "../../../utils/chart/escapeHandleKeyDown";
import { showTooltip } from "../../../utils/chart/showTooltip";
import { hideTooltip } from "../../../utils/chart/hideTooltip";

//styles
import { useTheme } from "../../../styling/styled-components";

//constants
import { ACTIVE_CONSUMERS } from "../../../constants/dashboard";

type Props = {
    data: number[];
    labels: string[];
    settings?: ChartSettings;
};

type ChartSettings = {
    toolTipLabels?: string[];
};

export default function LineChart({ labels, data, settings }: Props) {
    const chartRef = useRef<ChartJS>(null);
    const [options, setOptions] = useState({});

    const { isTablet } = useTheme();

    const dataSeries = {
        labels,
        datasets: [
            {
                label: settings.toolTipLabels,
                data,
                ...bluePointStyles(),
            },
        ],
    };

    const findLowestDataPoint = () => {
        if (data && data.length > 0) {
            const maxValue = Math.min(...data) - 15;
            return maxValue;
        } else {
            return 0;
        }
    };

    const findHighestDataPoint = () => {
        if (data && data.length > 0) {
            const maxValue = Math.max(...data) + 15;
            return maxValue;
        } else {
            return 0;
        }
    };

    useEffect(() => {
        let tooltip = chartRef.current ? chartRef.current.tooltip : null;
        document.addEventListener("keydown", (event) =>
            escapeHandleKeyDown(event.key, chartRef.current, tooltip)
        );
        return () => {
            document.removeEventListener("keydown", (event) =>
                escapeHandleKeyDown(event.key, chartRef.current, tooltip)
            );
        };
    }, []);

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        setOptions({
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                line: {
                    tension: 0.4,
                },
                point: {
                    radius: (tooltipItem) => {
                        if (isTablet) {
                            return 3;
                        } else {
                            return 5;
                        }
                    },
                },
            },
            scales: {
                y: {
                    grid: {
                        display: true,
                    },
                    ticks: {
                        stepSize:
                            findHighestDataPoint() - findLowestDataPoint(),
                        ...axisTicksExhaustStyles(),
                    },
                    title: {
                        ...axisTitles(ACTIVE_CONSUMERS?.HEADER),
                    },
                    max: findHighestDataPoint(),
                    min: findLowestDataPoint(),
                },
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        callback: (val, index) => {
                            let label;
                            labels?.map((item, ind) => {
                                if (index == ind && item !== "") {
                                    label = item;
                                }
                            });
                            return label;
                        },
                        padding: 0,
                        ...axisTicksExhaustStyles(),
                    },
                },
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => titleCallback(),
                        label: (tooltipItem, data) => {
                            let dataIndex = tooltipItem.dataIndex;
                            let label = "";
                            tooltipItem.dataset.label.map((item, index) => {
                                if (dataIndex == index) {
                                    label = item;
                                } else {
                                    return;
                                }
                            });
                            if (tooltipItem.raw) {
                                return `Total active consumers as of ${label}: ${tooltipItem.raw}`;
                            }
                        },
                    },
                    ...darkBlueTooltipStyles(),
                },
                hover: hoverOptions,
                legend: legendOptions,
                title: titleOptions,
            },
            onClick: (event, chartElements) => {
                let clickedOutsidePoint =
                    !chartElements || chartElements.length == 0;
                if (clickedOutsidePoint) {
                    hideTooltip(chart);
                }
                if (!clickedOutsidePoint) {
                    showTooltip(chart);
                }
            },
        });
        chart.resize();
    }, [data, labels]);

    return <Line ref={chartRef} options={options} data={dataSeries} />;
}
