import { format, parseISO } from "date-fns";
import { ScanAppUserAccountType, ScanAppUserStatus } from "../API";

export const ACCOUNT_TYPE_TOGGLE = {
    GROUP: "Group",
    INDIVIDUAL: "Individual",
};

export const CREATE_SCAN_APP_ACCOUNT = {
    HEADER: "Create new ScanApp account",
    CANCEL: "Cancel",
    SAVE: "Save",
    SAVE_MESSAGE: "Account created",
    ACCOUNT_TYPE: "Account type",
    GROUP_ACCOUNT_CREDENTIALS: "Create account credentials",
    GROUP_ACCOUNT_CREDENTIALS_HELP:
        "Group account passwords can only be changed on the Loop dashboard. As a best practice, group accounts should only be logged into one device at a time.",
    INDIVIDUAL_CREDENTIALS: "Send account invite to:",
    EMAIL_PLACEHOLDER: "johndoe@gmail.com",
    EMAIL_INVALID: "Please enter a valid email.",
    EMAIL: "Email",
    USERNAME: "Username",
    USERNAME_PLACEHOLDER: (slug: string) => `${slug}.cashier1`,
    USERNAME_HELP: (slug: string) =>
        `All group account usernames will start with the prefix: '${slug}'`,
    USERNAME_TAKEN:
        "That username is already taken for your organization. Try another.",
    PASSWORD: "Password",
    PASSWORD_PLACEHOLDER: "Password1!",
    PASSWORD_HELP:
        "Must be at least 8 characters long and include at least 1 capital letter, 1 number, 1 special character (e.g. !#$)",
    PASSWORD_ERROR: "Sorry, that password doesn't meet the requirements.",
    TEMP_PASSWORD_HELP:
        "A temporary password will be sent to this email to be used for the first login. After first login, will be prompted to create a permanent ScanApp password to complete account set-up.",
    INDIVIDUAL_ACCOUNT_TYPE_HELP:
        "Individual accounts should only be accessed and used by a single user. Individual accounts must be associated with an email.",
    GROUP_ACCOUNT_TYPE_HELP:
        "A group account can be shared by one or more users that will be performing the same tasks on the ScanApp. For example, the username 'Cashier1' could be a shared login used by all cashiers at a location.",
};

export const SCAN_APP_ACCOUNTS = {
    CREATE_ACCOUNT: "Create account",
    ACTIVE_ACCOUNTS: "Active accounts",
    ACTIVE_ACCOUNTS_SUBHEADER: (numRemaining: number) =>
        `Your organization's current subscription allows for ${numRemaining} more scan accounts. Contact help@topanga.io to create additional accounts. Please note, you cannot log in to the Loop Dashboard using ScanApp credentials.`,
    INACTIVE_ACCOUNTS: "Inactive accounts",
    INACTIVE_ACCOUNTS_SUBHEADER:
        "Inactive accounts have had no activity for the last 3 months.",
    LIMIT_REACHED: "You've reached your account limit",
    SCAN_APP_ACCOUNTS: "SCAN_APP_ACCOUNTS",
    TOTAL_ACCOUNTS: "ScanApp accounts",
    UNLIMITED_ACCOUNTS_HEADER:
        "Your organization has unlimited scan accounts. Please note, you cannot log in to the Loop Dashboard using ScanApp credentials.",
};

export const SCAN_APP_USER_LOCATION_SETTINGS = {
    LOCATION_SETTINGS_AND_PERMISSIONS: "Location settings & permissions",
    LOCATION_SETTINGS_AND_PERMISSIONS_HELP:
        "Scan activity will be associated with the account’s selected location. Selecting the correct location is important to ensure your loop data is accurate.",
    UPDATE_LOCATION_SETTINGS_AND_PERMISSION_HELP:
        "ScanApp accounts may only have one selected location at a time. Changing the selected location will update the location for this account across all devices immediately. It will not change the location of any data that was collected by this account prior to making the change.",
    LOCATION_PERMISSIONS: "Set scanning location permissions",
    LOCATION_PERMISSIONS_HELP:
        "Select each location the user should be able to access on the ScanApp.",
    ONE_LOCATION: "ScanApp accounts may only be set to one location at a time.",
    SELECTED_LOCATION_HINT: "Select a location",
    SELECTED_LOCATION_LABEL: "Selected location",
    STARTING_LOCATION_LABEL: "Default Scanning Location",
    FIRST_LOGIN:
        "The ScanApp account will be set to this location on first login.",
    CURRENTLY_USED:
        "The location that is currently being used for this ScanApp account.",
    CHANGE_LOCATIONS: "Allow user to change scanning locations",
    CHANGE_LOCATIONS_ON:
        "ON: The user can change their location to any of the locations they have access to in their ScanApp settings",
    CHANGE_LOCATIONS_OFF: "OFF: The user cannot change their location.",
    SELECT_ALL_LOCATIONS: "Select all locations",
    UNSELECT_ALL_LOCATIONS: "Unselect all locations",
    SELECTED_LOCATION: "SELECTED LOCATION",
    STARTING_LOCATION: "STARTING LOCATION",
};

export const SCAN_APP_USER_ROW = {
    USER_TYPE: (userType: ScanAppUserAccountType) =>
        userType == ScanAppUserAccountType.Individual ? "Individual" : "Group",
    ACCOUNT_STATUS: (accountStatus: ScanAppUserStatus) => {
        if (accountStatus == ScanAppUserStatus.Active) {
            return "Active";
        } else if (accountStatus == ScanAppUserStatus.Inactive) {
            return "Inactive";
        } else if (accountStatus == ScanAppUserStatus.Pending) {
            return "Pending";
        }
    },
    LOCATION_ACCESS: (allLocationsEnabled: boolean) =>
        allLocationsEnabled ? "Flexible" : "Fixed",
    CREATED: (date: string) => `Created: ${format(parseISO(date), "MM/dd/yy")}`,
    LAST_ACTIVE: (date: string) =>
        `Last active: ${format(parseISO(date), "MM/dd/yy")}`,
    INVITED: (date: string) =>
        `Invite sent: ${format(parseISO(date), "MM/dd/yy")}`,
    ALL_LOCATIONS: "All locations",
    SOME_LOCATIONS: (num: number) => `${num} locations`,
    DELETE_CONFIRM_TITLE: "Are you sure you want to delete this account?",
    DELETE_CONFIRM_SUBTITLE:
        "All active users will no longer be able to use the ScanApp",
    USER_DELETED: "Account deleted",
};

export const SCAN_APP_USERS_LIST = {
    ERROR: "Error loading data. Please try again.",
    NO_DATA: (isActive: boolean) =>
        isActive
            ? "No Active/Pending ScanApp accounts yet! Tap “Create account” to get started."
            : "No Inactive ScanApp accounts to display.",
    LOAD_MORE: "Load more...",
};

export const UPDATE_SCAN_APP_USER_PASSWORD = {
    HEADER: "Change password",
    PROMPT: "Enter a new password for this account",
    CANCEL: "Cancel",
    SAVE: "Save",
    SAVE_MESSAGE: "Account password updated",
    USERNAME: (username: string) => `Username: ${username}`,
    PASSWORD: "Password",
    PASSWORD_PLACEHOLDER: "Password1!",
    PASSWORD_HELP:
        "Must be at least 8 characters long and include at least 1 capital letter, 1 number, 1   special character (e.g. !#$)",
    PASSWORD_ERROR: "Sorry, that password doesn't meet the requirements.",
    LOGOUT_HELP:
        "Require all active users to log back into this account with the new credentials",
    CANCEL_TITLE:
        "Are you sure you want to leave without completing your changes?",
    CANCEL_SUBTITLE: "Your changes will not be saved",
};

export const UPDATE_SCAN_APP_USER_SETTINGS = {
    HEADER: "Location settings",
    CANCEL: "Cancel",
    SAVE: "Save",
    DONE: "Done",
    SAVE_MESSAGE: "Account settings updated",
    USERNAME: (username: string) => `Username: ${username}`,
    LOGOUT_HELP:
        "Require all active users to log back into this account with the new credentials",
    CANCEL_TITLE:
        "Are you sure you want to leave without completing your changes?",
    CANCEL_SUBTITLE: "Your changes will not be saved",
};
