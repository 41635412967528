export const searchRentalsActivity = /* GraphQL */ `
    query SearchRentals(
        $fromTimestamp: String!
        $toTimestamp: String!
        $consumerId: ID
    ) {
        searchRentals(
            fromTimestamp: $fromTimestamp
            toTimestamp: $toTimestamp
            consumerId: $consumerId
        ) {
            hitAggregations {
                key
                docCount
            }
            totalHits
            rentals {
                rentalId
                status
            }
        }
    }
`;
