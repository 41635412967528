export const checkColorCardVariation = (missedScanRate) => {
    if (missedScanRate <= 8) {
        return "GREEN";
    }
    if (8 < missedScanRate && missedScanRate <= 16) {
        return "YELLOW";
    }
    if (missedScanRate > 16) {
        return "RED";
    }
};
