//components
import Text from "../common/Text";
import HighlightHover from "./HighlightHover";

//images
import { ReactComponent as Cross } from "../../assets/vectors/Cross.svg";
import { ReactComponent as BackChevron } from "../../assets/vectors/BackChevron.svg";

//style
import styled, { useTheme } from "styled-components/macro";

const ChevronWrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    margin-right: ${({ theme }) => theme.spacing.large}px;
`;

const LabelText = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.large - 1}px;
    margin-right: ${({ theme }) => theme.spacing.xsmall}px;
`;

const BackButtonWrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing.medium}px;
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    margin-right: ${({ theme }) => theme.spacing.xsmall}px;
`;

const BackText = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.large + 1}px;
    padding-right: ${({ theme }) => theme.spacing.huge}%;
`;

const Container = styled.div`
    position: sticky;
    top: 0px;
    width: 516px;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    z-index: 200;
`;

const HoverDiv = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

type Props = {
    onClick: () => void;
    onClickLeft: () => void;
    label: string;
    leftlabel: string;
    showLeftLabel: boolean;
};

export default function Close({
    onClick,
    onClickLeft,
    label,
    leftlabel,
    shadow,
    showLeftLabel,
    ...props
}: Props) {
    const { colors } = useTheme();

    return (
        <Container>
            {showLeftLabel ? (
                <>
                    <BackButtonWrapper onClick={onClickLeft}>
                        <HighlightHover>
                            <BackChevron />
                        </HighlightHover>
                    </BackButtonWrapper>

                    <BackText
                        type="label"
                        size="large"
                        color={colors.WAVE_STORM}
                        onClick={onClickLeft}
                    >
                        {leftlabel}
                    </BackText>
                </>
            ) : (
                <></>
            )}

            <LabelText
                onClick={onClick}
                type="label"
                size="large"
                color={colors.WAVE_STORM}
            >
                {label}
            </LabelText>
            <HoverDiv>
                <ChevronWrapper onClick={onClick}>
                    <HighlightHover>
                        <Cross />
                    </HighlightHover>
                </ChevronWrapper>
            </HoverDiv>
        </Container>
    );
}
