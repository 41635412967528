//components
import Button from "../../common/Button";
import Text from "../../common/Text";

//style
import styled, { useTheme } from "../../../styling/styled-components";

//images
import { ReactComponent as DeleteRed } from "../../../assets/vectors/DeleteRed.svg";

const ArchivedButton = styled(Button)`
    border: 1px solid ${({ theme }) => theme.colors.RED_2};
    color: ${({ theme }) => theme.colors.RED_2};
    ${(props) =>
        props.status === "disabled"
            ? `border: 1px solid ${props.theme.colors.RED_2} !important`
            : ""};
    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.RED_3};
    }
`;

const DeleteIcon = styled.div`
    display: flex;
    width: 200px;
    height: ${({ theme }) => theme.spacing.large}px;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

const ButtonText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

export default function ArchiveButton({ onClick, buttonText }) {
    const { colors } = useTheme();
    return (
        <ArchivedButton
            onClick={onClick}
            type="secondary"
            label={
                <DeleteIcon>
                    <div>
                        <DeleteRed></DeleteRed>
                    </div>
                    <ButtonText type="label" size="medium" color={colors.RED_2}>
                        {buttonText}
                    </ButtonText>
                </DeleteIcon>
            }
        ></ArchivedButton>
    );
}
