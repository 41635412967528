import { checkQueryParamsHasPropAndConsumer } from "./checkQueryParamsHasPropAndConsumer";

export const deletePropFromSearchParams = (queryParams, searchParams) => {
    /* If both consumerId and prop are found on the searchParams, the search doesn't work. 
Need to delete the prop from searchParams so that the updated searchParams 
return the correct results */

    if (checkQueryParamsHasPropAndConsumer(queryParams)) {
        searchParams.delete("prop");
    }
    return searchParams;
};
