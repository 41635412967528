//react
import React from "react";

//style
import styled from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import NewLine from "../../common/NewLine";

//styles
import { useTheme } from "../../../styling/styled-components";
import { fadeIn } from "./Animation";

const TitleText = styled(Text)``;

const SubtitleText = styled(Text)`
    position: relative;
`;

const HeaderWrapper = styled.div`
    animation: ${fadeIn} 1s ease-in-out forwards;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

export default function OverviewHeader({ headerText }) {
    const { colors } = useTheme();

    return (
        <HeaderWrapper>
            <TitleText size="xlarge" type="header" color={colors.DEEP_BLUE_SEA}>
                {headerText.title}
            </TitleText>
            <NewLine />
            <SubtitleText size="medium" type="body" color={colors.ASH}>
                {headerText.subtitle}
            </SubtitleText>
        </HeaderWrapper>
    );
}
