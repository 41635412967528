// style
import { useTheme } from "../../../styling/styled-components";

// components
import Card from "../../common/card/Card";
import CardDetails from "./common/CardDetails";
import CardHeader from "./common/CardHeader";

// utils
import { isEmpty } from "../../../utils/helpers";
import { checkBorderColor } from "../../../utils/rentalOverlay/checkBorderColor";

//types
import { RentalProps } from "./common/RentalTypes";

type Props = {
    triggerAt: string;
    rental: RentalProps;
};

export default function CompleteCard({ rental, triggerAt }: Props) {
    const { colors } = useTheme();
    const locationName = rental.resolvingLoop
        ? rental.resolvingLoop.locations.closedAt.fullName
            ? rental.resolvingLoop.locations.closedAt.fullName
            : rental.resolvingLoop.locations.closedAt.name
        : "";

    return (
        <Card
            backgroundColor={colors.GREEN_4}
            borderColor={checkBorderColor(rental.status)}
        >
            <CardHeader header="Rental completed" date={triggerAt}></CardHeader>
            <CardDetails
                paddingLeft="18px"
                linkURL={
                    "/dashboard/activity/assetevents/" +
                    `?prop=${encodeURIComponent(rental.receivedAssetId)}`
                }
                asset={
                    isEmpty(rental.resolvingLoop)
                        ? ""
                        : `A ${rental.rentedAssetTypeName} was
    checked-in at ${locationName}.`
                }
                subText={`Asset ID #${rental.receivedAssetId}`}
            ></CardDetails>
        </Card>
    );
}
