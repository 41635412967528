// style
import styled, { useTheme } from "styled-components/macro";

// components
import Text from "../../common/Text";

// assets
import { ReactComponent as GreenCheckSmall } from "../../../assets/vectors/GreenCheckSmall.svg";

//constants
import { RETURN_RATE_CARDS } from "../../../constants/assets";

//utils
import { checkReturnReceivedStatusLabel } from "../../../utils/activity/checkReturnReceivedStatusLabel";

type Props = {
    rental: integer;
};

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.tiny}px;
`;

export default function AssetReceivedSubheader({ rental }: Props) {
    const { colors } = useTheme();

    const text = checkReturnReceivedStatusLabel(
        rental.createdAt,
        rental.status,
        rental.receivedAsset
    );

    const textType =
        rental.receivedAsset && text == RETURN_RATE_CARDS.RETURN_RECEIVED
            ? "label"
            : "body";
    const textColor =
        rental.receivedAsset && text == RETURN_RATE_CARDS.RETURN_RECEIVED
            ? colors.GREEN_2
            : colors.EXHAUST;

    const Icon = () => {
        if (rental.receivedAsset && text == RETURN_RATE_CARDS.RETURN_RECEIVED) {
            return <GreenCheckSmall />;
        } else {
            return <></>;
        }
    };

    return (
        <Container>
            <Icon />
            <Text type={textType} size="small" color={textColor}>
                {text}
            </Text>
        </Container>
    );
}
