import styled from "../../../styling/styled-components";
import Text from "../../common/Text";

const SMS = styled(Text)`
    padding-top: ${({ paddingTop }) => paddingTop};
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
    white-space: nowrap;
`;

export function SMSText({ children, type, size, color, paddingTop = "8px" }) {
    return (
        <SMS color={color} type={type} size={size} paddingTop={paddingTop}>
            {children}
        </SMS>
    );
}
