//react
import React, { useRef, useEffect, useState } from "react";

//style
import styled, { useTheme } from "styled-components/macro";

//components
import Text from "../common/Text";
import { LocationLine } from "./common/LocationLine";
import HighlightHover from "../common/HighlightHover";

//images
import { ReactComponent as RightArrow } from "../../assets/vectors/RightArrow.svg";

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    padding: ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.medium}px;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.medium}px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    border-radius: 4px;
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
    margin-top: ${({ theme }) => theme.spacing.small}px;
    cursor: pointer;
`;

const CardDiv = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.medium}px;
    flex: 1 0 0;
`;

const HeaderText = styled(Text)`
    flex: 1 0 0;
`;

const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    flex: 1 0 0;
    align-self: stretch;
    cursor: pointer;
    margin-bottom: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const AddressWrapper = styled.div`
    flex: 1 0 0;
    width: 242px;
`;

const ArrowDiv = styled.div`
    margin: auto;
`;
type Props = {
    address?: string;
    googlePlacesId?: number | string;
    nickname?: string;
};

export default function LocationCard({
    className,
    address,
    nickname,
    locations,
    onClick,
}: Props) {
    const { colors } = useTheme();
    const refContainer = useRef();
    const [dimensions, setDimensions] = useState({ height: 0 });

    useEffect(() => {
        if (refContainer.current) {
            setDimensions({
                height: refContainer.current.offsetHeight,
            });
        }
    }, []);

    function Address({ data }) {
        return (
            <AddressWrapper>
                <Text type="body" size="small" color={colors.EXHAUST}>
                    {data ? data?.line1 + " " + data?.line2 : ""}
                </Text>
                <div>
                    <Text type="body" size="small" color={colors.EXHAUST}>
                        {data ? data?.line3 + " " + data?.postalCode : ""}
                    </Text>
                </div>
            </AddressWrapper>
        );
    }

    return (
        <Wrapper className={className} ref={refContainer} onClick={onClick}>
            <CardDiv>
                <LeftWrapper>
                    {nickname ? (
                        <HeaderText
                            type="header"
                            size="small"
                            color={colors.DEEP_BLUE_SEA}
                        >
                            {nickname}
                        </HeaderText>
                    ) : null}
                    <Address data={address} />
                </LeftWrapper>
                <LocationLine height={dimensions.height}></LocationLine>
                <RightWrapper>
                    <Text type="label" size="small" color={colors.EXHAUST}>
                        SCANNING LOCATIONS ({locations.length})
                    </Text>
                    {locations.length > 0 ? (
                        locations.map((item, index) => (
                            <Text
                                type="label"
                                size="small"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {item.name}
                            </Text>
                        ))
                    ) : (
                        <Text type="label" size="small" color={colors.EXHAUST}>
                            To start collecting data on this location, you must
                            add at least one scanning location.
                        </Text>
                    )}
                </RightWrapper>
                <ArrowDiv>
                    <HighlightHover>
                        <RightArrow></RightArrow>
                    </HighlightHover>
                </ArrowDiv>
            </CardDiv>
        </Wrapper>
    );
}
