//react
import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

//components
import ConsumerQR from "./ConsumerQR";
import OverviewSection from "./OverviewSection";
import RentalActivity from "./RentalActivity";
import AssetActivity from "./AssetActivity";
import PageNotFound from "./PageNotFound";
import HighlightHover from "../common/HighlightHover";
import Overlay from "../common/overlay/Overlay";
import Header from "../common/overlay/Header";
import SubHeader from "../common/overlay/Subheader";

//style
import styled, {
    useTheme,
    findTextStyles,
} from "../../styling/styled-components";

//utils
import { prettifyPhoneNumber } from "../../utils/helpers";
import { isEmpty } from "../../utils/helpers";

//images
import { ReactComponent as ChevronBlue } from "../../assets/vectors/ChevronBlue.svg";
import { ReactComponent as ChevronBlueDown } from "../../assets/vectors/ChevronBlueDown.svg";

//apis
import * as queries from "../../graphql/queries";
import { useQuery } from "../../graphql/hooks/query";
import queryString from "query-string";

const HeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    padding-left: 40px;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    position: absolute;
    top: 78px;
`;

const ContactContainer = styled.div`
    gap: 0px;
`;

const EmptyData = styled.div`
    width: 440px;
`;

const AccordionBox = styled.div`
    ${({ type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.WAVE_STORM};
    background: none;
    border: none;
    padding: 0px;
    cursor: pointer;
    padding-top: 0px;
    display: flex;
    align-items: center;
    z-index: 1;
`;

export default function OverlayTakeover({
    onConsumerOpen,
    rentalId,
    consumerId,
    backButtonBool,
    fromRentalDetails,
    setFromRentalDetails,
    isOpen,
    closeConsumerOverlay,
    width,
}) {
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [createPass] = useQuery(queries.consumerQRData);
    const [panelWidth, setPanelWidth] = useState(0);
    const [opacity, setOpacity] = useState(0);
    const [
        getConsumer,
        getConsumerData,
        getConsumerLoading,
        getConsumerErrors,
    ] = useQuery(queries.getConsumer);
    const [
        getConsumerStats,
        getConsumerStatsData,
        getConsumerStatsLoading,
        getConsumerStatsErrors,
    ] = useQuery(queries.getConsumerStats);
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const { spacing, colors } = useTheme();

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    useEffect(() => {
        createPass();
    }, []);

    useEffect(() => {
        if (!consumerId || isEmpty(consumerId)) {
            return;
        }
        if (consumerId && queryParams.consumerId !== consumerId && isOpen) {
            setSearchParams(
                `?${new URLSearchParams({
                    consumerId: consumerId,
                })}`
            );
        }
    }, [consumerId, isOpen]);

    useEffect(() => {
        if (consumerId) {
            getConsumer({ consumerId: consumerId });
            getConsumerStats({ consumerId: consumerId });
        }
    }, [consumerId]);

    useEffect(() => {
        if (accordionOpen) {
            setPanelWidth("516px");
            setOpacity("100%");
        } else if (!accordionOpen) {
            setTimeout(() => setPanelWidth(0), setOpacity(0), 2000);
        }
    }, [accordionOpen]);

    const clearParamsAndCloseOverlay = () => {
        searchParams.delete("consumerId");
        searchParams.delete("rentalId");
        setSearchParams(searchParams);
        closeConsumerOverlay();
    };

    const adjustTop = () => {
        if (
            isEmpty(getConsumerData) ||
            (!getConsumerData.email && !getConsumerData.phoneNumber)
        ) {
            return "-36px";
        } else {
            return "12px";
        }
    };

    const triggerBackButton = () => {
        navigate(
            "/dashboard/activity/rentals/" +
                `?rentalId=${encodeURIComponent(rentalId)}`
        );
        backButtonBool();
        if (fromRentalDetails) {
            closeConsumerOverlay();
            onConsumerOpen();
            setFromRentalDetails(false);
        }
    };

    const overlayStyles = {
        width: 444,
        height: `${panelWidth}`,
        overflow: "hidden",
        transition: accordionOpen
            ? "height 1s ease, opacity 1s ease"
            : "height 0.2s ease, opacity 0.2s ease",
        transitionProperty: "height, opacity",
        position: "absolute",
        opacity: `${opacity}`,
        top: `${adjustTop()}`,
    };

    return (
        <>
            {isOpen && (
                <Overlay
                    scroll={"scroll"}
                    showLeft={fromRentalDetails}
                    onClick={closeConsumerOverlay}
                    onClickBack={triggerBackButton}
                    width={width}
                    onClickedOutside={clearParamsAndCloseOverlay}
                >
                    {getConsumerErrors &&
                        !getConsumerData &&
                        getConsumerErrors.errors[0].errorType ==
                            "object-does-not-exist" && (
                            <PageNotFound
                                onClick={onConsumerOpen}
                            ></PageNotFound>
                        )}
                    {getConsumerData && getConsumerStatsData && (
                        <>
                            <HeaderBox>
                                <Header
                                    color={
                                        getConsumerData
                                            ? getConsumerData.name
                                                ? colors.DEEP_BLUE_SEA
                                                : colors.EXHAUST
                                            : ""
                                    }
                                >
                                    {getConsumerData.name
                                        ? getConsumerData.name
                                        : "Unnamed Consumer"}
                                </Header>
                                <ContactContainer>
                                    {getConsumerData.phoneNumber ? (
                                        <SubHeader color={colors.DEEP_BLUE_SEA}>
                                            {prettifyPhoneNumber(
                                                getConsumerData.phoneNumber
                                            )}
                                        </SubHeader>
                                    ) : (
                                        <EmptyData></EmptyData>
                                    )}
                                    {getConsumerData.email ? (
                                        <SubHeader color={colors.DEEP_BLUE_SEA}>
                                            {getConsumerData.email}
                                        </SubHeader>
                                    ) : (
                                        <EmptyData></EmptyData>
                                    )}
                                </ContactContainer>
                                <AccordionBox
                                    type="other"
                                    size="large"
                                    onClick={toggleAccordion}
                                >
                                    {!accordionOpen
                                        ? "Show consumer QR"
                                        : "Hide consumer QR"}
                                    <div
                                        style={{
                                            width: !accordionOpen
                                                ? spacing.small
                                                : spacing.xsmall,
                                        }}
                                    ></div>
                                    {!accordionOpen ? (
                                        <div
                                            style={{ paddingTop: spacing.tiny }}
                                        >
                                            <HighlightHover>
                                                <ChevronBlueDown></ChevronBlueDown>
                                            </HighlightHover>
                                        </div>
                                    ) : (
                                        <HighlightHover>
                                            <ChevronBlue />
                                        </HighlightHover>
                                    )}
                                </AccordionBox>
                                {
                                    <div style={overlayStyles}>
                                        <ConsumerQR
                                            consumer={getConsumerData}
                                        />
                                    </div>
                                }
                            </HeaderBox>
                            {
                                <>
                                    {!accordionOpen ? (
                                        <OverviewSection
                                            consumer={getConsumerData}
                                        ></OverviewSection>
                                    ) : (
                                        <div
                                            style={{
                                                paddingTop: spacing.yuge,
                                            }}
                                        >
                                            <OverviewSection
                                                consumer={getConsumerData}
                                            ></OverviewSection>
                                        </div>
                                    )}
                                </>
                            }
                            <RentalActivity
                                consumer={getConsumerData}
                                consumerUpdatedAt={
                                    getConsumerStatsData.updatedAt
                                }
                            ></RentalActivity>
                            <AssetActivity
                                consumerStats={getConsumerStatsData}
                            ></AssetActivity>
                        </>
                    )}
                </Overlay>
            )}
        </>
    );
}
