import styled, { useTheme } from "../../../styling/styled-components";
import Text from "../../common/Text";

const DaysRate = styled(Text)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

export default function DaysLabelText({ children }) {
    const { colors } = useTheme();
    return (
        <DaysRate type="label" size="medium" color={colors.BLACK}>
            {children}
        </DaysRate>
    );
}
