//style
import styled from "../../../styling/styled-components";

//components
import SideLine from "../../common/SideLine";

const LineWrapper = styled.div`
    width: ${(props) => `${props.width}`}%;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    position: relative;
`;

export function LineDiv({ width, children }) {
    return (
        <LineWrapper width={width}>
            <SideLine />
        </LineWrapper>
    );
}
