import React from "react";
import { TEXT_STYLES } from "../../styling";
import styled from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";

type Props = {
    type?: keyof typeof TEXT_STYLES;
    size?: keyof typeof TEXT_STYLES.body &
        keyof typeof TEXT_STYLES.header &
        keyof typeof TEXT_STYLES.other &
        keyof typeof TEXT_STYLES.link;
    style?: React.CSSProperties | null;
    children?: JSX.Element | string | string[] | null;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    color?: string;
};

const StyledText = styled.div`
    ${({ type, size }) => findTextStyles(type, size)};
`;

export default function Component({
    type,
    size,
    style = {},
    children = null,
    onClick,
    className,
    color,
}: Props) {
    if (!!color) {
        style["color"] = color;
    }
    return (
        <StyledText
            className={className}
            onClick={onClick && onClick}
            style={style}
            type={type}
            size={size}
        >
            {children}
        </StyledText>
    );
}
