import React, { ReactNode } from "react";

//components
import ConditionalRenderable from "./ConditionalRenderable";

//use Displayable when adding in boolean logic that determines whether or not to display a component
//helps reduce clutter and indentation confusion within main components

type Props = {
    displayPrimaryComponent: boolean;
    SecondaryComponent: React.ComponentType;
    children: ReactNode;
};

export default function Displayable({
    displayPrimaryComponent,
    SecondaryComponent,
    children,
}: Props) {
    return (
        <ConditionalRenderable
            condition={displayPrimaryComponent}
            AlternateComponent={SecondaryComponent}
            children={children}
        />
    );
}
