//style
import styled, { useTheme } from "../../../../styling/styled-components";

//components
import Text from "../../../common/Text";

//constants
import { FORGIVENESS_POLICY } from "../../../../constants/forgiveness";

const BodyWrapper = styled.div`
    display: flex;
    padding: 0px ${({ theme }) => theme.spacing.large}px;
    align-items: flex-start;
    flex-direction: column;
    align-self: stretch;
`;

const BodyText = styled(Text)`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    width: 70%;
`;

const ForgivenessDescription = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing.small}px;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    flex-direction: column;
    align-self: stretch;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.SMOG};
    width: 70%;
`;

type Props = {
    forgivenessDescription: string;
    forgivenessExample: string;
};

export default function ForgivenessDetailsCard({
    forgivenessDescription,
    forgivenessExample,
    children,
}: Props) {
    const { colors } = useTheme();

    return (
        <BodyWrapper>
            {children}
            <BodyText type="body" size="medium" color={colors.DEEP_BLUE_SEA}>
                {forgivenessDescription}
            </BodyText>
            <ForgivenessDescription>
                <Text
                    style={{ fontStyle: "italic" }}
                    type="body"
                    size="medium"
                    color={colors.EXHAUST}
                >
                    {FORGIVENESS_POLICY.FORGIVENESS_USE_QUESTION}
                </Text>
                <Text type="body" size="medium" color={colors.EXHAUST}>
                    {forgivenessExample}
                </Text>
            </ForgivenessDescription>
        </BodyWrapper>
    );
}
