import styled from "../../../styling/styled-components";
import Text from "../../common/Text";

const ScheduledText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
    width: 180px;
`;

export function ScheduledTimeText({ children, type, size, color }) {
    return (
        <ScheduledText color={color} type={type} size={size}>
            {children}
        </ScheduledText>
    );
}
