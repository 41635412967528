//react
import React, { useEffect } from "react";

//components
import Loadable from "../common/Loadable";
import Footer from "./common/Footer";
import ChartHeader from "./common/ChartHeader";
import PieChart from "../common/charts/PieChart";
import Container from "./common/Container";
import AssetBreakdown from "./assetusage/AssetBreakdown";
import GrayCircle from "../common/GrayCircle";
import Errorable from "../common/Errorable";
import ErrorBlock from "./common/ErrorBlock";
import Text from "../common/Text";

//styles
import styled, { useTheme } from "../../styling/styled-components";

//constants
import {
    DASHBOARD_OVERVIEW,
    ASSET_USAGE_SUMMARY,
} from "../../constants/dashboard";
import { AssetUsageLabel } from "../../API";

//queries
import * as queries from "../../graphql/queries";
import { useQuery } from "../../graphql/hooks/query";

//utils
import { checkLastUpdatedDate } from "../../utils/chart/checkLastUpdatedDate";

const Header = styled.div``;

const PieWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.small}px;
    padding-bottom: ${({ theme }) => theme.spacing.huge}px;
    ${({ theme }) =>
        theme.isTablet
            ? `justify-content: align-start; align-items: align-start; padding-top: ${theme.spacing.xmlarge}px;`
            : "justify-content: center; align-items: center;"}
`;

const Body = styled.div`
    padding: ${({ theme }) => theme.spacing.large}px 0px;
    display: flex;
    width: 100%;
    gap: ${({ theme }) => theme.spacing.medium}px;
    ${({ theme }) =>
        theme.isTablet ? "flex-direction: row;" : "flex-direction: column;"}
`;

const LowDataText = styled(Text)`
    text-align: center;
    padding: ${({ theme }) => theme.spacing.medium}px;
    padding-top: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const LowDataWrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing.huge}px;
`;

const StatsWrapper = styled.div`
    width: 100%;
    ${({ theme }) =>
        theme.isTablet && `padding-left: ${theme.spacing.xlarge}px`}
    padding-bottom: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const DescriptionText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

export default function AssetUsageSummary({ asset }) {
    const { colors } = useTheme();
    const assetLabel = asset.name == "All asset types" ? "" : asset.name;
    const labels = [
        ASSET_USAGE_SUMMARY.IN_CIRCULATION,
        ASSET_USAGE_SUMMARY.BACK_STOCK,
    ];
    const lowDataArray = [0, 0, 0, 0];
    const [
        queryAssetUsage,
        queryAssetUsageData,
        queryAssetUsageLoading,
        queryAssetUsageErrors,
    ] = useQuery(queries.getStatAssetUsage);

    useEffect(() => {
        queryAssetUsage({ assetTypeId: asset.assetTypeId });
    }, [asset]);

    //asset usage variables
    const bucketsToRemove = [AssetUsageLabel.Retired, AssetUsageLabel.Lost];
    const circulationAndBackStockData =
        queryAssetUsageData?.usageBuckets.filter(
            (item) => !bucketsToRemove.includes(item.label)
        );

    const lastUpdated = queryAssetUsageData?.lastUpdated;
    const bucketCount = circulationAndBackStockData?.map(
        (obj) => obj.bucketCount
    );

    const keyColors = [colors.PLASTIC_BOTTLE, colors.BLANK_SLATE];

    const totalSum = bucketCount?.reduce((sum, count) => sum + count, 0);

    const calculatedPercentages =
        totalSum > 0
            ? bucketCount?.map((count) => (count / totalSum) * 100)
            : lowDataArray;

    const assetStats = circulationAndBackStockData?.map((item, index) => {
        return {
            ...item,
            percentage: calculatedPercentages[index],
            color: keyColors[index],
        };
    });

    const lowDataStats = calculatedPercentages.map((item, index) => {
        return {
            percentage: calculatedPercentages[index],
            color: keyColors[index],
            label: labels[index],
        };
    });

    const assetStatsCheck = queryAssetUsageData
        ? queryAssetUsageData.isAvailable
            ? assetStats
            : lowDataStats
        : "";

    const LoadingPieChart = () => {
        return <GrayCircle height={216} width={216} />;
    };

    const LowDataPieChart = () => {
        return (
            <GrayCircle height={216} width={216}>
                <LowDataWrapper>
                    <LowDataText
                        size="small"
                        type="label"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {DASHBOARD_OVERVIEW.LOW_DATA}
                    </LowDataText>
                </LowDataWrapper>
            </GrayCircle>
        );
    };

    const PieChartVisual = () => {
        return (
            <PieWrapper>
                <Loadable
                    dataLoaded={queryAssetUsageData}
                    LoadingComponent={LoadingPieChart}
                >
                    {queryAssetUsageData && queryAssetUsageData.isAvailable ? (
                        <PieChart
                            percentages={calculatedPercentages}
                            labels={labels}
                            pieColors={keyColors}
                        />
                    ) : (
                        <LowDataPieChart />
                    )}
                </Loadable>
            </PieWrapper>
        );
    };

    return (
        <Container>
            <Header>
                <ChartHeader
                    header={ASSET_USAGE_SUMMARY.HEADER(assetLabel)}
                    subheader={ASSET_USAGE_SUMMARY.DESCRIPTION}
                ></ChartHeader>
                <DescriptionText
                    type="body"
                    size="medium"
                    color={colors.EXHAUST}
                >
                    {ASSET_USAGE_SUMMARY.SUB_DESCRIPTION}
                </DescriptionText>
                <DescriptionText
                    type="body"
                    size="medium"
                    color={colors.EXHAUST}
                >
                    {ASSET_USAGE_SUMMARY.ADDITIONAL_INSIGHTS_DESCPRIPTION}
                </DescriptionText>
            </Header>
            <Errorable
                error={queryAssetUsageErrors}
                ErrorComponent={ErrorBlock}
            >
                <Body>
                    <PieChartVisual />
                    <Loadable dataLoaded={queryAssetUsageData}>
                        <StatsWrapper>
                            <AssetBreakdown assetStats={assetStatsCheck} />
                        </StatsWrapper>
                    </Loadable>
                </Body>
            </Errorable>
            <Footer
                loading={queryAssetUsageLoading}
                lastUpdated={checkLastUpdatedDate(lastUpdated)}
            ></Footer>
        </Container>
    );
}
