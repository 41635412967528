//style
import styled, { useTheme } from "../../../../styling/styled-components";

//components
import Text from "../../../common/Text";
import { Link } from "react-router-dom";
import { RightHalfCard } from "../../../common/card/RightHalfCard";

//TODO: refactor card details to minimize # of props TEC-1994

const SubTextWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.small}px;
`;

export default function CardDetails({
    subText,
    asset,
    paddingLeft = "12px",
    color,
    onClick,
    linkURL,
}) {
    const { colors } = useTheme();

    return (
        <RightHalfCard>
            <Text
                type="body"
                size="medium"
                color={!color ? colors.ROCK_BOTTOM : color}
                style={{ paddingLeft: paddingLeft }}
            >
                {asset}
            </Text>
            {linkURL ? (
                <Link
                    onClick={onClick}
                    to={linkURL}
                    style={{ color: colors.ASH, paddingLeft: paddingLeft }}
                >
                    <Text
                        type="body"
                        size="tiny"
                        color={!color ? colors.ASH : color}
                    >
                        {subText}
                    </Text>
                </Link>
            ) : (
                <SubTextWrapper>
                    <Text
                        type="body"
                        size="tiny"
                        color={!color ? colors.ASH : color}
                    >
                        {subText}
                    </Text>
                </SubTextWrapper>
            )}
        </RightHalfCard>
    );
}
