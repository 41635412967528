//style
import styled from "../../../styling/styled-components";

const LocationLineDiv = styled.div`
    width: 1px;
    ${(props) => props.height && `height: ${props.height - 48}px;`};
    background-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    opacity: 0.4000000059604645;
`;

export function LocationLine({ height }) {
    return <LocationLineDiv height={height}></LocationLineDiv>;
}
