export default function convertDollarStringToAssetValue(
    dollarString: string
): number {
    if (!dollarString) {
        return 0;
    }

    const parts: [string] = dollarString.replace(/\$/, "").split(".");
    const dollars: string = parts[0];
    const cents: string = parts.length > 1 ? parts[1] : 0;

    return parseInt(dollars) * 100 + parseInt(cents);
}
