//components
import Footer from "./common/Footer";
import ChartHeader from "./common/ChartHeader";
import LearnMore from "./assetusage/LearnMore";
import LostRetiredStats from "./assetusage/LostRetiredStats";
import AssetContainer from "./assetusage/AssetContainer";
import Errorable from "../common/Errorable";
import ErrorBlock from "./common/ErrorBlock";

//constants
import { RETIRED_ASSETS_SUMMARY } from "../../constants/dashboard";
import { AssetDetails } from "../../constants/dashboard";

//utils
import { checkLastUpdatedDate } from "../../utils/chart/checkLastUpdatedDate";

type Props = {
    retiredAssetDetails: AssetDetails;
    lastUpdated: string;
    error: string;
};

export default function RetiredAssetsSummary({
    retiredAssetDetails,
    lastUpdated,
    error,
}: Props) {
    const ErrorBlockStyled = () => {
        return <ErrorBlock height={120} />;
    };
    return (
        <AssetContainer>
            <div>
                <ChartHeader
                    header={retiredAssetDetails?.headerLabel}
                    subheader={RETIRED_ASSETS_SUMMARY.SUBHEADER}
                />
                <LearnMore
                    link={
                        "https://6ee79cc1-4189-45de-8bd7-a7b0e3cfce4d.usrfiles.com/ugd/0ef881_cc99d7643ecb4acb8919dafe833d5d0a.pdf"
                    }
                />
            </div>
            <Errorable error={error} ErrorComponent={ErrorBlockStyled}>
                <LostRetiredStats
                    totalHeader={RETIRED_ASSETS_SUMMARY.TOTAL}
                    assetTypeData={retiredAssetDetails?.assetTypeData}
                />
            </Errorable>
            <Footer lastUpdated={checkLastUpdatedDate(lastUpdated)} />
        </AssetContainer>
    );
}
