import { DASHBOARD_OVERVIEW } from "../../constants/dashboard";
import { isUTCTimezone } from "./isUTCTimezone";

export const checkLastUpdatedDate = (timestamp) => {
    if (timestamp) {
        if (!isUTCTimezone(timestamp)) {
            timestamp = timestamp + "Z";
        }
    }

    const currentDate = new Date();
    const timestampDate = new Date(timestamp);

    const formattedLocalTime = timestampDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
    });
    const formattedLocalDay = timestampDate.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    });

    const lastUpdatedDayAndTimeText = `Last updated ${formattedLocalDay} at ${formattedLocalTime}`;

    currentDate.setHours(0, 0, 0, 0);
    timestampDate.setHours(0, 0, 0, 0);

    const difference = currentDate.getTime() - timestampDate.getTime();
    const oneDayInMillis = 24 * 60 * 60 * 1000;

    if (difference < oneDayInMillis) {
        return DASHBOARD_OVERVIEW.LAST_UPDATED_TODAY(formattedLocalTime);
    } else if (difference < 2 * oneDayInMillis) {
        return DASHBOARD_OVERVIEW.LAST_UPDATED_YESTERDAY(formattedLocalTime);
    } else if (timestamp) {
        return DASHBOARD_OVERVIEW.LAST_UPDATED(
            lastUpdatedDayAndTimeText.toString()
        );
    }
};
