import React from "react";

import LoopList from "../loops/LoopList";
import DeprecatedText from "../common/DeprecatedText";
import ConsumerGlance from "./ConsumerGlance";
import styled from "styled-components/macro";
import { useNavigate, useParams } from "react-router-dom";
import { findTextStyles } from "../../styling/styled-components";
import { CONSUMER_DETAIL } from "../../constants/consumers";
import BackHeader from "../common/BackHeader";

const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: 0px ${({ theme }) => theme.spacing.large}px;
    padding-top: ${({ theme }) => theme.spacing.large}px;
`;

const Header = styled.div`
    padding: ${({ theme }) => theme.spacing.large}px 0px;
`;

const Activity = styled.div`
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding: ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.small}px;
    border-radius: 4px;
    margin: ${({ theme }) => theme.spacing.xlarge}px 0px;
`;

const Loops = styled.div`
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding-top: ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.large}px 120px;
    margin: 0px -${({ theme }) => theme.spacing.large}px;
`;

const Summary = styled.div`
    padding: ${({ theme }) => theme.spacing.large}px 0;
`;

const BlueText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const ExhaustText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

export default function Component() {
    const { consumerId } = useParams();
    const navigate = useNavigate();

    return (
        <Container>
            <BackHeader label={"Back"} onClick={() => navigate(-1)} />
            <Header>
                <BlueText type="header" size="large">
                    {CONSUMER_DETAIL.HEADER}
                </BlueText>
                <ExhaustText type="body" size="medium">
                    {CONSUMER_DETAIL.CONSUMER_ID(consumerId)}
                </ExhaustText>
            </Header>
            <Summary>
                <ConsumerGlance consumerId={consumerId} />
            </Summary>
            <Loops>
                <LoopList consumerId={consumerId} />
            </Loops>
        </Container>
    );
}
