// react
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// API
import useGraphClient from "../../hooks/useGraphClient";
import * as mutations from "../../graphql/mutations";
import {
    ScanAppUserAccountType,
    ScanAppUserStatus,
    ScanAppUserSettings,
} from "../../API";

// style
import styled, { useTheme } from "styled-components/macro";

// context
import LocationsContext from "../../contexts/LocationsContext";

// components
import ConfirmationModal from "../common/ConfirmationModal";
import Text from "../common/Text";
import PopupMenu from "../common/PopupMenu";
import HighlightHover from "../common/HighlightHover";

// images
import { ReactComponent as Dots } from "../../assets/vectors/Dots.svg";

// utils
import { toastError, toastSuccess } from "../../utils/toasts";

// constants
import { SCAN_APP_USER_ROW } from "../../constants/scanapp";
import { API_ERROR } from "../../constants/errors";

const Row = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.medium}px
        ${({ theme }) => theme.spacing.tiny}px;
    overflow: visible;
    margin-bottom: 1px;
`;

const Cell = styled.div`
    flex: 1;
`;

const CredsCell = styled(Cell)`
    flex: 1.5;
`;

const KebabCell = styled(Cell)`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
`;

const CellHeaderText = styled(Text)`
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const CellSubText = styled(Text)`
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

const KebabCellSubText = styled(Text)`
    margin-top: 5px;
`;

const BadgeWrapper = styled.div`
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px;
    border-width: 1px;
    text-align: center;
    align-self: flex-start;
`;

const ActiveBadge = styled(BadgeWrapper)`
    background-color: ${({ theme }) => theme.colors.GREEN_2};
    border: ${({ theme }) => theme.colors.GREEN_2} 1px solid;
`;

const InactiveBadge = styled(BadgeWrapper)`
    background-color: transparent;
    border: ${({ theme }) => theme.colors.EXHAUST} 1px solid;
`;

const PendingBadge = styled(BadgeWrapper)`
    background-color: transparent;
    border: ${({ theme }) => theme.colors.GREEN_2} 1px solid;
`;

const ActiveText = styled(Text)`
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

const InactiveText = styled(Text)`
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

const PendingText = styled(Text)`
    color: ${({ theme }) => theme.colors.GREEN_2};
`;

type StatusBadgeProps = {
    userStatus: ScanAppUserStatus;
};

const StatusBadge = ({ userStatus }: StatusBadgeProps) => {
    if (userStatus == ScanAppUserStatus.Active) {
        return (
            <ActiveBadge>
                <ActiveText type="label" size="small">
                    {SCAN_APP_USER_ROW.ACCOUNT_STATUS(userStatus)}
                </ActiveText>
            </ActiveBadge>
        );
    } else if (userStatus == ScanAppUserStatus.Inactive) {
        return (
            <InactiveBadge>
                <InactiveText type="label" size="small">
                    {SCAN_APP_USER_ROW.ACCOUNT_STATUS(userStatus)}
                </InactiveText>
            </InactiveBadge>
        );
    } else if (userStatus == ScanAppUserStatus.Pending) {
        return (
            <PendingBadge>
                <PendingText type="label" size="small">
                    {SCAN_APP_USER_ROW.ACCOUNT_STATUS(userStatus)}
                </PendingText>
            </PendingBadge>
        );
    }
};

type Props = {
    user: ScanAppUserSettings;
    deleteLocalUser: (deletedUserId: string) => void;
};

export default function ScanAppUserRow({ user, deleteLocalUser }: Props) {
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const navigate = useNavigate();
    const { locations } = useContext(LocationsContext).selections;
    const { colors } = useTheme();
    const graphClient = useGraphClient();

    const locationNames = (locationId: string): [string, string] => {
        const thisLocation = locations.find(
            (location) => location.locationId == locationId
        );

        return [thisLocation?.fullName, thisLocation?.address?.line1];
    };

    const onDeleteUser = async (userId: string): null => {
        setDeleteLoading(true);
        try {
            await graphClient.graphql({
                query: mutations.deleteScanAppUser,
                variables: {
                    input: { userId },
                },
            });
            setDeleteLoading(false);
            toastSuccess(SCAN_APP_USER_ROW.USER_DELETED);
            deleteLocalUser(userId);
        } catch (error) {
            console.error("Error deleting ScanApp user", error);
            setDeleteLoading(false);
            toastError(API_ERROR);
        }
    };

    const individualPopupOptions = [
        {
            label: "Location settings",
            isAvailable: true,
            onClick: () => navigate("scan-app/" + user.userId + "/settings"),
        },
        {
            label: "Delete account",
            isAvailable: true,
            onClick: () => setShouldShowCancel(true),
        },
    ];

    const groupPopupOptions = [
        ...individualPopupOptions,
        {
            label: "Change password",
            isAvailable: true,
            onClick: () => navigate("scan-app/" + user.userId + "/password"),
        },
    ];

    return (
        <Row>
            <ConfirmationModal
                isOpen={shouldShowCancel}
                title={SCAN_APP_USER_ROW.DELETE_CONFIRM_TITLE}
                subtitle={SCAN_APP_USER_ROW.DELETE_CONFIRM_SUBTITLE}
                onConfirm={() => onDeleteUser(user.userId)}
                confirmationLoading={deleteLoading}
                onCancel={() => {
                    setShouldShowCancel(false);
                }}
            />
            <CredsCell>
                <CellHeaderText type="header" size="smallest">
                    {user.username}
                </CellHeaderText>
                <CellSubText type="body" size="tiny">
                    {user.userId}
                </CellSubText>
            </CredsCell>
            <Cell>
                <CellHeaderText type="body" size="medium">
                    {SCAN_APP_USER_ROW.USER_TYPE(user.accountType)}
                </CellHeaderText>
                <CellSubText type="body" size="tiny">
                    {SCAN_APP_USER_ROW.CREATED(user.createdAt)}
                </CellSubText>
            </Cell>
            <Cell>
                <CellHeaderText type="body" size="medium">
                    {locationNames(user.selectedLocationId)[0]}
                </CellHeaderText>
                <CellSubText type="body" size="tiny">
                    {locationNames(user.selectedLocationId)[1]}
                </CellSubText>
            </Cell>
            <Cell>
                <CellHeaderText type="body" size="medium">
                    {SCAN_APP_USER_ROW.LOCATION_ACCESS(
                        user.allLocationsEnabled ||
                            user.enabledLocationIds.length > 1
                    )}
                </CellHeaderText>
                <CellSubText type="body" size="tiny">
                    {user.allLocationsEnabled
                        ? SCAN_APP_USER_ROW.ALL_LOCATIONS
                        : user.enabledLocationIds.length > 1
                        ? SCAN_APP_USER_ROW.SOME_LOCATIONS(
                              user.enabledLocationIds.length
                          )
                        : ""}
                </CellSubText>
            </Cell>
            <Cell>
                <KebabCell>
                    <div>
                        <StatusBadge userStatus={user.status} />
                        <KebabCellSubText type="body" size="tiny">
                            {user.status == ScanAppUserStatus.Pending
                                ? SCAN_APP_USER_ROW.INVITED(user.createdAt)
                                : SCAN_APP_USER_ROW.LAST_ACTIVE(
                                      user.lastActivity
                                  )}
                        </KebabCellSubText>
                    </div>
                    <HighlightHover>
                        <PopupMenu
                            customButton={<Dots color={colors.SUMMER_STORM} />}
                            customOpenButton={
                                <Dots color={colors.WAVE_STORM} />
                            }
                            buttonStyle={{ padding: "8px 18px" }}
                            options={
                                user.accountType ==
                                ScanAppUserAccountType.Individual
                                    ? individualPopupOptions
                                    : groupPopupOptions
                            }
                        />
                    </HighlightHover>
                </KebabCell>
            </Cell>
        </Row>
    );
}
ScanAppUserRow.defaultProps = {};
