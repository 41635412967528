import { SPACING } from "../../../styling";
import { COLORS } from "../../../styling/colors";

export const axisTitles = (title) => {
    return {
        color: COLORS.ROCK_BOTTOM,
        display: !!title,
        text: title,
        padding: SPACING.small,
        font: { family: "poppins", size: 11, weight: 500 },
    };
};
