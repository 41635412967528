//styled
import styled, {
    useTheme,
    findTextStyles,
} from "../../../../styling/styled-components";

//react
import { useState, useContext, useEffect } from "react";

//context
import GlobalContext from "../../../../contexts/GlobalContext";

//components
import OtherInput from "./OtherInput";
import CancelLoad from "./CancelLoad";
import { GrayOverlay } from "../common/CancelRental";
import DeprecatedText from "../../../common/DeprecatedText";
import Text from "../../../common/Text";
import CancelRentalDropdown from "./CancelRentalDropdown";
import { CancelButton } from "./CancelButton";

//utils
import { isEmpty } from "../../../../utils/helpers";

//apis
import useGraphClient from "../../../../hooks/useGraphClient";
import { adminCancelRental } from "../../../../graphql/customQueries/adminCancelRental";

const HeaderText = styled(Text)`
    width: ${({ width }) => width};
`;

const CancelModalDiv = styled.div`
    display: flex;
    width: 600px;
    padding: 30px 36px 48px 36px;
    align-items: center;
    border-radius: 4px;
    background: #fff;
    position: fixed;
    z-index: 6;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
`;

const CancelDiv = styled.div`
    display: flex;
    width: 528px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    padding-top: ${({ theme }) => theme.spacing.xlarge}px;
    padding-bottom: 36px;
`;

const DetailsText = styled(DeprecatedText)`
    ${({ type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ASH};
    align-self: stretch;
    padding-top: ${({ theme }) => theme.spacing.small}px;
    margin-bottom: ${({ theme }) => theme.spacing.xlarge}px;
`;

const SubText = styled(DeprecatedText)`
    ${({ type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ASH};
    flex: 1 0 0;
`;

const RefundText = styled(DeprecatedText)`
    ${({ type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding-top: ${({ theme }) => theme.spacing.xlarge}px;
`;

export default function CancelModal({
    onClick,
    onFilterClose,
    rentalId,
    triggerNotifications,
    loadingCancel,
    loading,
    rental,
}) {
    const { colors } = useTheme();
    const context = useContext(GlobalContext);
    const { username } = context.globalSelections;
    const [cancelReason, setCancelReason] = useState({
        label: "Select a reason",
        value: "Select a reason",
    });
    const [showOther, setShowOther] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [otherReason, setOtherReason] = useState("");
    const [canceledReason, setCanceledReason] = useState("");
    const refundDetails = `If you need to refund this fine through Grubhub, please submit a request to the email address provided in the rental details after canceling the rental.`;
    const cancelDetails = `When a rental is canceled, the customer is no longer
    responsible for it and no further actions or charges
    will occur. However, canceling a rental does not
    indicate that the asset has been returned. Once a rental
    has been canceled, it cannot be reactivated.`;

    const graphClient = useGraphClient();

    useEffect(() => {
        checkReason();
        disableButton();
        sendReason();
    }, [cancelReason, canceledReason, disabled, otherReason]);

    const sendReason = () => {
        if (isEmpty(cancelReason) || !cancelReason) {
            return;
        } else if (cancelReason.value == "Other") {
            setCanceledReason(`Other: ${otherReason}`);
        } else {
            setCanceledReason(cancelReason.value);
        }
    };

    const handleDropdownChange = (selectedValue) => {
        setCancelReason(selectedValue);
        if (selectedValue.label == "Other") {
            setShowOther(true);
        } else {
            setShowOther(false);
        }
    };

    const checkReason = () => {
        if (!cancelReason || isEmpty(cancelReason)) {
            return;
        } else if (cancelReason) {
            if (cancelReason.label == "Other") {
                setShowOther(true);
            } else {
                setShowOther(false);
            }
        }
    };

    const cancelRental = async () => {
        loadingCancel();
        try {
            const result = await graphClient.graphql({
                query: adminCancelRental,
                variables: {
                    input: {
                        rentalId,
                        canceledBy: username,
                        canceledReason,
                    },
                },
            });
            const cancelRentalResult = result.data.adminCancelRental;
            if (cancelRentalResult) {
                onFilterClose();
                triggerNotifications(cancelRentalResult);
            }
        } catch (err) {
            console.error("Error canceling rental", err);
        }
    };

    const disableButton = () => {
        if (
            isEmpty(cancelReason) ||
            !cancelReason ||
            cancelReason.value == "Select a reason"
        ) {
            setDisabled(true);
        } else setDisabled(false);
    };

    return (
        <>
            <GrayOverlay onClick={onClick} loading={loading}></GrayOverlay>
            {loading ? (
                <CancelLoad></CancelLoad>
            ) : (
                <CancelModalDiv data-override-outside-click={true}>
                    <CancelDiv>
                        <HeaderText
                            type="label"
                            size="medium"
                            color={colors.EXHAUST}
                        >
                            CANCEL RENTAL
                        </HeaderText>
                        <HeaderText
                            type="header"
                            size="xlarge"
                            color={colors.ROCK_BOTTOM}
                        >
                            To cancel a rental, you must provide a reason
                        </HeaderText>
                        <DetailsText type="body" size="medium">
                            {cancelDetails}
                        </DetailsText>
                        <CancelRentalDropdown
                            onChange={handleDropdownChange}
                            cancelReason={cancelReason}
                            setCancelReason={setCancelReason}
                            disabled={disabled}
                        ></CancelRentalDropdown>
                        <SubText type="label" size="small">
                            Reason for canceling
                        </SubText>
                        {!showOther ? (
                            <></>
                        ) : (
                            <OtherInput
                                otherReason={otherReason}
                                setOtherReason={setOtherReason}
                            ></OtherInput>
                        )}
                        {rental.lastPhase == "FINED" ? (
                            <RefundText type="label" size="medium">
                                {refundDetails}
                            </RefundText>
                        ) : (
                            <></>
                        )}
                        <CancelButton
                            onFilterClose={onFilterClose}
                            cancelRental={() => cancelRental()}
                            disabled={disabled}
                        ></CancelButton>
                    </CancelDiv>
                </CancelModalDiv>
            )}
        </>
    );
}
