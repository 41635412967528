export const customStyles = {
    headCells: {
        style: {
            display: "flex",
            height: "48px",
            padding: "12px 24px",
            alignItems: "center",
            gap: "12px",
            alignSelf: "stretch",
            fontFamily: "poppins",
            fontSize: 11,
        },
    },
    cells: {
        style: {
            display: "flex",
            height: "58px",
            padding: "12px 24px",
            alignItems: "center",
            gap: "12px",
            alignSelf: "stretch",
        },
    },
};
