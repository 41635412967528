//components
import Text from "../../common/Text";
import RentalShimmerCard from "./common/RentalShimmerCard";
import StatusIcon from "./StatusIcon";

//styles
import styled, { useTheme } from "../../../styling/styled-components";

//utils
import { humanizeLower } from "../../../utils/helpers";

const DateAccountText = styled(Text)``;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const StatusDetails = styled.div`
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const ButtonDiv = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing.tiny}px;
    position: relative;
    padding-left: ${({ theme }) => theme.spacing.yuge}px;
    margin-left: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const StatusText = styled.div`
    position: relative;
    right: ${({ theme }) => theme.spacing.xlarge}px;
`;

export default function StatusDetailsCard({ rental }) {
    const { colors } = useTheme();

    return (
        <StatusDetails>
            <StatusContainer>
                <StatusIcon rental={rental}></StatusIcon>
                <StatusText>
                    <DateAccountText
                        type="body"
                        size="medium"
                        color={colors.EXHAUST}
                    >
                        Status
                    </DateAccountText>
                    <Text
                        type="other"
                        size="medium"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {rental.status ? humanizeLower(rental.status) : "—"}
                    </Text>
                </StatusText>
                <ButtonDiv>
                    <RentalShimmerCard />
                </ButtonDiv>
            </StatusContainer>
        </StatusDetails>
    );
}
