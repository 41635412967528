//react
import React, { useState, useEffect } from "react";

//components
import LineChart from "./activeconsumers/LineChart";
import Text from "../common/Text";
import Footer from "./common/Footer";
import ConsumerStats from "./activeconsumers/ConsumerStats";
import DatePicker from "../common/DatePicker";
import ActiveConsumerDetails from "./activeconsumers/ActiveConsumerDetails";
import TitleWrapper from "./activeconsumers/TitleWrapper";
import Errorable from "../common/Errorable";
import ErrorBlock from "./common/ErrorBlock";
import Loadable from "../common/Loadable";

//styles
import styled, { useTheme } from "../../styling/styled-components";

//utils
import { parseISO, format, addDays } from "date-fns";
import { localizeISOStringDate } from "../../utils/helpers";
import { DATE_RANGE_ITEMS } from "./activeconsumers/dateOptions";
import { checkLastUpdatedDate } from "../../utils/chart/checkLastUpdatedDate";

//queries
import * as queries from "../../graphql/queries";
import { useQuery } from "../../graphql/hooks/query";

//constants
import {
    ACTIVE_CONSUMERS,
    DASHBOARD_OVERVIEW,
} from "../../constants/dashboard";

const Container = styled.div``;

const Chart = styled.div`
    padding: ${({ theme }) => theme.spacing.large}px 0px;
    z-index: -1;
    max-height: 400px;
    width: 100%;
`;

const LowDataDiv = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    padding-left: ${({ theme }) => theme.spacing.large}px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

type Props = {
    locationId: string;
};

export default function Component({ locationId }: Props) {
    const { colors } = useTheme();
    const [isLoading, setLoading] = useState(true);

    const today = new Date();
    const defaultDaysBack = -14;
    const defaultRange = addDays(today, defaultDaysBack);
    const [daysBack, setDaysBack] = useState(Math.abs(defaultDaysBack));
    const [dateLabel, setDateLabel] = useState(
        DATE_RANGE_ITEMS[1].label.toLowerCase()
    );
    const [dateRange, setDateRange] = useState([today, defaultRange]);

    const [
        queryActiveConsumerCounts,
        queryActiveConsumerCountsData,
        queryActiveConsumerCountsLoading,
        queryActiveConsumerCountsErrors,
    ] = useQuery(queries.queryActiveConsumerCounts);

    useEffect(() => {
        load();
    }, [dateRange, locationId]);

    const load = () => {
        setLoading(false);

        if (dateRange[1] && dateRange[0]) {
            queryActiveConsumerCounts({
                fromTimestamp: localizeISOStringDate(dateRange[1]),
                toTimestamp: localizeISOStringDate(dateRange[0]),
                locationId,
            });
        }
    };

    const handleChangeDate = (isRelative, newDaysBack, range) => {
        const daysAgo = addDays(today, newDaysBack);

        const selectedDate = DATE_RANGE_ITEMS.find(
            (date) => date.value == newDaysBack
        );
        if (selectedDate) {
            setDateLabel(selectedDate.label.toLowerCase());
        }
        setDaysBack(Math.abs(newDaysBack));
        setDateRange([today, daysAgo]);
    };

    queryActiveConsumerCountsErrors &&
        console.error(queryActiveConsumerCountsErrors);

    const toolTipLabels = queryActiveConsumerCountsData?.results.map((result) =>
        format(parseISO(result.timestamp), "MMM d").toUpperCase()
    );

    let finalIndex = queryActiveConsumerCountsData?.results.length - 1;

    const labels = queryActiveConsumerCountsData?.results.map((item, index) => {
        if (queryActiveConsumerCountsData) {
            if (index == 0) {
                return `${daysBack} days ago (${format(
                    parseISO(item.timestamp),
                    "MMM d"
                ).toUpperCase()})`;
            }
            if (index == finalIndex) {
                return `Today (${format(
                    parseISO(item.timestamp),
                    "MMM d"
                ).toUpperCase()})`;
            } else {
                return "";
            }
        }
    });

    const data = queryActiveConsumerCountsData?.results.map(
        (result) => result.count
    );

    const lastUpdated = queryActiveConsumerCountsData?.updatedAt;

    const chartSettings = {
        toolTipLabels: toolTipLabels,
    };

    const LowDataMessage = () => {
        return (
            <Loadable
                dataLoaded={queryActiveConsumerCountsData?.results.length == 0}
            >
                <LowDataDiv>
                    <Text type="body" size="small" color={colors.BLACK}>
                        {DASHBOARD_OVERVIEW.LOW_DATA}
                    </Text>
                </LowDataDiv>
            </Loadable>
        );
    };

    return (
        <Container>
            <HeaderWrapper>
                <TitleWrapper>
                    <Text type="header" size="medium" color={colors.BLACK}>
                        {ACTIVE_CONSUMERS.HEADER}
                    </Text>
                </TitleWrapper>
                <DatePicker
                    processDateChange={handleChangeDate}
                    initDaysBack={defaultDaysBack}
                    dateRangeItems={DATE_RANGE_ITEMS}
                />
            </HeaderWrapper>
            <ActiveConsumerDetails error={queryActiveConsumerCountsErrors} />
            <LowDataMessage />
            <Errorable
                error={queryActiveConsumerCountsErrors}
                ErrorComponent={ErrorBlock}
            >
                <Chart>
                    <LineChart
                        labels={labels}
                        data={data}
                        settings={chartSettings}
                    />
                </Chart>
                <ConsumerStats
                    data={queryActiveConsumerCountsData?.results}
                    dateRange={dateLabel}
                />
            </Errorable>
            <Footer
                loading={queryActiveConsumerCountsLoading}
                lastUpdated={checkLastUpdatedDate(lastUpdated)}
            />
        </Container>
    );
}
