//react
import { useEffect } from "react";

//styles
import styled from "../../styling/styled-components";

//components
import DaysToReturn from "./cyclerate/DaysToReturn";
import Footer from "./common/Footer";
import AssetReturned from "./cyclerate/AssetReturned";
import ShelfTime from "./cyclerate/ShelfTime";
import AssetChecked from "./cyclerate/AssetChecked";
import ChartHeader from "./common/ChartHeader";
import Errorable from "../common/Errorable";
import ErrorBlock from "./common/ErrorBlock";
import Container from "./common/Container";

//apis
import { useQuery } from "../../graphql/hooks/query";
import * as queries from "../../graphql/queries";

//utils
import { checkLastUpdatedDate } from "../../utils/chart/checkLastUpdatedDate";

//constants
import { CYCLE_STRINGS } from "../../constants/cyclerate";

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
        flex-direction: column;
    }
`;

const BodyWrapper = styled.div`
    width: 494px;
    height: 398px;
    position: relative;
    margin: auto;
    ${({ theme }) =>
        theme.isTablet ? `margin-top: ${theme.spacing.large}px;` : ""}
`;

export default function CycleRateSummary({ asset }: Props) {
    const [
        queryCycleRate,
        queryCycleRateData,
        queryCycleRateLoading,
        queryCycleRateErrors,
    ] = useQuery(queries.getStatCycleRate);
    const assetLabel = asset.name == "All asset types" ? "" : asset.name;

    useEffect(() => {
        queryCycleRate({ assetTypeId: asset.assetTypeId });
    }, [asset]);

    const lastUpdated = queryCycleRateData?.lastUpdated;

    const CycleRateChart = () => {
        return (
            <Errorable error={queryCycleRateErrors} ErrorComponent={ErrorBlock}>
                <BodyWrapper>
                    <DaysToReturn
                        cycleRateData={queryCycleRateData}
                    ></DaysToReturn>
                    <AssetReturned></AssetReturned>
                    <AssetChecked
                        cycleRateData={queryCycleRateData}
                    ></AssetChecked>
                    <ShelfTime cycleRateData={queryCycleRateData}></ShelfTime>
                </BodyWrapper>
            </Errorable>
        );
    };

    return (
        <Container>
            <Header>
                <div>
                    <ChartHeader
                        header={CYCLE_STRINGS.HEADER(assetLabel)}
                        subheader={CYCLE_STRINGS.SUBHEADER}
                    ></ChartHeader>
                </div>
            </Header>
            <CycleRateChart />
            <Footer
                loading={queryCycleRateLoading}
                lastUpdated={checkLastUpdatedDate(lastUpdated)}
            ></Footer>
        </Container>
    );
}
