//react
import { useEffect } from "react";

//style
import styled from "../../styling/styled-components";

//utils
import { showSelectedStatusDetailsPhaseView } from "../../utils/rentalVisual/showSelectedStatusDetailsPhaseView";

//components
import RentalStatusCard from "./RentalStatusCard";
import StatusIcon from "./StatusIcon";

//types
import {
    FilteredAggregations,
    StatusItem,
    RentalVisualDetails,
} from "./common/RentalVisualTypes";

const PhaseCardDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: ${({ theme }) => theme.spacing.large}px;
    @media (max-width: 1023px) {
        padding-right: 0px;
    }
`;

type Props = {
    filteredAggregations: FilteredAggregations[];
    statusFilters: StatusItem[];
    visualDetails: RentalVisualDetails;
};

export default function StatusCardPhaseView({
    filteredAggregations,
    statusFilters,
    visualDetails,
}: Props) {
    showSelectedStatusDetailsPhaseView(
        filteredAggregations,
        visualDetails.visualWidth
    );

    return (
        <PhaseCardDiv>
            <RentalStatusCard
                filteredAggregations={filteredAggregations}
                totalDocCount={visualDetails.totalDocCount}
                status={filteredAggregations ? filteredAggregations[0] : []}
            ></RentalStatusCard>
            <StatusIcon
                filteredAggregations={filteredAggregations}
                statusItem={filteredAggregations ? filteredAggregations[0] : []}
                statusFilters={statusFilters}
            ></StatusIcon>
        </PhaseCardDiv>
    );
}
