//styles
import styled, { useTheme } from "../../styling/styled-components";

//components
import Container from "./common/Container";
import ChartHeader from "./common/ChartHeader";
import ReuseStats from "./reuserate/ReuseStats";
import Loadable from "../common/Loadable";
import Footer from "./common/Footer";
import BarChart from "../common/charts/BarChart";
import ChartSpinner from "./common/ChartSpinner";
import ErrorBlock from "./common/ErrorBlock";
import Errorable from "../common/Errorable";
import Text from "../common/Text";

//queries
import * as queries from "../../graphql/queries";
import { useQuery } from "../../graphql/hooks/query";

//constants
import {
    REUSE_RATE_SUMMARY,
    DASHBOARD_OVERVIEW,
} from "../../constants/dashboard";
import { AssetType } from "./common/Types";

//react
import { useEffect, useState } from "react";

//utils
import { checkLastUpdatedDate } from "../../utils/chart/checkLastUpdatedDate";

const BarChartWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 580px;
    margin-top: ${({ theme }) => theme.spacing.large}px;
    @media screen and (max-width: 1600px) {
        width: 90%;
    }
    @media screen and (max-width: 1400px) {
        width: 96%;
    }
`;

const DetailsWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.large}px;
`;

export type Props = {
    asset: AssetType;
};

export default function ReuseRateSummary({ asset }: Props) {
    const [
        queryReuseRate,
        queryReuseRateData,
        queryReuseRateLoading,
        queryReuseRateErrors,
    ] = useQuery(queries.getStatReuseRate);
    const { colors, spacing } = useTheme();
    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const [hover, setHover] = useState(false);
    const lowEmptyDataSet = [0, 0, 0, 0, 10];
    //If using all 0's in the array, the y axis values on the graph aren't present
    //and we want the graph to default to 10 if there is low/no data
    const assetLabel = asset.name == "All asset types" ? "" : asset.name;
    const barColors = [
        colors.YELLOW_2,
        colors.YELLOW_3,
        colors.SPROUT,
        colors.GREEN_2,
        colors.GREEN_1,
    ];
    const hoverBarColors = [
        colors.YELLOW_2_HOVER,
        colors.YELLOW_3_HOVER,
        colors.SPROUT_HOVER,
        colors.GREEN_2_HOVER,
        colors.GREEN_1_HOVER,
    ];
    const completeRanks = [1, 2, 3, 4, 5];
    const labelWidth10 = ["1-10", "11-20", "21-30", "31-40", "41+"];
    const labelWidth20 = ["1-20", "21-40", "41-60", "61-80", "81+"];

    useEffect(() => {
        queryReuseRate({ assetTypeId: asset.assetTypeId });
    }, [asset]);

    useEffect(() => {
        if (queryReuseRateData) {
            updateChartData();
        }
    }, [queryReuseRateData, hover]);

    const lastUpdated = queryReuseRateData?.lastUpdated;

    // TODO: Backend will be updated during fast follow phase so that we don't have
    // to go through all these functions to find the missing ranks. Right now, if a rank has
    // a quanitity of 0, the response isn't being sent to the frontend, leaving the rank out of the visual.
    // Ticket: https://www.notion.so/topangaio/Stats-Reuse-Rate-include-bucket-with-rank-and-label-even-when-quantity-is-0-fad7f87e7a034558ac5fca1b4a6fe629?pvs=4

    const findAndAddMissingRanks = (reuseRateBuckets, completeRanks) => {
        const presentRanks = new Set(reuseRateBuckets.map((obj) => obj.rank));
        const missingRanks = completeRanks.filter(
            (rank) => !presentRanks.has(rank)
        );
        missingRanks.forEach((rank) => {
            reuseRateBuckets.push({ rank: rank, quantity: 0 });
        });
        let orderedBuckets = reuseRateBuckets.sort((a, b) => a.rank - b.rank);
        return orderedBuckets;
    };

    const updateLabelIncrements = (reuseRateBuckets) => {
        const label = reuseRateBuckets.map((obj) => obj.label);

        const checkLabelWidth = label.some((label) =>
            labelWidth10.includes(label)
        );
        if (checkLabelWidth) {
            setLabels(labelWidth10);
        } else {
            setLabels(labelWidth20);
        }
    };

    const onHoverBarCharts = (showHover) => {
        if (showHover) {
            setHover(true);
            return;
        } else {
            setHover(false);
            return;
        }
    };
    const toolTipText = (xAxisValue, optionLabel, value) => {
        return `Total assets used ${xAxisValue} times each: ${value}`;
    };

    const barChartSettings = {
        maintainAspectRatio: false,
        responsive: true,
        onHover: onHoverBarCharts,
        showLegend: false,
        xAxisLabel: REUSE_RATE_SUMMARY.X_AXIS,
        yAxisLabel: REUSE_RATE_SUMMARY.Y_AXIS,
        toolTipText: toolTipText,
        toolTipBackgroundColor: colors.MARINE_LAYER,
        toolTipEnabled: queryReuseRateData?.isAvailable ? true : false,
    };

    const updateChartData = () => {
        if (queryReuseRateData && queryReuseRateData.isAvailable) {
            let parsedLoopCountData = queryReuseRateData?.reuseRateBuckets;

            if (queryReuseRateData?.reuseRateBuckets.length < 5) {
                parsedLoopCountData = findAndAddMissingRanks(
                    parsedLoopCountData,
                    completeRanks
                );
            }

            const loopCount = parsedLoopCountData.map((result) => {
                return result.quantity;
            });

            setDatasets([
                {
                    label: updateLabelIncrements(parsedLoopCountData),
                    data: loopCount,
                    backgroundColor: hover ? hoverBarColors : barColors,
                    borderRadius: 4,
                    maxBarThickness: 108,
                    barPercentage: 1,
                    categoryPercentage: 0.85,
                    hoverBackgroundColor: barColors,
                },
            ]);
        }
        if (queryReuseRateData && !queryReuseRateData.isAvailable) {
            setDatasets([
                {
                    barPercentage: 0,
                    data: lowEmptyDataSet,
                },
            ]);
        }
    };

    return (
        <Container>
            <div>
                <ChartHeader
                    header={REUSE_RATE_SUMMARY.HEADER(assetLabel)}
                    subheader={REUSE_RATE_SUMMARY.DESCRIPTION}
                ></ChartHeader>
                <Errorable
                    error={queryReuseRateErrors}
                    ErrorComponent={() => ErrorBlock({ height: 560 })}
                >
                    <DetailsWrapper>
                        <ReuseStats reuseData={queryReuseRateData} />
                    </DetailsWrapper>
                    <Loadable dataLoaded={!queryReuseRateData?.isAvailable}>
                        <Text size="small" type="body" color={colors.BLACK}>
                            {DASHBOARD_OVERVIEW.LOW_DATA}
                        </Text>
                    </Loadable>
                    <Loadable
                        dataLoaded={datasets.length > 0}
                        LoadingComponent={ChartSpinner}
                    >
                        <BarChartWrapper>
                            <BarChart
                                datasets={datasets}
                                labels={labels}
                                settings={barChartSettings}
                            />
                        </BarChartWrapper>
                    </Loadable>
                </Errorable>
                <Footer
                    loading={queryReuseRateLoading}
                    lastUpdated={checkLastUpdatedDate(lastUpdated)}
                />
            </div>
        </Container>
    );
}
