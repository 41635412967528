//api
import { ObservationTag } from "../../../API";

//styling
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";

const Container = styled.div<{ color: string }>`
    display: flex;
    padding: 3px 12px;
    align-self: flex-start;
    align-items: flex-start;
    border-radius: 4px;
    background: ${(props) => props.color};
    width: fit-content;
`;
const LabelText = styled(Text)<{ color: string }>`
    text-align: center;
    color: ${(props) => props.color};
`;

type Props = {
    label: ObservationTag;
    className?: string;
};

type LabelConfig = {
    backgroundColor: string;
    textColor: string;
    copy: string;
};

export default function ObservationTagPill({ label, className }: Props) {
    const { colors } = useTheme();

    let labelConfig: LabelConfig;

    switch (label) {
        case ObservationTag.Donation:
            labelConfig = {
                backgroundColor: colors.GREEN_4,
                textColor: colors.ASH,
                copy: "DONATION",
            };
            break;

        default:
            return <></>;
    }

    return (
        <Container className={className} color={labelConfig.backgroundColor}>
            <LabelText type="label" size="small" color={labelConfig.textColor}>
                {labelConfig.copy}
            </LabelText>
        </Container>
    );
}
