//react
import React, { useState, useEffect } from "react";

//styles
import { useTheme } from "../../../styling/styled-components";

//components
import Card from "../../common/card/Card";
import CardHeader from "./common/CardHeader";
import CardDetails from "./common/CardDetails";

//apis
import useGraphClient from "../../../hooks/useGraphClient";
import * as queries from "../../../graphql/queries";

//types
import { RentalProps } from "./common/RentalTypes";

//utils
import { checkBorderColor } from "../../../utils/rentalOverlay/checkBorderColor";

type Props = {
    triggerAt: string;
    rental: RentalProps;
};

export default function CanceledRefundCard({ rental, triggerAt }: Props) {
    const { colors } = useTheme();
    const [cancelReason, setCancelReason] = useState("");
    const [cancelBy, setCancelBy] = useState("");

    const graphClient = useGraphClient();

    useEffect(() => {
        loadMetaData();
    }, [rental]);

    const loadMetaData = async () => {
        try {
            const results = await graphClient.graphql({
                query: queries.getRental,
                variables: {
                    rentalId: rental.rentalId,
                },
            });
            let cancelled = results.data.getRental.metadata;
            cancelled.map((item, index) => {
                if (item.key == "canceledBy") {
                    setCancelBy(item.value);
                }
                if (item.key == "canceledReason") {
                    setCancelReason(item.value);
                }
            });
        } catch (error) {
            console.error(
                "Error in getching rental in canceled with refund card",
                error
            );
        }
    };

    return (
        <Card
            backgroundColor={colors.ASPHALT}
            borderColor={checkBorderColor(rental.status)}
        >
            <CardHeader
                header="Rental canceled"
                subheader="(with refund)"
                date={triggerAt}
            ></CardHeader>
            <CardDetails
                asset={`Canceled by: ${cancelBy}`}
                subText={cancelReason}
            ></CardDetails>
        </Card>
    );
}
