//react
import { useContext, useEffect } from "react";

//components
import Filter, { FilterOption } from "./Filter";

//contexts
import LocationsContext from "../../contexts/LocationsContext";

//api
import { generateClient } from "aws-amplify/api";
import { listParentLocations } from "../../graphql/queries";

const STRINGS = {
    LOCATION: "Location",
};

type Props = {
    onClear: () => void; // how to handle when the clear CTA is hit
    onSave: (options: FilterOption[]) => void; // how to handle the selected FilterOptions when save CTA is hit
    selectedLocationIds?: string[]; // currently selected locationIds
    parentsOnly?: boolean; // only provide parent locations as options
    className?: string;
};

export default function LocationMultiSelect({
    onClear,
    onSave,
    selectedLocationIds = [],
    parentsOnly = false,
    className,
}: Props) {
    const graphClient = generateClient();

    const { selections: locationsSelections, updateSelections } =
        useContext(LocationsContext);

    const { locations, parentLocations, parentLocationsInitialized } =
        locationsSelections || {};

    // TODO: consider if this component should drive the lazy loading of locations
    // like it does below for the addition of parentLocations
    const loadParentLocations = async () => {
        try {
            const results = await graphClient.graphql({
                query: listParentLocations,
            });
            const parents = results.data.listParentLocations.results;
            updateSelections({
                parentLocations: parents,
                parentLocationsInitialized: true,
            });
        } catch (err) {
            console.error("Error with listParentLocations", err);
        }
    };

    useEffect(() => {
        if (parentLocationsInitialized === false) {
            loadParentLocations();
        }
    }, [parentLocationsInitialized, loadParentLocations]);

    const selectedLocations = parentsOnly ? parentLocations : locations;

    const locationFilters = selectedLocations
        .map((location) => ({
            label: location.fullName || location.name || location.locationId,
            value: location.locationId,
            selected: selectedLocationIds.includes(location.locationId),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const countSelected = (filters: FilterOption[]) => {
        const numberFiltersActivated = filters.reduce(
            (acc, cur) => (cur.selected ? acc + 1 : acc),
            0
        );
        return numberFiltersActivated > 0 ? `(${numberFiltersActivated})` : "";
    };

    const locationLabel = `${STRINGS.LOCATION} ${countSelected(
        locationFilters
    )}`;

    return (
        <Filter
            search
            buttonLabel={locationLabel}
            options={[...locationFilters]}
            onClear={onClear}
            onSave={(options: FilterOption[]) => onSave(options)}
            className={className}
        />
    );
}
