//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import LoopOpened from "./LoopOpened";
import DaysLabelText from "./DaysLabelText";
import GrayCircle from "../../common/GrayCircle";
import Loadable from "../../common/Loadable";

//constants
import { CYCLE_STRINGS } from "../../../constants/cyclerate";

const CircleWrapper = styled.div`
    position: relative;
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
    margin-left: ${({ theme }) => theme.spacing.xmlarge}%;
    margin-top: ${({ theme }) => -theme.spacing.large}%;
`;

const CycleRateWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    text-align: center;
`;

const LowDataWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    margin-left: ${({ theme }) => theme.spacing.xsmall}%;
    padding-left: ${({ theme }) => theme.spacing.tiny}px;
    text-align: center;
    width: 120px;
`;

export default function AssetChecked({ cycleRateData }: Props) {
    const { colors } = useTheme();
    const dataLoaded = cycleRateData ? cycleRateData.isAvailable : false;
    const cycleRateTotal = cycleRateData
        ? cycleRateData.isAvailable
            ? Math.round(cycleRateData.cycleRateTotal)
            : ""
        : "";
    const cycleRateUnit = cycleRateData
        ? cycleRateData.isAvailable
            ? cycleRateData.cycleRateUnit.toUpperCase()
            : ""
        : "";

    const LowCycleRateData = () => {
        return (
            <LowDataWrapper>
                <Text type="header" size="medium" color={colors.BLACK}>
                    {CYCLE_STRINGS.CYCLE_RATE_LABEL}
                </Text>
                <Text type="body" size="small" color={colors.DEEP_BLUE_SEA}>
                    {CYCLE_STRINGS.LOW_DATA_MESSAGE}{" "}
                    {CYCLE_STRINGS.CHECK_BACK_MESSAGE}
                </Text>
            </LowDataWrapper>
        );
    };

    const AverageCycleRate = () => {
        return (
            <Loadable
                dataLoaded={dataLoaded}
                LoadingComponent={LowCycleRateData}
            >
                <Loadable dataLoaded={cycleRateData}>
                    <Text type="header" size="xlarge" color={colors.BLACK}>
                        {cycleRateTotal}
                    </Text>
                </Loadable>
                <DaysLabelText>{cycleRateUnit}</DaysLabelText>
                <DaysLabelText>{CYCLE_STRINGS.CYCLE_LABEL}</DaysLabelText>
                <Text type="label" size="small" color={colors.EXHAUST}>
                    {CYCLE_STRINGS.APPROXIMATE_LABEL}
                </Text>
            </Loadable>
        );
    };

    return (
        <>
            <CircleWrapper>
                <GrayCircle height={140} width={140}>
                    <CycleRateWrapper>
                        <AverageCycleRate />
                    </CycleRateWrapper>
                </GrayCircle>
            </CircleWrapper>
            <LoopOpened />
        </>
    );
}
