// style
import styled from "../../styling/styled-components";

// images
import { ReactComponent as HelpCircle } from "../../assets/vectors/HelpCircle.svg";

// components
import { LightLinkText } from "../common/LinkText";

const TextWithIconContainer = styled.div`
    align-items: center;
    display: flex;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

export default function NeedHelpLink() {
    return (
        <LightLinkText
            type="link"
            size="large"
            href="https://www.topanga.io/post/dashboard-support"
            onClick={null}
        >
            <TextWithIconContainer>
                <HelpCircle /> Need Help?
            </TextWithIconContainer>
        </LightLinkText>
    );
}
