import convert from "convert";

import { WeightObservation } from "../../API";

const DEFAULT_WEIGHT_UNIT = "lb";
const DEFAULT_WEIGHT_UNIT_COPY = "lbs";

export const localizeWeightObservation = (
    observation: WeightObservation
): string => {
    // TODO: this does not support WeightObservations other than grams;
    // we do not really expect those.
    const converted = convert(observation.weight, "grams").to(
        DEFAULT_WEIGHT_UNIT
    );
    return `${Number(converted).toFixed(2)}${DEFAULT_WEIGHT_UNIT_COPY}`;
};
