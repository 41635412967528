//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import Loadable from "../../common/Loadable";
import ShimmerCard from "../../common/ShimmerCard";

const ScanDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.tiny}px;
    align-self: stretch;
`;

export default function SubheaderDetails({
    statDescription,
    assetLabel,
}: Props) {
    const { colors } = useTheme();

    const StyledShimmerCard = () => {
        return <ShimmerCard height={18} width={175} />;
    };

    return (
        <ScanDetails>
            {assetLabel && (
                <Text type="header" size="small" color={colors.BLACK}>
                    ({assetLabel})
                </Text>
            )}
            <Loadable
                dataLoaded={statDescription}
                LoadingComponent={StyledShimmerCard}
            >
                <Text type="body" size="medium" color={colors.DEEP_BLUE_SEA}>
                    {statDescription}
                </Text>
            </Loadable>
        </ScanDetails>
    );
}
