//react
import { useEffect, useState } from "react";

//styles
import styled from "../../styling/styled-components";

//components
import RetiredAssetsSummary from "./RetiredAssetsSummary";
import LostAssetsSummary from "./LostAssetsSummary";

//constants
import {
    LOST_ASSETS_SUMMARY,
    DASHBOARD_OVERVIEW,
    RETIRED_ASSETS_SUMMARY,
} from "../../constants/dashboard";
import { AssetUsageLabel } from "../../API";
import { AssetType } from "../../API";

//apis
import { useQuery } from "../../graphql/hooks/query";
import * as queries from "../../graphql/queries";

const ChartWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing.medium}px;
`;

export type Props = {
    asset: AssetType;
};

export default function LostRetiredAssetsSummary({ asset }: Props) {
    const assetLabel = asset?.name == "All asset types" ? "" : asset.name;
    const [retiredAssetData, setRetiredAssetData] = useState();
    const [lostAssetData, setLostAssetData] = useState();

    const [
        queryAssetUsage,
        queryAssetUsageData,
        queryAssetUsageLoading,
        queryAssetUsageErrors,
    ] = useQuery(queries.getStatAssetUsage);

    const lastUpdated = queryAssetUsageData?.lastUpdated;

    useEffect(() => {
        queryAssetUsage({ assetTypeId: asset.assetTypeId });
    }, [asset]);

    useEffect(() => {
        if (queryAssetUsageData && !queryAssetUsageErrors) {
            queryAssetUsageData?.usageBuckets?.map((item, index) => {
                if (item.label == AssetUsageLabel.Lost) {
                    setLostAssetData(item);
                }
                if (item.label == AssetUsageLabel.Retired) {
                    setRetiredAssetData(item);
                }
            });
        }
    }, [queryAssetUsageData]);

    const bucketCount = queryAssetUsageData?.usageBuckets?.map(
        (obj) => obj.bucketCount
    );
    const totalBucketCount = bucketCount?.reduce((acc, item) => acc + item, 0);

    const lostAssetsPercentage = !queryAssetUsageErrors
        ? Math.round((lostAssetData?.bucketCount / totalBucketCount) * 100)
        : "-";

    const lostAssetDetails = {
        headerLabel: LOST_ASSETS_SUMMARY.HEADER(assetLabel),
        percentageDetails: !queryAssetUsageErrors
            ? LOST_ASSETS_SUMMARY.PERCENTAGE_LOST(
                  totalBucketCount,
                  lostAssetsPercentage
              )
            : "-",
        assetTypeData: lostAssetData,
    };

    const retiredAssetDetails = {
        headerLabel: RETIRED_ASSETS_SUMMARY.HEADER(assetLabel),
        percentageDetails: "",
        assetTypeData: retiredAssetData,
    };

    return (
        <ChartWrapper>
            <LostAssetsSummary
                lostAssetDetails={lostAssetDetails}
                lastUpdated={DASHBOARD_OVERVIEW.LAST_UPDATED(lastUpdated)}
                error={queryAssetUsageErrors}
            />
            <RetiredAssetsSummary
                retiredAssetDetails={retiredAssetDetails}
                lastUpdated={DASHBOARD_OVERVIEW.LAST_UPDATED(lastUpdated)}
                error={queryAssetUsageErrors}
            />
        </ChartWrapper>
    );
}
