// react
import React, { useState, useEffect, useContext } from "react";

// style
import styled, { useTheme } from "styled-components/macro";

// components
import Text from "./Text";
import Select from "react-select";

// context
import LocationsContext from "../../contexts/LocationsContext";

// utils
import { getLocationDisplayText } from "../../utils/locations/getLocationDisplayText";

const STRINGS = {
    RANGE_HINT: "Show: ",
    TITLE: (num: number) => `Summary of all ${num} locations`,
    DROPDOWN_TITLE: "Enter a location name",
};

const RangeContainer = styled.div`
    flex-direction: row;
    align-items: end;
    display: flex;
`;

const SelectWrapper = styled.div`
    min-width: 245px;
`;

const RangeHint = styled(Text)``;

type Props = {
    onChange?: (location: [string, string]) => void;
    titleOverride?: string;
    setsContext?: boolean;
    initFromContext?: boolean;
    showHint?: boolean;
    style?: React.CSSProperties;
    selectedId?: string;
    formField?: boolean;
    isClearable?: boolean;
};

export default function LocationDropdown({
    onChange,
    setsContext,
    titleOverride,
    initFromContext,
    showHint,
    style,
    selectedId,
    formField,
    isClearable = true,
    ...props
}: Props) {
    const [locationItems, setLocationItems] = useState([]);
    const context = useContext(LocationsContext);
    const { locationId, locations, isInitialized } = context.selections;
    const { colors } = useTheme();

    const handleChange = (item: ListItem) => {
        onChange && onChange({ id: item?.id, label: item?.label });
        setsContext && context.updateSelections({ locationId: item?.id });
    };

    useEffect(() => {
        const items = (locations || []).map((location) => ({
            id: location.locationId,
            label: getLocationDisplayText(location),
            value: location.locationId,
        }));
        if (items) {
            items.sort((a, b) => a.label.localeCompare(b.label));
        }
        setLocationItems(items);
    }, [locations]);

    return (
        <RangeContainer>
            <SelectWrapper>
                <Select
                    styles={{
                        indicatorSeparator: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "transparent",
                        }),
                        container: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: "poppins",
                            fontSize: 12,
                            fontWeight: 500,
                            color: formField
                                ? colors.EXHAUST
                                : colors.WAVE_STORM,
                        }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: colors.BLANK_SLATE,
                            border: formField
                                ? `0.5px solid ${colors.MARINE_LAYER}`
                                : "none",
                        }),
                        placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: formField
                                ? colors.EXHAUST
                                : colors.WAVE_STORM,
                        }),
                        dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            svg: {
                                cursor: "pointer",
                                color: formField
                                    ? colors.EXHAUST
                                    : colors.WAVE_STORM,
                                ":hover": {
                                    color: colors.PLASTIC_BOTTLE,
                                },
                            },
                        }),
                        singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: formField ? colors.BLACK : colors.WAVE_STORM,
                        }),
                        indicatorsContainer: (baseStyles, state) => ({
                            ...baseStyles,
                            color: formField
                                ? colors.EXHAUST
                                : colors.WAVE_STORM,
                            svg: {
                                cursor: "pointer",
                                color: formField
                                    ? colors.EXHAUST
                                    : colors.WAVE_STORM,
                                ":hover": {
                                    color: colors.PLASTIC_BOTTLE,
                                },
                            },
                        }),
                    }}
                    options={locationItems}
                    onChange={handleChange}
                    isClearable={isClearable}
                    placeholder={
                        titleOverride
                            ? titleOverride
                            : STRINGS.TITLE((locations || []).length)
                    }
                    isSearchable
                    value={
                        initFromContext &&
                        locationItems.find(
                            (item) => item.id === (selectedId || locationId)
                        )
                    }
                    defaultValue={
                        initFromContext &&
                        locationItems.find(
                            (item) => item.id === (selectedId || locationId)
                        )
                    }
                    {...props}
                />
            </SelectWrapper>
        </RangeContainer>
    );
}

LocationDropdown.defaultProps = {
    setsContext: false,
    initFromContext: true,
    showHint: true,
    searchable: true,
};
