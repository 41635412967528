import React, { useState, useEffect } from "react";

//style
import styled, { useTheme } from "../../../styling/styled-components";

//compoennts
import Card from "../../common/card/Card";
import Text from "../../common/Text";
import { RightHalfCard } from "../../common/card/RightHalfCard";
import CardHeader from "./common/CardHeader";
import CardSMS from "./common/CardSMS";

//utils
import {
    colorChange,
    fontChange,
    fontChangeDate,
    removeDuplicateIds,
    findTriggerTime,
} from "./utils/helpers";
import { checkBorderColor } from "../../../utils/rentalOverlay/checkBorderColor";

const DescriptionText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xlarge}px;
`;

export default function LateCard({
    rentalConfigStep,
    lateConfig,
    notifications,
    lateNotification,
    notificationConfig,
    lastPhase,
}) {
    const [triggerTime, setTriggerTime] = useState();
    const [sentBool, setSentBool] = useState(false);
    const { colors } = useTheme();

    useEffect(() => {
        setTriggerTime(findTriggerTime(notifications, "LATE"));
        statusBoolNotifications();
    }, [notifications, triggerTime]);

    const checkDescription = (item) => {
        if (item.description !== "") {
            return item.description;
        }
    };
    const statusBool = () => {
        return (
            rentalConfigStep.status !== "SKIPPED" &&
            rentalConfigStep.status !== "PENDING"
        );
    };

    const statusBoolNotifications = () => {
        notifications.map((item) => {
            if (
                item.rentalPhase == "LATE" &&
                item.status == "NOTIFICATION_SENT"
            ) {
                setSentBool(true);
            }
        });
    };

    const SMSMessage = () => {
        return removeDuplicateIds(notificationConfig).map((item, index) => {
            if (item.rentalPhase == "LATE") {
                return (
                    <div key={`${index}.late`}>
                        <CardSMS
                            sent={sentBool}
                            name={item.name}
                            headerColor={fontChange(statusBool(), colors)}
                            dateColor={fontChangeDate(statusBool(), colors)}
                            date={triggerTime}
                            description={checkDescription(item)}
                            paddingTop="28px"
                        ></CardSMS>
                    </div>
                );
            } else {
                return;
            }
        });
    };

    return (
        <Card
            backgroundColor={colorChange(statusBool(), colors)}
            borderColor={checkBorderColor(
                rentalConfigStep.rentalPhase,
                lastPhase
            )}
        >
            <CardHeader
                header="Late"
                date={rentalConfigStep.triggerAt}
                colorHeader={fontChange(statusBool(), colors)}
                colorDate={fontChangeDate(statusBool(), colors)}
            ></CardHeader>
            <RightHalfCard>
                <DescriptionText
                    type="body"
                    size="medium"
                    color={fontChange(statusBool(), colors)}
                >
                    {checkDescription(lateConfig)}
                </DescriptionText>
                {notifications.length == 0 ? <></> : <SMSMessage></SMSMessage>}
            </RightHalfCard>
        </Card>
    );
}
