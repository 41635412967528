//style
import { useTheme } from "../../styling/styled-components";

//components
import LinkText from "./LinkText";

export default function EmailMessage({
    email,
    subject = "",
    body = "",
    children,
}) {
    const { colors } = useTheme();
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;
    return (
        <LinkText
            type="new_link"
            size="small"
            href={`mailto:${email}${params}`}
        >
            {children}
        </LinkText>
    );
}
