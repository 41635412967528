//styles
import { css } from "../../styling/styled-components";

//components
import ConfirmationModal from "./ConfirmationModal";

const wrapperStyle = css`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.large}px;
    align-self: stretch;
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

export default function UnsavedChangesBlocker({
    header,
    subheader,
    buttonCancelText,
    buttonActionText,
    onClickBack,
    onClickAction,
    isOpen,
    height,
}) {
    return (
        <ConfirmationModal
            isOpen={isOpen}
            title={header}
            subtitle={subheader}
            onConfirm={onClickAction}
            onCancel={onClickBack}
            buttonCancelText={buttonCancelText}
            buttonConfirmText={buttonActionText}
            wrapperStyle={wrapperStyle}
            height={height}
        ></ConfirmationModal>
    );
}
