//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import DaysLabelText from "./DaysLabelText";
import GrayCircle from "../../common/GrayCircle";

//constants
import { CYCLE_STRINGS } from "../../../constants/cyclerate";

const ShelfText = styled(Text)`
    padding-top: 8px;
`;

const ShelfWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.small}px;
    text-align: center;
    position: absolute;
    left: 43%;
    top: 80%;
`;

const ShelfTextWrapper = styled.div`
    padding-left: 16px;
`;

export default function ShelfDuration({ cycleRateData }: Props) {
    const { colors } = useTheme();
    let shelfDuration = cycleRateData
        ? cycleRateData.isAvailable
            ? Math.round(cycleRateData.shelfTime)
            : "—"
        : "";
    let shelfDurationUnit = cycleRateData
        ? cycleRateData.isAvailable
            ? cycleRateData.shelfTimeUnit.toUpperCase()
            : "Days"
        : "Days";

    return (
        <ShelfWrapper>
            <ShelfTextWrapper>
                <GrayCircle height={40} width={40}>
                    <ShelfText
                        type="header"
                        size="medium"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {shelfDuration}
                    </ShelfText>
                </GrayCircle>
            </ShelfTextWrapper>
            <DaysLabelText>{shelfDurationUnit}</DaysLabelText>
            <Text type="header" size="small" color={colors.BLACK}>
                {CYCLE_STRINGS.SHELF_LABEL}
            </Text>
        </ShelfWrapper>
    );
}
