import React, { ReactNode } from "react";

type Props = {
    condition: boolean;
    AlternateComponent: React.ComponentType;
    children: ReactNode;
};
export default function ConditionalRenderable({
    condition,
    AlternateComponent,
    children,
}: Props) {
    return condition ? (
        <>{children}</>
    ) : !AlternateComponent ? (
        <></>
    ) : (
        <AlternateComponent />
    );
}
