export const ERROR_MESSAGES = {
    CODE_REQUIREMENTS: "Your confirmation code should be 6 digits.",
    CORRECT_TITLE: "Please correct the errors below and then try again.",
    INVALID_EMAIL: "Please enter a valid email address",
    MAX_CODE_RESEND:
        "You’ve reached the maximum number of times you can resend the code. Please try again later.",
    MAX_RETRY:
        "Sorry, you’ve reached the maximum number of attempts to enter the correct confirmation code.",
    PASSWORD_CONFIRMATION: "That password doesn’t match the one entered above.",
    PASSWORD_CONSTRAINTS: "That password doesn’t meet our requirements.",
    PASSWORD_REQUIREMENTS:
        "Must be a different password with atleast 8 characters and include 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.",
    PASSWORD_NOT_RECOGNIZED:
        "The password you entered doesn’t match our records. Please try again.",
    TEMP_PASSWORD_EXPIRED:
        "Sorry, your temporary password has expired. Please reach out to an account administrator to have it reset.",
    USER_NOT_FOUND: "We don’t recognize that email, please try another.",
};

export const ERROR_TYPES = {
    MAX_CODE_RESEND_TYPE: "LimitExceededException",
    PASSWORD_NOT_RECOGNIZED_TYPE: "NotAuthorizedException",
    TEMP_PASSWORD_EXPIRED_TYPE:
        "Temporary password has expired and must be reset by an administrator.",
    USER_NOT_FOUND_TYPE: "UserNotFoundException",
};

export const CODE_ERRORS = {
    ATTEMPT_PROMPT: " attempt remaining",
    ATTEMPTS_PROMPT: " attempts remaining",
    CODE_REQUIREMENTS: "Your confirmation code should be 6 digits.",
    CODE_SENT: "Sorry, that doesn’t match our records. Please try again.",
    TRY_AGAIN_LATER:
        "Please try again later or contact an account administrator for help.",
};

export const API_ERROR = "Sorry, something went wrong. Please try again.";
