export const checkStatWindowValue = (statWindow, unitOfTime) => {
    let timeFrame = "";
    if (statWindow) {
        if (unitOfTime == "D") {
            timeFrame = "days";
        } else if (unitOfTime == "M") {
            timeFrame = "months";
        }

        let range = statWindow.replace(/\D/g, "");
        return range + " " + timeFrame;
    }
};
