import React from "react";
import { Location } from "../API";

export type LocationsSelections = {
    locationId: string | null;
    locations: Location[];
    parentLocations: Location[];
    isInitialized: boolean;
    parentLocationsInitialized: boolean;
};

export const emptyLocationsSelections: LocationsSelections = {
    locationId: null,
    locations: [],
    parentLocations: [],
    isInitialized: false,
    parentLocationsInitialized: false,
};

export default React.createContext({
    selections: emptyLocationsSelections,
    updateSelections: () => {},
});
