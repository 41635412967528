//styles
import { COLORS } from "../../../styling/colors";
import { bodyFont } from "./tooltipBodyFont";
import { tooltipSizing } from "./tooltipSizing";

export const darkBlueTooltipStyles = () => {
    return {
        events: ["click"],
        backgroundColor: COLORS.DEEP_BLUE_SEA,
        cursor: "pointer",
        enabled: true,
        mode: "index",
        intersect: false,
        yAlign: "top",
        usePointStyle: true,
        displayColors: false,
        ...tooltipSizing,
        ...bodyFont,
    };
};
