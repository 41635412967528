//components
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

//styles
import styled, { useTheme } from "../../../styling/styled-components";

//react
import { useEffect, useRef, useState } from "react";

const Container = styled.div`
    width: 200px;
    height: 200px;
`;

type Props = {
    percentages: number[];
    labels: string[];
    pieColors: string[];
};

export default function PieChart({ percentages, labels, pieColors }: Props) {
    const { colors, spacing } = useTheme();
    const chartRef = useRef<ChartJS>(null);
    const chart = chartRef.current;
    const [hover, setHover] = useState(false);

    const onResize = () => {
        if (!chart) {
            return;
        }
        chart.resize();
    };

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const data = {
        datasets: [
            {
                label: labels,
                data: percentages,
                backgroundColor: hover ? colors.BLANK_SLATE : pieColors,
                borderColor: colors.EXHAUST,
                borderWidth: 0.5,
                datalabels: {
                    color: colors.DEEP_BLUE_SEA,
                },
                hoverBackgroundColor: pieColors,
                hoverOffset: 3,
            },
        ],
    };

    const options = {
        plugins: {
            datalabels: {
                formatter: (value, context) => {
                    if (value >= 99 && value < 100) {
                        return "99%";
                    }
                    if (value >= 0 && value <= 3) {
                        return "";
                    }
                    if (Math.round(value) > 5) {
                        return Math.round(value) + "%";
                    } else return "";
                },
                font: {
                    size: 11, //need to use numbers for font sizes
                    weight: 500,
                    style: "normal",
                    letterSpacing: 0.11,
                    lineHeight: "normal",
                },
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItem, _) => {
                        return "";
                    },
                    label: (tooltipItem) => {
                        let status = "";
                        tooltipItem.dataset.label.map((item, index) => {
                            if (index == tooltipItem.dataIndex) {
                                status = item;
                            }
                        });
                        {
                            if (
                                tooltipItem.raw >= 99 &&
                                tooltipItem.raw < 100
                            ) {
                                return `${status}: 99%`;
                            }
                            if (tooltipItem.raw >= 0 && tooltipItem.raw < 1) {
                                return `${status}: 1%`;
                            }
                            if (Math.round(tooltipItem.raw) > 5) {
                                return (
                                    `${status}: ${Math.round(
                                        tooltipItem.raw
                                    )}` + "%"
                                );
                            } else return "";
                        }
                    },
                    labelTextColor: function (context) {
                        return colors.BLACK;
                    },
                },
                bodyFont: {
                    size: 11,
                    weight: 500,
                    style: "normal",
                    lineHeight: "normal",
                },
                usePointStyle: true,
                backgroundColor: colors.MARINE_LAYER,
                displayColors: false,
                cornerRadius: spacing.small,
                padding: spacing.small,
                caretSize: spacing.xsmall,
                pointer: "cursor",
            },
        },
        onHover: (event, chartElements) => {
            setHover(false);
            if (chartRef.current && chartElements.length > 0) {
                const chart = chartRef.current;
                const hoveredIndex = chartElements[0]?.index;

                chart.data.datasets.forEach((dataset, datasetIndex) => {
                    const meta = chart.getDatasetMeta(datasetIndex);
                    const elements = meta.data;

                    elements.forEach((element, index) => {
                        if (element) {
                            if (element.$context.dataIndex == hoveredIndex) {
                                return;
                            } else {
                                setHover(true);
                            }
                        }
                    });
                });
                chart.update();
            }
        },
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            position: "top",
        },
    };

    return (
        <Container>
            <Pie
                ref={chartRef}
                data={data}
                options={options}
                plugins={[ChartDataLabels]}
            />
        </Container>
    );
}
