import React, { ReactNode } from "react";

//components
import ConditionalRenderable from "./ConditionalRenderable";

type Props = {
    dataLoaded: boolean;
    LoadingComponent: React.ComponentType;
    children: ReactNode;
};

export default function Loadable({
    dataLoaded,
    LoadingComponent,
    children,
}: Props) {
    return (
        <ConditionalRenderable
            condition={dataLoaded}
            AlternateComponent={LoadingComponent}
            children={children}
        />
    );
}
