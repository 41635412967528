import { RETURN_RATE_CARDS } from "../../constants/assets";
import { RentalStatus } from "../../API";

type Props = {
    createdAt: string;
    status: string;
    assetReceived: string;
};

// [TEC-1875] return received status update
export const checkReturnReceivedStatusLabel = (
    createdAt,
    status,
    assetReceived
) => {
    const createdAtDate = new Date(createdAt);
    const returnReceivedReleaseDate = new Date("2024-03-05T00:00:00.000Z");
    const checkCreatedAtDate =
        createdAtDate < returnReceivedReleaseDate &&
        (status == RentalStatus.Expired || status == RentalStatus.Canceled)
            ? RETURN_RATE_CARDS.STATUS_UNKNOWN
            : assetReceived
            ? RETURN_RATE_CARDS.RETURN_RECEIVED
            : RETURN_RATE_CARDS.NOT_YET_RECEIVED;
    return checkCreatedAtDate;
};
