//components
import Text from "../../common/Text";
import TitleWrapper from "./TitleWrapper";
import Errorable from "../../common/Errorable";

//styles
import styled, { useTheme } from "../../../styling/styled-components";

//constants
import { ACTIVE_CONSUMERS } from "../../../constants/dashboard";

const SubheaderText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.large}px;
`;

export default function ActiveConsumerDetails({ error }: Props) {
    const { colors } = useTheme();

    return (
        <>
            <SubheaderText type="body" size="small" color={colors.EXHAUST}>
                {ACTIVE_CONSUMERS.SUBHEADER}
            </SubheaderText>
            <Errorable error={error}>
                <TitleWrapper>
                    <Text type="header" size="smallest" color={colors.BLACK}>
                        {ACTIVE_CONSUMERS.CHART_HEADER}
                    </Text>
                </TitleWrapper>
            </Errorable>
        </>
    );
}
