// style
import styled from "../../../styling/styled-components";

const CardStyled = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border: ${({ borderColor }) => borderColor};
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
    border-radius: 4px;
    padding-bottom: ${({ theme }) => theme.spacing.xxlarge}px;
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    width: ${({ borderColor }) =>
        borderColor == "" ||
        borderColor == "1px solid var(--ui-colors-green-1, #295732)" ||
        borderColor == "1px solid var(--greys-ash, #36393F)"
            ? "418px"
            : "424px"};
    margin-left: ${({ borderColor }) =>
        borderColor == "" ||
        borderColor == "1px solid var(--ui-colors-green-1, #295732)" ||
        borderColor == "1px solid var(--greys-ash, #36393F)"
            ? "6px"
            : "0px"};
`;

const CardTextDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-left: ${({ theme }) => theme.spacing.large}px;
    padding-right: ${({ theme }) => theme.spacing.large}px;
    gap: ${({ theme }) => theme.spacing.medium}px;
`;

export default function Card({
    children,
    backgroundColor,
    borderColor = `1px solid var(--greys-marine-layer, #c2cfe0)`,
}) {
    return (
        <CardStyled backgroundColor={backgroundColor} borderColor={borderColor}>
            <CardTextDiv>{children}</CardTextDiv>
        </CardStyled>
    );
}
