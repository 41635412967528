import React, { useState, useEffect } from "react";
import useGraphClient from "../../hooks/useGraphClient";
import * as queries from "../../graphql/queries";
import { format, parseISO, sub } from "date-fns";
import styled, { css } from "styled-components/macro";
import DeprecatedText from "../common/DeprecatedText";
import BackHeader from "../common/BackHeader";
import { OpenLoop, ClosedLoop } from "../../assets/vectors";
import Timeline from "./Timeline";
import { LoopStatus, AssetEvent, AssetOperationType } from "../../API";
import { findTextStyles } from "../../styling/styled-components";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LOOP_DETAIL } from "../../constants/loops";

const LoadingContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: ${({ theme }) => theme.spacing.large}px;
    padding-bottom: 120px;
`;

const Header = styled.div`
    padding: ${({ theme }) => theme.spacing.large}px 0;
`;

const Top = styled.div`
    justify-content: space-between;
    display: flex;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    flex-direction: row;
`;

const StatusContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
`;

const StatusWrapper = styled.div`
    flex-direction: row;
    display: flex;
`;

const LeftStatusDetail = styled.div`
    text-align: right;
    padding-right: ${({ theme }) => theme.spacing.xsmall}px;
`;

const RightStatusDetail = styled.div`
    text-align: left;
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const ConsumerHeader = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
`;

const Body = styled.div``;

const TopLeftContainer = styled.div`
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding: ${({ theme }) => theme.spacing.small}px;
    margin: ${({ theme }) => theme.spacing.small}px 0;
    flex: 1;
    padding-right: ${({ theme }) => theme.spacing.medium}px;
`;

const TopRightContainer = styled.div`
    justify-content: space-between;
    margin: ${({ theme }) => theme.spacing.small}px 0;
    flex: 1;
    padding-left: ${({ theme }) => theme.spacing.medium}px;
`;

const SmallHeader = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const RightComponentContainer = styled.div`
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding: ${({ theme }) => theme.spacing.small}px;
`;

const RightDivider = styled.div`
    padding: ${({ theme }) => theme.spacing.small}px 0;
`;

const BlueText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const LinkText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.WAVE_STORM};
`;

const CharcoalText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const GreyText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ASPHALT};
`;

const GreenText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.GREEN_2};
`;

export default function LoopDetail() {
    const [isLoading, setLoading] = useState(true);
    const [loop, setLoop] = useState({});
    const [assetEvents, setAssetEvents] = useState([]);

    const { loopId } = useParams();
    const navigate = useNavigate();

    const graphClient = useGraphClient();

    useEffect(() => {
        loadLoop();
    }, [loopId]);

    const loadLoop = async () => {
        setLoading(true);
        try {
            const result = await graphClient.graphql({
                query: queries.getLoop,
                variables: {
                    loopId,
                },
            });
            const loop = result.data.getLoop;
            setLoop(loop);
            const assetEventResults = await loadAssetEvents(
                loop.asset.assetId,
                loop.openedAt
            );
            setAssetEvents(assetEventResults);
            setLoading(false);
        } catch (err) {
            console.error("Error getting loop", err);
            setLoading(false);
        }
    };

    const loadAssetEvents = async (
        assetId: string,
        loopOpenedAt: string
    ): AssetEvent[] => {
        try {
            //FIXIT: surface if more results need to be fetched to Timeline
            const result = await graphClient.graphql({
                query: queries.queryAssetEvents,
                variables: {
                    assetId,
                    toTimestamp: loopOpenedAt,
                    fromTimestamp: sub(parseISO(loopOpenedAt), { days: 30 }),
                },
            });
            return filterAssetEvents(result.data.queryAssetEvents.results);
        } catch (err) {
            console.error("Error querying assetEvents", err);
            // caught in loadLoop
            throw err;
        }
    };

    // Filter only assetEvents that occurred after the previous closedLoop
    const filterAssetEvents = (assetEvents: AssetEvent[]): AssetEvent[] => {
        let filteredEvents = assetEvents;
        // assetEvents are returned in descending order of eventTimestamp
        const previousCloseIdx = assetEvents.findIndex(
            (event) =>
                event.assetOperation.operationType ==
                AssetOperationType.CloseLoop
        );
        // all assetEvents after last loop close
        if (previousCloseIdx > -1) {
            filteredEvents = filteredEvents.slice(0, previousCloseIdx);
        }
        const blackList = [
            AssetOperationType.CloseLoop,
            AssetOperationType.CreateLoop,
        ];
        filteredEvents = filteredEvents.filter(
            (event) =>
                blackList.indexOf(event.assetOperation.operationType) == -1
        );
        return filteredEvents;
    };

    const timelineLoopStatuses = loop.loopId
        ? [
              {
                  timestamp: loop.openedAt,
                  location: loop.locations.openedAt,
                  loopStatus: LoopStatus.Open,
              },
          ]
        : [];

    loop.loopStatus == LoopStatus.Closed &&
        timelineLoopStatuses.push({
            timestamp: loop.closedAt,
            location: loop.locations.closedAt,
            loopStatus: LoopStatus.Closed,
        });

    return (
        <Container>
            <BackHeader label={"Back"} onClick={() => navigate(-1)} />
            <Header>
                <BlueText type="header" size="large">
                    {LOOP_DETAIL.TITLE}
                </BlueText>
                <BlueText type="header" size="small">
                    {LOOP_DETAIL.LOOP_ID(loopId)}
                </BlueText>
            </Header>
            {isLoading ? (
                <LoadingContainer>
                    <ClipLoader loading />
                </LoadingContainer>
            ) : (
                <>
                    <Top>
                        <TopLeftContainer>
                            <GreyText type="body" size="large">
                                {LOOP_DETAIL.STATUS}
                            </GreyText>
                            <StatusContainer>
                                {loop.loopStatus == LoopStatus.Open ? (
                                    <img src={OpenLoop} />
                                ) : (
                                    <img src={ClosedLoop} />
                                )}
                                <StatusWrapper>
                                    <LeftStatusDetail>
                                        <GreenText type="label" size="medium">
                                            {LOOP_DETAIL.OPENED}
                                        </GreenText>
                                        <CharcoalText type="body" size="large">
                                            {format(
                                                parseISO(loop.openedAt),
                                                "MM/dd/yy"
                                            )}
                                        </CharcoalText>
                                    </LeftStatusDetail>
                                    <RightStatusDetail>
                                        {loop.loopStatus ==
                                        LoopStatus.Closed ? (
                                            <>
                                                <GreenText
                                                    type="label"
                                                    size="medium"
                                                >
                                                    {LOOP_DETAIL.CLOSED}
                                                </GreenText>
                                                <CharcoalText
                                                    type="body"
                                                    size="large"
                                                >
                                                    {format(
                                                        parseISO(loop.closedAt),
                                                        "MM/dd/yy"
                                                    )}
                                                </CharcoalText>
                                            </>
                                        ) : (
                                            <GreyText
                                                type="label"
                                                size="medium"
                                            >
                                                {LOOP_DETAIL.CLOSED}
                                            </GreyText>
                                        )}
                                    </RightStatusDetail>
                                </StatusWrapper>
                            </StatusContainer>
                        </TopLeftContainer>
                        <TopRightContainer>
                            <RightComponentContainer>
                                <SmallHeader>
                                    <GreyText type="body" size="large">
                                        {LOOP_DETAIL.ASSET}
                                    </GreyText>
                                </SmallHeader>

                                <CharcoalText type="body" size="large">
                                    {LOOP_DETAIL.ASSET_ID(loop.asset.assetId)}
                                </CharcoalText>
                                <CharcoalText type="body" size="large">
                                    {LOOP_DETAIL.ASSET_TYPE_NAME(
                                        loop.asset.assetType?.name
                                    )}
                                </CharcoalText>
                                <CharcoalText type="body" size="large">
                                    {LOOP_DETAIL.ASSET_TYPE_ID(
                                        loop.asset.assetType?.assetTypeId
                                    )}
                                </CharcoalText>
                            </RightComponentContainer>
                            <RightDivider />
                            <RightComponentContainer>
                                <SmallHeader>
                                    <ConsumerHeader>
                                        <GreyText type="body" size="large">
                                            {LOOP_DETAIL.CONSUMER}
                                        </GreyText>
                                        <Link
                                            to={
                                                "/dashboard/consumers/" +
                                                loop.consumer.consumerId
                                            }
                                        >
                                            <LinkText type="body" size="large">
                                                {LOOP_DETAIL.DETAILS}
                                            </LinkText>
                                        </Link>
                                    </ConsumerHeader>
                                </SmallHeader>
                                <BlueText
                                    type="body"
                                    size="large"
                                >{`ID: ${loop.consumer.consumerId}`}</BlueText>
                            </RightComponentContainer>
                        </TopRightContainer>
                    </Top>
                    <Body>
                        <Header>
                            <BlueText type="header" size="small">
                                {LOOP_DETAIL.EVENTS}
                            </BlueText>
                        </Header>
                        <Timeline
                            loopStatuses={timelineLoopStatuses}
                            resolutions={loop.resolutions || []}
                            assetEvents={assetEvents}
                        />
                    </Body>
                </>
            )}
        </Container>
    );
}
