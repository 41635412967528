import { lowercaseAllButFirstLetter } from "./lowercaseAllButFirstLetter";
import { RentalStatus } from "../../API";

export const adjustStatusLabels = (status) => {
    if (status == RentalStatus.CanceledWithRefund) {
        return "Canceled (with...";
    } else if (status == RentalStatus.InProgress) {
        return "In-progress";
    } else {
        return lowercaseAllButFirstLetter(status);
    }
};
