import {
    Sidebar,
    Menu,
    MenuItem,
    useProSidebar,
    menuClasses,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";

//styles
import styled, { useTheme } from "../../styling/styled-components";

//images
import { ReactComponent as Overview } from "../../assets/vectors/Overview.svg";
import { ReactComponent as Pulse } from "../../assets/vectors/Pulse.svg";
import { ReactComponent as Consumer } from "../../assets/vectors/Consumer.svg";
import { ReactComponent as Settings } from "../../assets/vectors/Settings.svg";
import { ReactComponent as Assets } from "../../assets/vectors/Assets.svg";
import { ReactComponent as StreamLine } from "../../assets/vectors/StreamLineLogo.svg";

//components
import { SidebarHeader } from "./SidebarHeader";
import { SidebarFooter } from "./SidebarFooter";
import { SIDEBAR } from "../../constants/sidebar";

const StreamLineDivider = styled.div`
    margin-top: 32px;
    margin-bottom: 12px;
    margin-left: 12px;
    margin-right: 12px;
    border-bottom: ${({ theme }) => theme.borderStyle.smallDivider};
`;

export default function NavSidebar() {
    const { collapsed, broken, toggleSidebar } = useProSidebar();
    const { colors } = useTheme();
    const location = useLocation();

    const menuItemStyles = {
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: 400,
        fontFamily: "poppins",
        cursor: "pointer",
    };

    return (
        <Sidebar
            backgroundColor={colors.DEEP_BLUE_SEA}
            collapsedWidth="84px"
            customBreakPoint="1280px"
        >
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                }}
            >
                <SidebarHeader
                    style={{ paddingBottom: "24px", paddingTop: "16px" }}
                />
                <div style={{ flex: 1 }}>
                    <Menu
                        menuItemStyles={{
                            icon: {
                                [`&.${menuClasses.active}`]: {
                                    borderRadius: "50%",
                                    border: "1px solid",
                                },
                            },
                            button: ({ level, active, disabled }) => {
                                const menuStyle = {
                                    cursor: "pointer",
                                    color: active
                                        ? colors.LIME_SQUEEZE
                                        : colors.SUMMER_STORM,
                                    backgroundColor: colors.DEEP_BLUE_SEA,
                                    "&:hover": {
                                        backgroundColor: colors.DEEP_BLUE_SEA,
                                        color: colors.BLANK_SLATE,
                                    },
                                };
                                if (level === 0) {
                                    return menuStyle;
                                } else if (level === 1) {
                                    return {
                                        paddingLeft: collapsed ? 20 : 55,
                                        textDecoration: active
                                            ? "underline"
                                            : "none",
                                        ...menuStyle,
                                    };
                                }
                            },
                        }}
                    >
                        <MenuItem
                            rootStyles={menuItemStyles}
                            active={location.pathname.includes("overview")}
                            component={
                                <Link
                                    onClick={() => broken && toggleSidebar()}
                                    to="/dashboard/overview"
                                />
                            }
                            icon={<Overview />}
                        >
                            {SIDEBAR.OVERVIEW}
                        </MenuItem>
                        <MenuItem
                            active={location.pathname.includes("activity")}
                            rootStyles={menuItemStyles}
                            component={
                                <Link
                                    onClick={() => broken && toggleSidebar()}
                                    to="/dashboard/activity/"
                                />
                            }
                            icon={<Pulse />}
                        >
                            {SIDEBAR.ACTIVITY}
                        </MenuItem>
                        <MenuItem
                            active={location.pathname.includes("assets")}
                            rootStyles={menuItemStyles}
                            component={
                                <Link
                                    onClick={() => broken && toggleSidebar()}
                                    to="/dashboard/assets"
                                />
                            }
                            icon={<Assets />}
                        >
                            {SIDEBAR.ASSETS}
                        </MenuItem>
                        <MenuItem
                            active={location.pathname.includes("consumers")}
                            rootStyles={menuItemStyles}
                            component={
                                <Link
                                    onClick={() => broken && toggleSidebar()}
                                    to="/dashboard/consumers"
                                />
                            }
                            icon={<Consumer />}
                        >
                            {SIDEBAR.CONSUMERS}
                        </MenuItem>
                        <MenuItem
                            active={location.pathname.includes(
                                "program-settings/locations"
                            )}
                            rootStyles={menuItemStyles}
                            component={
                                <Link
                                    onClick={() => broken && toggleSidebar()}
                                    to="/dashboard/program-settings/locations"
                                />
                            }
                            icon={<Settings />}
                        >
                            {SIDEBAR.SETTINGS}
                        </MenuItem>
                        <StreamLineDivider />
                        <MenuItem
                            active={location.pathname.includes("streamline")}
                            rootStyles={menuItemStyles}
                            component={
                                <Link
                                    onClick={() => broken && toggleSidebar()}
                                    to="/dashboard/streamline"
                                />
                            }
                            icon={<StreamLine />}
                        >
                            {"StreamLine Beta"}
                        </MenuItem>
                    </Menu>
                </div>
                <SidebarFooter />
            </div>
        </Sidebar>
    );
}
