//components
import Checkbox from "@mui/material/Checkbox";
import Text from "../../common/Text";

//styling
import styled from "../../../styling/styled-components";

//images
import { ReactComponent as FilterCheckedBox } from "../../../assets/vectors/FilterCheckedBox.svg";
import { ReactComponent as FilterUncheckedBox } from "../../../assets/vectors/FilterUncheckedBox.svg";

const STRINGS = {
    LABEL: "Only show labeled items",
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StyledText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

type Props = {
    isSelected: boolean;
    onChange: (isSelected: boolean) => void;
    className?: string;
};

export default function LabeledCheckBox({
    isSelected,
    onChange,
    className,
}: Props) {
    return (
        <Container>
            <Checkbox
                checkedIcon={<FilterCheckedBox />}
                icon={<FilterUncheckedBox />}
                onClick={() => {
                    onChange(!isSelected);
                }}
                checked={isSelected}
            />
            <StyledText type="label" size="small">
                {STRINGS.LABEL}
            </StyledText>
        </Container>
    );
}
