import React, { useEffect } from "react";
import DeprecatedText from "../common/DeprecatedText";
import styled from "styled-components/macro";
import { ScanAppUserAccountType } from "../../API";
import { findTextStyles } from "../../styling/styled-components";
import { ACCOUNT_TYPE_TOGGLE } from "../../constants/scanapp";

const Container = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    border: 1px solid #0450db;
    border-radius: 34px;
`;

const Option = styled.div`
    display: flex;
    cursor: pointer;
    flex: 1;
    text-align: center;
    border-radius: 34px;
    padding: ${({ theme }) => theme.spacing.tiny}px 0;
    margin: 1px;
    background-color: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.WAVE_STORM : "transparent"};
`;

const OptionText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.BLANK_SLATE : theme.colors.DEEP_BLUE_SEA};
    margin: 0 auto;
`;

type Props = {
    selectedType: ScanAppUserAccountType;
    onChange: (selectedType: ScanAppUserAccountType) => null;
};

export default function AccountTypeToggle({
    selectedType,
    onChange,
    style,
}: Props) {
    useEffect(() => {
        onChange(selectedType);
    }, [selectedType]);

    return (
        <Container style={style}>
            <Option
                isSelected={selectedType == ScanAppUserAccountType.Group}
                onClick={() => onChange(ScanAppUserAccountType.Group)}
            >
                <OptionText
                    type="header"
                    size="smallest"
                    isSelected={selectedType == ScanAppUserAccountType.Group}
                >
                    {ACCOUNT_TYPE_TOGGLE.GROUP}
                </OptionText>
            </Option>
            <Option
                isSelected={selectedType == ScanAppUserAccountType.Individual}
                onClick={() => onChange(ScanAppUserAccountType.Individual)}
            >
                <OptionText
                    type="header"
                    size="smallest"
                    isSelected={
                        selectedType == ScanAppUserAccountType.Individual
                    }
                >
                    {ACCOUNT_TYPE_TOGGLE.INDIVIDUAL}
                </OptionText>
            </Option>
        </Container>
    );
}

AccountTypeToggle.defaultProps = {};
