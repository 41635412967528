import React, { useState, useEffect } from "react";
import styled, {
    useTheme,
    findTextStyles,
} from "../../../styling/styled-components";
import Card from "../../common/card/Card";
import CardHeader from "./common/CardHeader";
import CardDetails from "./common/CardDetails";

export default function InitiatedCard({ rental, loop, onClickDisplay }) {
    const { colors } = useTheme();
    const locationName = loop.locations.openedAt.fullName
        ? loop.locations.openedAt.fullName
        : loop.locations.openedAt.name;

    return (
        <Card backgroundColor={colors.SEAGLASS}>
            <CardHeader header="Initiated" date={rental.createdAt}></CardHeader>
            <CardDetails
                linkURL={
                    "/dashboard/activity/assetevents/" +
                    `?prop=${encodeURIComponent(rental.rentedAssetId)}`
                }
                onClick={onClickDisplay}
                asset={`A ${rental.rentedAssetTypeName} was
                    checked-out at ${locationName}.`}
                subText={`Asset ID #${rental.rentedAssetId}`}
            ></CardDetails>
        </Card>
    );
}
