//react
import React, { useEffect, useState } from "react";

//components
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Popover from "@mui/material/Popover";
import Button from "../common/Button";
import DeprecatedText from "../common/DeprecatedText";
import Text from "../common/Text";
import DropdownLabel from "../common/dropdown/DropdownLabel.tsx";

//styling
import styled, { css } from "styled-components/macro";
import { styled as MUIStyled } from "@mui/material/styles";

//images
import { ReactComponent as Search } from "../../assets/vectors/Search.svg";
import { ReactComponent as FilterCheckedBox } from "../../assets/vectors/FilterCheckedBox.svg";
import { ReactComponent as FilterUncheckedBox } from "../../assets/vectors/FilterUncheckedBox.svg";
import { ReactComponent as FilterDownCaret } from "../../assets/vectors/FilterDownCaret.svg";
import { ReactComponent as FilterUpCaret } from "../../assets/vectors/FilterUpCaret.svg";

const FilterClosedText = styled(Text)`
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
    svg {
        margin-bottom: 2px;
        margin-left: ${({ theme }) => theme.spacing.xsmall}px;
    }
`;

const FilterOpenText = styled(FilterClosedText)`
    color: ${({ theme }) => theme.colors.WAVE_STORM};
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing.medium}px;
`;

const OptionLabel = styled(Text)`
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
    text-align: left;
    cursor: pointer;
`;

const ButtonWrapper = styled.div`
    cursor: pointer;
    width: fit-content;
`;

const StyledFormGroup = styled(FormGroup)`
    padding: ${({ theme }) => theme.spacing.medium}px;
    min-width: 162px;
    max-height: 230px;
    overflow-y: scroll;
    flex-wrap: inherit !important;
`;

const NumberSelectedText = styled(DeprecatedText)`
    padding: 12px ${({ theme }) => theme.spacing.medium}px 0;
`;

const FilterWrapper = styled.div``;

const StyledPopover = MUIStyled(Popover)`
    margin-top: 12px;

    .MuiPaper-root {
        max-width: 270px;
    }
`;

const StyledTextField = MUIStyled(TextField)`
    width: 198px;
    margin-bottom: -18px;

    .MuiInputBase-root {
        font-family: poppins;
        background: white;
        height: 37px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        letter-spacing: 0.01em;
        margin: 12px 15px;
        z-index: 9;
    }
`;

export type FilterOption = {
    label: string;
    value: string;
    selected: boolean;
};

interface Props {
    options: FilterOption[];
    onSave: (options: FilterOption[]) => void;
    onClear: () => void;
    buttonLabel?: string;
    customButton?: JSX.Element;
    customOpenButton?: JSX.Element;
    search?: boolean;
    disabled?: boolean;
    className?: string;
}

export default function PopupFilterMenu({
    options,
    onSave,
    onClear,
    buttonLabel,
    customButton,
    customOpenButton,
    search = false,
    disabled = false,
    className,
}: Props) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [popUpOptions, setPopUpOptions] = useState([...options]);
    const [searchTerm, setSearchTerm] = useState("");
    const [changesMade, setChangesMade] = useState(false);
    const filterFieldRef = React.useRef();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
        }
    };

    const someSelected = options.some((option) => option.selected);

    const handleClose = () => {
        setSearchTerm("");
        setAnchorEl(null);
        setChangesMade(false);
    };

    const onSelect = (idx: number) => {
        const newPopUpOptions = [...popUpOptions];
        newPopUpOptions[idx] = {
            ...newPopUpOptions[idx],
            selected: !newPopUpOptions[idx].selected,
        };
        setPopUpOptions([...newPopUpOptions]);
    };

    const open = Boolean(anchorEl);

    const handleClear = () => {
        onClear();
        handleClose();
    };

    const handleSave = () => {
        onSave(popUpOptions);
        handleClose();
    };

    useEffect(() => {
        if (open) {
            setPopUpOptions(options);
        }
    }, [anchorEl]);

    const handleStartAdornmentClick = (
        event: React.MouseEvent<HTMLElement>
    ) => {
        filterFieldRef.current?.focus();
    };

    function containsString(str1: string, str2: string): boolean {
        // Convert both strings to lowercase
        const lowerStr1 = str1.toLowerCase();
        const lowerStr2 = str2.toLowerCase();
        // Check if str1 is contained inside str2
        return lowerStr2.indexOf(lowerStr1) !== -1;
    }

    const renderOptions = () => {
        return (
            <StyledPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                {search && (
                    <StyledTextField
                        placeholder={"Search location name"}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment
                                    onClick={handleStartAdornmentClick}
                                    position="start"
                                >
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        autoComplete={"off"}
                        value={searchTerm}
                        inputRef={filterFieldRef}
                        onChange={(event) => setSearchTerm(event.target.value)}
                    />
                )}
                <StyledFormGroup>
                    {popUpOptions.map((option, idx) => {
                        return (
                            (!search ||
                                (search &&
                                    containsString(
                                        searchTerm,
                                        option.label
                                    ))) && (
                                <FormControlLabel
                                    key={option.label}
                                    control={
                                        <Checkbox
                                            checkedIcon={<FilterCheckedBox />}
                                            icon={<FilterUncheckedBox />}
                                            key={idx}
                                            onClick={() => {
                                                setChangesMade(true);
                                                onSelect(idx);
                                            }}
                                            checked={option.selected}
                                        />
                                    }
                                    label={
                                        <ListItemText>
                                            <OptionLabel
                                                type="label"
                                                size="small"
                                            >
                                                {option.label}
                                            </OptionLabel>
                                        </ListItemText>
                                    }
                                />
                            )
                        );
                    })}
                </StyledFormGroup>
                <NumberSelectedText>
                    {popUpOptions.reduce(
                        (acc, cur) => (cur.selected ? acc + 1 : acc),
                        0
                    )}{" "}
                    filters selected
                </NumberSelectedText>
                <ButtonsContainer>
                    <Button
                        status={someSelected ? "default" : "disabled"}
                        size="small"
                        type="secondary"
                        onClick={handleClear}
                        label="Clear"
                    />
                    <Button
                        status={changesMade ? "default" : "disabled"}
                        size="small"
                        type="primary"
                        onClick={handleSave}
                        label="Save"
                    />
                </ButtonsContainer>
            </StyledPopover>
        );
    };

    const renderButton = (open: boolean): JSX.Element => {
        if (open) {
            if (customOpenButton) {
                return customOpenButton;
            } else {
                return (
                    <DropdownLabel
                        isOpen={true}
                        label={
                            <FilterClosedText type="body" size="medium">
                                <>
                                    {buttonLabel}
                                    <FilterUpCaret />
                                </>
                            </FilterClosedText>
                        }
                    />
                );
            }
        } else {
            if (customButton) {
                return customButton;
            } else {
                return (
                    <DropdownLabel
                        isOpen={false}
                        label={
                            <FilterOpenText type="body" size="medium">
                                <>
                                    {buttonLabel}
                                    <FilterDownCaret />
                                </>
                            </FilterOpenText>
                        }
                    />
                );
            }
        }
    };

    return (
        <FilterWrapper className={className}>
            <div>
                <ButtonWrapper onClick={handleClick}>
                    {renderButton(open)}
                </ButtonWrapper>
            </div>
            {renderOptions()}
        </FilterWrapper>
    );
}
