import React, { useState, useEffect, useContext } from "react";
import * as queries from "../../graphql/queries";
import styled, { useTheme } from "styled-components/macro";
import LocationsContext from "../../contexts/LocationsContext";
import DeprecatedText from "../common/DeprecatedText";
import useGraphClient from "../../hooks/useGraphClient";
import ScanAppUserRow from "./ScanAppUserRow";
import { ScanAppUserStatus } from "../../API";
import { randomToken } from "../../utils/helpers";
import { render } from "@testing-library/react";
import { ClipLoader } from "react-spinners";
import { findTextStyles } from "../../styling/styled-components";
import { SCAN_APP_USERS_LIST } from "../../constants/scanapp";

const HeaderRow = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    border-radius: 4px;
    border-bottom-width: 3px;
    border-bottom-color: ${({ theme }) => theme.colors.SMOG};
    margin-bottom: ${({ theme }) => theme.spacing.tiny}px;
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    margin-top: ${({ theme }) => theme.spacing.medium}px;
`;

const Footer = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.yuge}px;
`;

const LoadMore = styled.div`
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.WAVE_STORM};
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    width: fit-content;
    margin: ${({ theme }) => theme.spacing.xlarge}px auto 0;
    align-self: center;
`;

const Cell = styled.div`
    flex: 1;
    overflow: clip;
`;

const CredsCell = styled(Cell)`
    flex: 1.5;
`;

const LoadMoreText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.WAVE_STORM};
`;

const HeaderCellText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    font-weight: 500;
`;

const CharcoalText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
    padding-top: ${({ theme }) => theme.spacing.medium}px;
`;

const COLUMNS = [
    "Email/Username",
    "Account type",
    "Selected Location",
    "Location access",
    "Status",
];

type Props = {
    isActive: boolean;
    countCallback?: (countString: string) => null;
};

export default function ScanAppUsersList({
    isActive,
    countCallback,
    ...props
}: Props) {
    const [dataLoading, setDataLoading] = useState(true);
    const [isInitiated, setIsInitiated] = useState(false);
    const [data, setData] = useState([]);
    const [paginationToken, setPaginationToken] = useState();

    const { locations } = useContext(LocationsContext).selections;

    const { colors, isDesktop, isTablet, isMobile } = useTheme();

    const columns = COLUMNS;

    const graphClient = useGraphClient();

    useEffect(() => {
        loadData(null);
    }, []);

    useEffect(() => {
        const countString = `${data.length}${paginationToken ? "+" : ""}`;
        countCallback(countString);
    }, [data, paginationToken]);

    const deleteLocalUser = (deletedUserId) => {
        setData(data.filter((user) => user.userId != deletedUserId));
    };

    const locationNames = (locationId: string): [string, string] => {
        const thisLocation = locations.find(
            (location) => location.locationId == locationId
        );

        return [thisLocation?.name, thisLocation?.address?.line1];
    };

    const loadData = async (thisToken: string) => {
        setDataLoading(true);
        try {
            const results = await graphClient.graphql({
                query: queries.listScanAppUsers,
                variables: {
                    isActive,
                    paginationToken: thisToken,
                },
            });

            const users = results.data.listScanAppUsers.results;
            const token = results.data.listScanAppUsers.nextToken;
            setData([...data, ...users]);
            setPaginationToken(token);
            setDataLoading(false);
            setIsInitiated(true);
        } catch (error) {
            console.error("Error listing ScanApp users", error);
            setDataLoading(false);
            setIsInitiated(true);
        }
    };

    const renderAllColumns = (item) => (
        <ScanAppUserRow
            user={item}
            key={item.userId}
            deleteLocalUser={deleteLocalUser}
        />
    );

    return (
        <div>
            {data.length > 0 ? (
                <HeaderRow>
                    {columns.map((col, idx) =>
                        idx == 0 ? (
                            <CredsCell key={col}>
                                <HeaderCellText type="body" size="medium">
                                    {col}
                                </HeaderCellText>
                            </CredsCell>
                        ) : (
                            <Cell key={col}>
                                <HeaderCellText type="body" size="medium">
                                    {col}
                                </HeaderCellText>
                            </Cell>
                        )
                    )}
                </HeaderRow>
            ) : null}
            {!!data && data.map((datum) => renderAllColumns(datum))}
            <Footer>
                {paginationToken ? (
                    <LoadMore onClick={() => loadData(paginationToken)}>
                        {dataLoading ? (
                            <ClipLoader
                                color={colors.WAVE_STORM}
                                size={10}
                                loading
                            />
                        ) : (
                            <LoadMoreText type="body" size="large">
                                {SCAN_APP_USERS_LIST.LOAD_MORE}
                            </LoadMoreText>
                        )}
                    </LoadMore>
                ) : null}
            </Footer>
        </div>
    );
}

ScanAppUsersList.defaultProps = {
    countCallback: (_) => null,
};
