import React, { useEffect } from "react";

import PercentageChart from "../common/charts/PercentageChart";
import DeprecatedText from "../common/DeprecatedText";
import styled, { css } from "styled-components/macro";
import { parseISO, format } from "date-fns";
import { useReturnRateStatistics } from "../../hooks/useReturnRateStatistics";
import { findTextStyles } from "../../styling/styled-components";
import { ALL_TIME_RETURN_RATE_CARD } from "../../constants/assets";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding: ${({ theme }) => theme.spacing.medium}px;
    justify-content: space-between;
`;

const Chart = styled.div`
    max-width: 180px;
    align-self: center;
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            padding-bottom: 18px;
            max-width: 200px;
            flex-direction: column;
            justify-content: center;
            flex: auto;
        `}
`;

const Header = styled.div``;

const Footer = styled.div``;

const HeaderText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.BLACK};
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const UpdatedText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    margin-top: ${({ theme }) => theme.spacing.large}px;
`;

type Props = { assetTypeId?: string };

export default function AllTimeReturnRateCard({ assetTypeId, style }: Props) {
    const [
        queryReturnRates,
        queryReturnRatesData,
        lastUpdated,
        queryReturnRatesLoading,
        queryReturnRatesErrors,
    ] = useReturnRateStatistics();

    useEffect(() => {
        queryReturnRates(assetTypeId);
    }, [assetTypeId]);

    const allTimeResult = queryReturnRatesData.find(
        (result) => result.key == "all-time"
    );

    return allTimeResult ? (
        <Container style={style}>
            <Header>
                <HeaderText type="label" size="small">
                    {ALL_TIME_RETURN_RATE_CARD.HEADER}
                </HeaderText>
            </Header>
            {allTimeResult && (
                <Chart>
                    <PercentageChart percentage={allTimeResult.percentage} />
                </Chart>
            )}
            <Footer>
                <UpdatedText type="body" size="tiny">
                    {ALL_TIME_RETURN_RATE_CARD.LAST_UPDATED(lastUpdated)}
                </UpdatedText>
            </Footer>
        </Container>
    ) : null;
}
