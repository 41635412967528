export const FORGIVENESS_POLICY = {
    HEADER: "Rental forgiveness policy",
    FORGIVENESS_DETAILS:
        " Forgiving a rental means that you do not intend to issue fines or penalize the consumer for failing to return a container before the rental expires. Forgiven rentals will display the “Forgiven” status in the Topanga dashboard and the consumer’s ReusePass account.",
    FORGIVENESS_FINED: "Rentals that have been fined cannot be forgiven.",
    REFUND_RENTAL:
        "To refund a rental, go to the rentals page and select the rental ID to view the details. Click the cancelation link provided and follow the steps to cancel the rental and request a refund.",
    NEED_HELP:
        "Looking for additional guidance on setting up your program’s forgiveness policy? Learn more at",
    CLIENT_SUCCESS: "or contact the Client Success Team for assistance.",
    INSTANT_FORGIVEN_ON: "Instant Forgiveness: ON",
    INSTANT_FORGIVEN_OFF: "Instant Forgiveness: OFF",
    INSTANT_FORGIVENESS_DETAILS:
        "When turned on, rentals will be automatically forgiven if they expire. The consumer will not be notified about the rental expiration.",
    FORGIVENESS_USE_QUESTION: "When would I use this?",
    INSTANT_FORGIVENESS_EXAMPLE_CASE:
        "If you are an academic campus, you may turn this on before finals week, to waive late fees during this period, and then turn it off when regular classes resume.",
    RETURN_RECEIVED_FORGIVEN_ON: "Return Received Forgiveness: ON",
    RETURN_RECEIVED_FORGIVEN_OFF: "Return Received Forgiveness: OFF",
    RETURN_RECEIVED_FORGIVENESS_DETAILS:
        "When turned on, failed rentals will be automatically updated to display the “Forgiven” status if a returned container is received before a fine is issued. Rentals that are automatically fined via a third-party platform are not eligible for Return Received Forgiveness.",
    RETURN_RECEIVED_ELIGIBILITY:
        "Rentals that are automatically fined via a third-party platform are not eligible for Return Received Forgiveness. Rentals that are automatically fined via a third-party platform are not eligible for Return Received Forgiveness.",
    RETURN_RECEIVED_EXAMPLE_CASE:
        "You may keep this turned on because you care more about if the container is received than when it is received.",
    FORGIVENESS_POLICY_TECH_SUPPORT:
        "If you need to change or remove the fine from your program, please reach out to our support team at ",
};
