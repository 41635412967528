import React from "react";
import DeprecatedText from "../common/DeprecatedText";
import styled from "styled-components/macro";
import { AssetOperationType, LoopStatus } from "../../API";
import { findTextStyles } from "../../styling/styled-components";
import { ASSET_OPERATION_LABELS } from "../../constants/activity";

const ClosedContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-start;
    position: relative;
    text-align: center;
    width: 80px;
`;

const OpenContainer = styled.div`
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-start;
    position: relative;
    text-align: center;
    width: 80px;
`;

const OtherStatusContainer = styled(ClosedContainer)`
    background-color: ${({ theme }) => theme.colors.SMOG};
`;

const BlueText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const WhiteText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

const AshText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ASH};
`;

type Props = {
    status: AssetOperationType;
};

export default function Component({ status }: Props) {
    if (status === AssetOperationType.CreateLoop) {
        return (
            <OpenContainer>
                <BlueText type="label" size="small">
                    {ASSET_OPERATION_LABELS[status].toUpperCase()}
                </BlueText>
            </OpenContainer>
        );
    }
    if (status == AssetOperationType.CloseLoop) {
        return (
            <ClosedContainer>
                <WhiteText type="label" size="small">
                    {ASSET_OPERATION_LABELS[status].toUpperCase()}
                </WhiteText>
            </ClosedContainer>
        );
    } else
        return (
            <OtherStatusContainer>
                <AshText type="label" size="small">
                    {ASSET_OPERATION_LABELS[status]?.toUpperCase()}
                </AshText>
            </OtherStatusContainer>
        );
}
