//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";

//constants
import { CYCLE_STRINGS } from "../../../constants/cyclerate";

const LoopSubText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const CheckoutWrapper = styled.div`
    width: 100px;
    text-align: center;
    position: relative;
    margin-top: ${({ theme }) => -theme.spacing.large}%;
    left: ${({ theme }) => -theme.spacing.large}px;
`;

export default function LoopOpened({}) {
    const { colors } = useTheme();

    return (
        <CheckoutWrapper>
            <Text type="header" size="small" color={colors.BLACK}>
                {CYCLE_STRINGS.ASSET_CHECKOUT_LABEL}
            </Text>
            <LoopSubText type="label" size="small" color={colors.EXHAUST}>
                {CYCLE_STRINGS.LOOP_OPEN}
            </LoopSubText>
        </CheckoutWrapper>
    );
}
