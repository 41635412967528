import React, { useState, useEffect } from "react";
import styled, {
    useTheme,
    findTextStyles,
} from "../../../styling/styled-components";
import { SMSDiv } from "../../common/card/SMSDiv";
import { ScheduledTimeText } from "../../common/card/ScheduledTimeText";
import { SMSText } from "../../common/card/SMSText";
import { RightHalfCard } from "../../common/card/RightHalfCard";
import { ReactComponent as Warning } from "../../../assets/vectors/Warning.svg";

const MessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100px;
`;

const IconWrapper = styled.div``;

export default function FinedAlert({ subText, asset }) {
    const { colors, spacing } = useTheme();
    return (
        <RightHalfCard>
            <SMSDiv>
                <IconWrapper>
                    <Warning />
                </IconWrapper>
                <MessageWrapper>
                    <SMSText
                        paddingTop={spacing.tiny}
                        type="body"
                        size="medium"
                        color={colors.RED_2}
                    >
                        {asset}
                    </SMSText>
                    <ScheduledTimeText
                        type="body"
                        size="tiny"
                        color={colors.ASH}
                    >
                        {subText}
                    </ScheduledTimeText>
                </MessageWrapper>
            </SMSDiv>
        </RightHalfCard>
    );
}
