//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Card from "../../common/card/Card";
import Text from "../../common/Text";

//types
import { RentalProps } from "./common/RentalTypes";

//utils
import { checkBorderColor } from "../../../utils/rentalOverlay/checkBorderColor";

//constants
import { FINED_ELIGIBLE_STRINGS } from "../../../constants/rentalOverlay/rentalCards";

type Props = {
    rental: RentalProps;
};

const FinedEligibleWrapper = styled.div`
    flex: 1 0 0;
`;

const FineEligibleTextDiv = styled.div`
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

export default function FinedEligibleCard({ rental }: Props) {
    const { colors } = useTheme();

    return (
        <Card
            backgroundColor={colors.SMOG}
            borderColor={checkBorderColor(rental.status)}
        >
            <FinedEligibleWrapper>
                <Text type="body" size="medium" color={colors.ROCK_BOTTOM}>
                    {FINED_ELIGIBLE_STRINGS.HEADER}
                </Text>
                <FineEligibleTextDiv>
                    <Text type="body" size="medium" color={colors.ROCK_BOTTOM}>
                        {FINED_ELIGIBLE_STRINGS.DESCRIPTION}
                    </Text>
                </FineEligibleTextDiv>
            </FinedEligibleWrapper>
        </Card>
    );
}
