// style
import styled, { useTheme } from "../../../styling/styled-components";

// components
import LinkText from "../../common/LinkText";
import Loadable from "../../common/Loadable";
import NewLine from "../../common/NewLine";
import {
    SubSectionHeader,
    SubSectionText,
} from "../../common/overlay/SubSection";

// utils
import { getFullFormattedTime } from "../../../utils/helpers";
import { checkReturnReceivedStatusLabel } from "../../../utils/activity/checkReturnReceivedStatusLabel";

//constants
import { RETURN_RATE_CARDS } from "../../../constants/assets";

const LineContainer = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

export default function AssetReceivedSection({ rental, returnCriteria }) {
    // [TEC-1875] return received status update
    let assetReceivedStatus = checkReturnReceivedStatusLabel(
        rental.createdAt,
        rental.status,
        rental.assetReceived
    );
    return (
        <Loadable
            dataLoaded={
                !!rental.resolvingLoop &&
                rental.status != "COMPLETED" &&
                assetReceivedStatus !== RETURN_RATE_CARDS.STATUS_UNKNOWN
            }
        >
            <SectionWithData rental={rental} />
        </Loadable>
    );
}

function SectionWithData({ rental }) {
    const { colors } = useTheme();
    const assetTypeName = rental.receivedAssetTypeName
        ? rental.receivedAssetTypeName
        : "—";
    const locationName = rental.resolvingLoop.locations.closedAt.fullName
        ? rental.resolvingLoop.locations.closedAt.fullName
        : rental.resolvingLoop.locations.closedAt.name;
    const returnDate = getFullFormattedTime(rental.resolvingLoop.closedAt);
    const linkToAsset =
        "/dashboard/activity/assetevents/?prop=" +
        encodeURIComponent(rental.receivedAssetId);

    return (
        <div>
            <LineContainer>
                <NewLine />
            </LineContainer>
            <SubSectionText>Return Received</SubSectionText>
            <SubSectionHeader color={colors.DEEP_BLUE_SEA}>
                {returnDate}
            </SubSectionHeader>
            <SubSectionHeader color={colors.DEEP_BLUE_SEA}>
                Location: {locationName}
            </SubSectionHeader>
            <SubSectionHeader color={colors.DEEP_BLUE_SEA}>
                {assetTypeName}:{" "}
                <LinkText href={linkToAsset}>
                    #{rental.receivedAssetId}
                </LinkText>
            </SubSectionHeader>
        </div>
    );
}
