// style
import styled, { useTheme } from "../../../styling/styled-components";

// components
import Button from "./../Button";
import Text from "./../Text";

const STRINGS = {
    APPLY_SELECTION: "Apply selection",
};

const SubmitButton = styled(Button)`
    margin-bottom: ${({ theme }) => theme.spacing.large}px;
    margin-left: 0px;
    margin-top: ${({ theme }) => theme.spacing.xlarge}px;
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    min-width: 0;
`;

export default function ConfirmButton({ clickable, onClick, ...props }: Props) {
    const { colors } = useTheme();
    const status = clickable ? "default" : "disabled";

    return (
        <SubmitButton type="submit" status={status} onClick={onClick}>
            <Text type="label" size="small" color={colors.BLANK_SLATE}>
                {STRINGS.APPLY_SELECTION}
            </Text>
        </SubmitButton>
    );
}
