//style
import styled, { useTheme } from "../../styling/styled-components";

//components
import StackedBarChart from "./StackedBarChart";
import Loadable from "../common/Loadable";
import StatusCardPhaseView from "./StatusCardPhaseView";
import StatusCardStatusView from "./StatusCardStatusView";
import Text from "../common/Text";
import VerticalLine from "../common/VerticalLine";
import PhaseGridView from "./PhaseGridView";
import Displayable from "../common/Displayable";
import ShimmerCard from "../common/ShimmerCard";

//constants
import { orderedRentalPhases } from "../../constants/rentalVisual/orderedRentalPhases";
import { RentalStatus } from "../../API";
import { RENTAL_VISUAL } from "../../constants/rentalVisual/rentalVisualStrings";

//utils
import { isEmpty } from "../../utils/helpers";

//react
import { useEffect, useRef, useState } from "react";

//types
import {
    FilteredAggregations,
    StatusItem,
    PhaseFilters,
} from "./common/RentalVisualTypes";

const RentalVisualDiv = styled.div`
    display: flex;
    width: 96.5%;
    height: 90px;
    @media (max-width: 1023px) {
        height: 106px;
    }
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: ${({ theme }) => theme.spacing.large}px;
    @media (max-width: 1023px) {
        padding: ${({ theme }) => theme.spacing.medium}px;
    }
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
    gap: ${({ theme }) => theme.spacing.large}px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    z-index: 0;
`;

const StatsDiv = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.large}px;
    align-self: stretch;
    @media screen and (max-width: 1023px) {
        gap: ${({ theme }) => theme.spacing.small}px;
    }
`;

const GridDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    flex: 1 1 0;
`;

const HeaderWrapper = styled.div`
    position: absolute;
`;

const PhaseHeader = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const LineWrapper = styled.div`
    display: flex;
    position: relative;
    height: 100px;
`;

const ShimmerCardWrapper = styled.div`
    position: relative;
`;

type Props = {
    filteredAggregations: FilteredAggregations[];
    statusFilters: StatusItem[];
    phaseFilters: PhaseFilters[];
};

export default function RentalViusal({
    filteredAggregations,
    statusFilters,
    phaseFilters,
}: Props) {
    const visualRef = useRef(null);
    const { colors, spacing } = useTheme();
    const [visualWidth, setVisualWidth] = useState(0);
    const [displayStatusResults, setDisplayStatusResults] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false);

    const [orderedPhasesResult, setOrderedPhasesResult] = useState([]);

    const orderPhases = (phases) => {
        if (phases) {
            phases.sort((a, b) => {
                return (
                    orderedRentalPhases.indexOf(a.label) -
                    orderedRentalPhases.indexOf(b.label)
                );
            });
            setOrderedPhasesResult(phases);
        }
    };

    const [totalDocCount, setTotalDocCount] = useState();
    const visualDetails = {
        visualWidth: visualWidth,
        totalDocCount: totalDocCount,
    };

    useEffect(() => {
        const selectedFilters = statusFilters.filter(
            (obj) => obj.selected === true
        );
        //check if filtered aggregations exist
        if (filteredAggregations) {
            if (
                //can remove this conditional once expired is removed as a status
                //TEC-1856 notion ticket
                //if 1 status filter is selected and filterAggregation length is 2 (meaning Failed status was selected), display Phase Rental Visual
                filteredAggregations.length == 2 &&
                selectedFilters.length == 1
            ) {
                setDisplayStatusResults(false);
            }
            //if 2 or more filters are selected or no filters are selected, display Status Rental Visual/Bar Chart
            if (selectedFilters.length >= 2 || isEmpty(selectedFilters)) {
                setDisplayStatusResults(true);
            }
            if (
                //if 1 status filter is selected and filterAggregation length is 1, display Phase Rental Visual
                filteredAggregations.length == 1 &&
                selectedFilters.length == 1
            ) {
                if (filteredAggregations[0].key !== RentalStatus.Expired) {
                    setDisplayStatusResults(false);
                } else if (selectedFilters[0].key == RentalStatus.Failed) {
                    setDisplayStatusResults(false);
                }
            }
        }
        //if filteredAggregations are null (no results returned from filters)
        if (!filteredAggregations) {
            //check which filters were selected
            const noFilterSelected = phaseFilters.every(
                (obj) => obj?.selected === false
            );
            //if no phase filters were selected, return
            if (noFilterSelected) {
                return;
            }
            //if phase filters were selected and one status filter selected, show Phase Rental Visual
            if (selectedFilters.length == 1) {
                setDisplayStatusResults(false);
            } else {
                setDisplayStatusResults(true);
            }
        }
        setTimeout(() => {
            setDataLoaded(true);
        }, 1000);
    }, [filteredAggregations, statusFilters, phaseFilters]);

    const handleResize = () => {
        if (visualRef && visualRef.current) {
            const updatedWidth = visualRef.current.offsetWidth;
            setVisualWidth(updatedWidth);
        }
    };

    useEffect(() => {
        if (filteredAggregations) {
            orderPhases(phaseFilters);
            setTotalDocCount(
                filteredAggregations.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.docCount;
                }, 0)
            );
        }
    }, [filteredAggregations, phaseFilters]);

    useEffect(() => {
        handleResize();
        window.addEventListener("load", handleResize);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("load", handleResize);
            window.removeEventListener("resize", handleResize);
        };
    }, [visualRef]);

    const StyledShimmerCard = () => {
        let shimmerCardWidth = visualWidth - spacing.huge;
        return (
            <ShimmerCardWrapper>
                <ShimmerCard height={12} width={shimmerCardWidth} />
            </ShimmerCardWrapper>
        );
    };

    return (
        <RentalVisualDiv ref={visualRef}>
            <StatsDiv>
                {/* re-renders on every load when using Displayable and causes visual bug */}
                {displayStatusResults ? (
                    <StatusCardStatusView
                        filteredAggregations={filteredAggregations}
                        statusFilters={statusFilters}
                        visualDetails={visualDetails}
                    />
                ) : (
                    <>
                        <StatusCardPhaseView
                            filteredAggregations={filteredAggregations}
                            statusFilters={statusFilters}
                            visualDetails={visualDetails}
                        />
                        <LineWrapper>
                            <VerticalLine />
                        </LineWrapper>
                        <GridDiv>
                            <HeaderWrapper>
                                <PhaseHeader
                                    type="label"
                                    size="small"
                                    color={colors.EXHAUST}
                                >
                                    {RENTAL_VISUAL.HEADER}
                                </PhaseHeader>
                            </HeaderWrapper>
                            <Displayable
                                displayPrimaryComponent={!displayStatusResults}
                            >
                                {orderedPhasesResult.map((item, index) => {
                                    return (
                                        <PhaseGridView
                                            filteredAggregations={
                                                filteredAggregations
                                            }
                                            phase={item}
                                            phaseFilters={phaseFilters}
                                        />
                                    );
                                })}
                            </Displayable>
                        </GridDiv>
                    </>
                )}
            </StatsDiv>
            <Loadable
                dataLoaded={dataLoaded}
                LoadingComponent={StyledShimmerCard}
            >
                <Displayable displayPrimaryComponent={displayStatusResults}>
                    <StackedBarChart
                        filteredAggregations={filteredAggregations}
                        totalDocCount={totalDocCount}
                        visualWidth={visualWidth}
                    ></StackedBarChart>
                </Displayable>
            </Loadable>
        </RentalVisualDiv>
    );
}
