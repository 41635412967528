import styled from "../../styling/styled-components";

const TooltipDiv = styled.div`
    position: absolute;
    display: flex;
    width: 200px;
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.small}px;
    border-radius: 5px;
    border: ${({ theme }) => theme.borderStyle.divider};
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: ${({ theme }) => theme.shadowStyle.containerDropShadow};
    z-index: 2000;
`;

export function Tooltip({ children }) {
    return <TooltipDiv>{children}</TooltipDiv>;
}
