import React from "react";

export type GlobalSelections = {
    isAuthenticated: boolean;
    username: string;
    organizationName: string;
    slug: string;
    scanAppUsers: number | null;
    scanAppUserLimit: number | null;
    requestInitToken: string | null;
    userAdmin?: boolean | null;
};

export const emptyGlobalSelection: GlobalSelections = {
    isAuthenticated: false,
    username: "",
    organizationName: "",
    slug: "",
    scanAppUsers: null,
    scanAppUserLimit: null,
    requestInitToken: null,
    userAdmin: null,
};

export default React.createContext({
    globalSelections: emptyGlobalSelection,
    updateSelections: () => {},
});
