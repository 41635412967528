export const LOOP_DETAIL = {
    TITLE: "Loop Details",
    LOOP_ID: (loopId: string) => `Loop ID: ${loopId}`,
    EVENTS: "Loop events",
    STATUS: "Status",
    DETAILS: "Details",
    ASSET: "Asset",
    ASSET_ID: (id?: string) => `ID: ${id || ""}`,
    ASSET_TYPE_NAME: (name?: string) => `Type name: ${name || ""}`,
    ASSET_TYPE_ID: (id?: string) => `Type ID: ${id || ""}`,
    CONSUMER: "Consumer",
    OPENED: "Opened",
    CLOSED: "Closed",
};

export const LOOP_LIST = {
    ERROR: "Error loading data. Please try again.",
    NO_LOOPS: "No loops found...",
    LOAD_MORE: "Load more...",
    MOBILE: "Please login to the dashboard on desktop or iPad to view all loop details.",
    LOOP_ID: "Loop ID",
    ASSET_TYPE: "Asset type",
    CONSUMER_ID: "Consumer ID",
    OPENED: "Opened",
    CLOSED: "Closed",
    STATUS: "Status",
};

export const LOOP_STATUS_BADGES = {
    OPEN: "Open",
    CLOSED: "Closed",
};

export const RESOLUTION_STATUS_BADGE = {
    PENDING: "Pending",
    SUCCEEDED: "Succeeded",
    FAILED: "Failed",
};

export const TIMELINE = {
    ASSET_EVENT_NOTES: "30 days of events prior to Loop opened",
    LOOP_OPENED: "Loop opened",
    LOOP_CLOSED: "Loop closed",
    RESOLUTION: (name: string) => `${name} resolution`,
};
