import styled, { useTheme } from "../../../../styling/styled-components";
import Button from "../../../common/Button";
import { ButtonText } from "../common/CancelRental";
import { buttonFontColor } from "../utils/helpers";

const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.large}px;
`;

const CancelStyleButton = styled(Button)`
    display: flex;
    width: 207px;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 44px;
`;
export function CancelButton({ onFilterClose, cancelRental, disabled }) {
    const { colors } = useTheme();

    return (
        <ButtonDiv data-override-outside-click={true}>
            <CancelStyleButton
                type="secondary"
                label={
                    <ButtonText
                        type="label"
                        size="large"
                        color={buttonFontColor("cancel", colors)}
                    >
                        {"Nevermind"}
                    </ButtonText>
                }
                onClick={onFilterClose}
            ></CancelStyleButton>
            <CancelStyleButton
                type="primary"
                onClick={cancelRental}
                status={disabled ? "disabled" : "default"}
                label={
                    <ButtonText
                        type="label"
                        size="large"
                        color={buttonFontColor("confirm", colors)}
                    >
                        {"Confirm & cancel rental"}
                    </ButtonText>
                }
            ></CancelStyleButton>
        </ButtonDiv>
    );
}
