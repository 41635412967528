import styled, { css } from "../../../styling/styled-components";

//components
import PercentageChart from "../../common/charts/PercentageChart";
import ShimmerCard from "../../common/ShimmerCard";

const Chart = styled.div`
    display: flex;
    flex: 1;
    max-height: 137px;
    max-width: fit-content;
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            padding-bottom: ${({ theme }) => theme.spacing.medium}px;
            max-width: 200px;
            flex-direction: column;
            justify-content: center;
            flex: auto;
        `}
`;

const LoadingSquareWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xmlarge}px;
    flex-shrink: 0;
    position: absolute;
    z-index: 10;
`;

const StyledSquareLoadingCard = () => {
    return (
        <LoadingSquareWrapper>
            <ShimmerCard height={50} width={50}></ShimmerCard>
        </LoadingSquareWrapper>
    );
};

export default function RatePercentageChart({ item, isShown, loading }) {
    const { percentage } = item;

    return (
        <>
            {loading && <StyledSquareLoadingCard></StyledSquareLoadingCard>}
            <Chart>
                <PercentageChart
                    percentage={isShown ? percentage : 0}
                    loading={loading}
                ></PercentageChart>
            </Chart>
        </>
    );
}
