//styles
import styled, { useTheme } from "../../styling/styled-components";

//svgs
import { ReactComponent as LargeCloud } from "../../assets/vectors/LargeCloud.svg";
import { ReactComponent as SmallCloud } from "../../assets/vectors/SmallCloud.svg";

//components
import Text from "../common/Text";

const PageNotFoundDiv = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const SmallCloudDiv = styled.div`
    padding-right: ${({ theme }) => theme.spacing.huge}px;
    padding-top: ${({ theme }) => theme.spacing.yuge}px;
    margin-top: ${({ theme }) => theme.spacing.yuge}px;
`;

const LargeCloudDiv = styled.div`
    padding-top: ${({ theme }) => theme.spacing.small}px;
    padding-left: ${({ theme }) => theme.spacing.huge}px;
`;

const NotFoundTextWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xmlarge}px;
    padding-left: ${({ theme }) => theme.spacing.xlarge}px;
`;

const PageNotFoundText = styled(Text)`
    width: 410px;
`;

const STRINGS = {
    HEADER: "The page you are looking for does not exist.",
    SUB_HEADER: "Please make sure you entered the correct URL and try again. ",
};

export default function PageNotFound() {
    const { colors } = useTheme();

    const CloudIcons = () => {
        return (
            <>
                <SmallCloudDiv>
                    <SmallCloud />
                </SmallCloudDiv>
                <LargeCloudDiv>
                    <LargeCloud />
                </LargeCloudDiv>
            </>
        );
    };

    return (
        <>
            <PageNotFoundDiv>
                <CloudIcons />
                <NotFoundTextWrapper>
                    <PageNotFoundText
                        type="header"
                        size="large"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {STRINGS.HEADER}
                    </PageNotFoundText>
                    <PageNotFoundText
                        type="body"
                        size="medium"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {STRINGS.SUB_HEADER}
                    </PageNotFoundText>
                </NotFoundTextWrapper>
            </PageNotFoundDiv>
        </>
    );
}
