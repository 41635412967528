//style
import styled from "../../../styling/styled-components";

//component
import Text from "../../common/Text";

const HeaderText = styled(Text)`
    margin-right: ${({ theme }) => theme.spacing.yuge}px;
    padding-right: ${({ theme }) => theme.spacing.large}px;
    padding-top: ${({ theme }) => theme.spacing.xlarge}px;
`;

export default function SectionTitle({ children, color, ...props }: Props) {
    return (
        <HeaderText type="header" size="little" color={color}>
            {children}
        </HeaderText>
    );
}
