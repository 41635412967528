// TODO: TEC-1978 combine code that this form and CreateAssetType both use into one form
// react
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// style
import styled, { useTheme } from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";

// API
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import useGraphClient from "../../hooks/useGraphClient";
import { UpdateAssetTypeInput, AssetType } from "../../API";

// images
import { ReactComponent as BackChevron } from "../../assets/vectors/BackChevron.svg";

// context
import AssetsContext from "../../contexts/AssetsContext";

// components
import Button from "../common/Button";
import ClipLoader from "react-spinners/ClipLoader";
import ConfirmationModal from "../common/ConfirmationModal";
import Text from "../common/Text";
import FormTextField from "../common/FormTextField";
import MetaDataForm from "./MetaDataForm";
import ScreenView from "../common/ScreenView";
import { toastError, toastSuccess } from "../../utils/toasts";

// utils
import { randomToken } from "../../utils/helpers";
import convertAssetValueToDollarString from "./utils/convertAssetValueToDollarString";
import convertDollarStringToAssetValue from "./utils/convertDollarStringToAssetValue";

// constants
import { API_ERROR } from "../../constants/errors";
import { EDIT_ASSET_TYPE } from "../../constants/assets";

const MAX_INTERACTIONS = 2;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const CancelButton = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CancelButtonLabel = styled(Text)`
    margin-left: ${({ theme }) => theme.spacing.xsmall}px;
    color: ${({ theme }) => theme.colors.WAVE_STORM};
`;

const SaveButton = styled(Button)`
    margin: 0;
`;

const AssetTypeWrapper = styled(Wrapper)`
    margin-top: 28px;
`;

const AddMetaDataWrapper = styled(Wrapper)`
    margin-top: 36px;
`;

const NewAssetLabel = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
`;

const NewAssetInstr = styled(Text)`
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const NewAssetTypeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xxlarge}px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: ${({ theme }) => theme.spacing.xxlarge}px
        ${({ theme }) => theme.spacing.large}px;
    margin-top: ${({ theme }) => theme.spacing.medium}px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const AddNewMetaDataWrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-top: ${({ theme }) => theme.spacing.xlarge}px;
`;

const PlusIcon = styled(Text)`
    color: ${({ theme }) => theme.colors.WAVE_STORM};
`;

const NewAssetTypeLabel = styled(Text)`
    color: ${({ theme }) => theme.colors.WAVE_STORM};
    margin-left: ${({ theme }) => theme.spacing.large}px;
`;

const NewAssetTextInput = styled(FormTextField)`
    flex: 1;
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const NameTextInput = styled(NewAssetTextInput)``;

const LabelNameWrapper = styled(Row)`
    justify-content: space-between;
`;

const LabelText = styled(Text)`
    margin-top: 4px;
    color: ${({ isNotEmpty, theme }) =>
        isNotEmpty ? theme.colors.EXHAUST : theme.colors.DEEP_BLUE_SEA};
`;

const CounterNameLabelText = styled(LabelText)`
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
`;

const ContentRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 515px;
    margin: 0 auto;
`;

const ContentWrapper = styled.div`
    width: 611px;
    margin: 0 auto;
`;

export default function EditAssetTypeScreen({ route, screenWidth }: Props) {
    const emptyMetadata = {
        key: "",
        value: "",
        isNew: true,
    };
    const { assetTypeId } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const returnToList = searchParams.get("returnToList") === "true";
    const { colors } = useTheme();
    const [getAssetTypeData, setGetAssetTypeData] = useState<AssetType>({});
    const [getAssetTypeLoading, setGetAssetTypeLoading] = useState(true);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [shouldShowMetadataErrors, setShouldShowMetaDataErrors] =
        useState<boolean>(false);
    const [newAssetName, setNewAssetName] = useState<string>("");
    const [newAssetDescr, setNewAssetDescr] = useState<string>("");
    const [newAssetExtId, setNewAssetExtId] = useState<number>();
    const [newAssetValue, setNewAssetValue] = useState<string>("");
    const [counterAssetName, setCounterAssetName] = useState<number>(0);
    const [newMetaData, setNewMetaData] = useState<array>([emptyMetadata]);
    const [isMetadataSectionOpen, setIsMetadataSectionOpen] = useState<boolean>(
        newMetaData && !!newMetaData[0].key
    );
    const [saveAssetTypeLoading, setSaveAssetTypeLoading] =
        useState<boolean>(false);
    const [counterInteraction, setCounterInteraction] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { selections, updateSelections } = useContext(AssetsContext);

    const graphClient = useGraphClient();

    const loadAssetTypes = async (thisToken?: string) => {
        try {
            const results = await graphClient.graphql({
                query: queries.listAssetTypes,
            });

            updateSelections({ assets: results.data.listAssetTypes.results });
        } catch (err) {
            console.error("Error listing AssetTypes", err);
        }
    };

    const setInitialData = () => {
        setNewAssetName(getAssetTypeData?.name);
        setNewAssetDescr(getAssetTypeData?.description);
        setNewAssetExtId(getAssetTypeData?.externalId);
        setNewAssetValue(
            convertAssetValueToDollarString(getAssetTypeData?.value)
        );
        setCounterInteraction(0);
        setIsFormValid(true);
        if (getAssetTypeData?.metadata?.length) {
            const initMetadata = getAssetTypeData?.metadata.map((data) => {
                return {
                    ...data,
                    isNew: true,
                };
            });
            setNewMetaData(initMetadata);
        }
    };

    useEffect(() => {
        setInitialData();
    }, [
        getAssetTypeData?.name,
        getAssetTypeData?.description,
        getAssetTypeData?.externalId,
        getAssetTypeData?.metadata,
    ]);

    useEffect(() => {
        getAssetType(assetTypeId);
    }, [assetTypeId]);

    useEffect(() => {
        const nonEmptyMetadata = newMetaData.filter(
            (data) => data.key || data.value
        );
        setIsMetadataSectionOpen(nonEmptyMetadata.length > 0);
    }, [newMetaData]);

    useEffect(() => {
        const formValidation = () => {
            if (!!newAssetName) {
                setIsFormValid(true);
            } else {
                setIsFormValid(false);
            }
        };
        formValidation();
    }, [newAssetName]);

    const getAssetType = async (assetTypeId: string) => {
        setGetAssetTypeLoading(true);
        try {
            const results = await graphClient.graphql({
                query: queries.getAssetType,
                variables: {
                    assetTypeId,
                },
            });
            setGetAssetTypeData(results.data.getAssetType);
            setGetAssetTypeLoading(false);
        } catch (err) {
            setGetAssetTypeLoading(false);
            console.error("Error getting AssetType", err);
        }
    };

    const toggleMetadataSection = () => {
        setIsMetadataSectionOpen(
            (isMetadataSectionOpen) => !isMetadataSectionOpen
        );
    };

    const cancelEdit = () => {
        closeModal();
        setInitialData();
        navigate("/dashboard/assets/" + (returnToList ? "" : assetTypeId));
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        if (counterInteraction > MAX_INTERACTIONS) {
            openModal();
        } else {
            cancelEdit();
        }
    };

    const addExtraEmptyMetaData = () => {
        const updatedMetadataArr = [...newMetaData];
        updatedMetadataArr.push(emptyMetadata);
        setNewMetaData(updatedMetadataArr);
    };

    const handleOnSaveSuccess = () => {
        setInitialData();
        if (returnToList) {
            setSaveAssetTypeLoading(false);
            toastSuccess(EDIT_ASSET_TYPE.SUCCESS_MESSAGE);
            loadAssetTypes();
            navigate("/dashboard/assets");
        } else {
            setSaveAssetTypeLoading(false);
            toastSuccess(EDIT_ASSET_TYPE.SUCCESS_MESSAGE);
            setTimeout(() => {
                loadAssetTypes();
                navigate("/dashboard/assets/" + assetTypeId);
            }, 2000); // hide toast after 2s
        }
    };

    const handleSaveAsset = async () => {
        const isMetadataValid = newMetaData.every((data) => {
            return !(!data.key && !!data.value) && !(!!data.key && !data.value);
        });

        if (!isMetadataValid) {
            toastError(EDIT_ASSET_TYPE.ERROR_TOAST_MESSAGE_INCOMPLETE);
            setShouldShowMetaDataErrors(true);
        } else {
            setSaveAssetTypeLoading(true);

            const reformattedNewMetadata = newMetaData.map((md) => {
                return Object.keys(md)
                    .filter(
                        (key) => key.includes("key") || key.includes("value")
                    )
                    .reduce((cur, key) => {
                        return Object.assign(cur, { [key]: md[key] });
                    }, {});
            });

            const newAssetType: UpdateAssetTypeInput = {
                assetTypeId,
                name: newAssetName,
                externalId: newAssetExtId,
                description: newAssetDescr,
                metadata: reformattedNewMetadata,
                value: convertDollarStringToAssetValue(newAssetValue),
            };
            try {
                const saveResponse = await graphClient.graphql({
                    query: mutations.updateAssetType,
                    variables: {
                        input: newAssetType,
                    },
                });
                setGetAssetTypeData(saveResponse.data.updateAssetType);
            } catch (error) {
                console.error(
                    "Error saving new AssetType",
                    JSON.stringify(error.message)
                );
                toastError(API_ERROR);
            } finally {
                handleOnSaveSuccess();
            }
        }
    };

    const handleAssetNameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setNewAssetName(text);
        setCounterAssetName(text.length);
        setCounterInteraction((counterInteraction) => counterInteraction + 1);
    };

    const handleAssetTextChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setNewAssetDescr(text);
        setCounterInteraction((counterInteraction) => counterInteraction + 1);
    };

    const handleAssetExternalIDChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setNewAssetExtId(text);
        setCounterInteraction((counterInteraction) => counterInteraction + 1);
    };

    const handleAssetValueChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setNewAssetValue(text);
        setCounterInteraction((counterInteraction) => counterInteraction + 1);
    };

    return (
        <ScreenView
            screenName="CREATE_ASSET_TYPE"
            shouldShowHeader={false}
            shouldShowCounter={false}
        >
            {getAssetTypeLoading || !getAssetTypeData ? (
                <ClipLoader loading />
            ) : (
                <Wrapper>
                    <ConfirmationModal
                        isOpen={isModalOpen}
                        title={EDIT_ASSET_TYPE.CANCEL_TITLE}
                        subtitle={EDIT_ASSET_TYPE.CANCEL_SUBTITLE}
                        onConfirm={cancelEdit}
                        onCancel={closeModal}
                    />
                    <Header>
                        <CancelButton onClick={handleCancel}>
                            <BackChevron />
                            <CancelButtonLabel type="other" size="large">
                                {EDIT_ASSET_TYPE.CANCEL_LABEL}
                            </CancelButtonLabel>
                        </CancelButton>
                        <SaveButton
                            label={EDIT_ASSET_TYPE.SAVE_LABEL}
                            status={
                                counterInteraction == 0
                                    ? "disabled"
                                    : isFormValid || saveAssetTypeLoading
                                    ? "default"
                                    : "disabled"
                            }
                            isLoading={saveAssetTypeLoading}
                            onClick={handleSaveAsset}
                        />
                    </Header>
                    <ContentWrapper>
                        <AssetTypeWrapper>
                            <NewAssetLabel type="header" size="large">
                                {EDIT_ASSET_TYPE.EDIT_ASSET_TYPE}
                            </NewAssetLabel>
                            <NewAssetTypeWrapper>
                                <ContentRow>
                                    <NameTextInput
                                        value={newAssetName}
                                        onChange={handleAssetNameChange}
                                        placeholder={
                                            EDIT_ASSET_TYPE.PLACEHOLDER_NAME
                                        }
                                        maxLength={
                                            EDIT_ASSET_TYPE.MAX_LENGTH_NAME
                                        }
                                        secureTextEntry={false}
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        returnKeyType="done"
                                        hintText={EDIT_ASSET_TYPE.LABEL_NAME}
                                        maxLength={
                                            EDIT_ASSET_TYPE.MAX_LENGTH_NAME
                                        }
                                    />
                                </ContentRow>
                                <ContentRow>
                                    <NewAssetTextInput
                                        value={newAssetDescr}
                                        onChange={handleAssetTextChange}
                                        placeholder={
                                            EDIT_ASSET_TYPE.PLACEHOLDER_DESCR
                                        }
                                        secureTextEntry={false}
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        returnKeyType="done"
                                        hintText={EDIT_ASSET_TYPE.LABEL_DESCR}
                                    />
                                </ContentRow>
                                <ContentRow>
                                    <NewAssetTextInput
                                        value={newAssetExtId}
                                        onChange={handleAssetExternalIDChange}
                                        keyboardType="numeric"
                                        placeholder={
                                            EDIT_ASSET_TYPE.PLACEHOLDER_EXTID
                                        }
                                        secureTextEntry={false}
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        returnKeyType="done"
                                        hintText={EDIT_ASSET_TYPE.LABEL_EXTID}
                                    />
                                </ContentRow>
                                <ContentRow>
                                    <NewAssetTextInput
                                        value={newAssetValue}
                                        onChange={handleAssetValueChange}
                                        placeholder={
                                            EDIT_ASSET_TYPE.PLACEHOLDER_VALUE
                                        }
                                        secureTextEntry={false}
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        returnKeyType="done"
                                        hintText={EDIT_ASSET_TYPE.LABEL_VALUE}
                                    />
                                </ContentRow>
                            </NewAssetTypeWrapper>
                            {isMetadataSectionOpen ? null : (
                                <AddNewMetaDataWrapper
                                    onClick={toggleMetadataSection}
                                >
                                    <PlusIcon type="other" size="large">
                                        +
                                    </PlusIcon>
                                    <NewAssetTypeLabel
                                        type="other"
                                        size="large"
                                    >
                                        {EDIT_ASSET_TYPE.ADD_META_DATA}
                                    </NewAssetTypeLabel>
                                </AddNewMetaDataWrapper>
                            )}
                        </AssetTypeWrapper>
                        {isMetadataSectionOpen ? (
                            <AddMetaDataWrapper>
                                <NewAssetLabel type="header" size="small">
                                    {EDIT_ASSET_TYPE.CREATE_METADATA_TITLE}
                                </NewAssetLabel>
                                <NewAssetInstr type="body" size="medium">
                                    {EDIT_ASSET_TYPE.CREATE_METADATA_INSTR}
                                </NewAssetInstr>
                                <MetaDataForm
                                    callbackUpdateNewMetadata={(
                                        updatedMetadata
                                    ) => {
                                        setNewMetaData(updatedMetadata);
                                        setCounterInteraction(
                                            (counterInteraction) =>
                                                counterInteraction + 1
                                        );
                                    }}
                                    newMetaData={newMetaData}
                                    shouldShowMetadataErrors={
                                        shouldShowMetadataErrors
                                    }
                                    toggleMetadataSection={
                                        toggleMetadataSection
                                    }
                                />
                                <AddNewMetaDataWrapper
                                    onClick={addExtraEmptyMetaData}
                                >
                                    <PlusIcon type="other" size="large">
                                        +
                                    </PlusIcon>
                                    <NewAssetTypeLabel
                                        type="other"
                                        size="large"
                                    >
                                        {EDIT_ASSET_TYPE.ADD_META_DATA}
                                    </NewAssetTypeLabel>
                                </AddNewMetaDataWrapper>
                            </AddMetaDataWrapper>
                        ) : null}
                    </ContentWrapper>
                </Wrapper>
            )}
        </ScreenView>
    );
}
