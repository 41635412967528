//react
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//component
import Text from "../common/Text";
import ConfirmationModal from "../common/ConfirmationModal";
import PopupMenu from "../common/PopupMenu";
import { ASSET_CARD } from "../../constants/assets";
import { toastError, toastSuccess } from "../../utils/toasts";
import HighlightHover from "../common/HighlightHover";
import LinkText from "../common/LinkText";

//images
import { ReactComponent as Dots } from "../../assets/vectors/Dots.svg";

//style
import styled, { useTheme } from "styled-components/macro";

//api
import { AssetType } from "../../API";
import useGraphClient from "../../hooks/useGraphClient";
import * as mutations from "../../graphql/mutations";

//constants
import { API_ERROR } from "../../constants/errors";

const Wrapper = styled.div`
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: ${({ theme }) => theme.spacing.large}px;
    margin-top: ${({ theme }) => theme.spacing.small}px;
    overflow: visible;
    height: 115px;
`;

const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const RightWrapper = styled.div`
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    cursor: pointer;
`;

const AssetDescription = styled(Text)``;

const IdWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

const ExternalId = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.small}px;
`;

const Id = styled(ExternalId)``;

type Props = {
    assetData?: AssetType;
    locationId?: string;
    className: string;
    loadAssetTypes: (paginationToken: string, appendMode: boolean) => void;
};

export default function AssetCard({
    className,
    assetData = {},
    loadAssetTypes,
}: Props) {
    const navigate = useNavigate();
    const { colors } = useTheme();
    const { assetTypeId, description, externalId, name } = assetData;
    const [shouldShowCancel, setShouldShowCancel] = useState(false);

    const graphClient = useGraphClient();

    const popupOptions = [
        {
            label: "Edit asset type",
            isAvailable: true,
            onClick: () => onEditAssetType(assetTypeId),
        },
        {
            label: "Delete asset type",
            isAvailable: true,
            onClick: () => setShouldShowCancel(true),
        },
    ];

    const onEditAssetType = () => {
        navigate(assetTypeId + "/edit?returnToList=true");
    };

    const onDeleteAssetType = async (assetTypeId: string): null => {
        try {
            await graphClient.graphql({
                query: mutations.deleteAssetType,
                variables: {
                    input: { assetTypeId },
                },
            });
            toastSuccess(ASSET_CARD.ASSET_TYPE_DELETED);
            setShouldShowCancel(false);
            loadAssetTypes("", false);
        } catch (error) {
            console.error("Error deleting AssetType", error);
            toastError(API_ERROR);
        }
    };

    return (
        <Wrapper className={className}>
            <ConfirmationModal
                isOpen={shouldShowCancel}
                title={ASSET_CARD.DELETE_CONFIRM_TITLE}
                subtitle={ASSET_CARD.DELETE_CONFIRM_SUBTITLE}
                onConfirm={() => onDeleteAssetType(assetTypeId)}
                onCancel={() => {
                    setShouldShowCancel(false);
                }}
            />
            <LeftWrapper>
                {name ? (
                    <LinkText
                        type="header"
                        size="large"
                        color={colors.WAVE_STORM}
                        onClick={() => navigate(assetTypeId)}
                    >
                        {name}
                    </LinkText>
                ) : null}
                {description ? (
                    <AssetDescription
                        type="body"
                        size="large"
                        color={colors.ROCK_BOTTOM}
                    >
                        {description}
                    </AssetDescription>
                ) : null}
                <IdWrapper>
                    {assetTypeId ? (
                        <Id type="body" size="medium" color={colors.EXHAUST}>
                            ID: {assetTypeId}
                        </Id>
                    ) : null}
                    {externalId ? (
                        <ExternalId
                            type="body"
                            size="medium"
                            color={colors.EXHAUST}
                        >
                            External ID: {externalId}
                        </ExternalId>
                    ) : null}
                </IdWrapper>
            </LeftWrapper>
            <RightWrapper>
                <HighlightHover>
                    <PopupMenu
                        customButton={<Dots color={colors.SUMMER_STORM} />}
                        customOpenButton={<Dots color={colors.WAVE_STORM} />}
                        options={popupOptions}
                    />
                </HighlightHover>
            </RightWrapper>
        </Wrapper>
    );
}
