// react
import React, { useState, useEffect, useContext } from "react";

// API
import { Location } from "../../API";

// style
import styled, { useTheme } from "styled-components/macro";

// context
import LocationsContext from "../../contexts/LocationsContext";

// components
import LocationDropdown from "../common/LocationDropdown";
import { Switch } from "@mui/material";
import Text from "../common/Text";

// constants
import { SCAN_APP_USER_LOCATION_SETTINGS } from "../../constants/scanapp";

const Container = styled.div``;

const HeaderWrapper = styled.div`
    padding-top: ${({ theme, updateMode }) =>
        updateMode ? theme.spacing.tiny : theme.spacing.huge}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const LocationsPermissionsWrapper = styled(HeaderWrapper)`
    padding-top: ${({ theme }) => theme.spacing.large}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const LocationsWrapper = styled.div``;

const StyledLocationDropdown = styled(LocationDropdown)``;

const Section = styled.div`
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.medium}px;
    margin: 3px;
`;

const SelectedLocationSection = styled(Section)`
    padding: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const ToggleSection = styled(Section)`
    flex-direction: row;
    align-items: center;
    display: flex;
    padding: ${({ theme }) => theme.spacing.medium}px
        ${({ theme }) => theme.spacing.xxlarge}px;
`;

const ToggleLocationSection = styled(ToggleSection)`
    justify-content: space-between;
`;

const ToggleLeft = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
`;

const ToggleBody = styled.div`
    padding-left: ${({ theme }) => theme.spacing.medium}px;
`;

const AllowAllLocationsWrapper = styled.div`
    cursor: pointer;
    width: fit-content;
`;

const SelectedLocationWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.SMOG};
    border-radius: 4px;
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
    padding-right: ${({ theme }) => theme.spacing.xsmall}px;
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    padding-bottom: ${({ theme }) => theme.spacing.tiny}px;
`;

const CharcoalText = styled(Text)`
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const AshText = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const ExhaustText = styled(Text)`
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

const HeaderText = styled(CharcoalText)``;

const LocationsHeaderText = styled(AshText)``;

const HelpText = styled(AshText)`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const OneLocationText = styled(HelpText)`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
`;

const LabelText = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

const SelectLabelText = styled(LabelText)`
    color: ${({ theme }) => theme.colors.ASH};
`;

const ContentWrapper = styled.div`
    width: 610px;
    margin: 0 auto;
`;

type LocationProps = {
    location: Location;
};

const LocationName = ({ location }: LocationProps) => {
    return (
        <ToggleBody>
            <BlueText type="label" size="medium">
                {location.fullName}
            </BlueText>
            <ExhaustText type="label" size="small">
                {`${location.address?.line1 || ""}${
                    location.address?.line3
                        ? ", " + location.address?.line3
                        : ""
                }${
                    location.address?.postalCode
                        ? ", " + location.address?.postalCode
                        : ""
                }`}
            </ExhaustText>
        </ToggleBody>
    );
};

const changeLocations = (
    allLocationsEnabled: boolean,
    enabledLocationIds: string[]
): boolean => {
    return allLocationsEnabled || enabledLocationIds.length > 1;
};

type Props = {
    allLocationsEnabled: boolean;
    enabledLocationIds: string[];
    selectedLocationId: string;
    onAllLocationsEnabledChange: (allLocationsEnabled: boolean) => null;
    onEnabledLocationIdsChange: (enabledLocationIds: string[]) => null;
    onSelectedLocationIdChange: (selectedLocationId: string) => null;
    updateMode: boolean;
};

export default function ScanAppUserLocationSettings({
    allLocationsEnabled,
    enabledLocationIds,
    selectedLocationId,
    onAllLocationsEnabledChange,
    onEnabledLocationIdsChange,
    onSelectedLocationIdChange,
    updateMode,
    style,
}: Props) {
    const [canChangeLocations, setCanChangeLocations] = useState<boolean>(
        changeLocations(allLocationsEnabled, enabledLocationIds)
    );

    const { colors } = useTheme();

    const { locations } = useContext(LocationsContext).selections;

    useEffect(() => {
        setCanChangeLocations(
            changeLocations(allLocationsEnabled, enabledLocationIds)
        );
    }, [allLocationsEnabled, enabledLocationIds]);

    useEffect(() => {
        if (allLocationsEnabled) {
            onEnabledLocationIdsChange(
                locations.map((location) => location.locationId)
            );
        }
    }, [allLocationsEnabled]);

    const handleToggleAllowedLocation = (locationId: string): null => {
        let newEnabledLocationIds = enabledLocationIds;
        if (enabledLocationIds.includes(locationId)) {
            newEnabledLocationIds = enabledLocationIds.filter(
                (location) => location != locationId
            );
        } else {
            newEnabledLocationIds = [...newEnabledLocationIds, locationId];
        }
        onAllLocationsEnabledChange(
            newEnabledLocationIds.length == locations.length
        );
        onEnabledLocationIdsChange(newEnabledLocationIds);
    };

    return (
        <Container style={style}>
            <HeaderWrapper updateMode={updateMode}>
                <HeaderText type="header" size="medium">
                    {updateMode
                        ? SCAN_APP_USER_LOCATION_SETTINGS.SELECTED_LOCATION_LABEL
                        : SCAN_APP_USER_LOCATION_SETTINGS.LOCATION_SETTINGS_AND_PERMISSIONS}
                </HeaderText>
                <HelpText type="body" size="medium">
                    {updateMode
                        ? SCAN_APP_USER_LOCATION_SETTINGS.UPDATE_LOCATION_SETTINGS_AND_PERMISSION_HELP
                        : SCAN_APP_USER_LOCATION_SETTINGS.LOCATION_SETTINGS_AND_PERMISSIONS_HELP}
                </HelpText>
                {!updateMode && (
                    <OneLocationText type="body" size="medium">
                        {SCAN_APP_USER_LOCATION_SETTINGS.ONE_LOCATION}
                    </OneLocationText>
                )}
            </HeaderWrapper>
            <SelectedLocationSection>
                <StyledLocationDropdown
                    showHint={false}
                    hintColor={colors.EXHAUST}
                    titleOverride={
                        SCAN_APP_USER_LOCATION_SETTINGS.SELECTED_LOCATION_HINT
                    }
                    selectedId={selectedLocationId}
                    onChange={(item) => {
                        onSelectedLocationIdChange(item.id);
                        onEnabledLocationIdsChange([
                            ...new Set([...enabledLocationIds, item.id]),
                        ]);
                    }}
                    searchable={false}
                    formField
                    isClearable={!updateMode}
                    clearOnClose={false}
                />
                <SelectLabelText type="other" size="medium">
                    {SCAN_APP_USER_LOCATION_SETTINGS.STARTING_LOCATION_LABEL}
                </SelectLabelText>
                <LabelText type="label" size="small">
                    {updateMode
                        ? SCAN_APP_USER_LOCATION_SETTINGS.CURRENTLY_USED
                        : SCAN_APP_USER_LOCATION_SETTINGS.FIRST_LOGIN}
                </LabelText>
            </SelectedLocationSection>
            <ToggleSection>
                <Switch
                    checked={canChangeLocations}
                    onChange={() => setCanChangeLocations(!canChangeLocations)}
                />
                <ToggleBody>
                    <BlueText type="label" size="medium">
                        {SCAN_APP_USER_LOCATION_SETTINGS.CHANGE_LOCATIONS}
                    </BlueText>
                    <ExhaustText type="label" size="small">
                        {canChangeLocations
                            ? SCAN_APP_USER_LOCATION_SETTINGS.CHANGE_LOCATIONS_ON
                            : SCAN_APP_USER_LOCATION_SETTINGS.CHANGE_LOCATIONS_OFF}
                    </ExhaustText>
                </ToggleBody>
            </ToggleSection>
            {canChangeLocations && (
                <LocationsWrapper>
                    <LocationsPermissionsWrapper>
                        <HeaderText type="header" size="smallest">
                            {
                                SCAN_APP_USER_LOCATION_SETTINGS.LOCATION_PERMISSIONS
                            }
                        </HeaderText>
                        <HelpText type="body" size="medium">
                            {
                                SCAN_APP_USER_LOCATION_SETTINGS.LOCATION_PERMISSIONS_HELP
                            }
                        </HelpText>
                    </LocationsPermissionsWrapper>
                    <AllowAllLocationsWrapper
                        onClick={() => {
                            if (allLocationsEnabled) {
                                onAllLocationsEnabledChange(false);
                                onEnabledLocationIdsChange([]);
                            } else {
                                onAllLocationsEnabledChange(true);
                                onEnabledLocationIdsChange([]);
                            }
                        }}
                    >
                        <BlueText type="link" size="small">
                            {allLocationsEnabled
                                ? SCAN_APP_USER_LOCATION_SETTINGS.UNSELECT_ALL_LOCATIONS
                                : SCAN_APP_USER_LOCATION_SETTINGS.SELECT_ALL_LOCATIONS}
                        </BlueText>
                    </AllowAllLocationsWrapper>
                    {locations.map((location) => (
                        <ToggleLocationSection key={location.locationId}>
                            <ToggleLeft>
                                <Switch
                                    checked={
                                        enabledLocationIds.includes(
                                            location.locationId
                                        ) ||
                                        allLocationsEnabled ||
                                        location.locationId ==
                                            selectedLocationId
                                    }
                                    onChange={() =>
                                        location.locationId !==
                                            selectedLocationId &&
                                        handleToggleAllowedLocation(
                                            location.locationId
                                        )
                                    }
                                />
                                <LocationName location={location} />
                            </ToggleLeft>
                            {location.locationId == selectedLocationId && (
                                <SelectedLocationWrapper>
                                    <BlueText type="label" size="small">
                                        {updateMode
                                            ? SCAN_APP_USER_LOCATION_SETTINGS.SELECTED_LOCATION
                                            : SCAN_APP_USER_LOCATION_SETTINGS.STARTING_LOCATION}
                                    </BlueText>
                                </SelectedLocationWrapper>
                            )}
                        </ToggleLocationSection>
                    ))}
                </LocationsWrapper>
            )}
        </Container>
    );
}

ScanAppUserLocationSettings.defaultProps = {
    selectedLocationId: null,
    enabledLocationIds: [],
    allLocationsEnabled: false,
    updateMode: true,
};
