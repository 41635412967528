//components
import { ClipLoader } from "react-spinners";

//styles
import styled from "../../../styling/styled-components";

const SpinnerWrapper = styled.div`
    height: 300px;
    position: relative;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}%;
    padding-top: ${({ theme }) => theme.spacing.medium}%;
`;

export default function ChartSpinner({}) {
    return (
        <SpinnerWrapper>
            <ClipLoader></ClipLoader>
        </SpinnerWrapper>
    );
}
