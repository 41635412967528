/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const containers = /* GraphQL */ `
  query Containers {
    containers {
      id
      name
      weight {
        weight
        units
      }
    }
  }
`;
export const hardwareUnit = /* GraphQL */ `
  query HardwareUnit($iotThingName: String!) {
    hardwareUnit(iotThingName: $iotThingName) {
      iotThingName
      location {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
        }
        metadata {
          key
          value
        }
        createdAt
        updatedAt
      }
      installedOn
      updatedAt
      createdAt
    }
  }
`;
export const hardwareUnits = /* GraphQL */ `
  query HardwareUnits($paginationToken: String) {
    hardwareUnits(paginationToken: $paginationToken) {
      results {
        iotThingName
        location {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
          createdAt
          updatedAt
        }
        installedOn
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const observation = /* GraphQL */ `
  query Observation($id: ID!) {
    observation(id: $id) {
      id
      observationType
      capturedBy {
        iotThingName
        location {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
          createdAt
          updatedAt
        }
        installedOn
        updatedAt
        createdAt
      }
      timestamp
      grossWeight {
        weight
        units
      }
      netWeight {
        weight
        units
      }
      images {
        bucket
        key
        url
        metadata
      }
      audio {
        observationId
        audioPath
        transcription
        transcribedBy
      }
      labelSessions {
        labelTimestamp
        menuId
        recognizableItemId
        recognizableItemType
        container {
          id
          name
          weight {
            weight
            units
          }
        }
        isFlagged
        labelType
        tags
        ... on HumanObservationLabel {
          labeler
          notes
        }
        ... on MachineObservationLabel {
          modelVersion
          modelMetadata
        }
      }
      statuses {
        uiCanceled
        uiCompleted
      }
      bestLabel {
        id
        name
        externalId
        costCurrency
        cost
        costUOMQty
        costUOM
        updatedAt
        createdAt
        ... on MenuItem {
          categories
          recipe {
            ingredientId
            qty
            qtyUOM
            perQty
            perUOM
          }
        }
        ... on Ingredient {
          labels
        }
      }
      container {
        id
        name
        weight {
          weight
          units
        }
      }
      value {
        value
        costCurrency
        byCount
        countingUnit
        count
      }
      tags
    }
  }
`;
export const observations = /* GraphQL */ `
  query Observations(
    $fromTimestamp: String!
    $toTimestamp: String!
    $parentLocationIds: [ID]
    $types: [ObservationType]
    $includeTags: [ObservationTag]
    $excludeTags: [ObservationTag]
    $searchQuery: String
    $aggregationOption: ObservationAggregationOption
    $paginateFrom: Int
    $size: Int
  ) {
    observations(
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      parentLocationIds: $parentLocationIds
      types: $types
      includeTags: $includeTags
      excludeTags: $excludeTags
      searchQuery: $searchQuery
      aggregationOption: $aggregationOption
      paginateFrom: $paginateFrom
      size: $size
    ) {
      observations {
        id
        observationType
        capturedBy {
          iotThingName
          location {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
          installedOn
          updatedAt
          createdAt
        }
        timestamp
        grossWeight {
          weight
          units
        }
        netWeight {
          weight
          units
        }
        images {
          bucket
          key
          url
          metadata
        }
        audio {
          observationId
          audioPath
          transcription
          transcribedBy
        }
        labelSessions {
          labelTimestamp
          menuId
          recognizableItemId
          recognizableItemType
          container {
            id
            name
            weight {
              weight
              units
            }
          }
          isFlagged
          labelType
          tags
          ... on HumanObservationLabel {
            labeler
            notes
          }
          ... on MachineObservationLabel {
            modelVersion
            modelMetadata
          }
        }
        statuses {
          uiCanceled
          uiCompleted
        }
        bestLabel {
          id
          name
          externalId
          costCurrency
          cost
          costUOMQty
          costUOM
          updatedAt
          createdAt
          ... on MenuItem {
            categories
            recipe {
              ingredientId
              qty
              qtyUOM
              perQty
              perUOM
            }
          }
          ... on Ingredient {
            labels
          }
        }
        container {
          id
          name
          weight {
            weight
            units
          }
        }
        value {
          value
          costCurrency
          byCount
          countingUnit
          count
        }
        tags
      }
      totalHits
      aggregations {
        ... on NetWeightByDay {
          day
          grams
        }
      }
    }
  }
`;
export const menu = /* GraphQL */ `
  query Menu($id: ID, $externalId: ID) {
    menu(id: $id, externalId: $externalId) {
      id
      menuDate
      externalId
      location {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
        }
        metadata {
          key
          value
        }
        createdAt
        updatedAt
      }
      mealPeriod
      menuItems {
        id
        name
        externalId
        categories
        recipe {
          ingredientId
          qty
          qtyUOM
          perQty
          perUOM
        }
        costCurrency
        cost
        costUOMQty
        costUOM
        updatedAt
        createdAt
      }
      ingredients {
        id
        name
        externalId
        labels
        costCurrency
        cost
        costUOMQty
        costUOM
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;
export const menus = /* GraphQL */ `
  query Menus(
    $locationId: ID!
    $mealPeriod: MealPeriod
    $startDate: String!
    $endDate: String
    $paginationToken: String
  ) {
    menus(
      locationId: $locationId
      mealPeriod: $mealPeriod
      startDate: $startDate
      endDate: $endDate
      paginationToken: $paginationToken
    ) {
      results {
        id
        menuDate
        externalId
        location {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
          createdAt
          updatedAt
        }
        mealPeriod
        menuItems {
          id
          name
          externalId
          categories
          recipe {
            ingredientId
            qty
            qtyUOM
            perQty
            perUOM
          }
          costCurrency
          cost
          costUOMQty
          costUOM
          updatedAt
          createdAt
        }
        ingredients {
          id
          name
          externalId
          labels
          costCurrency
          cost
          costUOMQty
          costUOM
          updatedAt
          createdAt
        }
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const menuItem = /* GraphQL */ `
  query MenuItem($id: ID, $externalId: ID) {
    menuItem(id: $id, externalId: $externalId) {
      id
      name
      externalId
      categories
      recipe {
        ingredientId
        qty
        qtyUOM
        perQty
        perUOM
      }
      costCurrency
      cost
      costUOMQty
      costUOM
      updatedAt
      createdAt
    }
  }
`;
export const ingredient = /* GraphQL */ `
  query Ingredient($id: ID, $externalId: ID) {
    ingredient(id: $id, externalId: $externalId) {
      id
      name
      externalId
      labels
      costCurrency
      cost
      costUOMQty
      costUOM
      updatedAt
      createdAt
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization {
    getOrganization {
      organizationId
      name
      slug
      externalId
      metadata {
        key
        value
      }
      scanAppUsers
      scanAppUserLimit
      instantForgivenessEnabled
      returnReceivedForgivenessEnabled
    }
  }
`;
export const getParentLocation = /* GraphQL */ `
  query GetParentLocation($locationId: ID!) {
    getParentLocation(locationId: $locationId) {
      locationId
      name
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
      }
      externalId
      locations {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
        }
        metadata {
          key
          value
        }
        createdAt
        updatedAt
      }
      metadata {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const listParentLocations = /* GraphQL */ `
  query ListParentLocations($paginationToken: String) {
    listParentLocations(paginationToken: $paginationToken) {
      results {
        locationId
        name
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
        }
        externalId
        locations {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
          createdAt
          updatedAt
        }
        metadata {
          key
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($locationId: ID!) {
    getLocation(locationId: $locationId) {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
      }
      metadata {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations($paginationToken: String) {
    listLocations(paginationToken: $paginationToken) {
      results {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
        }
        metadata {
          key
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAsset = /* GraphQL */ `
  query GetAsset($assetId: ID!) {
    getAsset(assetId: $assetId) {
      assetId
      externalId
      value
      assetType {
        assetTypeId
        name
        description
        externalId
        value
        metadata {
          key
          value
        }
      }
      metadata {
        key
        value
      }
    }
  }
`;
export const listAssets = /* GraphQL */ `
  query ListAssets($paginationToken: String) {
    listAssets(paginationToken: $paginationToken) {
      results {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
          }
        }
        metadata {
          key
          value
        }
      }
      nextToken
    }
  }
`;
export const getAssetType = /* GraphQL */ `
  query GetAssetType($assetTypeId: ID!) {
    getAssetType(assetTypeId: $assetTypeId) {
      assetTypeId
      name
      description
      externalId
      value
      metadata {
        key
        value
      }
    }
  }
`;
export const listAssetTypes = /* GraphQL */ `
  query ListAssetTypes($paginationToken: String) {
    listAssetTypes(paginationToken: $paginationToken) {
      results {
        assetTypeId
        name
        description
        externalId
        value
        metadata {
          key
          value
        }
      }
      nextToken
    }
  }
`;
export const getResolution = /* GraphQL */ `
  query GetResolution($resolutionId: ID!) {
    getResolution(resolutionId: $resolutionId) {
      resolutionId
      type {
        likeAssetTypeResolution {
          likeAssetTypeIds
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
        sameAssetResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
        sameAssetTypeResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
      }
      label
      useAgain
      name
      description
      metadata {
        key
        value
      }
    }
  }
`;
export const listResolutions = /* GraphQL */ `
  query ListResolutions($paginationToken: String) {
    listResolutions(paginationToken: $paginationToken) {
      results {
        resolutionId
        type {
          likeAssetTypeResolution {
            likeAssetTypeIds
            resolutionTiming {
              timestamp
              duration
              roundToTime
              roundToTimezone
            }
          }
          sameAssetResolution {
            resolutionTiming {
              timestamp
              duration
              roundToTime
              roundToTimezone
            }
          }
          sameAssetTypeResolution {
            resolutionTiming {
              timestamp
              duration
              roundToTime
              roundToTimezone
            }
          }
        }
        label
        useAgain
        name
        description
        metadata {
          key
          value
        }
      }
      nextToken
    }
  }
`;
export const getConsumer = /* GraphQL */ `
  query GetConsumer($consumerId: ID, $externalId: ID, $reuserId: ID) {
    getConsumer(
      consumerId: $consumerId
      externalId: $externalId
      reuserId: $reuserId
    ) {
      consumerId
      reuserId
      name
      phoneNumber
      email
      externalId
      metadata {
        key
        value
      }
      marketingOptIn {
        email
        phoneNumber
      }
      createdAt
      chargeables {
        chargeableId
        vendor
      }
    }
  }
`;
export const listConsumers = /* GraphQL */ `
  query ListConsumers($paginationToken: String) {
    listConsumers(paginationToken: $paginationToken) {
      results {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
        }
        marketingOptIn {
          email
          phoneNumber
        }
        createdAt
        chargeables {
          chargeableId
          vendor
        }
      }
      nextToken
    }
  }
`;
export const getConsumerStats = /* GraphQL */ `
  query GetConsumerStats($consumerId: ID!) {
    getConsumerStats(consumerId: $consumerId) {
      consumerId
      openLoops
      totalClosedLoops
      totalOpenLoops
      updatedAt
    }
  }
`;
export const getLoop = /* GraphQL */ `
  query GetLoop($loopId: ID!) {
    getLoop(loopId: $loopId) {
      loopId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
          }
        }
        metadata {
          key
          value
        }
      }
      consumer {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
        }
        marketingOptIn {
          email
          phoneNumber
        }
        createdAt
        chargeables {
          chargeableId
          vendor
        }
      }
      locations {
        openedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
          createdAt
          updatedAt
        }
        closedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
          createdAt
          updatedAt
        }
      }
      externalId
      openedAt
      closedAt
      loopStatus
      resolutions {
        loopId
        resolution {
          resolutionId
          type {
            likeAssetTypeResolution {
              likeAssetTypeIds
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
              }
            }
            sameAssetResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
              }
            }
            sameAssetTypeResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
              }
            }
          }
          label
          useAgain
          name
          description
          metadata {
            key
            value
          }
        }
        resolutionStatus
        triggerResolutionAt
      }
      updatedAt
      metadata {
        key
        value
      }
      rentalConfig {
        rentalConfigId
        version
        name
        description
        resolutionOption
        likeAssetTypeIds
        rentalStepConfigIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const queryLoops = /* GraphQL */ `
  query QueryLoops(
    $fromTimestamp: String
    $toTimestamp: String
    $consumerId: ID
    $loopStatus: LoopStatus
    $paginationToken: String
  ) {
    queryLoops(
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      consumerId: $consumerId
      loopStatus: $loopStatus
      paginationToken: $paginationToken
    ) {
      results {
        loopId
        asset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        consumer {
          consumerId
          reuserId
          name
          phoneNumber
          email
          externalId
          metadata {
            key
            value
          }
          marketingOptIn {
            email
            phoneNumber
          }
          createdAt
          chargeables {
            chargeableId
            vendor
          }
        }
        locations {
          openedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
          closedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
        }
        externalId
        openedAt
        closedAt
        loopStatus
        resolutions {
          loopId
          resolution {
            resolutionId
            type {
              likeAssetTypeResolution {
                likeAssetTypeIds
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetTypeResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
            }
            label
            useAgain
            name
            description
            metadata {
              key
              value
            }
          }
          resolutionStatus
          triggerResolutionAt
        }
        updatedAt
        metadata {
          key
          value
        }
        rentalConfig {
          rentalConfigId
          version
          name
          description
          resolutionOption
          likeAssetTypeIds
          rentalStepConfigIds
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const consumerQRData = /* GraphQL */ `
  query ConsumerQRData($consumerId: ID, $externalId: ID) {
    consumerQRData(consumerId: $consumerId, externalId: $externalId) {
      data
    }
  }
`;
export const assetQRData = /* GraphQL */ `
  query AssetQRData($assetId: ID!) {
    assetQRData(assetId: $assetId) {
      data
    }
  }
`;
export const getWebhook = /* GraphQL */ `
  query GetWebhook($webhookId: ID!) {
    getWebhook(webhookId: $webhookId) {
      webhookId
      name
      event
      endpoint
      status
      updatedAt
    }
  }
`;
export const listWebhooks = /* GraphQL */ `
  query ListWebhooks {
    listWebhooks {
      webhookId
      name
      event
      endpoint
      status
      updatedAt
    }
  }
`;
export const getAssetOperation = /* GraphQL */ `
  query GetAssetOperation($assetOperationId: ID!) {
    getAssetOperation(assetOperationId: $assetOperationId) {
      assetOperationId
      isVisible
      name
      operationType
      description
      externalId
      metadata {
        key
        value
      }
      updatedAt
    }
  }
`;
export const listAssetOperations = /* GraphQL */ `
  query ListAssetOperations($paginationToken: String) {
    listAssetOperations(paginationToken: $paginationToken) {
      results {
        assetOperationId
        isVisible
        name
        operationType
        description
        externalId
        metadata {
          key
          value
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryAssetOperations = /* GraphQL */ `
  query QueryAssetOperations(
    $operationType: AssetOperationType!
    $paginationToken: String
  ) {
    queryAssetOperations(
      operationType: $operationType
      paginationToken: $paginationToken
    ) {
      results {
        assetOperationId
        isVisible
        name
        operationType
        description
        externalId
        metadata {
          key
          value
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAssetEvent = /* GraphQL */ `
  query GetAssetEvent($assetEventId: ID!) {
    getAssetEvent(assetEventId: $assetEventId) {
      assetEventId
      assetId
      assetOperation {
        assetOperationId
        isVisible
        name
        operationType
        description
        externalId
        metadata {
          key
          value
        }
        updatedAt
      }
      eventTimestamp
      locationId
      metadata {
        key
        value
      }
      updatedAt
    }
  }
`;
export const queryAssetEvents = /* GraphQL */ `
  query QueryAssetEvents(
    $assetId: ID!
    $fromTimestamp: String!
    $toTimestamp: String!
    $paginationToken: String
  ) {
    queryAssetEvents(
      assetId: $assetId
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      paginationToken: $paginationToken
    ) {
      results {
        assetEventId
        assetId
        assetOperation {
          assetOperationId
          isVisible
          name
          operationType
          description
          externalId
          metadata {
            key
            value
          }
          updatedAt
        }
        eventTimestamp
        locationId
        metadata {
          key
          value
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryLoopCounts = /* GraphQL */ `
  query QueryLoopCounts(
    $fromTimestamp: String!
    $toTimestamp: String!
    $timezone: String!
    $timeGranularity: TimeGranularity!
    $locationId: ID
    $assetTypeId: ID
    $consumerId: ID
  ) {
    queryLoopCounts(
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      timezone: $timezone
      timeGranularity: $timeGranularity
      locationId: $locationId
      assetTypeId: $assetTypeId
      consumerId: $consumerId
    ) {
      results {
        timestamp
        loopStatus
        count
      }
      updatedAt
    }
  }
`;
export const queryActiveConsumerCounts = /* GraphQL */ `
  query QueryActiveConsumerCounts(
    $fromTimestamp: String!
    $toTimestamp: String!
    $locationId: ID
  ) {
    queryActiveConsumerCounts(
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      locationId: $locationId
    ) {
      results {
        timestamp
        count
      }
      updatedAt
    }
  }
`;
export const queryLoopDurations = /* GraphQL */ `
  query QueryLoopDurations(
    $fromCohortMonth: String!
    $toCohortMonth: String!
    $fromLoopDurationDays: Int!
    $toLoopDurationDays: Int!
    $assetTypeId: ID
  ) {
    queryLoopDurations(
      fromCohortMonth: $fromCohortMonth
      toCohortMonth: $toCohortMonth
      fromLoopDurationDays: $fromLoopDurationDays
      toLoopDurationDays: $toLoopDurationDays
      assetTypeId: $assetTypeId
    ) {
      results {
        loopDurationDays
        cumulativeLoopsOpened
        cumulativeLoopsClosed
        percentReturned
      }
      updatedAt
    }
  }
`;
export const getAssetTypeUtilization = /* GraphQL */ `
  query GetAssetTypeUtilization($assetTypeId: ID!, $utilizationDate: String) {
    getAssetTypeUtilization(
      assetTypeId: $assetTypeId
      utilizationDate: $utilizationDate
    ) {
      activeAssets
      retiredAssets
      inactiveAssets
      activeWindowDays
      updatedAt
    }
  }
`;
export const getReturnRate = /* GraphQL */ `
  query GetReturnRate($assetTypeId: ID) {
    getReturnRate(assetTypeId: $assetTypeId) {
      allTimeLoopsClosed
      allTimeLoopsOpened
      allTimeReturnRate
      d30LoopsClosed
      d30LoopsOpened
      d30ReturnRate
      d60LoopsClosed
      d60LoopsOpened
      d60ReturnRate
      d90LoopsClosed
      d90LoopsOpened
      d90ReturnRate
      lastUpdated
    }
  }
`;
export const getAssetLastKnownLocation = /* GraphQL */ `
  query GetAssetLastKnownLocation($assetId: ID!) {
    getAssetLastKnownLocation(assetId: $assetId) {
      assetId
      locationId
      lastAssetEventId
      lastEventTimestamp
      previousLocationId
      updatedAt
    }
  }
`;
export const queryInventoryChanges = /* GraphQL */ `
  query QueryInventoryChanges(
    $locationId: ID!
    $assetTypeId: ID!
    $fromTimestamp: String!
    $toTimestamp: String!
    $paginationToken: String
  ) {
    queryInventoryChanges(
      locationId: $locationId
      assetTypeId: $assetTypeId
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      paginationToken: $paginationToken
    ) {
      results {
        inventoryChangeId
        assetTypeId
        locationId
        description
        eventId
        eventTimestamp
        eventType
        adjustByAmount
        adjustToAmount
        createdAt
      }
      nextToken
    }
  }
`;
export const getCurrentInventory = /* GraphQL */ `
  query GetCurrentInventory($locationId: ID!, $assetTypeId: ID!) {
    getCurrentInventory(locationId: $locationId, assetTypeId: $assetTypeId) {
      assetTypeId
      locationId
      onHand
      timestamp
    }
  }
`;
export const queryHistoricalInventory = /* GraphQL */ `
  query QueryHistoricalInventory(
    $locationId: ID!
    $assetTypeId: ID!
    $fromDate: String!
    $toDate: String!
    $timezone: String!
    $paginationToken: String
  ) {
    queryHistoricalInventory(
      locationId: $locationId
      assetTypeId: $assetTypeId
      fromDate: $fromDate
      toDate: $toDate
      timezone: $timezone
      paginationToken: $paginationToken
    ) {
      results {
        assetTypeId
        locationId
        onHand
        timestamp
      }
      nextToken
    }
  }
`;
export const getScanAppUser = /* GraphQL */ `
  query GetScanAppUser($userId: ID!) {
    getScanAppUser(userId: $userId) {
      userId
      username
      accountType
      allLocationsEnabled
      enabledLocationIds
      selectedLocationId
      canChangeResolutions
      selectedResolutionIds
      lastActivity
      createdAt
      status
    }
  }
`;
export const listScanAppUsers = /* GraphQL */ `
  query ListScanAppUsers($isActive: Boolean!, $paginationToken: String) {
    listScanAppUsers(isActive: $isActive, paginationToken: $paginationToken) {
      results {
        userId
        username
        accountType
        allLocationsEnabled
        enabledLocationIds
        selectedLocationId
        canChangeResolutions
        selectedResolutionIds
        lastActivity
        createdAt
        status
      }
      nextToken
    }
  }
`;
export const searchAssetEvents = /* GraphQL */ `
  query SearchAssetEvents(
    $fromTimestamp: String!
    $toTimestamp: String!
    $timezone: String!
    $calendarInterval: String!
    $operationTypes: [String!]
    $locationIds: [String!]
    $loopStatuses: [LoopStatus!]
    $searchQuery: String
    $size: Int
    $paginateFrom: Int
  ) {
    searchAssetEvents(
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      timezone: $timezone
      calendarInterval: $calendarInterval
      operationTypes: $operationTypes
      locationIds: $locationIds
      loopStatuses: $loopStatuses
      searchQuery: $searchQuery
      size: $size
      paginateFrom: $paginateFrom
    ) {
      totalHits
      assetEvents {
        assetEventId
        asset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        assetOperation {
          assetOperationId
          isVisible
          name
          operationType
          description
          externalId
          metadata {
            key
            value
          }
          updatedAt
        }
        assetOperationType
        consumer {
          consumerId
          reuserId
          name
          phoneNumber
          email
          externalId
          metadata {
            key
            value
          }
          marketingOptIn {
            email
            phoneNumber
          }
          createdAt
          chargeables {
            chargeableId
            vendor
          }
        }
        eventTimestamp
        location {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
          createdAt
          updatedAt
        }
        loopStatus
        metadata {
          key
          value
        }
        updatedAt
      }
      hitAggregations {
        key
        docCount
        subtotals {
          key
          docCount
        }
      }
    }
  }
`;
export const getCharge = /* GraphQL */ `
  query GetCharge($chargeId: ID!) {
    getCharge(chargeId: $chargeId) {
      chargeId
      consumerId
      externalId
      chargeAmount
      chargeInfo
      chargeStatus
      failedReason {
        code
        message
      }
      loopId
      createdAt
      updatedAt
    }
  }
`;
export const getChargeByLoop = /* GraphQL */ `
  query GetChargeByLoop($loopId: ID!) {
    getChargeByLoop(loopId: $loopId) {
      chargeId
      consumerId
      externalId
      chargeAmount
      chargeInfo
      chargeStatus
      failedReason {
        code
        message
      }
      loopId
      createdAt
      updatedAt
    }
  }
`;
export const getRentalConfig = /* GraphQL */ `
  query GetRentalConfig($rentalConfigId: ID!, $version: String) {
    getRentalConfig(rentalConfigId: $rentalConfigId, version: $version) {
      rentalConfigId
      version
      name
      description
      resolutionOption
      likeAssetTypeIds
      rentalStepConfigIds
      createdAt
      updatedAt
    }
  }
`;
export const listRentalConfigs = /* GraphQL */ `
  query ListRentalConfigs($latestOnly: Boolean) {
    listRentalConfigs(latestOnly: $latestOnly) {
      rentalConfigId
      version
      name
      description
      resolutionOption
      likeAssetTypeIds
      rentalStepConfigIds
      createdAt
      updatedAt
    }
  }
`;
export const getRentalStepConfig = /* GraphQL */ `
  query GetRentalStepConfig($id: ID!) {
    getRentalStepConfig(id: $id) {
      id
      name
      description
      rentalPhase
      timing {
        timestamp
        duration
        roundToTime
        roundToTimezone
      }
      createdAt
      updatedAt
      ... on NotificationRentalStepConfig {
        notificationType
        notificationData
      }
    }
  }
`;
export const listPhaseChangeRentalStepConfigs = /* GraphQL */ `
  query ListPhaseChangeRentalStepConfigs($paginationToken: String) {
    listPhaseChangeRentalStepConfigs(paginationToken: $paginationToken) {
      rentalStepConfigs {
        id
        name
        description
        rentalPhase
        timing {
          timestamp
          duration
          roundToTime
          roundToTimezone
        }
        createdAt
        updatedAt
        ... on NotificationRentalStepConfig {
          notificationType
          notificationData
        }
      }
      nextToken
    }
  }
`;
export const listNotificationRentalStepConfigs = /* GraphQL */ `
  query ListNotificationRentalStepConfigs($paginationToken: String) {
    listNotificationRentalStepConfigs(paginationToken: $paginationToken) {
      rentalStepConfigs {
        id
        name
        description
        rentalPhase
        timing {
          timestamp
          duration
          roundToTime
          roundToTimezone
        }
        createdAt
        updatedAt
        ... on NotificationRentalStepConfig {
          notificationType
          notificationData
        }
      }
      nextToken
    }
  }
`;
export const getRental = /* GraphQL */ `
  query GetRental($rentalId: ID, $loopId: ID) {
    getRental(rentalId: $rentalId, loopId: $loopId) {
      rentalId
      rentalConfigId
      rentalConfigVersion
      loopId
      consumerId
      lastPhase
      status
      rentalStepIds
      rentedAssetId
      rentedAssetTypeName
      resolvingLoop {
        loopId
        asset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        consumer {
          consumerId
          reuserId
          name
          phoneNumber
          email
          externalId
          metadata {
            key
            value
          }
          marketingOptIn {
            email
            phoneNumber
          }
          createdAt
          chargeables {
            chargeableId
            vendor
          }
        }
        locations {
          openedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
          closedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
        }
        externalId
        openedAt
        closedAt
        loopStatus
        resolutions {
          loopId
          resolution {
            resolutionId
            type {
              likeAssetTypeResolution {
                likeAssetTypeIds
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetTypeResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
            }
            label
            useAgain
            name
            description
            metadata {
              key
              value
            }
          }
          resolutionStatus
          triggerResolutionAt
        }
        updatedAt
        metadata {
          key
          value
        }
        rentalConfig {
          rentalConfigId
          version
          name
          description
          resolutionOption
          likeAssetTypeIds
          rentalStepConfigIds
          createdAt
          updatedAt
        }
      }
      resolvingLoopId
      receivedAssetId
      receivedAssetTypeName
      createdAt
      updatedAt
      metadata {
        key
        value
      }
      forgivenessDetails {
        timestamp
        reason
      }
    }
  }
`;
export const listRentals = /* GraphQL */ `
  query ListRentals(
    $consumerId: ID!
    $rentalStatus: RentalStatus
    $paginationToken: String
  ) {
    listRentals(
      consumerId: $consumerId
      rentalStatus: $rentalStatus
      paginationToken: $paginationToken
    ) {
      rentals {
        rentalId
        rentalConfigId
        rentalConfigVersion
        loopId
        consumerId
        lastPhase
        status
        rentalStepIds
        rentedAssetId
        rentedAssetTypeName
        resolvingLoop {
          loopId
          asset {
            assetId
            externalId
            value
            assetType {
              assetTypeId
              name
              description
              externalId
              value
              metadata {
                key
                value
              }
            }
            metadata {
              key
              value
            }
          }
          consumer {
            consumerId
            reuserId
            name
            phoneNumber
            email
            externalId
            metadata {
              key
              value
            }
            marketingOptIn {
              email
              phoneNumber
            }
            createdAt
            chargeables {
              chargeableId
              vendor
            }
          }
          locations {
            openedAt {
              locationId
              name
              parentId
              fullName
              externalId
              address {
                line1
                line2
                line3
                postalCode
                country
                latitude
                longitude
                googlePlacesId
              }
              metadata {
                key
                value
              }
              createdAt
              updatedAt
            }
            closedAt {
              locationId
              name
              parentId
              fullName
              externalId
              address {
                line1
                line2
                line3
                postalCode
                country
                latitude
                longitude
                googlePlacesId
              }
              metadata {
                key
                value
              }
              createdAt
              updatedAt
            }
          }
          externalId
          openedAt
          closedAt
          loopStatus
          resolutions {
            loopId
            resolution {
              resolutionId
              type {
                likeAssetTypeResolution {
                  likeAssetTypeIds
                  resolutionTiming {
                    timestamp
                    duration
                    roundToTime
                    roundToTimezone
                  }
                }
                sameAssetResolution {
                  resolutionTiming {
                    timestamp
                    duration
                    roundToTime
                    roundToTimezone
                  }
                }
                sameAssetTypeResolution {
                  resolutionTiming {
                    timestamp
                    duration
                    roundToTime
                    roundToTimezone
                  }
                }
              }
              label
              useAgain
              name
              description
              metadata {
                key
                value
              }
            }
            resolutionStatus
            triggerResolutionAt
          }
          updatedAt
          metadata {
            key
            value
          }
          rentalConfig {
            rentalConfigId
            version
            name
            description
            resolutionOption
            likeAssetTypeIds
            rentalStepConfigIds
            createdAt
            updatedAt
          }
        }
        resolvingLoopId
        receivedAssetId
        receivedAssetTypeName
        createdAt
        updatedAt
        metadata {
          key
          value
        }
        forgivenessDetails {
          timestamp
          reason
        }
      }
      nextToken
    }
  }
`;
export const searchRentals = /* GraphQL */ `
  query SearchRentals(
    $fromTimestamp: String!
    $toTimestamp: String!
    $rentalPhases: [RentalPhase!]
    $rentalStatuses: [RentalStatus!]
    $assetTypeIds: [String!]
    $consumerId: ID
    $searchQuery: String
    $size: Int
    $paginateFrom: Int
  ) {
    searchRentals(
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      rentalPhases: $rentalPhases
      rentalStatuses: $rentalStatuses
      assetTypeIds: $assetTypeIds
      consumerId: $consumerId
      searchQuery: $searchQuery
      size: $size
      paginateFrom: $paginateFrom
    ) {
      totalHits
      rentals {
        organizationId
        rentalId
        rentalConfig {
          rentalConfigId
          version
          name
          description
          resolutionOption
          likeAssetTypeIds
          rentalStepConfigIds
          createdAt
          updatedAt
        }
        loopId
        lastPhase
        status
        consumer {
          consumerId
          reuserId
          name
          phoneNumber
          email
          externalId
          metadata {
            key
            value
          }
          marketingOptIn {
            email
            phoneNumber
          }
          createdAt
          chargeables {
            chargeableId
            vendor
          }
        }
        rentalStepIds
        rentedAsset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        receivedAsset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        resolvingLoop {
          loopId
          closedAt
        }
        resolvingLoopId
        dueAt
        createdAt
        updatedAt
        metadata {
          key
          value
        }
        charge {
          chargeStatus
        }
        forgivenessDetails {
          timestamp
          reason
        }
      }
      hitAggregations {
        key
        docCount
        subtotals {
          key
          docCount
        }
      }
    }
  }
`;
export const getRentalStep = /* GraphQL */ `
  query GetRentalStep($id: ID!) {
    getRentalStep(id: $id) {
      id
      rentalId
      rentalStepConfigId
      rentalPhase
      triggerAt
      status
      statusUpdatedAt
      createdAt
      rentalStepConfigType
    }
  }
`;
export const listRentalStepsForRental = /* GraphQL */ `
  query ListRentalStepsForRental(
    $rentalId: ID!
    $rentalStepStatus: RentalStepStatus
  ) {
    listRentalStepsForRental(
      rentalId: $rentalId
      rentalStepStatus: $rentalStepStatus
    ) {
      id
      rentalId
      rentalStepConfigId
      rentalPhase
      triggerAt
      status
      statusUpdatedAt
      createdAt
      rentalStepConfigType
    }
  }
`;
export const listOrganizationRentalPhases = /* GraphQL */ `
  query ListOrganizationRentalPhases {
    listOrganizationRentalPhases
  }
`;
export const getStatReturnRate = /* GraphQL */ `
  query GetStatReturnRate($assetTypeId: ID) {
    getStatReturnRate(assetTypeId: $assetTypeId) {
      returnRates {
        window
        loopsClosed
        loopsOpened
        returnRate
      }
      isAvailable
      assetTypeId
      lastUpdated
    }
  }
`;
export const getStatLoopDurations = /* GraphQL */ `
  query GetStatLoopDurations($assetTypeId: ID) {
    getStatLoopDurations(assetTypeId: $assetTypeId) {
      loopDurations {
        days
        percentReturned
      }
      isAvailable
      assetTypeId
      lastUpdated
    }
  }
`;
export const getStatCycleRate = /* GraphQL */ `
  query GetStatCycleRate($assetTypeId: ID) {
    getStatCycleRate(assetTypeId: $assetTypeId) {
      isAvailable
      assetTypeId
      loopDuration
      loopDurationUnit
      shelfTime
      shelfTimeUnit
      cycleRateTotal
      cycleRateUnit
      lastUpdated
    }
  }
`;
export const getStatCheckoutsReturns = /* GraphQL */ `
  query GetStatCheckoutsReturns(
    $window: StatWindow
    $assetTypeId: ID
    $parentLocationIds: [ID]
  ) {
    getStatCheckoutsReturns(
      window: $window
      assetTypeId: $assetTypeId
      parentLocationIds: $parentLocationIds
    ) {
      isAvailable
      statWindow
      assetTypeId
      parentLocationIds
      avgByDay {
        dayOfWeek
        avgLoopsOpened
        totalOpened
        avgLoopsClosed
        totalClosed
        daysCount
      }
      avgOpenedForWindow
      totalOpenedForWindow
      avgClosedForWindow
      totalClosedForWindow
      lastUpdated
    }
  }
`;
export const getStatMissedScans = /* GraphQL */ `
  query GetStatMissedScans($assetTypeId: ID) {
    getStatMissedScans(assetTypeId: $assetTypeId) {
      isAvailable
      assetTypeId
      percentMissed
      previousPercentMissed
      previousComparisonWindow
      lastUpdated
    }
  }
`;
export const getStatAssetUsage = /* GraphQL */ `
  query GetStatAssetUsage($assetTypeId: ID) {
    getStatAssetUsage(assetTypeId: $assetTypeId) {
      usageBuckets {
        label
        bucketCount
      }
      isAvailable
      assetTypeId
      lastUpdated
    }
  }
`;
export const getStatReuseRate = /* GraphQL */ `
  query GetStatReuseRate($assetTypeId: ID) {
    getStatReuseRate(assetTypeId: $assetTypeId) {
      reuseRateBuckets {
        rank
        label
        quantity
      }
      avgUsesPerAsset
      mostUsedAssetCount
      mostUsedAssetId
      isAvailable
      assetTypeId
      lastUpdated
    }
  }
`;
