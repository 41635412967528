export const adminCancelRental = /* GraphQL */ `
    mutation AdminCancelRental($input: AdminCancelRentalInput!) {
        adminCancelRental(input: $input) {
            rentalId
            rentalConfigId
            rentalConfigVersion
            loopId
            consumerId
            lastPhase
            status
            rentalStepIds
            rentedAssetId
            rentedAssetTypeName
            resolvingLoop {
                loopId
                asset {
                    assetId
                    externalId
                    value
                    assetType {
                        assetTypeId
                        name
                        description
                        externalId
                        value
                        metadata {
                            key
                            value
                            __typename
                        }
                        __typename
                    }
                    metadata {
                        key
                        value
                        __typename
                    }
                    __typename
                }
                consumer {
                    consumerId
                    reuserId
                    name
                    phoneNumber
                    email
                    externalId
                    metadata {
                        key
                        value
                        __typename
                    }
                    marketingOptIn {
                        email
                        phoneNumber
                        __typename
                    }
                    createdAt
                    __typename
                }
                locations {
                    openedAt {
                        locationId
                        name
                        parentId
                        fullName
                        externalId
                        address {
                            line1
                            line2
                            line3
                            postalCode
                            country
                            latitude
                            longitude
                            googlePlacesId
                            __typename
                        }
                        metadata {
                            key
                            value
                            __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                    }
                    closedAt {
                        locationId
                        name
                        parentId
                        fullName
                        externalId
                        address {
                            line1
                            line2
                            line3
                            postalCode
                            country
                            latitude
                            longitude
                            googlePlacesId
                            __typename
                        }
                        metadata {
                            key
                            value
                            __typename
                        }
                        createdAt
                        updatedAt
                        __typename
                    }
                    __typename
                }
                externalId
                openedAt
                closedAt
                loopStatus
                resolutions {
                    loopId
                    resolution {
                        resolutionId
                        type {
                            likeAssetTypeResolution {
                                likeAssetTypeIds
                                resolutionTiming {
                                    timestamp
                                    duration
                                    roundToTime
                                    roundToTimezone
                                    __typename
                                }
                                __typename
                            }
                            sameAssetResolution {
                                resolutionTiming {
                                    timestamp
                                    duration
                                    roundToTime
                                    roundToTimezone
                                    __typename
                                }
                                __typename
                            }
                            sameAssetTypeResolution {
                                resolutionTiming {
                                    timestamp
                                    duration
                                    roundToTime
                                    roundToTimezone
                                    __typename
                                }
                                __typename
                            }
                            __typename
                        }
                        label
                        useAgain
                        name
                        description
                        metadata {
                            key
                            value
                            __typename
                        }
                        __typename
                    }
                    resolutionStatus
                    triggerResolutionAt
                    __typename
                }
                updatedAt
                metadata {
                    key
                    value
                    __typename
                }
                rentalConfig {
                    rentalConfigId
                    version
                    name
                    description
                    resolutionOption
                    likeAssetTypeIds
                    rentalStepConfigIds
                    createdAt
                    updatedAt
                    __typename
                }
                __typename
            }
            resolvingLoopId
            receivedAssetId
            receivedAssetTypeName
            createdAt
            updatedAt
            metadata {
                key
                value
                __typename
            }
            forgivenessDetails {
                timestamp
                reason
                __typename
            }
            __typename
        }
    }
`;
