//react
import React, { useState, useEffect } from "react";

//components
import Text from "../../common/Text";
import { LocationInputForm } from "../common/LocationInputField";
import ScanningLocationDetails from "./ScanningLocationDetails";
import Overlay from "../../common/overlay/Overlay";

//style
import styled, { useTheme } from "../../../styling/styled-components";

//api
import * as queries from "../../../graphql/queries";
import { useQuery } from "../../../graphql/hooks/query";

//utils
import { handleUsernameChange } from "../../../utils/locations/handleUsernameChange";

const HeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    flex: 1 0 0;
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const ScanningLocationText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    width: 100%;
`;

const ParentLocationWrapper = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.yuge}px;
`;

export default function ScanningLocationOverlay({
    onClick,
    onTriggerBack,
    onReturnToMain,
    scanningLocationId,
    parentLocationId,
    toggleArchiveScanModal,
    width,
    closeScanLocation,
}) {
    const { colors } = useTheme();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showNameErrors, setShowNameErrors] = useState<boolean>(false);
    const [showScanningNameErrors, setShowScanningNameErrors] =
        useState<boolean>(false);
    const [updatedScanningName, setUpdatedScanningName] = useState("");
    const [noErrors, setNoErrors] = useState(true);
    const [showBlocker, setShowBlocker] = useState(false);

    const [
        getLocation,
        getLocationData,
        getLocationLoading,
        getLocationErrors,
    ] = useQuery(queries.getLocation);

    const [
        getParentLocation,
        getParentLocationData,
        getParentLocationLoading,
        geParentLocationErrors,
    ] = useQuery(queries.getParentLocation);

    useEffect(() => {
        setShowScanningNameErrors(false);
        setShowNameErrors(false);
        getLocation({ locationId: scanningLocationId });
        getParentLocation({ locationId: parentLocationId });
    }, [scanningLocationId, parentLocationId]);

    useEffect(() => {
        if (getLocationData && getParentLocationData) {
            setUpdatedScanningName(getLocationData.name);
            setDataLoaded(true);
        }
    }, [getLocationData, getParentLocationData]);

    useEffect(() => {
        if (getLocationData) {
            setShowScanningNameErrors(false);
            setShowNameErrors(false);
            setNoErrors(true);
            checkUniqueScanningName();
        }
    }, [updatedScanningName]);

    const checkUniqueScanningName = () => {
        if (updatedScanningName.trimStart().trimEnd().length == 0) {
            setNoErrors(false);
            return;
        }
        if (
            updatedScanningName.trimStart().trimEnd() ==
            getParentLocationData.name.trimEnd()
        ) {
            setShowNameErrors(true);
            setShowScanningNameErrors(false);
            setNoErrors(false);
            return;
        }
        if (
            getParentLocationData.locations.length > 0 &&
            getLocationData.name !== updatedScanningName.trimStart()
        ) {
            getParentLocationData.locations.map((item, index) => {
                if (updatedScanningName.trimStart() == item.name) {
                    setShowScanningNameErrors(true);
                    setShowNameErrors(false);
                    setNoErrors(false);
                    return;
                }
            });
        } else {
            setShowScanningNameErrors(false);
            setShowNameErrors(false);
            setNoErrors(true);
        }
    };

    return (
        <>
            {dataLoaded && (
                <Overlay
                    width={width}
                    onClick={() => onClick(showBlocker)}
                    onClickBack={() => onTriggerBack(showBlocker)}
                    showLeft={true}
                    onClickedOutside={closeScanLocation}
                >
                    <ParentLocationWrapper>
                        <>
                            <HeaderDiv>
                                <ScanningLocationText
                                    type="body"
                                    size="medium"
                                    color={colors.EXHAUST}
                                >
                                    {getParentLocationData.name}
                                </ScanningLocationText>
                                <ScanningLocationText
                                    type="header"
                                    size="large"
                                    color={colors.DEEP_BLUE_SEA}
                                >
                                    {getLocationData.name}
                                </ScanningLocationText>
                                <ScanningLocationText
                                    type="header"
                                    size="smallest"
                                    color={colors.DEEP_BLUE_SEA}
                                >
                                    Location ID: {getLocationData.locationId}
                                </ScanningLocationText>
                            </HeaderDiv>
                            <LocationInputForm
                                infoText="Scanning Location Name"
                                maxLength={150}
                                width="medium"
                                hasError={
                                    showNameErrors || showScanningNameErrors
                                        ? true
                                        : false
                                }
                                error={
                                    showNameErrors
                                        ? "The scanning and parent location names must be different. "
                                        : "That name already exists. Please enter a unique scanning location name."
                                }
                                onChange={(e) =>
                                    handleUsernameChange(
                                        e,
                                        setUpdatedScanningName,
                                        getParentLocationData.name
                                    )
                                }
                                value={`${getParentLocationData.name} - ${updatedScanningName}`}
                            ></LocationInputForm>
                            <ScanningLocationDetails
                                onReturnToMain={onReturnToMain}
                                updatedScanningName={updatedScanningName}
                                getLocationData={getLocationData}
                                noErrors={noErrors}
                                toggleArchiveScanModal={toggleArchiveScanModal}
                                setShowBlocker={setShowBlocker}
                            ></ScanningLocationDetails>
                        </>
                        : (<></>)
                    </ParentLocationWrapper>
                </Overlay>
            )}
        </>
    );
}
