//style
import styled, { useTheme } from "../../../styling/styled-components";

//component
import Text from "../../common/Text";
import SideLine from "../../common/SideLine";
import RentalShimmerCard from "./common/RentalShimmerCard";

const Details = styled.div`
    margin-top: ${({ theme }) => -theme.spacing.xsmall}px;
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
`;

export default function RentalLoadingSubSection({
    children,
    sectionDetails,
    ...props
}: Props) {
    const { colors } = useTheme();

    return (
        <>
            {sectionDetails.map((item, index) => {
                return (
                    <div style={{ width: "100%" }} key={index}>
                        <Details>
                            <Text
                                type="body"
                                size="medium"
                                color={colors.EXHAUST}
                            >
                                {item.title}
                            </Text>
                            <RentalShimmerCard></RentalShimmerCard>
                        </Details>
                        <SideLine />
                    </div>
                );
            })}
        </>
    );
}
