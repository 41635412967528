//style
import { useTheme } from "../../../styling/styled-components";

//components
import Card from "../../common/card/Card";
import Text from "../../common/Text";
import CardHeader from "./common/CardHeader";
import FinedWrapper from "../../common/card/FineWrapper";

//utils
import { colorChange } from "./utils/helpers";
import { checkRentalStatus } from "../../../utils/rentalOverlay/checkRentalStatus";
import { isEmpty } from "../../../utils/helpers";

//constants
import {
    FINED_MANUAL_STRINGS,
    FINED_AUTOMATIC_STRINGS,
} from "../../../constants/rentalOverlay/rentalCards";
import { ChargeStatus } from "../../../API";

export default function FinedAutomaticCard({
    rentalConfigStep,
    chargeDetails,
}) {
    const { colors } = useTheme();

    const checkFinedAutomaticHeader = () => {
        if (!isEmpty(chargeDetails)) {
            if (chargeDetails.chargeStatus == ChargeStatus.Refunded) {
                return FINED_AUTOMATIC_STRINGS.REFUND_SUCCESS;
            } else {
                return FINED_AUTOMATIC_STRINGS.HEADER;
            }
        } else {
            return FINED_AUTOMATIC_STRINGS.HEADER;
        }
    };

    const checkFinedAutomaticDescription = () => {
        if (!isEmpty(chargeDetails)) {
            if (chargeDetails.chargeStatus == ChargeStatus.Refunded) {
                return "";
            } else {
                return FINED_AUTOMATIC_STRINGS.DESCRIPTION;
            }
        } else {
            return FINED_AUTOMATIC_STRINGS.DESCRIPTION;
        }
    };

    return (
        <Card
            backgroundColor={colorChange(
                checkRentalStatus(rentalConfigStep),
                colors
            )}
            borderColor={"1px solid var(--greys-ash, #1745cf)"}
        >
            <CardHeader
                header={FINED_AUTOMATIC_STRINGS.CARD_LABEL_AUTOMATIC}
                date={rentalConfigStep.triggerAt}
                colorHeader={colors.ROCK_BOTTOM}
                colorDate={colors.ASH}
            />
            <FinedWrapper>
                <Text type="body" size="medium" color={colors.ROCK_BOTTOM}>
                    {checkFinedAutomaticHeader()}
                </Text>
                <Text type="body" size="tiny" color={colors.ASH}>
                    {checkFinedAutomaticDescription()}
                </Text>
            </FinedWrapper>
        </Card>
    );
}
