//style
import styled, { useTheme } from "styled-components";

//components
import Displayable from "../../common/Displayable";
import TimelineIcon from "./TimelineIcon";

//enums
import {
    RentalStatus,
    RentalPhase,
    RentalStep,
    ForgivenessReason,
} from "../../../API";

const Container = styled.div`
    display: flex;
    position: relative;
`;

const TimelineBarWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: ${({ theme }) => theme.spacing.xsmall}px;
`;

const TimelineBar = styled.div`
    width: 12px;
    background-color: ${(props) => props.color};
    opacity: ${(props) => props.opacity};
    height: ${(props) => (props.adjustBarHeight ? "60%" : "100%")};
    margin-top: ${(props) =>
        props.adjustBarHeight ? `${props.theme.spacing.xxlarge}` : "0"}px;
`;

const CompletedFinalStepTimelineBar = styled.div`
    width: 12px;
    height: 100%;
    margin-bottom: ${(props) =>
        props.adjustFinalBarHeight ? `${props.theme.spacing.huge}` : "0"}px;
    background: ${(props) => props.displayEndofTimelineBar};
`;

type RentalTimelineObj = {
    lastPhase: RentalPhase;
    status: RentalStatus;
    forgivenessReason: ForgivenessReason;
};

type Props = {
    rentalStep: RentalStep;
    rental: RentalTimelineObj;
    showRedPhaseDot: boolean;
};

export default function ProgressBar({
    rentalStep,
    rental,
    showRedPhaseDot,
}: Props) {
    const { colors } = useTheme();

    const showYellowPhaseDot = rentalStep?.status == RentalStatus.Forgiven;

    const adjustBarHeight = rentalStep?.rentalPhase == RentalPhase.Initiated;
    const adjustFinalBarHeight = showRedPhaseDot || showYellowPhaseDot;
    const finalCompletedStep =
        rentalStep?.status == RentalStatus.Completed ||
        rentalStep?.status == RentalStatus.Canceled ||
        rentalStep?.status == RentalStatus.Expired ||
        rentalStep?.status == RentalStatus.CanceledWithRefund ||
        rentalStep?.status == RentalStatus.Failed ||
        rentalStep?.status == RentalStatus.Forgiven;

    const showBlueProgressBar =
        finalCompletedStep || rentalStep?.status == "CHECK_FAILED";

    const checkFinalStatusPhase =
        rental?.lastPhase == rentalStep?.status &&
        rental?.status == rentalStep?.status;

    const displayEndofTimelineBar =
        checkFinalStatusPhase ||
        rentalStep.status == RentalStatus.Expired ||
        rentalStep.status == RentalStatus.Failed;

    const displayDefaultTimelineBar =
        showBlueProgressBar && !finalCompletedStep && !displayEndofTimelineBar;

    const displayFinalPhaseFineRedBar =
        rentalStep.rentalPhase == RentalPhase.FinedManual ||
        rentalStep.rentalPhase == RentalPhase.FinedAutomatic ||
        rentalStep.rentalPhase == RentalPhase.FineError;

    const returnReceivedFailedBar =
        showRedPhaseDot && rental?.status == RentalStatus.Forgiven;
    const returnReceivedForgivenBar =
        rental?.forgivenessReason ==
            ForgivenessReason.ReturnReceivedForgiveness && showYellowPhaseDot;
    const fineErrorForgivenPhaseBar =
        rental?.forgivenessReason ==
            ForgivenessReason.ReturnReceivedForgiveness &&
        rentalStep.rentalPhase == RentalPhase.FineError;
    const finalFineErrorForgivenStatusBar =
        fineErrorForgivenPhaseBar && showYellowPhaseDot;

    const determineProgressBarColor = () => {
        //background prop doesn't work well with the color theme
        //directly added in the colors to achieve the correct design
        if (finalFineErrorForgivenStatusBar) {
            return `linear-gradient(to bottom, #F4B925 50%, #ffffff 50%)`;
        }
        if (fineErrorForgivenPhaseBar) {
            return `linear-gradient(to bottom, #E54D4D 50%, #F4B925 50%)`;
        }
        if (returnReceivedFailedBar) {
            return `linear-gradient(to bottom, #6096fb 50%, #E54D4D 50%)`;
        }
        if (returnReceivedForgivenBar) {
            return `linear-gradient(to bottom, #E54D4D 50%, #ffffff 50%)`;
        }
        if (showRedPhaseDot) {
            return `linear-gradient(to bottom, #E54D4D 50%, #ffffff 50%)`;
        }
        if (showYellowPhaseDot) {
            return `linear-gradient(to bottom, #6096fb 50%,  #ffffff 50%)`;
        }
        if (displayEndofTimelineBar) {
            return `linear-gradient(to bottom, #E54D4D 50%, #E54D4D 50%)`;
        } else if (displayFinalPhaseFineRedBar) {
            return `linear-gradient(to bottom, #E54D4D 50%,  #ffffff 50%)`;
        } else if (!displayEndofTimelineBar) {
            return `linear-gradient(to bottom, #6096fb 50%, #c2cfe066 50%)`;
        } else if (displayEndofTimelineBar) {
            return `linear-gradient(to bottom, #6096fb 50%, #ffffff 50%)`;
        } else {
            return `linear-gradient(to bottom, #6096fb 50%, #c2cfe066 50%)`;
        }
    };

    return (
        <Container>
            <TimelineBarWrapper>
                <Displayable
                    displayPrimaryComponent={displayDefaultTimelineBar}
                >
                    <TimelineBar
                        color={colors.PLASTIC_BOTTLE}
                        adjustBarHeight={adjustBarHeight}
                    />
                </Displayable>
                <TimelineIcon
                    status={rentalStep?.status}
                    phase={rentalStep?.rentalPhase}
                    showRedPhaseDot={showRedPhaseDot}
                    returnReceivedFailed={returnReceivedFailedBar}
                />
                <Displayable displayPrimaryComponent={!showBlueProgressBar}>
                    <TimelineBar
                        color={colors.MARINE_LAYER}
                        opacity={0.4000000059604645}
                    />
                </Displayable>
                <Displayable
                    displayPrimaryComponent={
                        finalCompletedStep || checkFinalStatusPhase
                    }
                >
                    <CompletedFinalStepTimelineBar
                        displayEndofTimelineBar={determineProgressBarColor()}
                        adjustFinalBarHeight={adjustFinalBarHeight}
                    />
                </Displayable>
            </TimelineBarWrapper>
        </Container>
    );
}
