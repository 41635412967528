import React, { useEffect } from "react";
import DeprecatedText from "../common/DeprecatedText";
import styled, { useTheme } from "styled-components/macro";
import * as queries from "../../graphql/queries";
import { useQuery } from "../../graphql/hooks/query";
import { prettifyNumber } from "../../utils/helpers";
import { findTextStyles } from "../../styling/styled-components";
import { ACTIVE_UTILIZATION_CARDS } from "../../constants/assets";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const AshText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ASH};
`;

const SubHeaderText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const StormText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
`;

const BlueText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    flex: 12;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding: ${({ theme }) => theme.spacing.medium}px;
`;

const Divider = styled.div`
    flex: 1;
`;

const CardHeader = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const Rate = styled.div``;

type Props = { assetTypeId: string };

export default function Component({ assetTypeId }: Props) {
    const { isDesktop } = useTheme();

    const [
        getAssetTypeUtilization,
        getAssetTypeUtilizationData,
        getAssetTypeUtilizationLoading,
        getAssetTypeUtilizationErrors,
    ] = useQuery(queries.getAssetTypeUtilization);

    useEffect(() => {
        getAssetTypeUtilization({ assetTypeId });
    }, [assetTypeId]);

    const renderRateCard = (props) => {
        const { header, subHeader, value, key } = props;
        return (
            <Card key={key}>
                <CardHeader>
                    <AshText type="body" size="large">
                        {header}
                    </AshText>
                    <SubHeaderText type="body" size="tiny">
                        {subHeader}
                    </SubHeaderText>
                </CardHeader>
                <Rate>
                    {value != null ? (
                        <BlueText type="header" size="xlarge">
                            {prettifyNumber(value)}
                        </BlueText>
                    ) : (
                        <StormText type="header" size="xlarge">
                            {ACTIVE_UTILIZATION_CARDS.NO_RATE}
                        </StormText>
                    )}
                </Rate>
            </Card>
        );
    };

    return (
        getAssetTypeUtilizationData && (
            <Container>
                {renderRateCard({
                    header: ACTIVE_UTILIZATION_CARDS.ACTIVE_ASSETS,
                    subHeader: ACTIVE_UTILIZATION_CARDS.ACTIVE_ASSETS_DESCR,
                    value: getAssetTypeUtilizationData?.activeAssets,
                    key: "active-assets",
                })}
                <Divider />
                {renderRateCard({
                    header: ACTIVE_UTILIZATION_CARDS.LOST_ASSETS,
                    subHeader: ACTIVE_UTILIZATION_CARDS.LOST_ASSETS_DESCR,
                    value: getAssetTypeUtilizationData?.inactiveAssets,
                    key: "lost-assets",
                })}
                <Divider />
                {renderRateCard({
                    header: ACTIVE_UTILIZATION_CARDS.RETIRED_ASSETS,
                    subHeader: ACTIVE_UTILIZATION_CARDS.RETIRED_ASSETS_DESCR,
                    value: getAssetTypeUtilizationData?.retiredAssets,
                    key: "retired-assets",
                })}
            </Container>
        )
    );
}

Component.defaultProps = {};
