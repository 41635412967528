import * as tokens from "./tokens";

const baseColors = {
    ASH: tokens.greysAsh,
    ASPHALT: tokens.greysAsphalt,
    BLACK: tokens.greysOnyx,
    BLANK_SLATE: tokens.greysBlankSlate,
    DEEP_BLUE_SEA: tokens.brandDeepBlueSea,
    EXHAUST: tokens.greysExhaust,
    GREEN_1: tokens.uiColorsGreen1,
    GREEN_1_HOVER: tokens.dataVizHoverGreen1,
    GREEN_2: tokens.uiColorsGreen2,
    GREEN_2_HOVER: tokens.dataVizHoverGreen2,
    GREEN_3: tokens.uiColorsGreen3,
    GREEN_4: tokens.uiColorsGreen4,
    HOVER_DEEP_BLUE_SEA: tokens.dataVizHoverDeepBlueSea,
    HOVER_SUMMER_STORM: tokens.dataVizHoverSummerStorm,
    LIME_SQUEEZE: tokens.brandLimeSqueeze,
    MARINE_LAYER: tokens.greysMarineLayer,
    MOUNTAIN_HAZE: tokens.dataVizMountainHaze,
    OCEANS_EDGE: tokens.dataVizOceanSEdge,
    ONYX: tokens.greysOnyx,
    PEBBLE: tokens.dataVizPebble,
    PLASTIC_BOTTLE: tokens.uiColorsPlasticBottle,
    RED_1: tokens.uiColorsRed1,
    RED_2: tokens.uiColorsRed2,
    RED_3: tokens.uiColorsRed3,
    RED_4: tokens.uiColorsRed4,
    ROCK_BOTTOM: tokens.uiColorsRockBottom,
    SEAGLASS: tokens.uiColorsSeaglass,
    SMOG: tokens.greysSmog,
    SPRING_MIST: tokens.dataVizSpringMist,
    SPROUT: tokens.brandSprout,
    SPROUT_HOVER: tokens.dataVizHoverSprout,
    SUMMER_STORM: tokens.greysSummerStorm,
    YELLOW_1: tokens.uiColorsYellow1,
    YELLOW_2: tokens.uiColorsYellow2,
    YELLOW_2_HOVER: tokens.dataVizHoverYellow2,
    YELLOW_3: tokens.uiColorsYellow3,
    YELLOW_3_HOVER: tokens.dataVizHoverYellow3,
    YELLOW_4: tokens.uiColorsYellow4,
    WAVE_STORM: tokens.uiColorsWavestorm,
};

const semanticColors = {
    default: baseColors.WAVE_STORM,
    hover: baseColors.PLASTIC_BOTTLE,
    hoverCancel: baseColors.RED_3,
    pressed: baseColors.ROCK_BOTTOM,
    disabled: baseColors.MARINE_LAYER,
    disabled_dark: baseColors.MARINE_LAYER,
    disabled_light: baseColors.EXHAUST,
};

// change default disabled to disabled_dark

export const COLORS = {
    ...baseColors,
    ...semanticColors,
};
