//components
import Text from "../../common/Text";

//style
import styled from "../../../styling/styled-components";

const RentalStatusDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
    padding-left: ${(props) => props.paddingLeft}px;
    padding-top: ${(props) => props.paddingTop}px;
`;

const StyledIcon = styled.div`
    position: relative;
    padding-top: ${({ theme }) => theme.spacing.small}px;
    opacity: ${(props) => props.opacity};
`;

const StyledStatusText = styled(Text)`
    opacity: ${(props) => props.opacity};
`;

const BarChartBlock = styled.div`
    background-color: ${(props) => props.color};
    height: 12px;
    width: ${(props) => props.width}px;
`;

export function RentalStatusWrapper({
    children,
    paddingLeft = "0",
    paddingTop = "0",
}) {
    return (
        <RentalStatusDiv paddingLeft={paddingLeft} paddingTop={paddingTop}>
            {children}
        </RentalStatusDiv>
    );
}

export function StyleIconDiv({ children, opacity = "100%" }) {
    return <StyledIcon opacity={opacity}>{children}</StyledIcon>;
}

export function StatusText({ children, type, size, color, opacity = "100%" }) {
    return (
        <StyledStatusText
            type={type}
            size={size}
            color={color}
            opacity={opacity}
        >
            {children}
        </StyledStatusText>
    );
}

export function StackChartStatus({ children, width, color }) {
    return (
        <BarChartBlock width={width} color={color}>
            {children}
        </BarChartBlock>
    );
}
