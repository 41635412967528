import React, { useState, useEffect } from "react";
import { parseISO, format } from "date-fns";
import * as queries from "../../graphql/queries";
import styled, { useTheme } from "styled-components/macro";
import { prettifyUID } from "../../utils/helpers";
import LoopStatusBadge from "./LoopStatusBadges";
import ScreenView from "../common/ScreenView";
import DeprecatedText from "../common/DeprecatedText";
import { LoopStatus } from "../../API";
import useGraphClient from "../../hooks/useGraphClient";
import ClipLoader from "react-spinners/ClipLoader";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Button from "../common/Button";
import { findTextStyles } from "../../styling/styled-components";
import { LOOP_LIST } from "../../constants/loops";
import MediaQuery from "react-responsive";

const LoopsScreenView = styled(ScreenView)`
    ${ScreenView.ScreenHeader} {
        justify-content: space-between;
    }
`;

const FiltersContainer = styled.div`
    flex-direction: row;
    display: flex;
    padding: ${({ theme }) => theme.spacing.medium}px 0px;
`;

const Filter = styled.div`
    flex-direction: row;
    display: flex;
`;

const FilterWrapper = styled.div`
    cursor: pointer;
`;

const FilterLabel = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ isSelected, theme }) =>
        isSelected ? theme.colors.DEEP_BLUE_SEA : theme.colors.ASH};
`;

const SelectedDivider = styled.div`
    background-color: ${({ theme }) => theme.colors.GREEN_3};
    height: 3px;
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const FilterDivider = styled.div`
    background-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    width: 0.5px;
    margin: 0 ${({ theme }) => theme.spacing.medium}px;
`;

const HeaderRow = styled.div`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    border-radius: 4px;
    border-bottom-width: 3px;
    border-bottom-color: ${({ theme }) => theme.colors.SMOG};
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
`;

const Footer = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.yuge}px;
`;

const LoadMore = styled(Button)`
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.WAVE_STORM};
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    margin: ${({ theme }) => theme.spacing.xlarge}px auto;
`;

const LoadMoreText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.WAVE_STORM};
`;

const CellText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const CellSubText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
`;

const ItemSeparator = styled.div`
    padding-bottom: 1px;
`;

const LoopsSummaryCardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 20px;
    padding-horizontal: 20px;
`;

const LoopsSummaryCard = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: ${({ theme }) => theme.spacing.medium}px;
`;

const LoopsLength = styled(DeprecatedText)`
    color: ${({ theme }) => theme.colors.ASH};
`;

const LoopsLabel = styled(DeprecatedText)`
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const FILTERS = [
    { id: 0, label: "All Loops", status: null },
    { id: 1, label: "Open Loops", status: LoopStatus.Open },
    { id: 2, label: "Closed Loops", status: LoopStatus.Closed },
];

const LESS_COLUMNS = ["Loop ID", "Asset type", "Consumer ID", "Status"];

const OPEN_LOOPS_DESCR = "Total loops opened";
const CLOSED_LOOPS_DESCR = "Total loops closed";

type Props = {
    consumerId?: string;
    fromTimestamp?: string;
    toTimestamp?: string;
};

export default function LoopList({
    fromTimestamp,
    toTimestamp,
    consumerId,
    style,
}: Props) {
    const [selectedFilterId, setSelectedFilterId] = useState(0);
    const [selectedPaginationToken, setSelectedPaginationToken] = useState();
    const [selectedLoops, setSelectedLoops] = useState([]);
    const [initiatedFilterIds, setInitiatedFilterIds] = useState([]);
    const [loopsLoading, setLoopsLoading] = useState(true);
    const [queryLoopsErrors, setQueryLoopsErrors] = useState(null);
    const [loopsLoaded, setLoopsLoaded] = useState(false);
    const [allLoops, setAllLoops] = useState([]);
    const [allLoopsPaginationToken, setAllLoopsPaginationToken] =
        useState(null);

    const [openLoops, setOpenLoops] = useState([]);
    const [openLoopsPaginationToken, setOpenLoopsPaginationToken] =
        useState(null);

    const [closedLoops, setClosedLoops] = useState([]);
    const [closedLoopsPaginationToken, setClosedLoopsPaginationToken] =
        useState(null);

    const { colors, isDesktop, isTablet, isMobile, spacing, breakpoints } =
        useTheme();

    const graphClient = useGraphClient();

    const selectedStatus = FILTERS.find(
        (filter) => filter.id == selectedFilterId
    ).status;

    const customStyles = {
        headCells: {
            style: {
                fontFamily: "poppins",
            },
        },
        cells: {
            style: {
                padding: `${spacing.large}px ${spacing.medium}px`,
                alignItems: "start",
            },
        },
    };

    useEffect(() => {
        if (!initiatedFilterIds.includes(selectedFilterId)) {
            loadLoops(
                FILTERS.find((filter) => filter.id == selectedFilterId).status,
                null,
                selectedFilterId
            );
        }
        if (selectedFilterId == 0) {
            setSelectedPaginationToken(allLoopsPaginationToken);
            setSelectedLoops(allLoops);
        } else if (selectedFilterId == 1) {
            setSelectedPaginationToken(openLoopsPaginationToken);
            setSelectedLoops(openLoops);
        } else if (selectedFilterId == 2) {
            setSelectedPaginationToken(closedLoopsPaginationToken);
            setSelectedLoops(closedLoops);
        }
    }, [selectedFilterId]);

    useEffect(() => {
        if (isMobile) {
            setLoopsLoading(true);
            loadLoops(LoopStatus.Open, null, 1);
            loadLoops(LoopStatus.Closed, null, 2);
        }
    }, [isMobile]);

    const loadLoops = async (
        loopStatus: LoopStatus,
        thisToken: string,
        selectedFilterId: string
    ) => {
        setQueryLoopsErrors(null);
        setLoopsLoading(true);
        try {
            const results = await graphClient.graphql({
                query: queries.queryLoops,
                variables: {
                    fromTimestamp,
                    toTimestamp,
                    consumerId,
                    paginationToken: thisToken,
                    loopStatus,
                },
            });

            const loops = results.data.queryLoops.results;
            const token = results.data.queryLoops.nextToken;
            if (!loopStatus) {
                setAllLoops([...allLoops, ...loops]);
                setSelectedLoops([...allLoops, ...loops]);
                setAllLoopsPaginationToken(token);
                setSelectedPaginationToken(token);
            } else if (loopStatus == LoopStatus.Open) {
                setOpenLoops([...openLoops, ...loops]);
                setSelectedLoops([...openLoops, ...loops]);
                setOpenLoopsPaginationToken(token);
                setSelectedPaginationToken(token);
            } else if (loopStatus == LoopStatus.Closed) {
                setClosedLoops([...closedLoops, ...loops]);
                setSelectedLoops([...closedLoops, ...loops]);
                setClosedLoopsPaginationToken(token);
                setSelectedPaginationToken(token);
            }
            setInitiatedFilterIds([...initiatedFilterIds, selectedFilterId]);
            setLoopsLoading(false);
            setLoopsLoaded(true);
        } catch (error) {
            console.error("Error in querying loops", error);
            setQueryLoopsErrors(error);
            setLoopsLoading(false);
        }
    };

    const mobileColumns = [
        {
            name: LOOP_LIST.LOOP_ID,
            cell: (row, index, column, id) => (
                <DeprecatedText>
                    <Link to={"/dashboard/loops/" + row.loopId}>
                        {prettifyUID(row.loopId)}
                    </Link>
                </DeprecatedText>
            ),
        },
        {
            name: LOOP_LIST.ASSET_TYPE,
            cell: (row) => (
                <CellText type="header" size="small">
                    {row.asset?.assetType?.name}
                </CellText>
            ),
        },
        {
            name: LOOP_LIST.CONSUMER_ID,
            cell: (row, index, column, id) => (
                <DeprecatedText>
                    <Link
                        to={"/dashboard/consumers/" + row.consumer.consumerId}
                    >
                        {prettifyUID(row.consumer.consumerId)}
                    </Link>
                </DeprecatedText>
            ),
        },
        {
            name: LOOP_LIST.STATUS,
            cell: (row, index, column, id) => (
                <LoopStatusBadge status={row.loopStatus} />
            ),
        },
    ];

    const columns = mobileColumns.slice();
    columns.splice(
        -1,
        0,
        {
            name: LOOP_LIST.OPENED,
            cell: (row, index, column, id) => (
                <div>
                    <CellText type="header" size="small">
                        {row.locations.openedAt.name ||
                            prettifyUID(row.locations.openedAt.locationId)}
                    </CellText>
                    <CellSubText>
                        {format(parseISO(row.openedAt), "MM/dd/yy hh:mm:ss a")}
                    </CellSubText>
                </div>
            ),
        },
        {
            name: LOOP_LIST.CLOSED,
            cell: (row, index, column, id) =>
                row.closedAt && (
                    <div>
                        <CellText type="header" size="small">
                            {row.locations.closedAt.name ||
                                prettifyUID(row.locations.closedAt.locationId)}
                        </CellText>
                        <CellSubText>
                            {format(
                                parseISO(row.closedAt),
                                "MM/dd/yy hh:mm:ss a"
                            )}
                        </CellSubText>
                    </div>
                ),
        }
    );

    const renderFilters = () => {
        return (
            <FiltersContainer>
                {FILTERS.map((filter, idx) => {
                    const isFilterSelected = selectedFilterId == filter.id;
                    return (
                        <Filter key={filter.id}>
                            <FilterWrapper
                                onClick={() => setSelectedFilterId(filter.id)}
                            >
                                <FilterLabel
                                    type={isFilterSelected ? "header" : "body"}
                                    size={isFilterSelected ? "small" : "large"}
                                    isSelected={isFilterSelected}
                                >
                                    {filter.label}
                                </FilterLabel>
                                {isFilterSelected ? <SelectedDivider /> : null}
                            </FilterWrapper>
                            {idx < FILTERS.length - 1 && <FilterDivider />}
                        </Filter>
                    );
                })}
            </FiltersContainer>
        );
    };

    return (
        <LoopsScreenView
            style={style}
            title={renderFilters()}
            counter={
                selectedPaginationToken
                    ? `${selectedLoops.length}+`
                    : `${selectedLoops.length}`
            }
        >
            <MediaQuery minWidth={breakpoints.xlarge}>
                <DataTable
                    columns={columns}
                    customStyles={customStyles}
                    keyField="loopId"
                    data={selectedLoops}
                    progressPending={!loopsLoaded && loopsLoading}
                    progressComponent={<ClipLoader loading />}
                />
            </MediaQuery>
            <MediaQuery maxWidth={breakpoints.xlarge}>
                <DataTable
                    columns={mobileColumns}
                    customStyles={customStyles}
                    keyField="loopId"
                    data={selectedLoops}
                    progressPending={!loopsLoaded && loopsLoading}
                    progressComponent={<ClipLoader loading />}
                />
            </MediaQuery>

            <Footer>
                {selectedPaginationToken ? (
                    <LoadMore
                        type="secondary"
                        onClick={() =>
                            loadLoops(
                                selectedStatus,
                                selectedPaginationToken,
                                selectedFilterId
                            )
                        }
                        label={
                            loopsLoading ? (
                                <ClipLoader
                                    color={colors.WAVE_STORM}
                                    size={10}
                                    loading
                                />
                            ) : (
                                <LoadMoreText type="body" size="large">
                                    {LOOP_LIST.LOAD_MORE}
                                </LoadMoreText>
                            )
                        }
                    ></LoadMore>
                ) : null}
            </Footer>
        </LoopsScreenView>
    );
}
