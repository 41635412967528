import styled from "../../styling/styled-components";
import { keyframes } from "styled-components";

const fadeIn = keyframes`
0% {
  opacity: 0.6;
}
50% {
  opacity: 1.0;
}
100% {
  opacity: 0.6;
}
`;

const SkeletonContainer = styled.div`
    display: flex;
    align-items: center;
    animation: ${fadeIn} 1s ease-in-out infinite;
    background-color: ${({ theme }) => theme.colors.SMOG};
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    border-radius: 4px;
    height: ;
`;

export default function ShimmerCard({ height, width }) {
    return (
        <SkeletonContainer height={height} width={width}></SkeletonContainer>
    );
}
