//apis
import { RentalStatus } from "../API";

//utils
import { humanizeLower } from "../utils/helpers";

export const STATUS_LIST = [
    {
        label: "In-progress",
        value: RentalStatus.InProgress,
        key: RentalStatus.InProgress,
        selected: false,
    },
    {
        label: humanizeLower(RentalStatus.Completed),
        value: RentalStatus.Completed,
        key: RentalStatus.Completed,
        selected: false,
    },
    {
        label: humanizeLower(RentalStatus.Failed),
        value: RentalStatus.Failed,
        key: RentalStatus.Failed,
        selected: false,
    },
    {
        label: humanizeLower(RentalStatus.Forgiven),
        value: RentalStatus.Forgiven,
        key: RentalStatus.Forgiven,
        selected: false,
    },
    {
        label: humanizeLower(RentalStatus.Canceled),
        value: RentalStatus.Canceled,
        key: RentalStatus.Canceled,
        selected: false,
    },
    {
        label: "Canceled (with refund)",
        value: RentalStatus.CanceledWithRefund,
        key: RentalStatus.CanceledWithRefund,
        selected: false,
    },
];
