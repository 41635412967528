import * as styledComponents from "styled-components";
import { TEXT_STYLES } from ".";
import { theme } from "./theme";

const {
    default: styled,
    css,
    ThemeProvider,
    useTheme,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<any>;

const findTextStyles = (
    type: keyof typeof TEXT_STYLES = "body",
    size: keyof typeof TEXT_STYLES.body &
        keyof typeof TEXT_STYLES.header &
        keyof typeof TEXT_STYLES.other &
        keyof typeof TEXT_STYLES.link = "large"
) => TEXT_STYLES[type][size];

export { css, ThemeProvider, theme, useTheme, findTextStyles };
export default styled;
