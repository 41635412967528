import { useTheme } from "../../../styling/styled-components";

//components
import Card from "../../common/card/Card";
import Text from "../../common/Text";
import { LeftHalfCard } from "../../common/card/LeftHalfCard";
import CardDetails from "./common/CardDetails";

//utils
import { colorChange, fontChange, fontChangeDate } from "./utils/helpers";
import { checkBorderColor } from "../../../utils/rentalOverlay/checkBorderColor";

export default function DueOnCard({ rentalConfigStep, lastPhase }) {
    const { colors } = useTheme();
    let due = new Date(rentalConfigStep.triggerAt);
    let dueTime = due.toLocaleString("en-us", {
        weekday: "long",
        month: "long",
        day: "numeric",
    });
    let dueHour = due.toLocaleString("en-us", {
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
    });

    const statusBool = () => {
        return (
            rentalConfigStep.status !== "SKIPPED" &&
            rentalConfigStep.status !== "PENDING"
        );
    };

    return (
        <Card
            backgroundColor={colorChange(statusBool(), colors)}
            borderColor={checkBorderColor(
                rentalConfigStep.rentalPhase,
                lastPhase
            )}
        >
            <LeftHalfCard>
                <Text
                    type="body"
                    size="tiny"
                    color={fontChangeDate(statusBool(), colors)}
                >
                    DUE ON
                </Text>
                <Text
                    type="header"
                    size="medium"
                    color={fontChange(statusBool(), colors)}
                >
                    {dueTime}
                </Text>
                <Text
                    type="body"
                    size="tiny"
                    color={fontChangeDate(statusBool(), colors)}
                >
                    {dueHour}
                </Text>
            </LeftHalfCard>
            <CardDetails />
        </Card>
    );
}
