import styled, {
    useTheme,
    findTextStyles,
} from "../../../../styling/styled-components";
import { ClipLoader } from "react-spinners";
import DeprecatedText from "../../../common/DeprecatedText";

const LoadingDiv = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    border-radius: 4px;
    position: fixed;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    align-items: center;
    z-index: 6;
`;

const LoadingText = styled(DeprecatedText)`
    ${({ type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.BLACK};
    position: fixed;
    padding-top: ${({ theme }) => theme.spacing.huge}px;
`;

export default function CancelLoad({ loading = true }) {
    const { colors } = useTheme();
    return (
        <LoadingDiv>
            {loading && (
                <>
                    <ClipLoader
                        color={colors.WAVE_STORM}
                        size={26}
                        loading={loading}
                    />
                    <LoadingText type="header" size="xlarge">
                        {"Canceling the rental..."}
                    </LoadingText>
                </>
            )}
        </LoadingDiv>
    );
}
