import React from "react";
import TimelineEvent, { TimelineEventProps } from "./TimelineEvent";
import DeprecatedText from "../common/DeprecatedText";
import styled from "styled-components/macro";
import { parseISO } from "date-fns";
import { prettifyUID } from "../../utils/helpers";
import ResolutionStatusBadge from "./ResolutionStatusBadge";
import AssetOperationTypeBadge from "./AssetOperationTypeBadge";

import {
    LoopStatus,
    Location,
    ResolutionStatus,
    LoopResolution,
    AssetEvent,
} from "../../API";
import { findTextStyles } from "../../styling/styled-components";
import { TIMELINE } from "../../constants/loops";

const Container = styled.div``;

const Notes = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

const Timeline = styled.div`
    flex-direction: row;
    display: flex;
`;

const Line = styled.div`
    background-color: ${({ theme }) => theme.colors.SMOG};
    width: 10px;
    border-radius: 100px;
`;

const Events = styled.div`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    padding-bottom: ${({ theme }) => theme.spacing.xlarge}px;
    flex: 1;
    margin-left: -21px;
`;

const OcurredTitleText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const OcurredBodyText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
`;

const FutureText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
`;

const CharcoalText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const locationBody = (location: Location): React.ReactNode => {
    return (
        <>
            <CharcoalText type="label" size="medium">
                {location.name}
            </CharcoalText>
            {location.address?.line1 && (
                <CharcoalText type="body" size="medium">
                    {location.address.line1}
                </CharcoalText>
            )}
            {location.address?.line3 && (
                <CharcoalText type="body" size="medium">
                    {location.address.line3}
                </CharcoalText>
            )}
        </>
    );
};

const loopStatusToEvent = (status: LoopStatus): TimelineEventProps => {
    const titleText =
        status.loopStatus == LoopStatus.Open
            ? TIMELINE.LOOP_OPENED
            : TIMELINE.LOOP_CLOSED;

    return {
        timestamp: parseISO(status.timestamp),
        title: (
            <OcurredTitleText type="label" size="large">
                {titleText}
            </OcurredTitleText>
        ),
        // closedAtLocationId had been optional, so some legacy loops will have null
        body: status.location ? locationBody(status.location) : <></>,
        badge: <></>,
        tickType: "green",
    };
};

const resolutionToEvent = (resolution: LoopResolution): TimelineEventProps => {
    return {
        timestamp: parseISO(resolution.triggerResolutionAt),
        title:
            resolution.resolutionStatus == ResolutionStatus.Pending ? (
                <FutureText type="label" size="large">
                    {TIMELINE.RESOLUTION(
                        resolution.resolution.name ||
                            prettifyUID(resolution.resolution.id)
                    )}
                </FutureText>
            ) : (
                <OcurredTitleText type="label" size="large">
                    {TIMELINE.RESOLUTION(
                        resolution.resolution.name ||
                            prettifyUID(resolution.resolution.id)
                    )}
                </OcurredTitleText>
            ),
        body:
            resolution.resolutionStatus == ResolutionStatus.Pending ? (
                <FutureText>{resolution.resolution.description}</FutureText>
            ) : (
                <OcurredBodyText>
                    {resolution.resolution.description}
                </OcurredBodyText>
            ),

        badge: <ResolutionStatusBadge status={resolution.resolutionStatus} />,
        tickType:
            resolution.resolutionStatus == ResolutionStatus.Succeeded
                ? "green"
                : resolution.resolutionStatus == ResolutionStatus.Failed
                ? "red"
                : "grey",
    };
};

const assetEventToEvent = (event: AssetEvent): TimelineEventProps => {
    return {
        timestamp: parseISO(event.eventTimestamp),
        title: (
            <OcurredTitleText type="label" size="large">
                {event.assetOperation.name}
            </OcurredTitleText>
        ),
        body: (
            <OcurredBodyText>
                {event.assetOperation.description}
            </OcurredBodyText>
        ),
        badge: (
            <AssetOperationTypeBadge
                assetOperationType={event.assetOperation.operationType}
            />
        ),
        tickType: "green",
    };
};

type Props = {
    loopStatuses: Array<LoopStatus>;
    resolutions: Array<LoopResolution>;
    assetEvents: Array<AssetEvent>;
};

export default function Component({
    loopStatuses,
    resolutions,
    assetEvents,
}: Props) {
    const statusEvents = loopStatuses.map((status) =>
        loopStatusToEvent(status)
    );
    const resolutionEvents = resolutions.map((resolution) =>
        resolutionToEvent(resolution)
    );
    const assetEventEvents = assetEvents.map((event) =>
        assetEventToEvent(event)
    );

    const events = [
        ...statusEvents,
        ...resolutionEvents,
        ...assetEventEvents,
    ].sort((a, b) => a.timestamp - b.timestamp);

    return (
        <Container>
            {assetEvents.length > 0 && (
                <Notes>
                    <OcurredBodyText type="body" size="tiny">
                        {TIMELINE.ASSET_EVENT_NOTES}
                    </OcurredBodyText>
                </Notes>
            )}
            <Timeline>
                <Line />
                <Events>
                    {events.map((event, idx) => (
                        <TimelineEvent {...event} key={idx} />
                    ))}
                </Events>
            </Timeline>
        </Container>
    );
}

Component.defaultProps = {
    resolutions: [],
    loopStatuses: [],
    assetEvents: [],
};
