import styled, { css, useTheme } from "../../../styling/styled-components";

//components
import RatePercentageChart from "./RatePercentageChart";
import Text from "../../common/Text";
import ShimmerCard from "../../common/ShimmerCard";
import Loadable from "../../common/Loadable";

//constants
import ChartDescriptionText from "./ChartDescriptionText";

const Rate = styled.div`
    min-width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
    align-self: flex-start;
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
        align-self: center;'
        flex-direction: column;
            justify-content: center;
        `}
`;

const Stats = styled.div`
    flex: 1;
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px;
            margin-vertical: ${({ theme }) => theme.spacing.small}px;
        `}
`;

const SlideRateText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.small}px;
    text-align: center;
`;

const LoadingWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    flex-shrink: 0;
`;

const StyledLoadingCard = () => {
    return (
        <LoadingWrapper>
            <ShimmerCard width={137} height={17}></ShimmerCard>
        </LoadingWrapper>
    );
};

const ChartLoadingCard = () => {
    return (
        <LoadingWrapper>
            <ShimmerCard width={45} height={47}></ShimmerCard>
        </LoadingWrapper>
    );
};

export default function RateChart({ item, isShown, loading }) {
    const { rateText } = item ? item : "";
    const { colors } = useTheme();
    let textDescription = `${rateText} return rate`;

    return (
        <Rate>
            <Loadable dataLoaded={item} LoadingComponent={ChartLoadingCard}>
                <RatePercentageChart
                    item={item}
                    isShown={isShown}
                    loading={loading}
                ></RatePercentageChart>
            </Loadable>
            <Stats>
                <Loadable
                    dataLoaded={item}
                    LoadingComponent={StyledLoadingCard}
                >
                    <SlideRateText
                        type="label"
                        size="medium"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {textDescription}
                    </SlideRateText>
                </Loadable>
                <Loadable
                    dataLoaded={item}
                    LoadingComponent={StyledLoadingCard}
                >
                    <ChartDescriptionText
                        item={item}
                        isShown={isShown}
                    ></ChartDescriptionText>
                </Loadable>
            </Stats>
        </Rate>
    );
}
