//react
import React, { useState, useEffect } from "react";

//components
import Text from "../../common/Text";
import { NextButton } from "../common/NextButton";
import { toastError, toastSuccess } from "../../../utils/toasts";
import { ButtonDiv } from "../common/ButtonDiv";
import { OverlayWrapper } from "../common/OverlayWrapper";
import LocationTag from "../common/LocationTag";
import EmailMessage from "../../common/EmailMessage";
import ArchiveButton from "../common/ArchiveButton";
import SideLine from "../../common/SideLine";

//style
import styled, { useTheme } from "../../../styling/styled-components";

//api
import useGraphClient from "../../../hooks/useGraphClient";
import * as mutations from "../../../graphql/mutations";

//utils
import { checkMetaDataComplete } from "../../../utils/locations/checkMetaData";
import { checkWithin24Hours } from "../../../utils/locations/checkWithin24Hours";
import _ from "lodash";

const ButtonText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const ArchiveText = styled.div`
    width: 336px;
    padding-left: ${({ theme }) => theme.spacing.huge}px;
    margin-left: ${({ theme }) => theme.spacing.xxlarge}px;
    &:hover {
        color: ${({ theme }) => theme.colors.RED_3};
    }
`;

const ArchiveDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: ${({ theme }) => theme.spacing.large}px;
    padding-right: ${({ theme }) => theme.spacing.small}px;
    padding-left: ${({ theme }) => theme.spacing.large}px;
`;

const ButtonWrapper = styled.div``;

const LineAddTag = styled.div`
    width: 444px;
    padding-left: ${({ theme }) => theme.spacing.xmlarge}px;
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const TagDiv = styled.div`
    position: relative;
    left: ${({ theme }) => -theme.spacing.xsmall}%;
`;

const AddTagText = styled(Text)`
    &:hover {
        color: ${(props) =>
            props.type == "label" ? props.theme.colors.PLASTIC_BOTTLE : ""};
    }
`;

export default function ScanningLocationDetails({
    updatedScanningName,
    getLocationData,
    noErrors,
    onReturnToMain,
    toggleArchiveScanModal,
    setShowBlocker,
}) {
    const emptyMetadata = {
        key: "",
        value: "",
        isNew: true,
    };
    const { colors, spacing } = useTheme();
    const [isLabelHovered, setIsLabelHovered] = useState(false);
    const [newMetaData, setNewMetaData] = useState<array>(
        typeof getLocationData.metadata[0] !== "object"
            ? [emptyMetadata]
            : _.cloneDeep(getLocationData.metadata)
    );
    const [originalMetaData, setOriginalMetaData] = useState<array>(
        _.cloneDeep(getLocationData.metadata)
    );
    const [canArchive, setCanArchive] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [showTags, setShowTags] = useState(true);
    const [shouldShowMetadataErrors, setShouldShowMetadataErrors] =
        useState<boolean>(false);

    const graphClient = useGraphClient();

    useEffect(() => {
        setCanArchive(checkWithin24Hours(getLocationData.createdAt));
        setOriginalMetaData(_.cloneDeep(getLocationData.metadata));
        setNewMetaData(_.cloneDeep(getLocationData.metadata));
    }, [getLocationData.locationId]);

    useEffect(() => {
        checkMetaDataComplete(newMetaData, setShouldShowMetadataErrors);
    }, [newMetaData]);

    useEffect(() => {
        setEnableButton(false);
        enableSaveChanges();
        checkDisplayBlocker();
    }, [newMetaData, updatedScanningName]);

    const toggleLabelHover = () => {
        setIsLabelHovered(!isLabelHovered);
    };

    const reformattedNewMetadata = newMetaData.map((md) => {
        return Object.keys(md)
            .filter((key) => key.includes("key") || key.includes("value"))
            .reduce((cur, key) => {
                return Object.assign(cur, { [key]: md[key] });
            }, {});
    });

    const onUpdateScanningLocation = async () => {
        try {
            const result = await graphClient.graphql({
                query: mutations.updateLocation,
                variables: {
                    input: {
                        locationId: getLocationData.locationId,
                        name: updatedScanningName,
                        metadata: reformattedNewMetadata,
                    },
                },
            });
            if (result) {
                onReturnToMain();
                toastSuccess("The location was successfully updated");
            }
        } catch (error) {
            toastError("The location could not be updated");
            console.error("Error updating scanning location", error);
        }
    };

    const enableSaveChanges = () => {
        if (updatedScanningName !== getLocationData.name) {
            setEnableButton(true);
            return;
        }

        if (
            newMetaData.length == 1 &&
            newMetaData[0].key == "" &&
            newMetaData[0].value == "" &&
            !showTags
        ) {
            setEnableButton(true);
            return;
        }

        if (newMetaData.length !== originalMetaData.length) {
            setEnableButton(true);
            return;
        }
        let buttonEnabled = false;

        newMetaData.map((item, index) => {
            originalMetaData.map((original, index2) => {
                if (item.key && item.value) {
                    if (
                        item.key.trimStart().trimEnd() !==
                        original.key.trimStart().trimEnd()
                    ) {
                        buttonEnabled = true;
                    }
                    if (
                        item.value.trimStart().trimEnd() !==
                        original.value.trimStart().trimEnd()
                    ) {
                        buttonEnabled = true;
                    }
                }
            });
        });
        if (buttonEnabled) {
            setEnableButton(true);
            return;
        } else {
            setEnableButton(false);
        }
    };

    const checkDisplayBlocker = () => {
        if (enableButton && noErrors && !shouldShowMetadataErrors) {
            setShowBlocker(true);
        } else {
            return setShowBlocker(false);
        }
    };

    return (
        <>
            <div
                style={{
                    position: "relative",
                    height: 444,
                    overflowY: "scroll",
                }}
            >
                <OverlayWrapper>
                    {!showTags ? (
                        <>
                            <LineAddTag>
                                <SideLine></SideLine>
                            </LineAddTag>
                            <TagDiv
                                style={{
                                    position: "relative",
                                    left: -spacing.yuge,
                                }}
                                onMouseEnter={toggleLabelHover}
                                onMouseLeave={toggleLabelHover}
                            >
                                <NextButton
                                    type="secondary"
                                    onClick={() => setShowTags(true)}
                                    style={{ border: "0px" }}
                                    label={
                                        <AddTagText
                                            type="label"
                                            size="medium"
                                            color={
                                                !isLabelHovered
                                                    ? colors.WAVE_STORM
                                                    : colors.PLASTIC_BOTTLE
                                            }
                                        >
                                            {"+ Add tag (optional)"}
                                        </AddTagText>
                                    }
                                ></NextButton>
                            </TagDiv>
                            <LineAddTag>
                                <SideLine></SideLine>
                            </LineAddTag>
                        </>
                    ) : (
                        <LocationTag
                            newMetaData={newMetaData}
                            setNewMetaData={setNewMetaData}
                            shouldShowMetadataErrors={shouldShowMetadataErrors}
                            setShowTags={setShowTags}
                        ></LocationTag>
                    )}
                </OverlayWrapper>
            </div>

            <ButtonDiv>
                {!canArchive ? (
                    <ButtonWrapper>
                        <ArchiveText>
                            <NextButton
                                type="primary"
                                status={
                                    enableButton &&
                                    noErrors &&
                                    !shouldShowMetadataErrors
                                        ? "default"
                                        : "disabled"
                                }
                                onClick={onUpdateScanningLocation}
                                label={
                                    <ButtonText
                                        type="label"
                                        size="large"
                                        color={colors.BLANK_SLATE}
                                    >
                                        {"Save changes"}
                                    </ButtonText>
                                }
                            ></NextButton>
                            <Text
                                type="body"
                                size="medium"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                If you need to archive a location please reach
                                out to{" "}
                                <span style={{ paddingLeft: spacing.medium }}>
                                    {" "}
                                    our support
                                </span>{" "}
                                team at{" "}
                                <EmailMessage
                                    email="techsupport@topanga.io"
                                    subject={`Archive Location Request`}
                                    body=""
                                >
                                    {"techsupport@topanga.io"}
                                </EmailMessage>
                            </Text>
                        </ArchiveText>
                    </ButtonWrapper>
                ) : (
                    <ArchiveDiv>
                        <ArchiveButton
                            buttonText={"Archive this location"}
                            onClick={() =>
                                toggleArchiveScanModal(updatedScanningName)
                            }
                        ></ArchiveButton>
                        <NextButton
                            type="primary"
                            onClick={onUpdateScanningLocation}
                            status={
                                enableButton &&
                                noErrors &&
                                !shouldShowMetadataErrors
                                    ? "default"
                                    : "disabled"
                            }
                            label={
                                <ButtonText
                                    type="label"
                                    size="large"
                                    color={colors.BLANK_SLATE}
                                >
                                    {"Save changes"}
                                </ButtonText>
                            }
                        ></NextButton>
                    </ArchiveDiv>
                )}
            </ButtonDiv>
        </>
    );
}
