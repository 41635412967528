import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";

//constants
import { DASHBOARD_OVERVIEW } from "../../../constants/dashboard";

const AllTimeDescriptionText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    text-align: center;
    @media screen and (max-width: 1025px) {
        padding-left: ${({ theme }) => theme.spacing.small}px;
    }
`;

export default function ChartDescriptionText({ item, isShown }) {
    const { loopsClosed, loopsOpened, percentage } = item;
    const { colors } = useTheme();
    const calculatedPercentage = percentage ? Math.round(percentage * 100) : 0;
    const textColor = isShown ? colors.EXHAUST : colors.DEEP_BLUE_SEA;
    const textDescription = isShown
        ? `${loopsClosed} of ${loopsOpened} returned (${calculatedPercentage}%)`
        : DASHBOARD_OVERVIEW.LOW_DATA;

    return (
        <AllTimeDescriptionText type="body" size="small" color={textColor}>
            {textDescription}
        </AllTimeDescriptionText>
    );
}
