// react
import React, { useContext } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";

// API
import { signOut } from "aws-amplify/auth";

// style
import styled from "styled-components/macro";

// images
import { ReactComponent as CollapseMenu } from "../../assets/vectors/CollapseMenu.svg";
import { ReactComponent as ExpandMenu } from "../../assets/vectors/ExpandMenu.svg";

// context
import GlobalContext, {
    emptyGlobalSelection,
} from "../../contexts/GlobalContext";

// components
import Line from "../common/Line";
import NeedHelpLink from "./NeedHelpLink";
import Text from "../common/Text";

// constants
import { SIDEBAR } from "../../constants/sidebar";

interface SidebarFooterProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
}

const StyledSidebarFooter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const StyledCollapsedSidebarFooter = styled.a`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    background-color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const UserWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.medium}px;
`;

const UserInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const UserName = styled(Text)`
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

const UserEmail = styled(Text)``;

const UserOrg = styled(Text)`
    font-weight: 500;
`;

const SignOutCta = styled.div``;

const SignOutLabel = styled(Text)`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
    &:hover {
        text-decoration: underline;
    }
`;

const CollapseMenuWrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.medium}px;
    background-color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
    &:hover {
        color: ${({ theme }) => theme.colors.BLANK_SLATE};
    }
`;

const ExpandMenuWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.xsmall}px;
    color: ${({ theme }) => theme.colors.PLASTIC_BOTTLE};
    ${StyledCollapsedSidebarFooter}:hover & {
        color: ${({ theme }) => theme.colors.BLANK_SLATE};
    }
`;

const CollapseMenuLabel = styled(Text)`
    margin-left: ${({ theme }) => theme.spacing.small}px;
    ${CollapseMenuWrapper}:hover & {
        color: ${({ theme }) => theme.colors.BLANK_SLATE};
    }
`;

const HelpLinkContainer = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.large}px;
`;

export const BrokenSidebarFooter: React.FC<SidebarFooterProps> = ({
    children,
    ...rest
}) => {
    return (
        <StyledCollapsedSidebarFooter>
            <ExpandMenuWrapper>
                <ExpandMenu />
            </ExpandMenuWrapper>
        </StyledCollapsedSidebarFooter>
    );
};

function ContainerComponent({ broken, children }) {
    return broken ? (
        <>{children}</>
    ) : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            {children}
        </div>
    );
}

export const SidebarFooter: React.FC<SidebarFooterProps> = ({
    children,
    ...rest
}) => {
    const context = useContext(GlobalContext);
    const { organizationName, username } = context.globalSelections;
    const { collapseSidebar, toggleSidebar, broken, collapsed, toggled } =
        useProSidebar();
    const navigate = useNavigate();

    const handleSignOut = async () => {
        await signOut();
        context.updateSelections(emptyGlobalSelection);
        navigate("/login");
    };

    return (
        <ContainerComponent broken={broken}>
            {collapsed ? (
                <StyledCollapsedSidebarFooter onClick={() => collapseSidebar()}>
                    <ExpandMenuWrapper>
                        <ExpandMenu />
                    </ExpandMenuWrapper>
                </StyledCollapsedSidebarFooter>
            ) : (
                <StyledSidebarFooter {...rest}>
                    <Container>
                        <UserWrapper>
                            <HelpLinkContainer>
                                <NeedHelpLink />
                            </HelpLinkContainer>
                            <Line />
                            <UserInfoWrapper>
                                <UserOrg type="label" size="small">
                                    {organizationName || null}
                                </UserOrg>
                                <UserEmail type="label" size="small">
                                    {username || null}
                                </UserEmail>
                            </UserInfoWrapper>
                            <SignOutCta onClick={handleSignOut}>
                                <SignOutLabel type="label" size="large">
                                    {SIDEBAR.SIGN_OUT}
                                </SignOutLabel>
                            </SignOutCta>
                        </UserWrapper>
                        <CollapseMenuWrapper onClick={() => collapseSidebar()}>
                            <CollapseMenu />
                            <CollapseMenuLabel type="label" size="small">
                                {SIDEBAR.COLLAPSE}
                            </CollapseMenuLabel>
                        </CollapseMenuWrapper>
                    </Container>
                </StyledSidebarFooter>
            )}
        </ContainerComponent>
    );
};
