import DeprecatedText from "../../../common/DeprecatedText";
import styled, { findTextStyles } from "../../../../styling/styled-components";

export function GrayOverlay({ children, loading, onClick }) {
    const GrayBackground = styled.div`
        background-color: ${({ theme }) => theme.colors.ASPHALT};
        opacity: 0.65;
        @media screen {
            position: absolute;
            right: 10px;
            top: -100px;
            width: 110%;
            height: 110%;
        }
        z-index: 4;
        backdrop-filter: ${loading ? "blur(8px)" : "blur(0px)"};
    `;
    return (
        <GrayBackground data-override-outside-click={true} onClick={onClick}>
            {children}
        </GrayBackground>
    );
}

export function ButtonText({ children, type, size, color }) {
    const CancelButtonText = styled(DeprecatedText)`
        ${({}) => findTextStyles(type, size)}
        color: ${color};
        svg {
            margin-bottom: 2px;
            margin-left: ${({ theme }) => theme.spacing.xsmall}px;
        }
    `;
    return <CancelButtonText>{children}</CancelButtonText>;
}
