//styles
import styled from "../../../styling/styled-components";

//components
import CycleRateDuration from "./CycleRateDuration";

//images
import { ReactComponent as CycleArrowRight } from "../../../assets/vectors/CycleArrowRight.svg";
import { ReactComponent as CycleArrowLeft } from "../../../assets/vectors/CycleArrowLeft.svg";

const DaysToReturnWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.large}px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`;

const RightCycleArrowDiv = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const LeftCycleArrowDiv = styled.div`
    padding: ${({ theme }) => theme.spacing.small}px;
`;

export default function DaysToReturn({ cycleRateData }: Props) {
    return (
        <DaysToReturnWrapper>
            <RightCycleArrowDiv>
                <CycleArrowRight></CycleArrowRight>
            </RightCycleArrowDiv>
            <CycleRateDuration
                cycleRateData={cycleRateData}
            ></CycleRateDuration>
            <LeftCycleArrowDiv>
                <CycleArrowLeft></CycleArrowLeft>
            </LeftCycleArrowDiv>
        </DaysToReturnWrapper>
    );
}
