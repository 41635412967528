import { css } from "styled-components";
import * as tokens from "./tokens";

export const SHADOW = {
    defaultStyle: () =>
        css`
      shadow-color: #000;
      shadow-offset: {
          width: 0,
          height: 4,
      };
      shadow-opacity: 0.22;
      shadow-radius: 2.22;
      elevation: 3;
    `,
    containerDropShadow: tokens.containerDropShadow,
    overlayDropShadow: tokens.overlayDropShadow,
};
