//Created custom query for getRentalStepConfig since codegen version doesn't include __typename attribute

export const getCompleteRentalStepConfig = /* GraphQL */ `
    query GetRentalStepConfig($id: ID!) {
        getRentalStepConfig(id: $id) {
            id
            name
            description
            rentalPhase
            timing {
                timestamp
                duration
                roundToTime
                roundToTimezone
            }
            __typename
            createdAt
            updatedAt
            ... on NotificationRentalStepConfig {
                notificationType
                notificationData
            }
        }
    }
`;
