//components
import LinkText from "../../common/LinkText";
import Displayable from "../../common/Displayable";
import ForgivenPolicyTooltip from "./../ForgivenPolicyTooltip";

//constants
import { RENTAL_VISUAL } from "../../../constants/rentalVisual/rentalVisualStrings";
import { RentalStatus } from "../../../API";

//react
import { useState } from "react";

//utils
import { handleLinkForgivenessPageNavigation } from "../../../utils/settings/handleLinkForgivenessPageNavigation";

type Props = { status: string };

export default function ForgivenPolicyLink({ status }: Props) {
    const [showTooltip, setShowTooltip] = useState(false);

    const onHoverOpenTooltip = () => {
        setShowTooltip(true);
    };

    const onLeaveCloseTooltip = () => {
        setShowTooltip(false);
    };

    return (
        <Displayable
            displayPrimaryComponent={
                status?.toUpperCase() == RentalStatus.Forgiven
            }
        >
            <div
                onMouseEnter={onHoverOpenTooltip}
                onMouseLeave={onLeaveCloseTooltip}
            >
                <LinkText
                    size="small"
                    type="new_link"
                    onClick={handleLinkForgivenessPageNavigation}
                >
                    {RENTAL_VISUAL.FORGIVEN_POLICY}
                </LinkText>
            </div>
            <ForgivenPolicyTooltip showTooltip={showTooltip} />
        </Displayable>
    );
}
