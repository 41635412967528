//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import StatsLoadingCard from "./StatsLoadingCard";
import Loadable from "../../common/Loadable";

//constants
import { ASSET_USAGE_SUMMARY } from "../../../constants/dashboard";
import { AssetUsageLabel } from "../../../API";

const AssetKeyDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    border-bottom: ${({ theme }) => theme.borderStyle.smallDivider};
`;

const TotalAssets = styled.div`
    padding-top: ${({ theme }) => theme.spacing.large}px;
    flex: 1 0 0;
    margin-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const PercentageStats = styled.div`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

const PercentageText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const KeyWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const CircleKey = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    border: ${({ theme }) => theme.borderStyle.keyContrast};
    border-radius: 50%;
`;

type AssetStatistics = {
    bucketCount: number;
    label: string;
    color: string;
    percentage: number;
    _typename: string;
};

type Props = {
    assetStats: AssetStatistics[];
};

export default function AssetBreakdown({ assetStats }: Props) {
    const { colors } = useTheme();

    const organizeAssetUsageDetails = (assetDetails) => {
        let neverUsedHeader =
            assetDetails.label == AssetUsageLabel.NeverUsed ||
            assetDetails.label == ASSET_USAGE_SUMMARY.NEVER_USED
                ? ASSET_USAGE_SUMMARY.BACK_STOCK_DETAILS
                : "";
        let subheader =
            assetDetails.label == AssetUsageLabel.Active ||
            assetDetails.label == ASSET_USAGE_SUMMARY.ACTIVE
                ? ASSET_USAGE_SUMMARY.IN_CIRCULATION_DETAILS
                : neverUsedHeader;

        let label =
            assetDetails.label == AssetUsageLabel.Active
                ? ASSET_USAGE_SUMMARY.IN_CIRCULATION
                : ASSET_USAGE_SUMMARY.BACK_STOCK;
        let bucketCount = assetDetails.bucketCount
            ? assetDetails.bucketCount
            : 0;
        const roundPercentage = () => {
            let assetDetailsPercentage = assetDetails.percentage;
            if (assetDetailsPercentage >= 99 && assetDetailsPercentage < 100) {
                return 99;
            }
            if (assetDetailsPercentage >= 0 && assetDetailsPercentage < 1) {
                return 1;
            }
            if (Math.round(assetDetailsPercentage) > 5) {
                return Math.round(assetDetailsPercentage);
            } else return "";
        };

        return {
            subheader: subheader,
            label: label,
            bucketCount: bucketCount,
            percentage: roundPercentage(),
        };
    };

    const AssetUsageText = ({ assetBreakdown }) => {
        return (
            <Loadable
                dataLoaded={assetStats}
                LoadingComponent={StatsLoadingCard}
            >
                <TotalAssets>
                    <Text
                        color={colors.DEEP_BLUE_SEA}
                        size="small"
                        type="label"
                    >
                        {assetBreakdown.label}
                    </Text>
                    <Text color={colors.EXHAUST} size="small" type="label">
                        {assetBreakdown.subheader}
                    </Text>
                </TotalAssets>
            </Loadable>
        );
    };

    const AssetUsageCount = ({ assetBreakdown }) => {
        return (
            <Loadable
                dataLoaded={assetStats}
                LoadingComponent={StatsLoadingCard}
            >
                <PercentageStats>
                    <Text
                        color={colors.DEEP_BLUE_SEA}
                        size="small"
                        type="header"
                    >
                        {assetBreakdown.bucketCount}
                    </Text>
                    <PercentageText
                        color={colors.EXHAUST}
                        size="small"
                        type="body"
                    >{` (${assetBreakdown.percentage}%)`}</PercentageText>
                </PercentageStats>
            </Loadable>
        );
    };

    return (
        <>
            {assetStats.map((item, index) => {
                let assetBreakdown = organizeAssetUsageDetails(item);
                return (
                    <AssetKeyDiv>
                        <KeyWrapper>
                            <CircleKey color={item.color} />
                        </KeyWrapper>
                        <AssetUsageText assetBreakdown={assetBreakdown} />
                        <AssetUsageCount assetBreakdown={assetBreakdown} />
                    </AssetKeyDiv>
                );
            })}
        </>
    );
}
