//react
import React, { useState, useEffect, useRef } from "react";

//components
import AddLocationStepOne from "./AddLocationStepOne";
import AddLocationStepTwo from "./AddLocationStepTwo";
import AddLocationStepThree from "./AddLocationStepThree";
import { LocationText } from "./common/LocationText";
import { HeaderDiv } from "./common/HeaderDiv";
import Overlay from "../common/overlay/Overlay";

//style
import { useTheme } from "../../styling/styled-components";

//api
import * as mutations from "../../graphql/mutations";
import useGraphClient from "../../hooks/useGraphClient";
import { toastError } from "../../utils/toasts";

//constants
import { emptyMetadata } from "../../constants/locations";

//utils
import { handleUsernameChange } from "../../utils/locations/handleUsernameChange";
import { returnStepDescription } from "../../utils/locations/returnStepDescription";

export default function LocationsOverlay({
    onClick,
    onClickSave,
    listLocationsData,
    width,
    closeAddLocation,
}) {
    //variables for steps 1,2 and 3 states
    const [stepNumber, setStepNumber] = useState(1);
    const [headerText, setHeaderText] = useState({
        stepDescription: "",
        stepInstruction: "",
        stepSubDescription: "",
    });
    const [loading, setLoading] = useState(false);

    //scanning-related states
    const [scanningLocationName, setScanningLocationName] = useState("");
    const [showAddButton, setShowAddButton] = useState(true);

    //variables for apis
    const [scanningLocations, setScanningLocations] = useState([]);
    const [newMetaData, setNewMetaData] = useState<array>([emptyMetadata]);
    const [parentLocation, setParentLocation] = useState({});
    const [addressDetails, setAddressDetails] = useState({
        parentName: "",
        streetAddress: "",
        streetAddress2: "",
        state: "",
        postalCode: "",
        country: "",
    });

    //style
    const { colors } = useTheme();

    // hooks
    const graphClient = useGraphClient();

    useEffect(() => {
        handleUpdateHeaderText();
    }, [stepNumber, showAddButton]);

    const setParentLocationDetails = (e, key) => {
        let addressCopy = { ...addressDetails };
        addressCopy[key] = e;
        setAddressDetails({
            ...addressCopy,
        });
    };

    const handleUpdateHeaderText = () => {
        const updatedText = returnStepDescription(stepNumber, showAddButton);
        setHeaderText({ ...headerText, ...updatedText });
    };

    const clickContinueButton = () => {
        let addLocationStepNumber = stepNumber;
        setStepNumber((addLocationStepNumber += 1));
        if (Object.keys(parentLocation).length === 0) {
            addParentLocation();
        }
    };

    const clickSaveButton = () => {
        setShowAddButton(true);
        setStepNumber(2);
    };

    const clickContinue = () => {
        let addLocationStepNumber = stepNumber;
        setStepNumber((addLocationStepNumber += 1));
    };

    const onClickAddScanLocation = () => {
        setShowAddButton(false);
        setStepNumber(2);
    };

    const triggerBackButton = () => {
        if (stepNumber == 2 && showAddButton) {
            setStepNumber(1);
            renderAddLocationStepNumber();
            handleUpdateHeaderText();
        }
        if (stepNumber == 2 && !showAddButton) {
            setShowAddButton(true);
            setStepNumber(2);
            renderAddLocationStepNumber();
            handleUpdateHeaderText();
        }
        if (stepNumber == 3) {
            setStepNumber(2);
            renderAddLocationStepNumber();
            handleUpdateHeaderText();
        }
    };

    const renderAddLocationStepNumber = () => {
        if (stepNumber == 1) {
            return (
                <AddLocationStepOne
                    clickContinueButton={clickContinueButton}
                    addressDetails={addressDetails}
                    setParentLocationDetails={setParentLocationDetails}
                    listLocationsData={listLocationsData.results}
                ></AddLocationStepOne>
            );
        }
        if (stepNumber == 2) {
            return (
                <AddLocationStepTwo
                    clickContinue={clickContinue}
                    showAddButton={showAddButton}
                    onClickAddScanLocation={onClickAddScanLocation}
                    scanningLocationName={scanningLocationName}
                    setScanningLocationName={setScanningLocationName}
                    scanningLocations={scanningLocations}
                    addAndSaveScan={addAndSaveScan}
                    locationPrefix={addressDetails.parentName}
                    handleUsernameChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                        handleUsernameChange(
                            event,
                            setScanningLocationName,
                            addressDetails.parentName
                        )
                    }
                    parentLocationId={parentLocation.locationId}
                    setNewMetaData={setNewMetaData}
                    newMetaData={newMetaData}
                    loading={loading}
                ></AddLocationStepTwo>
            );
        }
        if (stepNumber == 3) {
            return (
                <AddLocationStepThree
                    addressDetails={addressDetails}
                    onClick={onClickSave}
                    scanningLocations={scanningLocations}
                ></AddLocationStepThree>
            );
        }
    };

    const reformattedNewMetadata = newMetaData.map((md) => {
        return Object.keys(md)
            .filter((key) => key.includes("key") || key.includes("value"))
            .reduce((cur, key) => {
                return Object.assign(cur, { [key]: md[key] });
            }, {});
    });

    const addParentLocation = async () => {
        try {
            const result = await graphClient.graphql({
                query: mutations.createParentLocation,
                variables: {
                    input: {
                        name: addressDetails.parentName,
                        address: {
                            line1: addressDetails.streetAddress,
                            line2: addressDetails.streetAddress2,
                            line3: addressDetails.state,
                            postalCode: addressDetails.postalCode,
                            country: addressDetails.country,
                        },
                    },
                },
            });
            const createParentLocationData = result.data.createParentLocation;
            setParentLocation(createParentLocationData);
        } catch (err) {
            toastError("The location could not be added.");
            console.error("Error adding parent location", err);
        }
    };

    const addScanningLocation = async () => {
        setLoading(true);
        try {
            const result = await graphClient.graphql({
                query: mutations.createLocation,
                variables: {
                    input: {
                        name: scanningLocationName,
                        parentId: parentLocation.locationId,
                        metadata: reformattedNewMetadata,
                    },
                },
            });
            const createLocationData = result.data.createLocation;
            setScanningLocations((prev) => [...prev, createLocationData]);
            setScanningLocationName("");
            setLoading(false);
            setNewMetaData([emptyMetadata]);
        } catch (err) {
            console.error("Error adding scanning location", err);
            toastError("The location could not be added");
        }
    };

    const addAndSaveScan = () => {
        addScanningLocation();
        clickSaveButton();
    };

    return (
        <>
            {listLocationsData && (
                <Overlay
                    onClickedOutside={closeAddLocation}
                    width={width}
                    onClick={onClick}
                    showLeft={stepNumber == 1 ? false : true}
                    onClickBack={triggerBackButton}
                >
                    <>
                        <HeaderDiv>
                            <LocationText
                                type="body"
                                size="medium"
                                color={colors.EXHAUST}
                            >
                                {`Add location / Step ${stepNumber} of 3`}
                            </LocationText>
                            <LocationText
                                type="header"
                                size="large"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {headerText.stepInstruction}
                            </LocationText>
                            <LocationText
                                type="body"
                                size="medium"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {headerText.stepDescription}
                            </LocationText>
                            <LocationText
                                type="body"
                                size="medium"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {headerText.stepSubDescription}
                            </LocationText>
                        </HeaderDiv>
                        {renderAddLocationStepNumber()}
                    </>
                </Overlay>
            )}
        </>
    );
}
