import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import styled from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";
import DeprecatedText from "./DeprecatedText";

const OptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.medium}px;
    top: 100%;
    right: 50%;
    flex: 1;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding: ${({ theme }) => theme.spacing.medium}px;
`;

const Row = styled.div``;

const OptionLabel = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.WAVE_STORM};
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
`;

const ButtonWrapper = styled.div`
    cursor: pointer;
    padding: ${({ theme }) => theme.spacing.medium}px;
`;

interface Props {
    button: string;
    buttonStyle: Record<string, unknown>;
    customButton: JSX.Element;
    customOpenButton: JSX.Element;
    options: array;
}

export default function PopupMenu({
    button,
    buttonStyle,
    customButton,
    customOpenButton,
    options,
}: Props) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const renderOptions = () => {
        return (
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                {options.map((option) => {
                    return (
                        <MenuItem
                            key={option.label}
                            isAvailable={option.isAvailable}
                            onClick={() => {
                                option.onClick();
                                handleClose();
                            }}
                        >
                            <ListItemText>
                                <OptionLabel type="header" size="small">
                                    {option.label}
                                </OptionLabel>
                            </ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        );
    };

    const component = button
        ? button
        : anchorEl
        ? customOpenButton
            ? customOpenButton
            : customButton
        : customButton;

    return (
        <div>
            <div>
                <ButtonWrapper style={buttonStyle} onClick={handleClick}>
                    {component}
                </ButtonWrapper>
            </div>
            {renderOptions()}
        </div>
    );
}
