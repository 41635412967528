import React from "react";
import DeprecatedText from "./DeprecatedText";
import styled, { css, useTheme } from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";

const titles = {
    ASSET_TYPES: "ASSET TYPES",
    ASSET_TYPES_DETAILS: "Back to all asset types",
    CONSUMERS: "CONSUMERS",
    LOCATIONS: "LOCATIONS",
    LOOP_LIST: "Loop List",
    ACTIVITY: "ACTIVITY",
    OVERVIEW: "OVERVIEW",
    SCAN_APP_ACCOUNTS: "SCANAPP ACOUNTS",
    RENTALS: "Rentals",
    PROGRAM_SETTINGS: "PROGRAM SETTINGS",
};

const StyledScrollView = styled.div`
    background-color: ${({ theme }) => theme.colors.SMOG};
    display: flex;
    flex-direction: column;
    position: relative;
    padding: ${({ theme }) => theme.spacing.xlarge}px
        ${({ theme: { isMobile } }) =>
            isMobile &&
            css`
                margin-top: 72px;
            `};
    min-height: -webkit-fill-available;
    min-height: -moz-available;
`;

const ScreenHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const HeaderLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const HeaderRight = styled.div``;

const Body = styled.div`
    padding-bottom: 120px;
`;

const Title = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

const Counter = styled.div`
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.small}px;
    margin-left: ${({ theme }) => theme.spacing.small}px;
`;

const CounterValue = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

type Props = {
    children?: JSX.Element | string | string[];
    counter: string;
    shouldShowHeader: boolean;
    shouldShowCounter?: boolean;
    screenName?: string; // @deprecated - use title instead
    headerRight?: JSX.Element;
    title: JSX.Element | string;
    className?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
};

export default function ScreenView({
    className,
    children,
    counter = "0",
    screenName,
    headerRight,
    shouldShowHeader = true,
    shouldShowCounter = true,
    style,
    onClick,
    title,
}: Props) {
    const defaultTitle = title || titles[screenName];
    const { isMobile } = useTheme();
    return (
        <StyledScrollView
            className={className}
            contentContainerStyle={{ flex: 1 }}
            style={style}
        >
            {shouldShowHeader && !isMobile ? (
                <ScreenHeader>
                    <HeaderLeft>
                        <Title type="header" size="little">
                            {defaultTitle}
                        </Title>
                        {shouldShowCounter ? (
                            <Counter>
                                <CounterValue type="body" size="medium">
                                    {counter}
                                </CounterValue>
                            </Counter>
                        ) : null}
                    </HeaderLeft>
                    <HeaderRight>{headerRight}</HeaderRight>
                </ScreenHeader>
            ) : null}
            <Body onClick={onClick}>{children}</Body>
        </StyledScrollView>
    );
}

ScreenView.ScreenHeader = ScreenHeader;
