//enums
import { RentalStatus } from "../../../../API";

//images
import {
    BlueDottedCircle,
    GreenCheck,
    GreyX,
    RedHourglass,
    ForgivenHeart,
} from "../../../common/IconWithShadow";

type Props = {
    status: string;
};

export default function StatusIcon({ status }: Props) {
    let icon = null;

    switch (status) {
        case RentalStatus.Completed:
            icon = <GreenCheck />;
            break;
        case RentalStatus.Expired:
            icon = <RedHourglass />;
            break;
        case RentalStatus.Failed:
            icon = <RedHourglass />;
            break;
        case RentalStatus.InProgress:
            icon = <BlueDottedCircle />;
            break;
        case RentalStatus.Forgiven:
            icon = <ForgivenHeart />;
            break;
        default:
            icon = <GreyX />;
            break;
    }

    return icon;
}
