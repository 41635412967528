export const orderedRentalPhases = [
    "Initiated",
    "About to expire",
    "Fined (automatic)",
    "Late pending",
    "Expired",
    "Fine error",
    "Late",
    "Fined (manual)",
    "Fine pending",
    "Failed pending",
];
