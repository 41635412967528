//enums
import { RentalStatus } from "../../../../API";

//images
import {
    BlueDottedCircleSmall,
    GreenCheckSmall,
    GreyXSmall,
    RedHourglassSmall,
    ForgivenHeartSmall,
} from "../../../common/IconWithShadow";

type Props = {
    status: string;
};

export default function RentalStatusIconSmall({ status }: Props) {
    let icon = null;

    switch (status) {
        case RentalStatus.Completed:
            icon = <GreenCheckSmall />;
            break;
        case RentalStatus.Expired:
            icon = <RedHourglassSmall />;
            break;
        case RentalStatus.Failed:
            icon = <RedHourglassSmall />;
            break;
        case RentalStatus.InProgress:
            icon = <BlueDottedCircleSmall />;
            break;
        case RentalStatus.Forgiven:
            icon = <ForgivenHeartSmall />;
            break;
        default:
            icon = <GreyXSmall />;
            break;
    }

    return icon;
}
