export const checkMetaDataComplete = (
    newMetaData,
    setShouldShowMetadataErrors
) => {
    const isMetadataValid = newMetaData.every((data) => {
        // no input okay, but if you enter one you need to enter both
        return !(!data.key && !!data.value) && !(!!data.key && !data.value);
    });
    if (!isMetadataValid) {
        setShouldShowMetadataErrors(true);
    } else {
        setShouldShowMetadataErrors(false);
    }
};
