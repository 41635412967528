//style
import styled from "../../../styling/styled-components";

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    flex: 1 0 0;
    width: 444px;
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
`;

export function HeaderDiv({ children }) {
    return <Header>{children}</Header>;
}
