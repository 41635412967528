//styles
import { COLORS } from "../../../styling/colors";
import { bodyFont } from "./tooltipBodyFont";
import { tooltipSizing } from "./tooltipSizing";

export const lightBlueTooltipStyles = () => {
    return {
        enabled: true,
        events: ["click"],
        pointer: "cursor",
        usePointStyle: true,
        backgroundColor: COLORS.MARINE_LAYER,
        displayColors: false,
        ...tooltipSizing,
        ...bodyFont,
    };
};
