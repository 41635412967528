//react
import React, { useState } from "react";

//components
import Text from "../../common/Text";
import SideLine from "../../common/SideLine";
import { LineDiv } from "../common/LineDiv";
import { NextButton } from "../common/NextButton";
import EmailMessage from "../../common/EmailMessage";
import ArchiveButton from "../common/ArchiveButton";
import { ButtonDiv } from "../common/ButtonDiv";
import HighlightHover from "../../common/HighlightHover";

//style
import styled, { useTheme } from "../../../styling/styled-components";

//images
import { ReactComponent as RightArrow } from "../../../assets/vectors/RightArrow.svg";

const ButtonText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const ScanningText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    width: 100%;
`;

const WrapperDiv = styled.div`
    display: flex;
    flexdirection: row;
    width: 100%;
    cursor: pointer;
`;

const ArrowHoverDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const ArchiveDiv = styled.div`
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const LocationButtonDiv = styled.div``;

const ScanningLocationDiv = styled.div`
    width: 300px;
    display: flex;
    justify-content: flex-start;
    padding-right: ${({ theme }) => theme.spacing.large}px;
    position: relative;
    top: ${({ theme }) => -theme.spacing.large}px;
    height: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const ScanningButtonDiv = styled.div`
    width: ${({ theme }) => theme.spacing.large}%;
    display: flex;
    justify-content: flex-start;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const ScanningArrowDiv = styled.div`
    position: absolute;
    right: ${({ theme }) => theme.spacing.xlarge}px;
`;

const ButtonWrapper = styled.div`
    width: 350px;
    margin-left: ${({ theme }) => theme.spacing.yuge}px;
    padding-top: ${({ theme }) => theme.spacing.medium}px;
`;

export default function ParentLocationDetails({
    getParentLocationData,
    onClickOpenScanning,
    onClickOpenAddScan,
    toggleArchiveModal,
}) {
    const { colors, spacing } = useTheme();
    const [isLabelHovered, setIsLabelHovered] = useState(false);

    const toggleLabelHover = () => {
        setIsLabelHovered(!isLabelHovered);
    };

    return (
        <>
            <div
                style={{
                    position: "relative",
                    height: 410,
                    overflowY: "scroll",
                }}
            >
                <LineDiv width={84}>
                    <SideLine></SideLine>
                </LineDiv>
                {getParentLocationData.locations.length > 0 ? (
                    getParentLocationData.locations.map((item, index) => {
                        return (
                            <>
                                <WrapperDiv
                                    onClick={() =>
                                        onClickOpenScanning(item.locationId)
                                    }
                                >
                                    <ScanningText
                                        type="label"
                                        size="medium"
                                        color={colors.DEEP_BLUE_SEA}
                                        onClick={() => console.log(item)}
                                    >
                                        {item.name}
                                    </ScanningText>
                                    <ArrowHoverDiv>
                                        <ScanningArrowDiv>
                                            <HighlightHover>
                                                <RightArrow></RightArrow>
                                            </HighlightHover>
                                        </ScanningArrowDiv>
                                    </ArrowHoverDiv>
                                </WrapperDiv>
                                <LineDiv width={84}>
                                    <SideLine></SideLine>
                                </LineDiv>
                            </>
                        );
                    })
                ) : (
                    <>
                        <ScanningButtonDiv
                            onMouseEnter={toggleLabelHover}
                            onMouseLeave={toggleLabelHover}
                        >
                            <NextButton
                                onClick={onClickOpenAddScan}
                                type="secondary"
                                label={
                                    <ButtonText
                                        type="label"
                                        size="medium"
                                        color={
                                            !isLabelHovered
                                                ? colors.WAVE_STORM
                                                : colors.PLASTIC_BOTTLE
                                        }
                                    >
                                        {"+ Add scanning location"}
                                    </ButtonText>
                                }
                            ></NextButton>
                        </ScanningButtonDiv>
                        <LocationButtonDiv />
                        <div
                            style={{
                                paddingTop:
                                    getParentLocationData.locations.length > 0
                                        ? spacing.small
                                        : 0,
                            }}
                        >
                            <LineDiv width={84}>
                                <SideLine></SideLine>
                            </LineDiv>
                        </div>
                    </>
                )}

                {getParentLocationData.locations.length > 0 && (
                    <>
                        <ScanningLocationDiv
                            onMouseEnter={toggleLabelHover}
                            onMouseLeave={toggleLabelHover}
                        >
                            <NextButton
                                onClick={onClickOpenAddScan}
                                type="secondary"
                                style={{
                                    border: "0px",
                                    width: "110%",
                                }}
                                label={
                                    <ButtonText
                                        type="label"
                                        size="medium"
                                        color={
                                            !isLabelHovered
                                                ? colors.WAVE_STORM
                                                : colors.PLASTIC_BOTTLE
                                        }
                                    >
                                        {"+ Add another scanning location"}
                                    </ButtonText>
                                }
                            ></NextButton>
                        </ScanningLocationDiv>
                        <LineDiv width={84}>
                            <SideLine></SideLine>
                        </LineDiv>
                    </>
                )}
            </div>
            <ButtonDiv height={"true"}>
                {getParentLocationData.locations.length > 0 ? (
                    <ButtonWrapper>
                        <Text
                            type="body"
                            size="medium"
                            color={colors.DEEP_BLUE_SEA}
                        >
                            If you need to archive a location please reach out
                            to{" "}
                            <span
                                style={{
                                    paddingLeft: spacing.medium,
                                }}
                            >
                                {" "}
                                our support
                            </span>{" "}
                            team at{" "}
                            <EmailMessage
                                email="techsupport@topanga.io"
                                subject={`Archive Location Request`}
                                body=""
                            >
                                {"techsupport@topanga.io"}
                            </EmailMessage>
                        </Text>
                    </ButtonWrapper>
                ) : (
                    <ArchiveDiv>
                        <ArchiveButton
                            onClick={toggleArchiveModal}
                            buttonText={"Archive parent location"}
                        ></ArchiveButton>
                    </ArchiveDiv>
                )}
            </ButtonDiv>
        </>
    );
}
