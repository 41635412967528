//components
import Text from "../../common/Text";

//styling
import styled, { useTheme } from "../../../styling/styled-components";

const STRINGS = {
    COPY: "Nothing here to see.",
};

const Container = styled.div`
    padding: 18px;
`;

const NoDataText = styled(Text)`
    max-width: 500px;
    text-align: center;
`;

type Props = {
    copy?: string;
    className?: string;
};

export default function NoTableData({ copy = STRINGS.COPY, className }: Props) {
    const { colors } = useTheme();

    return (
        <Container>
            <NoDataText type="body" size="medium" color={colors.DEEP_BLUE_SEA}>
                {copy}
            </NoDataText>
        </Container>
    );
}
