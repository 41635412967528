export const SIGNIN = {
    CREATE_NEW_PASSWORD_TITLE: "Create new password",
    CREATE_NEW_PASSWORD_SUBTITLE:
        "Welcome! Please create a  password for your account.",
    FORGOT_PASSWORD: "Forgot password?",
    NEW_PASSWORD_BUTTON: "Update password",
    NEW_PASSWORD_TITLE: "Welcome! Please enter a new password.",
    PASSWORD_LABEL: "Password",
    PASSWORD_CONSTRAINTS:
        "Password must be at least 8 characters long, contain at least 1 number, and contain at least 1 uppercase letter.",
    RETURN: "Return to sign in",
    SIGN_IN_TITLE: "Sign in to your account",
    SIGN_IN_BUTTON: "Sign in",
    TITLE: "Loop Dashboard",
    USERNAME_LABEL: "Email",
};

export const NEW_PASSWORD = {
    BACK: "Back",
    BACK_SIGN_IN: "Back to sign in",
    CODE: "6-digit confirmation code",
    CODE_PROMPT: "Enter the 6-digit code we just sent to ",
    CONFIRM_EMAIL:
        "Confirm the email associated with your account and we’ll send you a secure PIN to reset.",
    CONTINUE: "Continue",
    ERROR: "Must be a different password with at least 8 characters and include 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.",
    FORGOT_PASSWORD: "Forgot password",
    NEW_PASSWORD_PROMPT: "Enter your new password.",
    NEW_PASSWORD_BUTTON: "Change password",
    PASSWORD_HINT: "Password",
    PASSWORD_CONSTRAINTS:
        "Must be a different password with at least 8 characters and include 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.",
    PROMPT: "Retrieve code sent to your email and set a new password",
    RE_ENTER_PASSWORD: "Re-enter password",
    RESEND_CODE: "Resend code",
    RESET_PASSWORD: "Reset password",
    SUCCESS_CODE_SENT: "New code sent!",
    USERNAME: "Email",
};

export const FORGOT_PASSWORD = {
    BACK: "Back",
    BACK_SIGN_IN: "Back to sign in",
    CODE: "6-digit confirmation code",
    CODE_PROMPT: "Enter the 6-digit code we just sent to ",
    CONFIRM: "Confirm",
    CONFIRM_EMAIL:
        "Confirm the email associated with your account and we’ll send you a secure PIN to reset.",
    CONTINUE: "Continue",
    ERROR: "Must be a different password with at least 8 characters and include 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.",
    FOLLOW_STEPS: "Follow the steps below to reset your password.",
    FORGOT_PASSWORD: "Forgot password",
    NEW_PASSWORD_PROMPT: "Create a new password",
    NEW_PASSWORD_BUTTON: "Change password",
    PASSWORD_HINT: "Password",
    PASSWORD_CONSTRAINTS:
        "Must be a different password with at least 8 characters and include 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.",
    PROMPT: "Retrieve code sent to your email and set a new password",
    RE_ENTER_PASSWORD: "Re-enter password",
    RESEND_CODE: "Resend code",
    RETURN: "Return to sign in",
    STEPONE: "Step 1",
    STEPTWO: "Step 2",
    SUCCESS_CODE_SENT: "New code sent!",
    USERNAME: "Email",
};

export const SIGN_OUT = {
    HEADER: "Topanga.io",
};
