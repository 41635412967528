import React, { useEffect } from "react";
import LineChart from "./LineChart";
import DeprecatedText from "../DeprecatedText";
import styled from "styled-components/macro";
import { parseISO, format } from "date-fns";
import * as queries from "../../../graphql/queries";
import { useQuery } from "../../../graphql/hooks/query";
import { findTextStyles } from "../../../styling/styled-components";
import "chart.js/auto";

const STRINGS = {
    HEADER: "Loop Durations",
    SUBHEADER:
        "Loop Durations measure how long it takes your consumers to return assets. If the 7 day Loop Duration is 60%, you can expect 60% of your assets to be returned within 7 days. This statistic is refreshed nightly.",
    LAST_UPDATED: (date: string) =>
        date
            ? `Last updated ${format(parseISO(date), "MM/dd/yy hh:mm:ss a")}`
            : "",
    X_AXIS: "Days from Loop Creation",
    Y_AXIS: "Percent of Assets Returned",
};
const Container = styled.div``;

const Header = styled.div``;

const HeaderText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.BLACK};
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

const SubHeaderText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
`;

const Chart = styled.div`
    padding: ${({ theme }) => theme.spacing.large}px 0px;
    z-index: -1;
    max-height: 450px;
`;

const UpdatedText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    margin-top: ${({ theme }) => theme.spacing.large}px;
`;

type Props = {
    assetTypeId: string;
    hideTitle?: boolean;
};
export default function LoopDurationCurve({ assetTypeId, hideTitle }: Props) {
    const [
        queryLoopDurations,
        queryLoopDurationsData,
        queryLoopDurationsLoading,
        queryLoopDurationsErrors,
    ] = useQuery(queries.queryLoopDurations);

    useEffect(() => {
        load();
    }, [assetTypeId]);

    const load = () => {
        queryLoopDurations({
            fromCohortMonth: "2022-01-01",
            toCohortMonth: new Date().toISOString(),
            fromLoopDurationDays: 0,
            toLoopDurationDays: 90,
            assetTypeId,
        });
    };

    queryLoopDurationsErrors && console.error(queryLoopDurationsErrors);

    const labels = queryLoopDurationsData?.results.map(
        (result) => result.loopDurationDays
    );

    const data = queryLoopDurationsData?.results.map(
        (result) => result.percentReturned
    );

    const lastUpdated = queryLoopDurationsData?.updatedAt;
    return (
        <Container>
            {!hideTitle && (
                <Header>
                    <HeaderText type="header" size="medium">
                        {STRINGS.HEADER}
                    </HeaderText>
                    <SubHeaderText type="body" size="tiny">
                        {STRINGS.SUBHEADER}
                    </SubHeaderText>
                </Header>
            )}
            <Chart>
                <LineChart
                    labels={labels}
                    data={data}
                    formatYLabel={(val: number) => `${(val * 100).toFixed(0)}%`}
                    xAxisTitle={STRINGS.X_AXIS}
                    yAxisTitle={STRINGS.Y_AXIS}
                    maintainAspectRatio={false}
                />
            </Chart>
            <UpdatedText type="body" size="tiny">
                {STRINGS.LAST_UPDATED(lastUpdated)}
            </UpdatedText>
        </Container>
    );
}

LoopDurationCurve.defaultProps = { hideTitle: false };
