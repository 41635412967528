export const SIDEBAR = {
    OVERVIEW: "Overview",
    LOOPS: "Loops",
    ACTIVITY: "Activity",
    ASSETS: "Assets & Inventory",
    CONSUMERS: "Consumers",
    SETTINGS: "Program Settings",
    LOCATIONS: "Locations",
    SCAN_APP: "ScanApp Accounts",
    SIGN_OUT: "Sign Out",
    COLLAPSE: "Collapse Menu",
};
