// react
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// API
import useGraphClient from "../../hooks/useGraphClient";
import * as queries from "../../graphql/queries";

// context
import GlobalContext from "../../contexts/GlobalContext";

// components
import Button from "../common/Button";
import ScanAppUsersList from "./ScanAppUsersList";
import ScreenView from "../common/ScreenView";
import Text from "../common/Text";
import TextWithCounter from "../common/text/TextWithCounter";

// style
import styled, { css } from "styled-components/macro";
import { useTheme } from "../../styling/styled-components";

// constants
import { SCAN_APP_ACCOUNTS } from "../../constants/scanapp";

const Container = styled(ScreenView)`
    height: auto;
`;

const StyledCreateButton = styled(Button)`
    background-color: transparent;
    box-shadow: none;
    margin: 0 auto;
    ${({ theme }) =>
        css`
            background-color: transparent;
            &:hover {
                background-color: ${theme.colors.SEAGLASS};
            }
        `};
`;

const Counter = styled.div`
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.small}px;
    margin-left: ${({ theme }) => theme.spacing.small}px;
`;

const HeaderRight = styled.div`
    align-items: flex-end;
`;

const TotalAccountsWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing.xlarge}px;
`;

const ActiveAccountsWrapper = styled.div``;

const InactiveAccountsWrapper = styled(ActiveAccountsWrapper)``;

const HeaderWrapper = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const CharcoalText = styled(Text)`
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const LimitReachedText = styled(CharcoalText)``;

const SubHeaderText = styled(CharcoalText)`
    max-width: 475px;
`;

const InactiveSubHeaderText = styled(SubHeaderText)`
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

export default function ScanAppAccounts() {
    const [activeAccountsCount, setActiveAccountsCount] = useState<string>("-");
    const [inactiveAccountsCount, setInactiveAccountsCount] =
        useState<string>("-");
    const [totalAccountsCount, setTotalAccountsCount] = useState<number>(0);
    const [limitReached, setLimitReached] = useState(false);
    const [isLimited, setIsLimited] = useState(false);
    const navigate = useNavigate();
    const { scanAppUsers, scanAppUserLimit } =
        useContext(GlobalContext).globalSelections;

    const { deletedUserId, createdUserId, updatedUserId } = {};

    const { colors, spacing } = useTheme();

    const graphClient = useGraphClient();

    useEffect(() => {
        getTotalAccounts();
    }, [deletedUserId, createdUserId]);

    useEffect(() => {
        if (
            !parseInt(activeAccountsCount) &&
            parseInt(activeAccountsCount) !== 0
        ) {
            return;
        }
        if (
            !parseInt(inactiveAccountsCount) &&
            parseInt(inactiveAccountsCount) !== 0
        ) {
            return;
        }
        setTotalAccountsCount(
            parseInt(activeAccountsCount) + parseInt(inactiveAccountsCount)
        );
    }, [activeAccountsCount, inactiveAccountsCount]);

    useEffect(() => {
        if (scanAppUserLimit == -1) {
            setLimitReached(false);
            setIsLimited(false);
        } else {
            setIsLimited(true);
            setLimitReached(totalAccountsCount >= scanAppUserLimit);
        }
    }, [scanAppUsers, scanAppUserLimit, totalAccountsCount]);

    const getTotalAccounts = async (): null => {
        try {
            const results = await graphClient.graphql({
                query: queries.getOrganization,
            });
            setTotalAccountsCount(results.data.getOrganization.scanAppUsers);
        } catch (err) {
            console.error("Error getting organization scanAppUsers", err);
        }
    };

    return (
        <>
            <TotalAccountsWrapper>
                <TextWithCounter
                    type="label"
                    size="large"
                    count={totalAccountsCount}
                    color={colors.ROCK_BOTTOM}
                >
                    {SCAN_APP_ACCOUNTS.TOTAL_ACCOUNTS}
                </TextWithCounter>
                <div>
                    <StyledCreateButton
                        type="secondary"
                        status={limitReached ? "disabled" : "default"}
                        label={SCAN_APP_ACCOUNTS.CREATE_ACCOUNT}
                        onClick={() => navigate("scan-app/create")}
                    />
                    {limitReached && (
                        <LimitReachedText type="body" size="medium">
                            {SCAN_APP_ACCOUNTS.LIMIT_REACHED}
                        </LimitReachedText>
                    )}
                </div>
            </TotalAccountsWrapper>
            <ActiveAccountsWrapper>
                <TextWithCounter
                    type="label"
                    size="small"
                    count={activeAccountsCount}
                    color={colors.ROCK_BOTTOM}
                >
                    {SCAN_APP_ACCOUNTS.ACTIVE_ACCOUNTS}
                </TextWithCounter>
                <ScanAppUsersList
                    isActive={true}
                    countCallback={(count) => setActiveAccountsCount(count)}
                    deletedUserId={deletedUserId}
                    createdUserId={createdUserId}
                    updatedUserId={updatedUserId}
                />
            </ActiveAccountsWrapper>
            <InactiveAccountsWrapper>
                <TextWithCounter
                    type="label"
                    size="small"
                    count={inactiveAccountsCount}
                    color={colors.ROCK_BOTTOM}
                >
                    {SCAN_APP_ACCOUNTS.INACTIVE_ACCOUNTS}
                </TextWithCounter>
                <InactiveSubHeaderText type="body" size="medium">
                    {SCAN_APP_ACCOUNTS.INACTIVE_ACCOUNTS_SUBHEADER}
                </InactiveSubHeaderText>
                <ScanAppUsersList
                    isActive={false}
                    countCallback={(count) => setInactiveAccountsCount(count)}
                    deletedUserId={deletedUserId}
                    createdUserId={createdUserId}
                    updatedUserId={updatedUserId}
                />
            </InactiveAccountsWrapper>
        </>
    );
}
