// style
import styled, { useTheme } from "../../../styling/styled-components";

// components
import Text from "../Text";

const Radio = styled.label`
    cursor: pointer;
    display: flex;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

const RadioInput = styled.input`
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid ${({ theme }) => theme.colors.WAVE_STORM};
    border-radius: 50%;
    height: ${({ theme }) => theme.spacing.medium}px;
    margin: 0;
    width: ${({ theme }) => theme.spacing.medium}px;
    cursor: pointer;
    ::after {
        content: "";
        display: block;
        border-radius: 50%;
        height: ${({ theme }) => theme.spacing.small}px;
        margin: 2px;
        width: ${({ theme }) => theme.spacing.small}px;
    }
    :checked {
        ::after {
            background-color: ${({ theme }) => theme.colors.WAVE_STORM};
        }
    }
`;

export default function RadioButtons({
    options,
    onChange,
    selectedOption,
}: Props) {
    const { colors } = useTheme();

    return (
        <>
            {options.map((item, index) => {
                return (
                    <Radio key={index}>
                        <RadioInput
                            type="radio"
                            name="optionSelected"
                            value={item.value}
                            checked={item.value == selectedOption}
                            onChange={(event) => onChange(event, item)}
                        />
                        <Text
                            type="label"
                            size="medium"
                            color={colors.DEEP_BLUE_SEA}
                        >
                            {item.label}
                        </Text>
                    </Radio>
                );
            })}
        </>
    );
}
