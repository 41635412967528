//style
import { useTheme } from "../../../styling/styled-components";

//components
import Card from "../../common/card/Card";
import Text from "../../common/Text";
import CardHeader from "./common/CardHeader";
import { RightHalfCard } from "../../common/card/RightHalfCard";

//utils
import { colorChange, fontChange, fontChangeDate } from "./utils/helpers";
import { checkBorderColor } from "../../../utils/rentalOverlay/checkBorderColor";

export default function AboutToExpireCard({
    rentalConfigStep,
    finePendingConfig,
    lastPhase,
}) {
    const { colors } = useTheme();

    const checkDescription = (item) => {
        if (item.description !== "") {
            return item.description;
        }
    };

    const statusBool = () => {
        return (
            rentalConfigStep.status !== "SKIPPED" &&
            rentalConfigStep.status !== "PENDING"
        );
    };

    return (
        <Card
            backgroundColor={colorChange(statusBool(), colors)}
            borderColor={checkBorderColor(
                rentalConfigStep.rentalPhase,
                lastPhase
            )}
        >
            <CardHeader
                header={"About to expire"}
                date={rentalConfigStep.triggerAt}
                colorHeader={fontChange(statusBool(), colors)}
                colorDate={fontChangeDate(statusBool(), colors)}
            ></CardHeader>
            <RightHalfCard>
                <Text
                    type="body"
                    size="medium"
                    color={fontChange(statusBool(), colors)}
                >
                    {checkDescription(finePendingConfig)}
                </Text>
            </RightHalfCard>
        </Card>
    );
}
