import React from "react";

//styles
import styled from "../../styling/styled-components";

const StyledLine = styled.div`
    height: ${(props) => props.height};
    width: 0.5px;
    background-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    transform: translateX(-50%);
`;

export default function VerticalLine({ height = "100%" }: Props) {
    return <StyledLine height={height} />;
}
