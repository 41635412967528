import { format, parseISO } from "date-fns";

export const ALL_TIME_RETURN_RATE_CARD = {
    HEADER: "ALL-TIME",
    LAST_UPDATED: (date: string) =>
        date
            ? `Last updated ${format(parseISO(date), "MM/dd/yy hh:mm:ss a")}`
            : "",
};

export const ASSET_CARD = {
    ON_HAND: "Inventory on hand",
    ASSET_TYPE_DELETED: "Asset Type deleted",
    DELETE_CONFIRM_TITLE: "Are you sure you want to delete this asset type?",
    DELETE_CONFIRM_SUBTITLE:
        "Deleting will detach all associated asset IDs. This cannot be undone!",
};

export const ASSET_TYPES_LIST = {
    ASSET_TYPES: "Asset Types",
    ASSET_TYPES_DESCR:
        "Please login to the dashboard on desktop or tablet to do more.",
    ADD_ASSET_TYPE: "Add asset type",
    DETAIL_LABEL: "Back to all asset types",
    LOAD_MORE: "Load more",
};

export const ASSET_TYPE_DETAIL = {
    RETURN_RATE: "Return Rate",
    EDIT_OPTION: "Edit Details",
    RETURN_RATE_DESCR:
        "Return Rate is a measure of how often your consumers return assets when a return is expected. It’s calculated across all locations and refreshed nightly.",
    LOOP_DURATIONS: "Loop Durations",
    LOOP_DURATIONS_DESCR:
        "Loop Durations measure how long it takes your consumers to return assets. If the 7 day Loop Duration is 60%, you can expect 60% of your assets to be returned within 7 days. It's calculated across all locations and refreshed nightly.",
    ASSET_UTILIZATION: "Asset Utilization",
    ASSET_UTILIZATION_DESCR:
        "Asset Utilization gives you insight into how utilized your asset inventory is. It's calculated across all locations and refreshed nightly.",
    INVENTORY: "On Hand Inventory",
    INVENTORY_DESCR:
        "On Hand Inventory is the total number of assets at a particular location. Any time an event is associate with an asset, we update the last known location to keep your On Hand inventory level up to date.",
};

export const ACTIVE_UTILIZATION_CARDS = {
    ACTIVE_ASSETS: "ACTIVE ASSETS",
    ACTIVE_ASSETS_DESCR: "Assets that have been used in the last 60 days.",
    RETIRED_ASSETS: "RETIRED ASSETS",
    RETIRED_ASSETS_DESCR:
        "Total removed from circulation due to damage or wear.",
    LOST_ASSETS: "INACTIVE ASSETS",
    LOST_ASSETS_DESCR: "Assets that have not been used in the last 60 days.",
    NO_RATE: "N/A",
};

const ASSET_TYPE_FORM = {
    CANCEL_LABEL: "Cancel",
    CANCEL_TITLE:
        "Are you sure you want to leave without completing your changes?",
    CANCEL_SUBTITLE: "Your changes will not be saved",
    CREATE_METADATA_TITLE: "Meta Data",
    CREATE_METADATA_INSTR:
        "Both a key and value are required for each meta data element.",
    ADD_META_DATA: "Add meta data",
    PLACEHOLDER_NAME: "“Large container”",
    PLACEHOLDER_DESCR: "“8 ounces”",
    PLACEHOLDER_EXTID: "“123456”",
    PLACEHOLDER_VALUE: "“$0.00”",
    LABEL_NAME: "Name",
    LABEL_DESCR: "Size/Description (optional)",
    LABEL_EXTID: "External ID (optional)",
    LABEL_VALUE: "Charge Amount (optional)",
    MAX_LENGTH_NAME: 50,
    NUMBER_CHARACTERS_NAME: "characters remaining",
    ERROR_TOAST_MESSAGE_INCOMPLETE:
        "Please complete all of the required fields.",
};

export const CREATE_ASSET_TYPE = {
    ...ASSET_TYPE_FORM,
    LOCATION_DROPDOWN_TITLE: "Select location",
    SAVE_LABEL: "Save asset type",
    CREATE_ASSET_TYPE: "New Asset Type",
    CREATE_NUMBER_ASSETS_HINT: "“1000”",
    CREATE_ASSET_TYPE_INSTR:
        "Describe the asset type using a name and size that your customers and team will recognize.",
    ADD_INVENTORY: "Add inventory to another location",
    CREATE_INVENTORY_TITLE: "Enter starting inventory",
    CREATE_INVENTORY_INSTR:
        "Enter the amount of assets of this type that you plan to order and allocate across each allocation. Completing this step will give you a more accurate representation of on-hand inventory.",
    LABEL_LOCATION: "Starting location",
    LABEL_NUMBER_ASSETS: "Number of assets",
    ERROR_MISSING_ASSET_INVENTORY:
        "Please enter the number of assets to continue.",
    ERROR_MISSING_ASSET_INVENTORY_LOCATION:
        "Please select a location to continue.",
    INV_ADJUST_REASON: "Initialized amount.",
    SUCCESS_MESSAGE: "Asset type saved",
};

export const EDIT_ASSET_TYPE = {
    ...ASSET_TYPE_FORM,
    SAVE_LABEL: "Done",
    EDIT_ASSET_TYPE: "Edit asset details",
    SUCCESS_MESSAGE: "Changes saved",
};

export const METADATA_FORM = {
    DELETE_METADATA: "Delete",
    ERROR_MISSING_KEY: "Please enter a key to continue.",
    ERROR_MISSING_VALUE: "Please enter a value to continue.",
    LABEL_METADATA_KEY: "Key",
    LABEL_METADATA_VALUE: "Value",
    PLACEHOLDER_METADATA_KEY: "“Example string”",
    PLACEHOLDER_METADATA_VALUE: "“123”",
};

export const RETURN_RATE_CARDS = {
    HEADER: "RECENT",
    LAST_UPDATED: (date: string) =>
        date
            ? `Last updated ${format(parseISO(date), "MM/dd/yy hh:mm:ss a")}`
            : "",
    ALERT: "Heads up! ",
    WARNING_DETAILS:
        "All rentals that expired or were canceled prior to March 15, 2024, the date that the “Return received” feature was released, have an unknown return status.",
    STATUS_UNKNOWN: "Return status unknown",
    NOT_YET_RECEIVED: "Not yet received",
    RETURN_RECEIVED: "Return received",
};
