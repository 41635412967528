import React from "react";
/*
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 * This module is deprecated, do not use this component, add to
 * the functionality, or fix any bugs with this. In all of these
 * cases you should work in the NewLine component.
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 *             WARNING
 */

export default function Line({ width = "100%" }) {
    return (
        <hr
            style={{
                position: "relative",
                top: -18,
                zIndex: 0,
                border: "none",
                borderTop: "2px solid #D6D8DC",
                cursor: "pointer",
                margin: "1rem 0",
                width: `${width}`,
            }}
        />
    );
}
