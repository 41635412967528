//style
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import Displayable from "../../common/Displayable";

//enums
import { RETURN_RATE_CARDS } from "../../../constants/assets";

//react
import { RefObject } from "react";

const Container = styled.div`
    position: relative;
    display: flex;
    padding-top: ${({ theme }) => theme.spacing.small}px;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
    z-index: 1000;
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    border-radius: 5px;
    border: ${({ theme }) => theme.borderStyle.smallDivider};
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: ${({ theme }) => theme.shadowStyle.containerDropShadow};
`;

const AlertDiv = styled.div`
    position: absolute;
    top: ${({ theme }) => -theme.spacing.xmlarge}px;
    left: 164px;
    white-space: normal;
`;

const StyledText = styled(Text)`
    width: 210px;
`;

type Props = {
    showAlert: boolean;
    popUpRef: RefObject;
};

export default function AssetReceivedAlert({ showAlert, popUpRef }: Props) {
    const { colors } = useTheme();

    return (
        <Displayable displayPrimaryComponent={showAlert}>
            <div ref={popUpRef}>
                <AlertDiv>
                    <Container>
                        <StyledText
                            type="body"
                            size="small"
                            color={colors.DEEP_BLUE_SEA}
                        >
                            <b>{RETURN_RATE_CARDS.ALERT}</b>
                            {RETURN_RATE_CARDS.WARNING_DETAILS}
                        </StyledText>
                    </Container>
                </AlertDiv>
            </div>
        </Displayable>
    );
}
