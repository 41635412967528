//react
import React, { useState, useEffect, useRef } from "react";

//apis
import * as queries from "../../graphql/queries";
import useGraphClient from "../../hooks/useGraphClient";

//style
import styled, { useTheme } from "styled-components/macro";
import { customStyles } from "../common/TableStyles";

//utils
import {
    prettifyUID,
    prettifyPhoneNumber,
    prettifyDate,
    prettifyTime,
} from "../../utils/helpers";
import { truncateConsumer } from "../../utils/consumers/truncateConsumer";

//components
import ScreenView from "../common/ScreenView";
import Text from "../common/Text";
import DataTable from "react-data-table-component";
import Button from "../common/Button";
import ClipLoader from "react-spinners/ClipLoader";
import OverlayTakeover from "../overlay/OverlayTakeover";
import LinkText from "../common/LinkText";

//constants
import { CONSUMER_LIST } from "../../constants/consumers";

const Container = styled(ScreenView)``;

const Footer = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.yuge}px;
`;

const LoadMore = styled(Button)`
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.WAVE_STORM};
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    margin-top: ${({ theme }) => theme.spacing.xlarge}px;
    align-self: center;
`;

const NoDataText = styled(Text)``;

const CellText = styled(Text)``;

const TimeText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

type Props = {
    fromTimestamp?: string;
    toTimestamp?: string;
    consumerId?: string;
};

export default function ConsumerList({
    fromTimestamp,
    toTimestamp,
    ...props
}: Props) {
    const [dataLoading, setDataLoading] = useState(true);
    const [isInitiated, setIsInitiated] = useState(false);
    const [data, setData] = useState([]);
    const [paginationToken, setPaginationToken] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [panelWidth, setPanelWidth] = useState(0);
    const [consumerId, setConsumerId] = useState({});
    const { colors, isDesktop, isTablet, isMobile, spacing, breakpoints } =
        useTheme();

    const graphClient = useGraphClient();

    useEffect(() => {
        loadData(null);
    }, []);

    useEffect(() => {
        if (isOpen) {
            setPanelWidth("516px");
        } else {
            setPanelWidth(0);
        }
    }, [isOpen]);

    const loadData = async (thisToken: string) => {
        setDataLoading(true);
        try {
            const results = await graphClient.graphql({
                query: queries.listConsumers,
                variables: {
                    paginationToken: thisToken,
                },
            });
            const consumers = results.data.listConsumers.results;
            const token = results.data.listConsumers.nextToken;
            setData([...data, ...consumers]);
            setPaginationToken(token);
            setDataLoading(false);
            setIsInitiated(true);
        } catch (error) {
            console.error("Error in listing consumers in consumers tab", error);
            setDataLoading(false);
            setIsInitiated(true);
        }
    };

    const closeConsumerOverlay = () => {
        setIsOpen(false);
    };

    const consumerSideBar = (row) => {
        setIsOpen(true);
        setConsumerId(row.consumerId);
    };

    const noDataText = () => {
        return "There are no consumers of record.";
    };

    const NoDataComponent = (
        <div style={{ padding: 18 }}>
            <NoDataText type="body" size="medium" color={colors.DEEP_BLUE_SEA}>
                {noDataText()}
            </NoDataText>
        </div>
    );

    const columns = [
        {
            name: CONSUMER_LIST.NAME,
            width: "20%",
            cell: (row, index, column, id) => (
                <LinkText
                    type={"new_link"}
                    size={"small"}
                    onClick={() => consumerSideBar(row)}
                >
                    {row.name
                        ? truncateConsumer(row.name, !isDesktop, 18)
                        : "Unnamed Consumer"}
                </LinkText>
            ),
        },
        {
            name: CONSUMER_LIST.PHONE_NUMBER,
            width: isDesktop ? "20%" : "17%",
            grow: isDesktop ? 0.8 : 0.5,
            selector: (row) => (
                <CellText type="label" size="small" color={colors.EXHAUST}>
                    {row.phoneNumber
                        ? prettifyPhoneNumber(row.phoneNumber)
                        : "—"}
                </CellText>
            ),
        },
        {
            name: CONSUMER_LIST.EMAIL_ADDRESS,
            width: isDesktop ? "20%" : "18%",
            selector: (row) => (
                <CellText type="label" size="small" color={colors.EXHAUST}>
                    {row.email ? row.email : "—"}
                </CellText>
            ),
        },
        {
            name: CONSUMER_LIST.CONSUMER_ID,
            width: isDesktop ? "16%" : "18%",
            selector: (row) => (
                <CellText type="label" size="small" color={colors.EXHAUST}>
                    {row.consumerId ? prettifyUID(row.consumerId) : "—"}
                </CellText>
            ),
        },
        {
            name: CONSUMER_LIST.CREATED_AT,
            width: "20%",
            selector: (row) => (
                <div>
                    <CellText type="body" size="tiny" color={colors.EXHAUST}>
                        {row.createdAt ? prettifyDate(row.createdAt) : "—"}
                    </CellText>
                    <TimeText type="body" size="tiny" color={colors.EXHAUST}>
                        {row.createdAt ? prettifyTime(row.createdAt) : "—"}
                    </TimeText>
                </div>
            ),
        },
    ];

    return (
        <Container
            screenName="CONSUMERS"
            counter={paginationToken ? `${data.length}+` : `${data.length}`}
            style={props.style}
        >
            <OverlayTakeover
                closeConsumerOverlay={closeConsumerOverlay}
                isOpen={isOpen}
                consumerId={consumerId}
                width={panelWidth}
            ></OverlayTakeover>
            <DataTable
                noDataComponent={NoDataComponent}
                columns={columns}
                keyField="consumerId"
                data={data}
                progressPending={dataLoading && !isInitiated}
                progressComponent={<ClipLoader loading />}
                customStyles={customStyles}
            />

            <Footer>
                {paginationToken ? (
                    <LoadMore
                        label={
                            dataLoading ? (
                                <ClipLoader
                                    color={colors.BLANK_SLATE}
                                    size={10}
                                    loading
                                />
                            ) : (
                                CONSUMER_LIST.LOAD_MORE
                            )
                        }
                        onClick={() => loadData(paginationToken)}
                    />
                ) : null}
            </Footer>
        </Container>
    );
}
