//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";

const AssetStatDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    border-bottom: ${({ theme }) => theme.borderStyle.smallDivider};
`;

const PercentageStats = styled.div`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

const TotalAssets = styled.div`
    padding-top: ${({ theme }) => theme.spacing.large}px;
    flex: 1 0 0;
    margin-bottom: ${({ theme }) => theme.spacing.large}px;
`;

export default function LostRetiredStats({
    totalHeader,
    percentageDetails = "",
    assetTypeData,
}: Props) {
    const { colors } = useTheme();

    return (
        <AssetStatDiv>
            <TotalAssets>
                <Text color={colors.DEEP_BLUE_SEA} size="small" type="label">
                    {totalHeader}
                </Text>
                <Text color={colors.EXHAUST} size="small" type="label">
                    {percentageDetails}
                </Text>
            </TotalAssets>
            <PercentageStats>
                <Text color={colors.DEEP_BLUE_SEA} size="small" type="header">
                    {assetTypeData?.bucketCount}
                </Text>
            </PercentageStats>
        </AssetStatDiv>
    );
}
