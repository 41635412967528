import { DefaultTheme } from "styled-components";
import { COLORS } from "./colors";
import { SHADOW } from "./shadow";
import { SPACING } from "./spacing";
import { TEXT_STYLES } from "./texts";
import { BORDER } from "./border";

const theme: DefaultTheme = {
    breakpoints: {
        small: 0,
        medium: 414,
        mobile: { maxWidth: 767 },
        large: 768,
        xlarge: 1024,
        xxlarge: 1440,
    },
    colors: COLORS,
    context: {},
    desktopBreakpoint: "large",
    shadowStyle: SHADOW,
    spacing: SPACING,
    textStyles: TEXT_STYLES,
    borderStyle: BORDER,
};

export { theme };
