import React, { useState, useEffect } from "react";
import styled, {
    useTheme,
    findTextStyles,
} from "../../../styling/styled-components";
import { keyframes } from "styled-components";

export default function ShimmerCard({}) {
    const fadeIn = keyframes`
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1.0;
    }
    100% {
      opacity: 0.6;
    }
  `;
    const SkeletonContainer = styled.div`
        display: flex;
        align-items: center;
        animation: ${fadeIn} 1s ease-in-out infinite;
        background-color: ${({ theme }) => theme.colors.SMOG};
        height: 105px;
        width: 440px;
        border-radius: 4px;
        margin-bottom: ${({ theme }) => theme.spacing.xsmall}px;
    `;

    return <SkeletonContainer></SkeletonContainer>;
}
