//style
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Card from "../../common/card/Card";
import CardHeader from "./common/CardHeader";
import CardDetails from "./common/CardDetails";

//constants
import { FAILED_CARD } from "../../../constants/rentalOverlay/rentalCards";

type Props = {
    triggerAt: string;
};

const FailedWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.medium}px;
`;

export default function FailedCard({ triggerAt }: Props) {
    const { colors } = useTheme();

    return (
        <Card backgroundColor={colors.RED_4} borderColor={""}>
            <CardHeader
                header={FAILED_CARD.HEADER}
                date={triggerAt}
            ></CardHeader>
            <FailedWrapper>
                <CardDetails asset={FAILED_CARD.CARD_DETAILS}></CardDetails>
            </FailedWrapper>
        </Card>
    );
}
