// style
import styled from "../../../styling/styled-components";

// assets
import { ReactComponent as WarningSolid } from "../../../assets/vectors/WarningSolid.svg";
import { ReactComponent as WarningClear } from "../../../assets/vectors/WarningClear.svg";

//components
import Displayable from "../../common/Displayable";

//Use absolute positioning due to limitations of the react-data-table-component
const Container = styled.div`
    position: absolute;
    left: 130px;
    bottom: 72px;
`;

type Props = {
    displayReturnReceivedAlert: () => void;
    showDefaultAlert: boolean;
};

export default function AssetReceivedWarning({
    displayReturnReceivedAlert,
    showDefaultAlert,
}: Props) {
    const WarningAlertOpen = () => {
        return <WarningSolid />;
    };

    return (
        <Container>
            <Displayable
                displayPrimaryComponent={!showDefaultAlert}
                SecondaryComponent={WarningAlertOpen}
            >
                <WarningClear
                    onMouseEnter={() => displayReturnReceivedAlert()}
                    onClick={() => displayReturnReceivedAlert()}
                />
            </Displayable>
        </Container>
    );
}
