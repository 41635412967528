//react
import { useEffect, useState, useRef } from "react";

//components
import Text from "../../common/Text";
import Button from "../../common/Button";
import Tag from "../../common/Tag";

//style
import styled, { useTheme } from "../../../styling/styled-components";

//images
import { ReactComponent as Info } from "../../../assets/vectors/Info.svg";
import { ReactComponent as InfoFill } from "../../../assets/vectors/InfoFill.svg";

//utils
import { addExtraEmptyMetaData } from "../../../utils/locations/addExtraMetaData";
import { checkMetaDataLength } from "../../../utils/locations/checkMetaDataLength";
import useClickOutside from "../../../utils/useClickOutside";

const TagHeader = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    align-self: stretch;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const AddTagButton = styled(Button)`
    display: flex;
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 0;
    height: 44px;
    border: 1px solid ${(props) => props.theme.colors.BLANK_SLATE};
`;

const InfoHover = styled.div`
    position: absolute;
    display: flex;
    z-index: 10;
    width: 180px;
    top: ${({ theme }) => theme.spacing.tiny}%;
    left: ${({ theme }) => theme.spacing.large}%;
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.xmlarge}px
        ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    border-radius: 5px;
    border: ${({ theme }) => `1 solid ${theme.colors.MARINE_LAYER}`};
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: ${({ theme }) => theme.shadowStyle.containerDropShadow};
`;

const AddTagDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    position: relative;
    width: ${({ theme }) => theme.spacing.huge}px;
    top: ${({ theme }) => -theme.spacing.huge}px;
    left: ${({ theme }) => theme.spacing.large}px;
`;

export default function LocationTag({
    newMetaData,
    setNewMetaData,
    shouldShowMetadataErrors,
    setShowTags,
}) {
    const { colors, spacing } = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const [tagClicked, setTagClicked] = useState(false);
    const [isButtonHovered, setIsButtonHovered] = useState(false);
    const [count, setCount] = useState(0);
    const [isMetadataSectionOpen, setIsMetadataSectionOpen] =
        useState<boolean>(false);
    const tagTipRef = useRef(null);
    const [renderLocationTag, setRenderLocationTag] = useState(false);
    const emptyMetadata = {
        key: "",
        value: "",
        isNew: true,
    };
    let checkNewMetaDataKeyValue =
        !newMetaData[0]?.key || !newMetaData[0]?.value;
    const addTagTextColor = checkNewMetaDataKeyValue
        ? colors.ASPHALT
        : colors.WAVE_STORM;

    useEffect(() => {
        checkMetaDataLength(newMetaData, setCount);
        if (newMetaData.length == 0) {
            setNewMetaData([emptyMetadata]);
            setRenderLocationTag(true);
        } else {
            setRenderLocationTag(true);
        }
    }, [newMetaData]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key == "Escape") {
                setIsHovered(false);
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const toggleHover = () => {
        if (tagClicked) {
            setIsHovered(true);
        } else {
            setIsHovered(!isHovered);
        }
    };

    const toggleButtonHover = () => {
        setIsButtonHovered(!isButtonHovered);
    };

    const openPopUp = () => {
        setIsHovered(true);
        setTagClicked(true);
    };

    const toggleMetadataSection = () => {
        setIsMetadataSectionOpen(
            (isMetadataSectionOpen) => !isMetadataSectionOpen
        );
    };

    useClickOutside(tagTipRef, () => {
        setIsHovered(false);
        setTagClicked(false);
    });

    return (
        <>
            {renderLocationTag && (
                <>
                    <TagHeader>
                        <Text type="label" size="small" color={colors.ASH}>
                            Tags ({count})
                        </Text>
                        {!isHovered ? (
                            <Info
                                onMouseEnter={toggleHover}
                                onClick={openPopUp}
                            ></Info>
                        ) : (
                            <>
                                <InfoFill
                                    onClick={openPopUp}
                                    ref={tagTipRef}
                                ></InfoFill>
                                <InfoHover
                                    onClick={openPopUp}
                                    onMouseLeave={toggleHover}
                                >
                                    <Text
                                        type="body"
                                        size="small"
                                        color={colors.DEEP_BLUE_SEA}
                                    >
                                        Tags are an optional way to help you
                                        organize and manage locations. For
                                        example, you might want to have a record
                                        of the Manager of each location or track
                                        whether or not a location offers mobile
                                        ordering.
                                    </Text>
                                </InfoHover>
                            </>
                        )}
                    </TagHeader>
                    <div
                        style={{
                            position: "relative",
                            paddingLeft: spacing.small,
                        }}
                    >
                        <Tag
                            callbackUpdateNewMetadata={(updatedMetadata) => {
                                setNewMetaData(updatedMetadata);
                            }}
                            newMetaData={newMetaData}
                            shouldShowMetadataErrors={shouldShowMetadataErrors}
                            toggleMetadataSection={toggleMetadataSection}
                            setShowTags={setShowTags}
                        ></Tag>
                        <AddTagDiv
                            onMouseEnter={toggleButtonHover}
                            onMouseLeave={toggleButtonHover}
                        >
                            <AddTagButton
                                type="secondary"
                                onClick={() =>
                                    checkNewMetaDataKeyValue
                                        ? ""
                                        : addExtraEmptyMetaData(
                                              newMetaData,
                                              setNewMetaData
                                          )
                                }
                                style={{ border: "0px" }}
                                label={
                                    <Text
                                        type="label"
                                        size="medium"
                                        color={addTagTextColor}
                                    >
                                        {"+ Add tag (optional)"}
                                    </Text>
                                }
                            ></AddTagButton>
                        </AddTagDiv>
                    </div>
                </>
            )}
        </>
    );
}
