import React from "react";
import { AssetType } from "../API";

export type AssetSelections = {
    assets: Array<AssetType>;
    isInitialized: boolean;
};

export const emptyAssetSelections: AssetSelections = {
    assets: [],
    isInitialized: false,
};

export default React.createContext({
    selections: emptyAssetSelections,
    updateSelections: () => {},
});
