import { RentalStatus } from "../../API";
import { COLORS } from "../../styling/colors";

export const findRentalStatusColor = (status) => {
    let color = "";

    switch (status) {
        case RentalStatus.Completed:
            color = COLORS.GREEN_2;
            break;
        case RentalStatus.Expired:
            color = COLORS.RED_3;
            break;
        case RentalStatus.Failed:
            color = COLORS.RED_3;
            break;
        case RentalStatus.InProgress:
            color = COLORS.WAVE_STORM;
            break;
        case RentalStatus.Forgiven:
            color = COLORS.YELLOW_1;
            break;
        default:
            color = COLORS.ASH;
            break;
    }

    return color;
};
