import React from "react";
import DeprecatedText from "../common/DeprecatedText";
import styled from "styled-components/macro";
import { ResolutionStatus } from "../../API";
import { findTextStyles } from "../../styling/styled-components";
import { RESOLUTION_STATUS_BADGE } from "../../constants/loops";

const RedContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.RED_2};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
`;

const GreenContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.GREEN_2};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
`;

const PendingContainer = styled.div`
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.MARINE_LAYER};
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
`;

const FiredText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

const PendingText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.MARINE_LAYER};
`;

type Props = {
    status: ResolutionStatus;
};

export default function Component({ status }: Props) {
    if (status == ResolutionStatus.Pending) {
        return (
            <PendingContainer>
                <PendingText type="label" size="small">
                    {RESOLUTION_STATUS_BADGE.PENDING}
                </PendingText>
            </PendingContainer>
        );
    }
    if (status == ResolutionStatus.Succeeded) {
        return (
            <GreenContainer>
                <FiredText type="label" size="small">
                    {RESOLUTION_STATUS_BADGE.SUCCEEDED}
                </FiredText>
            </GreenContainer>
        );
    }
    if (status == ResolutionStatus.Failed) {
        return (
            <RedContainer>
                <FiredText type="label" size="small">
                    {RESOLUTION_STATUS_BADGE.FAILED}
                </FiredText>
            </RedContainer>
        );
    }
}
