export function randomToken() {
    return (
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
    );
}

export function frequencyMapper(details) {
    const detailsMap = { "-1": {}, "1": {}, "2": {}, "3": {}, "4": {} };
    details.map((detail, detailIdx) => {
        detailsMap[detail.frequencyWeeks][detail.distributionCenterProductId] =
            detail;
    });

    return detailsMap;
}

export function displayDollars(cents) {
    return `$${(cents / 100).toFixed(2)}`;
}

export function convertCents(dollars) {
    return Number(dollars.replace("$", "").replace(".", ""));
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function prettifyUID(uid: string): string {
    return uid && uid.substring(0, 8);
}

export function prettifyNumber(num: number): string {
    return num.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

export function validateEmail(email): boolean {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export function prettifyPhoneNumber(str: string): string | null {
    //Filter only numbers from the input
    const cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        const intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
            ""
        );
    }

    return null;
}

export function localizeISOStringDate(date: Date): string {
    // sv-SE returns yyyy-mm-dd format for ISO compatability
    // https://github.com/nodejs/node/issues/45945
    return date.toLocaleDateString("sv-SE");
}

export function prettifyDate(date: Date): string {
    const isDate = new Date(date);
    const formatDate = isDate.toLocaleDateString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
    });
    return formatDate;
}

export function prettifyTime(date: Date): string {
    const isDate = new Date(date);
    const hours = isDate.getHours();
    const minutes = isDate.getMinutes();
    const period = hours >= 12 ? "pm" : "am";

    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${period}`;
}

export function convertDate(date: Date): string {
    const dateString = new Date(date);
    const changeFormat = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
    };

    const formatDate = dateString.toLocaleDateString("en-US", changeFormat);
    return formatDate;
}

export function humanizeLower(str) {
    return str.split("_").reduce((result, word, index) => {
        if (index == 0) {
            return result + word[0].toUpperCase() + word.slice(1).toLowerCase();
        } else {
            return result + " " + word.toLowerCase();
        }
    }, "");
}

export function isEmpty(obj) {
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
}

export function getFullFormattedTime(string) {
    let initiated = new Date(string);
    let initiatedTime = initiated.toLocaleString("en-us", {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
    });
    return initiatedTime;
}

export function getShortFormattedTime(string) {
    let initiated = new Date(string);
    let initiatedTime = initiated.toLocaleString("en-us", {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    });
    return initiatedTime;
}

export function truncateString(str, maxLength: integer) {
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.substring(0, maxLength) + "...";
    }
}

export function graphErrorDetails(
    errors: any[] | void,
    errorTypeMatch: string | void
): [string | void, string | void] {
    let matchedError = {};

    errors = errors || [];

    if (errors.length == 0) {
        return [null, null];
    } else {
        if (errorTypeMatch) {
            matchedError = errors.find(
                (error) => error.errorType == errorTypeMatch
            );
        } else {
            matchedError = errors[0];
            if (matchedError.message == "Network Error") {
                return ["NetworkError", "Network Error"];
            }
        }
        return [matchedError?.errorType, matchedError?.errorMessage];
    }
}
