export const lineAndPointRadiusStyles = (hideFirstPoint) => {
    return {
        line: {
            tension: 0.4,
        },
        point: {
            radius: (tooltipItem) => {
                if (tooltipItem.dataIndex == 0 && hideFirstPoint) {
                    return 0;
                } else {
                    return 5;
                }
            },
        },
    };
};
