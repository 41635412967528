import React from "react";
import DeprecatedText from "../common/DeprecatedText";
import styled from "styled-components/macro";
import { format } from "date-fns";
import { GreenTick, GreyTick, RedTick } from "../../assets/vectors";
import { findTextStyles } from "../../styling/styled-components";

const Container = styled.div`
    padding: ${({ theme }) => theme.spacing.large}px 0;
`;

const TickWrapper = styled.div`
    z-index: 1;
`;

const AboveFold = styled.div`
    padding-left: 48px;
    margin-bottom: -12px;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    align-items: flex-end;
`;

const FoldWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Fold = styled.div`
    background-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    height: 1px;
    flex: 1;
    margin: ${({ theme }) => theme.spacing.xsmall}px 0;
    margin-left: -6px;
`;

const BelowFold = styled.div`
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    margin-top: -12px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
`;

const LeftItem = styled.div`
    flex: 1;
`;

const CenterItem = styled.div`
    flex: 1;
`;

const RightItem = styled.div`
    flex: 1;
    display: flex;
    justify-content: end;
`;

const TimestampText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

export type TimelineEventProps = {
    timestamp: Date;
    title: React.ReactNode;
    body: React.ReactNode;
    badge: React.ReactNode;
    tickType: string;
};

export default function Component({
    timestamp,
    title,
    body,
    badge,
    tickType,
}: TimelineEventProps) {
    const tick = {
        green: <img src={GreenTick} />,
        grey: <img src={GreyTick} />,
        red: <img src={RedTick} />,
        default: <img src={GreyTick} />,
    }[tickType];

    return (
        <Container>
            <AboveFold>
                <LeftItem>
                    <TimestampText type="label" size="small">
                        {format(timestamp, "MM/dd/yy hh:mm:ss a")}
                    </TimestampText>
                </LeftItem>
                <CenterItem>{title}</CenterItem>
                <RightItem>{badge}</RightItem>
            </AboveFold>
            <FoldWrapper>
                <TickWrapper>{tick}</TickWrapper>
                <Fold />
            </FoldWrapper>
            <BelowFold>
                <LeftItem />
                <CenterItem>{body}</CenterItem>
                <RightItem />
            </BelowFold>
        </Container>
    );
}
