import React, { useEffect, useState, useRef, useContext } from "react";
import GlobalContext from "../../contexts/GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";

// style
import styled from "../../styling/styled-components";

//component imports
import ScreenView from "../common/ScreenView";
import SecondaryNav from "../common/SecondaryNav";
import Locations from "../locations/Locations";
import ScanAppAccounts from "../scanapp/ScanAppAccounts";
import { SCAN_APP_ACCOUNTS } from "../../constants/scanapp";
import ForgivenessPolicy from "./forgivenessPolicy/ForgivenessPolicy";

//api
import useGraphClient from "../../hooks/useGraphClient";
import * as queries from "../../graphql/queries";

const Container = styled(ScreenView)``;

const LOCATIONS_TEXT = {
    DESCRIPTION:
        "Parent locations are physical places, such as a dining hall or student center. Scanning locations are specific areas within a parent location where assets will be scanned, such as cash registers or dishwashers.",
};

export default function ProgramSettings({}) {
    //program setting options
    const [selectedTab, setSelectedTab] = useState(0);
    const [tabDescription, setTabDescription] = useState(
        LOCATIONS_TEXT.DESCRIPTION
    );

    //scanapp data
    const [isLimited, setIsLimited] = useState(false);
    const [totalAccountsCount, setTotalAccountsCount] = useState<number>(0);
    const { scanAppUsers, scanAppUserLimit } =
        useContext(GlobalContext).globalSelections;
    const { deletedUserId, createdUserId } = {};

    //navigation constants
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;

    //hooks
    const graphClient = useGraphClient();

    const tabOptions = ["Locations", "ScanApp accounts", "Rentals"];

    //navigate to url,render correct data
    useEffect(() => {
        if (pathname) {
            if (pathname.includes("locations")) {
                setSelectedTab(0);
                selectTabIndex(0);
            }
            if (pathname.includes("scan-app")) {
                setSelectedTab(1);
                selectTabIndex(1);
            }
            if (pathname.includes("rentals")) {
                setSelectedTab(2);
                selectTabIndex(2);
            }
        }
    }, [pathname]);

    //call scanapp data to display correct description
    useEffect(() => {
        getTotalAccounts();
    }, [deletedUserId, createdUserId]);

    useEffect(() => {
        if (scanAppUserLimit == -1) {
            setIsLimited(false);
        } else {
            setIsLimited(true);
        }
    }, [scanAppUsers, scanAppUserLimit, totalAccountsCount]);

    //when tab selected, set correct description and navigate to url
    const selectTabIndex = (index) => {
        setSelectedTab(index);
        if (index == 0) {
            navigate("locations");
            setTabDescription(LOCATIONS_TEXT.DESCRIPTION);
        }
        if (index == 1) {
            if (isLimited) {
                setTabDescription(
                    SCAN_APP_ACCOUNTS.ACTIVE_ACCOUNTS_SUBHEADER(
                        scanAppUserLimit - totalAccountsCount
                    )
                );
            } else {
                setTabDescription(SCAN_APP_ACCOUNTS.UNLIMITED_ACCOUNTS_HEADER);
            }
            return navigate("scan-app");
        }
        if (index == 2) {
            setTabDescription("");
            return navigate("rentals");
        }
    };

    //render selected component
    const renderSelectedTab = (index) => {
        if (index == 0) {
            return <Locations></Locations>;
        }
        if (index == 1) {
            return <ScanAppAccounts></ScanAppAccounts>;
        }
        if (index == 2) {
            return <ForgivenessPolicy />;
        }
    };

    //retrieve total accounts for org
    const getTotalAccounts = async (): null => {
        try {
            const results = await graphClient.graphql({
                query: queries.getOrganization,
            });
            setTotalAccountsCount(results.data.getOrganization.scanAppUsers);
        } catch (err) {
            console.error("Error getting organization scanAppUsers", err);
        }
    };

    return (
        <Container screenName="PROGRAM_SETTINGS" shouldShowCounter={false}>
            <SecondaryNav
                onClick={selectTabIndex}
                tabOptions={tabOptions}
                description={tabDescription}
                selectedTab={selectedTab}
            ></SecondaryNav>
            {renderSelectedTab(selectedTab)}
        </Container>
    );
}
