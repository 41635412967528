//style
import styled from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import RentalStatusIcon from "../../overlay/rental/common/RentalStatusIcon";

//utils
import { adjustStatusLabels } from "../../../utils/activity/adjustStatusLabels";
import { findRentalStatusColor } from "../../../utils/rentalOverlay/findRentalStatusColor";

const StatusWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const StatusText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;
type Props = {
    status: string;
};

export default function StatusLabel({ status }: Props) {
    return (
        <StatusWrapper>
            <RentalStatusIcon status={status} />
            <StatusText
                color={findRentalStatusColor(status)}
                type="label"
                size="small"
            >
                {adjustStatusLabels(status)}
            </StatusText>
        </StatusWrapper>
    );
}
