import styled from "../../../styling/styled-components";

const RightHalfTextSideDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: auto;
`;

export function RightHalfCard({ children }) {
    return <RightHalfTextSideDiv>{children}</RightHalfTextSideDiv>;
}
