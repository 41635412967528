//components
import Footer from "./common/Footer";
import AssetContainer from "./assetusage/AssetContainer";
import ChartHeader from "./common/ChartHeader";
import LearnMore from "./assetusage/LearnMore";
import LostRetiredStats from "./assetusage/LostRetiredStats";
import ErrorBlock from "./common/ErrorBlock";
import Errorable from "../common/Errorable";

//constants
import { LOST_ASSETS_SUMMARY } from "../../constants/dashboard";
import { AssetDetails } from "../../constants/dashboard";

//utils
import { checkLastUpdatedDate } from "../../utils/chart/checkLastUpdatedDate";

type Props = {
    lostAssetDetails: AssetDetails;
    lastUpdated: string;
    error: string;
};

export default function LostAssetsSummary({
    lostAssetDetails,
    lastUpdated,
    error,
}: Props) {
    const ErrorBlockStyled = () => {
        return <ErrorBlock height={120} />;
    };

    return (
        <AssetContainer>
            <div>
                <ChartHeader
                    header={lostAssetDetails?.headerLabel}
                    subheader={LOST_ASSETS_SUMMARY.SUBHEADER}
                />
                <LearnMore
                    link={
                        "https://www.topanga.io/post/understanding-inventory-usage-of-reusable-containers"
                    }
                />
            </div>
            <Errorable error={error} ErrorComponent={ErrorBlockStyled}>
                <LostRetiredStats
                    totalHeader={LOST_ASSETS_SUMMARY.TOTAL}
                    percentageDetails={lostAssetDetails?.percentageDetails}
                    assetTypeData={lostAssetDetails?.assetTypeData}
                />
            </Errorable>
            <Footer lastUpdated={checkLastUpdatedDate(lastUpdated)} />
        </AssetContainer>
    );
}
