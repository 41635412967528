//style
import styled, { useTheme } from "../../styling/styled-components";

//components
import Text from "../common/Text";
import Displayable from "../common/Displayable";
import { Tooltip } from "../common/Tooltip";

//api
import useGraphClient from "../../hooks/useGraphClient";
import * as queries from "../../graphql/queries";

//react
import { useEffect, useState } from "react";

//constants
import { RENTAL_VISUAL } from "../../constants/rentalVisual/rentalVisualStrings";

type Props = { showTooltip: boolean };

export default function ForgivenPolicyTooltip({ showTooltip }: Props) {
    const { colors } = useTheme();
    const [organizationData, setOrganizationData] = useState({});

    const graphClient = useGraphClient();

    useEffect(() => {
        getOrganizationData();
    }, [showTooltip]);

    const getOrganizationData = async (): null => {
        try {
            const results = await graphClient.graphql({
                query: queries.getOrganization,
            });
            const data = results.data.getOrganization;
            setOrganizationData(data);
        } catch (err) {
            console.error(
                "Error getting organization in forgiveness tooltip",
                err
            );
        }
    };

    const displayForgivenessTypeText = () => {
        if (
            organizationData?.instantForgivenessEnabled &&
            organizationData?.returnReceivedForgivenessEnabled
        ) {
            return RENTAL_VISUAL.INSTANT_RECEIVED_ON;
        }
        if (
            organizationData?.instantForgivenessEnabled &&
            !organizationData?.returnReceivedForgivenessEnabled
        ) {
            return RENTAL_VISUAL.INSTANT_ON_RECEIVED_OFF;
        }
        if (
            !organizationData?.instantForgivenessEnabled &&
            organizationData?.returnReceivedForgivenessEnabled
        ) {
            return RENTAL_VISUAL.INSTANT_OFF_RECEIVED_ON;
        }
        if (
            !organizationData?.instantForgivenessEnabled &&
            !organizationData?.returnReceivedForgivenessEnabled
        ) {
            return RENTAL_VISUAL.INSTANT_OFF_RECEIVED_OFF;
        }
    };

    return (
        <Displayable displayPrimaryComponent={showTooltip}>
            <Tooltip>
                <Text size="small" type="body" color={colors.DEEP_BLUE_SEA}>
                    {displayForgivenessTypeText()}
                </Text>
            </Tooltip>
        </Displayable>
    );
}
