//style
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Card from "../../common/card/Card";
import CardHeader from "./common/CardHeader";
import CardDetails from "./common/CardDetails";
import LinkText from "../../common/LinkText";

//constants
import { FORGIVEN_CARD_STRINGS } from "../../../constants/rentalOverlay/rentalCards";

//enums
import { ForgivenessReason } from "../../../API";
import { ForgivenessDetails } from "../../../API";

//utils
import { handleLinkForgivenessPageNavigation } from "../../../utils/settings/handleLinkForgivenessPageNavigation";

type Props = {
    forgivenessDetails: ForgivenessDetails;
};

const LinkWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.small}px;
`;

export default function ForgivenCard({ forgivenessDetails }: Props) {
    const { colors } = useTheme();
    const description =
        forgivenessDetails.reason == ForgivenessReason.InstantForgiveness
            ? FORGIVEN_CARD_STRINGS.INSTANT_FORGIVENESS
            : FORGIVEN_CARD_STRINGS.RETURN_RECEIVED_FORGIVENESS;

    return (
        <Card backgroundColor={colors.YELLOW_4} borderColor={""}>
            <CardHeader
                header={FORGIVEN_CARD_STRINGS.HEADER}
                date={forgivenessDetails.timestamp}
            ></CardHeader>
            <div>
                <CardDetails asset={description}></CardDetails>
                <LinkWrapper>
                    <LinkText
                        size="small"
                        type="new_link"
                        onClick={handleLinkForgivenessPageNavigation}
                    >
                        {FORGIVEN_CARD_STRINGS.POLICY_LINK}
                    </LinkText>
                </LinkWrapper>
            </div>
        </Card>
    );
}
