//react
import React, { useEffect, useState, useContext } from "react";

//component
import SideLine from "../../common/SideLine";
import Text from "../../common/Text";
import Button from "../../common/Button";
import HighlightHover from "../../common/HighlightHover";
import SectionTitle from "../../common/overlay/SectionTitle";
import {
    BasicSubSectionSection,
    SubSectionGeneric,
} from "../../common/overlay/SubSection";
import ReturnReceivedDetails from "./ReturnReceivedDetails";
import Displayable from "../../common/Displayable";
import RentalStatusIcon from "./common/RentalStatusIcon";

//style
import styled, { useTheme } from "../../../styling/styled-components";

//images
import { ReactComponent as RentalProfile } from "../../../assets/vectors/RentalProfile.svg";
import { ReactComponent as RightArrow } from "../../../assets/vectors/RightArrow.svg";

//utils
import { humanizeLower } from "../../../utils/helpers";
import { displayLastPhaseLabel } from "../../../utils/activity/displayLastPhaseLabel";

//enums
import { RentalStatus, RentalPhase } from "../../../API";

//api
import { Consumer } from "../../../API";
import { generateClient } from "aws-amplify/api";
import * as queries from "../../../graphql/queries";
import { graphErrorDetails } from "../../../utils/helpers";

//context
import GlobalContext from "../../../contexts/GlobalContext";

const STRINGS = {
    CONSUMER_NAME: (consumer: Consumer) =>
        consumer?.name ? consumer.name : "Unnamed Consumer",
    CONSUMER_EMAIL: (consumer: Consumer) =>
        consumer?.email ? consumer.email : "-",
    CANCEL_BUTTON: "Cancel rental",
    CURRENT_PHASE: "Current phase",
    RENTAL_DETAILS_TITLE: "RENTAL DETAILS",
};

const OverviewBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const DetailsWrapper = styled.div`
    width: 100%;
`;

const CancelButtonWrapper = styled.div``;

const StatusWrapper = styled.div`
    position: relative;
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const DateAccountText = styled(Text)``;

const ProfileText = styled(Text)``;

const DateText = styled(Text)`
    white-space: nowrap;
`;

const ProfileBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 14px;
`;

const ProfileContainer = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 444px;
`;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const GrubHubFinedText = styled(Text)`
    align-self: stretch;
    width: 279px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
`;

const LinkTextStyle = styled(Text)`
    margin-left: auto;
`;

const StatusDetailsWrapper = styled.div`
    display: flex;
    width: 340px;
`;

const FilterButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.small}px;
    border: 1px solid
        ${(props) =>
            props.statusDisabled
                ? props.theme.colors.ASPHALT
                : props.theme.colors.ASH};
    color: ${(props) =>
        props.statusDisabled
            ? props.theme.colors.ASPHALT
            : props.theme.colors.ASH};
    ${(props) =>
        props.statusDisabled
            ? `border: 1px solid ${props.theme.colors.ASPHALT} !important`
            : ""};
    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.EXHAUST};
    }
    ${(props) =>
        props.statusDisabled ? `cursor: default` : `cursor: pointer`};
`;

const FilterOpen = styled(Text)`
    color: ${(props) =>
        props.statusDisabled
            ? props.theme.colors.ASH
            : props.theme.colors.ASPHALT};
    &:hover {
        color: ${(props) =>
            props.statusDisabled
                ? props.theme.colors.ASPHALT
                : props.theme.colors.ASPHALT};
    }
`;

export default function RentalDetails({
    rental,
    setConsumerId,
    consumer,
    setIsOpen,
    onFilterOpen,
    rentalConfig,
    setFromRentalDetails,
    openConsumerOverlay,
}) {
    const [statusDisabled, setStatusDisabled] = useState(false);
    const { colors } = useTheme();
    const context = useContext(GlobalContext);
    const [chargeDetails, setChargeDetails] = useState({});
    const { organizationName } = context.globalSelections;

    const graphClient = generateClient();

    useEffect(() => {
        if (rental) {
            if (rental.loopId !== chargeDetails?.loopId) {
                setChargeDetails({});
                getChargeOnLoop();
            }
        }
    }, [rental.rentalId, rental.loopId]);

    //TODO: Update string to release date TEC-1914
    const createdAtDate = new Date(rental.createdAt);
    const releaseDate = new Date("2024-08-07T00:00:00.000Z");

    const getChargeOnLoop = async () => {
        try {
            const results = await graphClient.graphql({
                query: queries.getChargeByLoop,
                variables: {
                    loopId: rental.loopId,
                },
            });
            if (results) {
                setChargeDetails(results.data.getChargeByLoop);
            } else {
                setChargeDetails({});
            }
        } catch (error) {
            const [getChargeErrorType, _getChargeErrorMessage] =
                graphErrorDetails(error?.errors);
            console.error("Error getting charge", getChargeErrorType);
        }
    };

    const status = rental.status
        ? rental.status == RentalStatus.Expired
            ? humanizeLower(RentalStatus.Failed)
            : rental.status == RentalStatus.InProgress
            ? "In-progress"
            : humanizeLower(rental.status)
        : "—";

    const ConsumerProfileSubSection = () => {
        return (
            <ProfileContainer onClick={() => openConsumerDetails()}>
                <RentalProfile />
                <ProfileBox>
                    <ProfileText
                        type="label"
                        size="medium"
                        color={colors.WAVE_STORM}
                    >
                        {STRINGS.CONSUMER_NAME(consumer)}
                    </ProfileText>
                    <DateText
                        type="body"
                        size="medium"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {STRINGS.CONSUMER_EMAIL(consumer)}
                    </DateText>
                </ProfileBox>
                <LinkTextStyle color={colors.WAVE_STORM}>
                    <HighlightHover>
                        <RightArrow></RightArrow>
                    </HighlightHover>
                </LinkTextStyle>
            </ProfileContainer>
        );
    };

    const RentalStatusDetails = () => {
        return (
            <StatusContainer>
                <StatusDetailsWrapper>
                    <div>
                        <RentalStatusIcon status={rental.status} />
                        <Displayable
                            displayPrimaryComponent={
                                rental.status == RentalStatus.Canceled &&
                                rental.lastPhase == RentalPhase.Fined
                            }
                        >
                            <GrubHubFinedText
                                type="body"
                                size="medium"
                                color={colors.EXHAUST}
                            >
                                Need to refund this fine through Grubhub? Email{" "}
                                <Mailto
                                    email="reusepass@topanga.io"
                                    subject={`Refund Request at ${organizationName} - Rental ID: ${rental.rentalId}`}
                                    body=""
                                >
                                    {"reusepass@topanga.io"}
                                </Mailto>{" "}
                                with "Refund Request" in the subject line and
                                include a link to this page.
                            </GrubHubFinedText>
                        </Displayable>
                    </div>
                    <StatusWrapper>
                        <DateAccountText
                            type="body"
                            size="medium"
                            color={colors.EXHAUST}
                        >
                            Status
                        </DateAccountText>
                        <DateText
                            type="other"
                            size="medium"
                            color={colors.DEEP_BLUE_SEA}
                        >
                            {status}
                        </DateText>
                    </StatusWrapper>
                </StatusDetailsWrapper>
                <CancelButtonWrapper>
                    <FilterButton
                        statusDisabled={statusDisabled}
                        size="small"
                        type="secondary"
                        label={
                            <FilterOpen
                                type="body"
                                size="medium"
                                onClick={statusDisabled ? null : onFilterOpen}
                                statusDisabled={!statusDisabled}
                            >
                                {STRINGS.CANCEL_BUTTON}
                            </FilterOpen>
                        }
                    ></FilterButton>
                </CancelButtonWrapper>
            </StatusContainer>
        );
    };

    const updateCurrentPhaseLabel = () => {
        if (chargeDetails) {
            if (
                chargeDetails.chargeStatus == "PENDING" ||
                chargeDetails.chargeStatus == "FAILED"
            ) {
                if (createdAtDate < releaseDate) {
                    return "Fined (manual)";
                }
            }
            if (chargeDetails.chargeStatus == "SUCCESS") {
                if (createdAtDate < releaseDate) {
                    return "Fined (automatic)";
                }
            }
        }
        return displayLastPhaseLabel(rental.lastPhase) || "-";
    };

    const subSectionComponents = [
        <ConsumerProfileSubSection />,
        <BasicSubSectionSection
            title={STRINGS.CURRENT_PHASE}
            description={updateCurrentPhaseLabel()}
        />,
        <RentalStatusDetails />,
        <ReturnReceivedDetails
            rental={rental}
            returnCriteria={rentalConfig.resolutionOption}
        />,
    ];

    useEffect(() => {
        checkDisabled();
    }, [rental, statusDisabled]);

    const openConsumerDetails = () => {
        setConsumerId(consumer.consumerId);
        openConsumerOverlay();
        setFromRentalDetails(true);
    };

    const checkDisabled = () => {
        if (
            rental.status == RentalStatus.CanceledWithRefund ||
            rental.status == RentalStatus.Canceled ||
            rental.status == RentalStatus.Completed ||
            rental.status == RentalStatus.Forgiven
        ) {
            setStatusDisabled(true);
        } else {
            setStatusDisabled(false);
        }
    };

    const Mailto = ({ email, subject = "", body = "", children }) => {
        let params = subject || body ? "?" : "";
        if (subject) params += `subject=${encodeURIComponent(subject)}`;
        if (body)
            params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;
        return (
            <a
                style={{ color: colors.WAVE_STORM }}
                href={`mailto:${email}${params}`}
            >
                {children}
            </a>
        );
    };

    return (
        <OverviewBox>
            <DetailsWrapper>
                <SectionTitle color={colors.ASH}>
                    {STRINGS.RENTAL_DETAILS_TITLE}
                </SectionTitle>
                <SideLine />
                <SubSectionGeneric
                    subSectionComponents={subSectionComponents}
                ></SubSectionGeneric>
            </DetailsWrapper>
        </OverviewBox>
    );
}
