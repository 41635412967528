import SignIn from "./SignIn";
import { TopangaWhite } from "../../assets/vectors";
import styled, { css } from "styled-components/macro";
import { Route, Routes } from "react-router-dom";
import DeprecatedText from "../common/DeprecatedText";
import ForgotPassword from "./ForgotPassword";
import { ROUTES } from "../routes/routes";
import { SIGN_OUT } from "../../constants/signin";
import { findTextStyles } from "../../styling/styled-components";

type Props = { children: ReactNode };

const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 0px ${({ theme }) => theme.spacing.medium}px;
    min-height: 100vh;
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            padding: 0px;
            background-color: ${({ theme }) => theme.colors.SMOG};
        `}
`;

const StyledScrollView = styled.div`
    width: 100%;
`;

const SignInWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding: ${({ theme }) => theme.spacing.huge}px 0px;
    margin: 0 auto;
    @media (max-width: ${({ theme }) => theme.breakpoints.large - 1}px) {
        padding: 36px ${({ theme }) => theme.spacing.large}px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.large}px) {
        max-width: 482px;
    }
    border-radius: 4px;
`;

const Header = styled.div`
    background-color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding: ${({ theme }) => theme.spacing.medium}px;
    justify-content: center;
    align-items: flex-start;
`;

const StyledLink = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
`;

const HeaderText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    font-family: lato;
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
    margin-left: ${({ theme }) => theme.spacing.xsmall}px;

    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            font-size: 14px;
            line-height: 21px;
        `}
`;

export default function SignInTemplateLayout() {
    return (
        <Container>
            <StyledScrollView>
                <Header>
                    <StyledLink target="_blank" href="https://www.topanga.io/">
                        <img src={TopangaWhite} />
                        <HeaderText size="medium" type="body">
                            {SIGN_OUT.HEADER}
                        </HeaderText>
                    </StyledLink>
                </Header>
                <SignInWrapper>
                    <Routes>
                        <Route index element={<SignIn />} />

                        <Route
                            path={ROUTES.FORGOT_PASSWORD}
                            element={<ForgotPassword />}
                        />
                    </Routes>
                </SignInWrapper>
            </StyledScrollView>
        </Container>
    );
}
