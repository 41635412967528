export const checkStreetAddress = (address) => {
    let regExpStreetAddress = /^(?=.*[a-zA-Z]).+/;
    if (address == "") {
        return;
    }
    if (!regExpStreetAddress.test(address)) {
        return true;
    } else {
        return false;
    }
};
