// react
import React, { useState, useEffect, useContext, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";

// API
import useGraphClient from "../../hooks/useGraphClient";
import {
    AssetEventSearchDocument,
    AssetOperationType,
    LoopStatus,
} from "../../API";
import * as queries from "../../graphql/queries";

// style
import { findTextStyles } from "../../styling/styled-components";
import { customStyles } from "../common/TableStyles";
// DO NOT REMOVE THE IMPORT BELOW. We keep trying to replace it with our locally built one and
// it breaks a ton of stuff, somehow even stuff not in this component. We've been
// trying to look into it, but it continues to be hard to pin down.
import styled, { useTheme, css } from "styled-components/macro";

// components
import ClipLoader from "react-spinners/ClipLoader";
import DataTable from "react-data-table-component";
import EventSearch, { SearchTag } from "../common/EventSearch";
import EventStatusBadge from "./EventStatusBadge";
import LocationMultiSelect from "../common/LocationMultiSelect";
import Text from "../common/Text";
import Filter, { FilterOption } from "../common/Filter";
import LinkText from "../common/LinkText";
import OverlayTakeover from "../overlay/OverlayTakeover";
import ScreenViewTable from "../common/ScreenViewTable";
import NoTableData from "../common/table/NoTableData";

// context
import LocationsContext from "../../contexts/LocationsContext";

//constants
import {
    ASSET_EVENT_LIST,
    ASSET_OPERATION_LABELS,
} from "../../constants/activity";

//images
import { ReactComponent as Close } from "../../assets/vectors/Close.svg";

// utils
import { parseISO, format } from "date-fns";
import {
    prettifyUID,
    prettifyPhoneNumber,
    truncateString,
} from "../../utils/helpers";
import queryString from "query-string";
import formatCount from "../../utils/formatCount";
import { truncateConsumer } from "../../utils/consumers/truncateConsumer";
import { removePrefix } from "../../utils/activity/removePrefix";

const MAX_RETURNED_RESULTS = 10000;

const Container = styled(ScreenViewTable)``;

const StyledEventSearch = styled(EventSearch)`
    width: 280px;
`;

const HeaderCellText = styled(Text)`
    padding-bottom: ${({ theme }) => theme.spacing.tiny}px;
`;
const CellText = styled(Text)``;

const Counter = styled.div`
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.small}px;
    position: relative;
    height: 25px;
    max-width: 65px;
    @media screen and (max-width: 1023px) {
        position: absolute;
        right: 36px;
        top: 180px;
    }
    @media screen and (max-width: 945px) {
        position: absolute;
        right: 36px;
        top: 200px;
    }
`;

const CounterValue = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    text-align: center;
`;

const FilterLeft = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.medium}px;
`;

const FilterRight = styled.div`
    display: flex;
`;

const TopWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
    ${({ theme }) =>
        theme.isTablet || theme.isMobile ? "flex-direction: column;" : ""}
`;

const FilterPill = styled.span`
    background-color: ${({ theme }) => theme.colors.WAVE_STORM};
    border-radius: 100px;
    padding: ${({ theme }) => theme.spacing.tiny}px
        ${({ theme }) => theme.spacing.xsmall}px;
    display: flex;
    width: fit-content;
    margin-right: ${({ theme }) => theme.spacing.small}px;
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
    cursor: pointer;
    svg {
        cursor: pointer;
    }
`;

const PillText = styled(Text)`
    color: ${({ theme }) => theme.colors.BLANK_SLATE};

    svg {
        position: relative;
        top: 2px;
        padding-left: ${({ theme }) => theme.spacing.tiny}px;
        color: ${({ theme }) => theme.colors.SUMMER_STORM};
    }
`;

const FilterPillContainer = styled.div`
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
`;

const ClearAllText = styled(Text)`
    color: ${({ theme }) => theme.colors.WAVE_STORM};
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    cursor: pointer;
`;

const TooMuchDataText = styled(Text)`
    padding: ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.xlarge}px;
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    text-align: center;
`;

function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const EVENT_TYPE_FILTERS: FilterOption[] = [
    {
        label: ASSET_OPERATION_LABELS[AssetOperationType.Fill],
        value: AssetOperationType.Fill,
        selected: false,
    },
    {
        label: ASSET_OPERATION_LABELS[AssetOperationType.CreateLoop],
        value: AssetOperationType.CreateLoop,
        selected: false,
    },
    {
        label: ASSET_OPERATION_LABELS[AssetOperationType.CloseLoop],
        value: AssetOperationType.CloseLoop,
        selected: false,
    },
    {
        label: ASSET_OPERATION_LABELS[AssetOperationType.Retire],
        value: AssetOperationType.Retire,
        selected: false,
    },
    {
        label: ASSET_OPERATION_LABELS[AssetOperationType.Clean],
        value: AssetOperationType.Clean,
        selected: false,
    },
];

const LOOP_STATUS_FILTERS: FilterOption[] = [
    {
        label: capitalizeFirstLetter(LoopStatus.Closed),
        value: LoopStatus.Closed,
        selected: false,
    },
    {
        label: capitalizeFirstLetter(LoopStatus.Open),
        value: LoopStatus.Open,
        selected: false,
    },
];

const searchTags: SearchTag[] = [
    { label: "PhoneNumber", value: "consumer.phoneNumber" },
    { label: "ConsumerName", value: "consumer.name" },
    { label: "ConsumerID", value: "consumer.consumerId" },
    { label: "AssetID", value: "asset.assetId" },
    { label: "AssetType", value: "asset.assetTypeName" },
    { label: "AssetExternalID", value: "asset.externalId" },
    { label: "ConsumerExternalID", value: "consumer.externalId" },
    { label: "OperationExternalID", value: "assetOperation.externalId" },
    { label: "OperationID", value: "assetOperation.assetOperationId" },
    { label: "OperationName", value: "assetOperation.name" },
    { label: "OperationType", value: "assetOperationType" },
    { label: "LocationName", value: "location.fullName" },
];

type Props = {
    fromTimestamp?: string;
    toTimestamp?: string;
    assetId?: string;
    onClearSearch: () => void;
};

export default function ActivityHome({
    fromTimestamp,
    toTimestamp,
    assetId,
    onClearSearch,
    ...props
}: Props) {
    let [searchParams, setSearchParams] = useSearchParams();
    const [dataLoading, setDataLoading] = useState(true);
    const [data, setData] = useState(null);
    const context = useContext(LocationsContext);
    const { locations } = context.selections;
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const propValue = queryParams.prop;
    let removePrefixPropValue = removePrefix(propValue, "consumerId=");
    const [checkProps, setCheckProps] = useState(false);

    const graphClient = useGraphClient();

    const LOCATION_FILTERS = locations
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
        .map((location) => ({
            label: location.fullName || location.locationId,
            value: location.locationId,
            selected: false,
        }));

    const [dateRange, setDateRange] = useState(
        searchParams.getAll("date") || [fromTimestamp, toTimestamp]
    );

    const [eventFilters, setEventFilters] = useState(
        [...EVENT_TYPE_FILTERS].map((filter) =>
            searchParams.getAll("eventType")?.includes(filter.value)
                ? { ...filter, selected: true }
                : filter
        )
    );
    const [statusFilters, setStatusFilters] = useState(
        [...LOOP_STATUS_FILTERS].map((filter) =>
            searchParams.getAll("loopStatus")?.includes(filter.value)
                ? { ...filter, selected: true }
                : filter
        )
    );
    const [locationFilters, setLocationFilters] = useState(
        [...LOCATION_FILTERS].map((filter) =>
            searchParams.getAll("location")?.includes(filter.value)
                ? { ...filter, selected: true }
                : filter
        )
    );
    const [rowsPerPage, setRowsPerPage] = useState(
        searchParams.get("rowsPerPage") || 50
    );
    const [page, setPage] = useState(searchParams.get("page") || 1);
    const [searchTerm, setSearchTerm] = useState(
        searchParams.get("search") || ""
    );
    const [isOpen, setIsOpen] = useState(false);
    const [panelWidth, setPanelWidth] = useState(0);
    const activityContainer = useRef(null);
    const eventSearch = useRef(null);
    const [consumerId, setConsumerId] = useState({});

    const { colors, isDesktop, isTablet, isMobile, spacing } = useTheme();

    const enableLoopStatusFilter =
        !eventFilters.reduce((acc, cur) => acc || cur.selected, false) ||
        eventFilters.filter(
            (filter) =>
                filter.value === AssetOperationType.CreateLoop &&
                filter.selected
        ).length;

    useEffect(() => {
        loadData();
        if (propValue && !checkProps) {
            setSearchTerm(removePrefixPropValue);
            setCheckProps(true);
        }
    }, [
        eventFilters,
        statusFilters,
        locationFilters,
        page,
        rowsPerPage,
        dateRange,
        searchTerm,
    ]);

    useEffect(() => {
        window.addEventListener("popstate", refreshOnBackClick);
        return () => {
            window.removeEventListener("popstate", refreshOnBackClick);
        };
    }, []);

    const refreshOnBackClick = () => {
        window.location.reload();
    };

    useEffect(() => {
        setDateRange([fromTimestamp, toTimestamp]);
    }, [fromTimestamp, toTimestamp]);

    useEffect(() => {
        if (isOpen) {
            setPanelWidth("516px");
        } else {
            setPanelWidth(0);
        }
    }, [isOpen]);

    useEffect(() => {
        if (
            !queryParams.consumerId ||
            location.search.includes(`consumerId=%`)
        ) {
            return;
        } else if (queryParams) {
            consumerSideBar(queryParams);
        }
    }, [location.search]);

    useEffect(() => {
        if (locationFilters.length == 0) {
            updateLocationFilterList();
        }
    }, [locations]);

    const updateLocationFilterList = () => {
        setLocationFilters(
            [...LOCATION_FILTERS].map((filter) =>
                searchParams.getAll("location")?.includes(filter.value)
                    ? { ...filter, selected: true }
                    : filter
            )
        );
    };

    const clearSearchTerm = () => {
        onClearSearch();
        setSearchTerm("");
    };

    const openConsumerOverlay = () => {
        setTimeout(() => {
            setIsOpen(true);
        }, 50);
    };

    const closeConsumerOverlay = () => {
        setTimeout(() => {
            setIsOpen(false);
        }, 50);
        setConsumerId("");
        searchParams.delete("consumerId");
        setSearchParams(searchParams);
    };

    const loadData = async () => {
        setDataLoading(true);
        if (dateRange[1] && dateRange[0]) {
            let fromDate = new Date(dateRange[0]);
            let toDate = new Date(dateRange[1]);
            try {
                const userTimezone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone;

                const results = await graphClient.graphql({
                    query: queries.searchAssetEvents,
                    variables: {
                        fromTimestamp: fromDate.toISOString(),
                        toTimestamp: toDate.toISOString(),
                        timezone: userTimezone,
                        calendarInterval: "1w",
                        operationTypes: eventFilters
                            .filter((operation) => operation.selected)
                            .map((operation) => operation.value),
                        locationIds: locationFilters
                            .filter((location) => location.selected)
                            .map((location) => location.value),
                        loopStatuses: statusFilters
                            .filter((status) => status.selected)
                            .map((status) => status.value),
                        searchQuery: searchTerm ? searchTerm : null,
                        size: rowsPerPage,
                        paginateFrom: (page - 1) * rowsPerPage,
                    },
                });
                const assetEvents = results.data.searchAssetEvents;
                setData(assetEvents);
                setDataLoading(false);
            } catch (error) {
                console.error(
                    "Error searching asset events in asset events page",
                    error
                );
                setDataLoading(false);
            }
        }
    };

    const secondaryConsumerId = (row: AssetEventSearchDocument) => {
        if (row.consumer?.name) {
            return (
                prettifyPhoneNumber(row.consumer?.phoneNumber) ||
                row.consumer?.email ||
                prettifyUID(row.consumer?.consumerId)
            );
        } else if (row.consumer?.phoneNumber) {
            return row.consumer?.email || prettifyUID(row.consumer?.consumerId);
        } else return "";
    };

    const consumerSideBar = (row) => {
        openConsumerOverlay();
        setConsumerId(row.consumerId);
    };

    const columns = [
        {
            name: ASSET_EVENT_LIST.DATE,
            grow: 0.4,
            width: "18%",
            selector: (row: AssetEventSearchDocument) => (
                <div>
                    <HeaderCellText
                        type="body"
                        size="tiny"
                        color={colors.EXHAUST}
                    >
                        {format(parseISO(row.eventTimestamp), "MM/dd/yy")}
                    </HeaderCellText>
                    <CellText type="body" size="tiny" color={colors.EXHAUST}>
                        {format(parseISO(row.eventTimestamp), "h:mm aaaaa'm'")}
                    </CellText>
                </div>
            ),
        },
        {
            name: ASSET_EVENT_LIST.EVENT,
            grow: isMobile || isTablet ? 1 : 1,
            width: "20%",
            selector: (row: AssetEventSearchDocument) => (
                <div style={{ display: "flex" }}>
                    <EventStatusBadge status={row.assetOperationType} />
                </div>
            ),
        },
        {
            name: ASSET_EVENT_LIST.ASSET_ID,
            grow: 0.5,
            width: "20%",
            selector: (row: AssetEventSearchDocument) => (
                <CellText type="label" size={"small"} color={colors.EXHAUST}>
                    {row.asset.assetId}
                </CellText>
            ),
        },
        {
            name: ASSET_EVENT_LIST.LOCATION,
            width: "20%",
            selector: (row: AssetEventSearchDocument) => (
                <CellText type="label" size={"small"} color={colors.EXHAUST}>
                    {row.location.fullName || row.location.name}
                </CellText>
            ),
        },
        {
            name: ASSET_EVENT_LIST.CONSUMER,
            width: "20%",
            cell: (row: AssetEventSearchDocument, index, column, id) =>
                row.consumer ? (
                    <div>
                        <HeaderCellText
                            type="link"
                            size="small"
                            color={colors.EXHAUST}
                        >
                            <LinkText
                                type={"new_link"}
                                size={"small"}
                                onClick={() => consumerSideBar(row.consumer)}
                            >
                                {truncateConsumer(
                                    row.consumer.name,
                                    !isDesktop,
                                    18
                                ) ||
                                    prettifyPhoneNumber(
                                        row.consumer.phoneNumber
                                    ) ||
                                    prettifyUID(row.consumer.consumerId)}
                            </LinkText>
                        </HeaderCellText>
                        <CellText type="body" size="tiny">
                            {isDesktop
                                ? secondaryConsumerId(row)
                                : truncateString(secondaryConsumerId(row), 10)}
                        </CellText>
                    </div>
                ) : (
                    <div>-</div>
                ),
        },
    ];

    const handleOnEventClear = (
        clearFilters: (options: FilterOption[]) => void,
        defaultOptions: FilterOption[],
        type: string
    ) => {
        clearFilters(defaultOptions);
        searchParams.delete(type);
        setSearchParams(searchParams);
    };

    const handleOnEventSave = (
        setFilters: (options: FilterOption[]) => void,
        options: FilterOption[],
        type: string
    ) => {
        setFilters([...options]);
        if (
            enableLoopStatusFilter &&
            statusFilters.reduce((acc, cur) => acc || cur.selected, false)
        ) {
            setStatusFilters(LOOP_STATUS_FILTERS);
        }
        searchParams.delete(type);
        options
            .filter((filterOption) => filterOption.selected)
            .forEach((filterOption) =>
                searchParams.append(type, filterOption.value)
            );
        setSearchParams(searchParams);
    };

    const handleSearch = (term: string) => {
        setSearchTerm(term);
        term ? searchParams.set("search", term) : searchParams.delete("search");
        setSearchParams(searchParams);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setRowsPerPage(newPerPage);
        searchParams.set("rowsPerPage", newPerPage);
        setSearchParams(searchParams);
    };

    const handlePageChange = (page) => {
        setPage(page);
        searchParams.set("page", page);
        setSearchParams(searchParams);
        activityContainer.current?.scrollIntoView();
    };

    const filterLabel = (filters: FilterOption[]) => {
        const numberFiltersActivated = filters.reduce(
            (acc, cur) => (cur.selected ? acc + 1 : acc),
            0
        );
        return numberFiltersActivated > 0 ? `(${numberFiltersActivated})` : "";
    };

    const togglePillSelectedState = (str, arr, type, setFilters) => {
        if (
            type === "eventType" &&
            enableLoopStatusFilter &&
            statusFilters.reduce((acc, cur) => acc || cur.selected, false)
        ) {
            setStatusFilters(LOOP_STATUS_FILTERS);
            searchParams.delete("loopStatus");
        }
        setFilters(
            arr.map((obj) => {
                if (obj.value === str) {
                    return {
                        value: obj.value,
                        label: obj.label,
                        selected: !obj.selected,
                    };
                }
                return obj;
            })
        );
        const newParams = searchParams
            .getAll(type)
            .filter((param) => param != str);
        searchParams.delete(type);
        newParams.forEach((param) => searchParams.append(type, param));
        setSearchParams(searchParams);
    };

    let filterList = [];
    filterList = filterList.concat(
        eventFilters.map((filter) => ({
            ...filter,
            onChange: setEventFilters,
            type: "eventType",
            filters: eventFilters,
        }))
    );
    filterList = filterList.concat(
        statusFilters.map((filter) => ({
            ...filter,
            label: "Loop Status: " + filter.label,
            onChange: setStatusFilters,
            type: "loopStatus",
            filters: statusFilters,
        }))
    );
    filterList = filterList.concat(
        locationFilters.map((filter) => ({
            ...filter,
            onChange: setLocationFilters,
            type: "location",
            filters: locationFilters,
        }))
    );
    const selectedFilters = filterList.filter((filter) => filter.selected);

    const activatedFilters = () => {
        return selectedFilters.length > 0 ? (
            <FilterPillContainer>
                {selectedFilters.map((activatedFilter) => (
                    <FilterPill
                        key={activatedFilter.value}
                        onClick={() =>
                            togglePillSelectedState(
                                activatedFilter.value,
                                activatedFilter.filters,
                                activatedFilter.type,
                                activatedFilter.onChange
                            )
                        }
                    >
                        <PillText type="body" size="tiny">
                            {truncateString(activatedFilter.label, 30)}
                            <Close />
                        </PillText>
                    </FilterPill>
                ))}{" "}
                {selectedFilters.length > 1 && (
                    <ClearAllText
                        onClick={clearAllFilters}
                        type="link"
                        size="small"
                    >
                        Clear all filters
                    </ClearAllText>
                )}
            </FilterPillContainer>
        ) : (
            ""
        );
    };

    const clearAllFilters = () => {
        setEventFilters(EVENT_TYPE_FILTERS);
        setLocationFilters(LOCATION_FILTERS);
        setStatusFilters(LOOP_STATUS_FILTERS);
        searchParams = new URLSearchParams();
        setSearchParams(searchParams);
    };

    const eventTypeLabel = "Event Type " + filterLabel(eventFilters);

    const noDataText = () => {
        if (searchTerm || selectedFilters.length) {
            return "Sorry, we didn't find any matches.";
        } else {
            return "No activity occurred within the selected time frame.";
        }
    };

    const totalResults = data?.hitAggregations
        ? data?.hitAggregations.reduce((acc, cur) => acc + cur.docCount, 0)
        : 0;

    const formatResultCount = (count: number) => {
        return count <= 999999 ? formatCount(count) : formatCount(count, true);
    };

    const endOfTable =
        page === Math.floor(data?.totalHits / rowsPerPage) + 1 ||
        (!(data?.totalHits % rowsPerPage) &&
            page === data?.totalHits / rowsPerPage);

    return (
        <Container shouldShowCounter={false}>
            <OverlayTakeover
                isOpen={isOpen}
                closeConsumerOverlay={closeConsumerOverlay}
                consumerId={consumerId}
                width={panelWidth}
            ></OverlayTakeover>
            <TopWrapper ref={activityContainer}>
                <FilterLeft>
                    <Filter
                        buttonLabel={eventTypeLabel}
                        options={[...eventFilters]}
                        onClear={() =>
                            handleOnEventClear(
                                setEventFilters,
                                EVENT_TYPE_FILTERS,
                                "eventType"
                            )
                        }
                        onSave={(options: FilterOption[]) =>
                            handleOnEventSave(
                                setEventFilters,
                                options,
                                "eventType"
                            )
                        }
                    />
                    <LocationMultiSelect
                        selectedLocationIds={searchParams.getAll("location")}
                        onClear={() =>
                            handleOnEventClear(
                                setLocationFilters,
                                LOCATION_FILTERS,
                                "location"
                            )
                        }
                        onSave={(options: FilterOption[]) =>
                            handleOnEventSave(
                                setLocationFilters,
                                options,
                                "location"
                            )
                        }
                    />
                    <Filter
                        disabled={!enableLoopStatusFilter}
                        buttonLabel={"Loop Status"}
                        options={[...statusFilters]}
                        onClear={() =>
                            handleOnEventClear(
                                setStatusFilters,
                                LOOP_STATUS_FILTERS,
                                "loopStatus"
                            )
                        }
                        onSave={(options: FilterOption[]) =>
                            handleOnEventSave(
                                setStatusFilters,
                                options,
                                "loopStatus"
                            )
                        }
                    />
                    <StyledEventSearch
                        searchTags={searchTags}
                        tagRows={[2, 3]}
                        value={searchTerm}
                        loading={dataLoading && searchTerm}
                        ref={eventSearch}
                        onChange={handleSearch}
                        consumerDetail={removePrefixPropValue}
                        assetId={assetId}
                        clearSearchTerm={clearSearchTerm}
                    />
                </FilterLeft>
                <FilterRight>
                    {data ? (
                        <Counter count={totalResults}>
                            <CounterValue type="body" size="medium">
                                {formatResultCount(totalResults)}
                            </CounterValue>
                        </Counter>
                    ) : (
                        ""
                    )}
                </FilterRight>
            </TopWrapper>
            {activatedFilters()}
            <DataTable
                noDataComponent={<NoTableData copy={noDataText()} />}
                columns={columns}
                keyField="assetEventId"
                data={data?.assetEvents || []}
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50, 100]}
                paginationServer
                paginationTotalRows={data?.totalHits}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={dataLoading}
                progressComponent={<ClipLoader loading />}
                customStyles={customStyles}
            />
            {data?.totalHits === MAX_RETURNED_RESULTS && endOfTable ? (
                <TooMuchDataText size="medium" type="body">
                    We love data just as much as you, but you’ve reached the
                    maximum number of items we can load into view (10,000). Try
                    using search or filters to narrow your selection or contact
                    a Topanga team member if you need additional assistance.
                </TooMuchDataText>
            ) : null}
        </Container>
    );
}
