import { useEffect } from "react";

/*
 * Provides a way for any ref to call a function when it detects a click outside of itself
 * Copied from https://stackoverflow.com/a/63359417
 *
 * Usage:
 *
 * In the functional component set the following lines up
 *
 *   const elementRef = useRef("some-ref-name");
 *   useClickOutside(elementRef, () => {
 *     <whatever functions I want to call when a click outside the element happens>
 *   }
 *
 * Then in the actual component you need to add a ref attribute to the part
 * of the component that will trigger the click handler
 *
 *   <div ref={elementRef}>CONTENTS OF DIV</div>
 *
 * The exclueded refs are refs that can be clicked that will prevent the onClickOutside
 * function from triggering. An example for this is if you have a button that opens a dropdown,
 * you want the dropdown to close when the user clicks outside the dropdown or when the user
 * clicks the button again. In order for clicking the button to close the dropdown, we need
 * to disable onClickOutside from firing.
 */
export default function useClickOutside(
    ref,
    onClickOutside,
    excludedRefs = []
) {
    useEffect(() => {
        /**
         * Invoke Function onClick outside of element
         */
        function handleClickOutside(event) {
            if (
                ref.current &&
                ref.current.contains &&
                !ref.current.contains(event.target)
            ) {
                for (let i = 0; i < excludedRefs.length; i++) {
                    const excludedRef = excludedRefs[i];
                    if (
                        excludedRef.current &&
                        excludedRef.current.contains &&
                        excludedRef.current.contains(event.target)
                    ) {
                        return;
                    }
                }
                if (
                    event.target.closest("[data-override-outside-click]") !==
                    null
                )
                    return;
                onClickOutside();
            }
        }
        // Bind
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Dispose
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClickOutside]);
}
