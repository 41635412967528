import { ADD_DESCRIPTION } from "../../constants/locations";

export const returnStepDescription = (stepNumber, showButton) => {
    if (stepNumber == 1) {
        return {
            stepInstruction: ADD_DESCRIPTION.STEP_ONE_INSTRUCTION,
            stepDescription: ADD_DESCRIPTION.STEP_ONE_DESCRIPTION,
            stepSubDescription: "",
        };
    }
    if (stepNumber == 2 && showButton) {
        return {
            stepInstruction: ADD_DESCRIPTION.STEP_TWO_INSTRUCTION,
            stepDescription: ADD_DESCRIPTION.STEP_TWO_DESCRIPTION,
            stepSubDescription: ADD_DESCRIPTION.STEP_TWO_SUB_DESCRIPTION,
        };
    }
    if (stepNumber == 2 && !showButton) {
        return {
            stepInstruction: ADD_DESCRIPTION.STEP_TWO_ENTER_SCAN_LOCATION,
            stepDescription: ADD_DESCRIPTION.STEP_TWO_NAME_LOCATION,
            stepSubDescription: "",
        };
    }
    if (stepNumber == 3) {
        return {
            stepInstruction: ADD_DESCRIPTION.STEP_THREE_INSTRUCTION,
            stepDescription: ADD_DESCRIPTION.STEP_THREE_DESCRIPTION,
            stepSubDescription: "",
        };
    }
};
