//react
import React, { useEffect } from "react";

//components
import SideLine from "../common/SideLine";
import SectionTitle from "../common/overlay/SectionTitle";
import SubSection from "../common/overlay/SubSection";

//utiles
import { prettifyDate } from "../../utils/helpers";

//styles
import styled, {
    findTextStyles,
    useTheme,
} from "../../styling/styled-components";

export default function OverviewSection({ consumer }) {
    const { colors } = useTheme();
    const adjustTop = () => {
        if (!consumer.email && !consumer.phoneNumber) {
            return "110px";
        } else if (!consumer.email || !consumer.phoneNumber) {
            return "140px";
        } else {
            return "170px";
        }
    };
    const subSectionDetails = [
        {
            title: "Date Account Created",
            description: !consumer.createdAt
                ? "-"
                : prettifyDate(consumer.createdAt),
        },
        {
            title: "External ID",
            description: consumer.externalId ? consumer.externalId : "-",
        },

        {
            title: "Consumer ID",
            description: consumer.consumerId,
        },
    ];

    const OverviewBox = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: ${adjustTop()};
        padding-left: 40px;
        width: 440px;
    `;

    return (
        <OverviewBox>
            <SectionTitle color={colors.ASH}>OVERVIEW</SectionTitle>
            <SideLine />
            <SubSection sectionDetails={subSectionDetails}></SubSection>
        </OverviewBox>
    );
}
