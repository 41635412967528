//styles
import styled from "../../../styling/styled-components";

const ChartTitleDiv = styled.div`
    padding-top: ${({ theme }) => theme.spacing.large}px;
    padding-left: ${({ theme }) => theme.spacing.large}px;
`;

export default function TitleWrapper({ children }: Props) {
    return <ChartTitleDiv>{children}</ChartTitleDiv>;
}
