import React from "react";
import DeprecatedText from "../common/DeprecatedText";
import styled from "styled-components/macro";
import { LoopStatus } from "../../API";
import { findTextStyles } from "../../styling/styled-components";
import { LOOP_STATUS_BADGES } from "../../constants/loops";

const ClosedContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.GREEN_2};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-start;
    position: relative;
`;

const OpenContainer = styled.div`
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.GREEN_2};
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-start;
    position: relative;
`;

const GreenText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.GREEN_2};
`;

const WhiteText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

type Props = {
    status: LoopStatus;
};

export default function Component({ status }: Props) {
    if (status == LoopStatus.Open) {
        return (
            <OpenContainer>
                <GreenText type="label" size="small">
                    {LOOP_STATUS_BADGES.OPEN}
                </GreenText>
            </OpenContainer>
        );
    }
    if (status == LoopStatus.Closed) {
        return (
            <ClosedContainer>
                <WhiteText type="label" size="small">
                    {LOOP_STATUS_BADGES.CLOSED}
                </WhiteText>
            </ClosedContainer>
        );
    } else return <div></div>;
}
