//style
import styled from "../../../styling/styled-components";

const Wrapper = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.yuge}px;
    position: relative;
`;

export function OverlayWrapper({ children }) {
    return <Wrapper>{children}</Wrapper>;
}
