//components
import Text from "../../common/Text";

//styles
import styled, { css, useTheme } from "../../../styling/styled-components";

//images
import { ReactComponent as WarningYellow } from "../../../assets/vectors/WarningYellow.svg";

const STRINGS = {
    ERROR_MESSAGE:
        "An error occurred and the data cannot be displayed. Please refresh the page or try again later.",
};

const Container = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing.medium}px
        ${({ theme }) => theme.spacing.huge}px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    flex-shrink: 0;
    background-color: ${({ theme }) => theme.colors.SMOG};
    margin-top: ${({ theme }) => theme.spacing.small}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    border-radius: 4px;
    ${(props) => `width: ${props.width}px;`}
    ${(props) => props.height && `height: ${props.height}px;`}
`;

const ErrorMessage = styled(Text)`
    text-align: center;
    ${(props) => props.width && `width: 250px;`}
`;

export default function ErrorBlock({ width, height = "382" }) {
    const { colors } = useTheme();

    return (
        <Container width={width} height={height}>
            <WarningYellow></WarningYellow>
            <ErrorMessage
                type="label"
                size="small"
                color={colors.DEEP_BLUE_SEA}
                width={width}
            >
                {STRINGS.ERROR_MESSAGE}
            </ErrorMessage>
        </Container>
    );
}
