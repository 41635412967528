//react
import { useState, useEffect } from "react";

//style
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import { NextButton } from "../common/NextButton";
import { ButtonDiv } from "../common/ButtonDiv";
import { LocationInputForm } from "../common/LocationInputField";
import Select from "react-select";
import { toastSuccess, toastError } from "../../../utils/toasts";
import Overlay from "../../common/overlay/Overlay";

//apis
import * as queries from "../../../graphql/queries";
import { useQuery } from "../../../graphql/hooks/query";
import useGraphClient from "../../../hooks/useGraphClient";
import * as mutations from "../../../graphql/mutations";

//utils
import _ from "lodash";
import { checkUniqueParentName } from "../../../utils/locations/checkUniqueParentName";
import { checkStreetAddress } from "../../../utils/locations/checkStreetAddress";

const COUNTRY_OPTIONS = [
    { value: "USA", label: "United States of America" },
    { value: "Canada", label: "Canada" },
];
const DropDownDiv = styled.div`
    display: flex;
    height: ${({ theme }) => theme.spacing.xxlarge}px;
    padding: ${({ theme }) => theme.spacing.small}px;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
`;

const ButtonWrap = styled.div`
    width: ${({ theme }) => theme.spacing.small}%;
    padding-left: ${({ theme }) => theme.spacing.yuge}px;
    margin-left: ${({ theme }) => theme.spacing.xxlarge}px;
    &:hover {
        color: ${({ theme }) => theme.colors.RED_3};
    }
`;

const CountryText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const ParentLocationText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    width: 100%;
`;

const HeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    flex: 1 0 0;
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
`;

export default function EditParentLocation({
    onTriggerBack,
    onClick,
    parentLocationId,
    listParentLocations,
    onReturnToMain,
    width,
    closeEditParent,
}) {
    const { colors, spacing } = useTheme();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [locationDetails, setLocationDetails] = useState({});
    const [enableButton, setEnableButton] = useState(false);
    const [showErrorState, setShowErrorState] = useState(false);
    const [showErrorStreetState, setShowErrorStreetState] = useState(false);
    const [checkBlur, setCheckBlur] = useState(false);

    const graphClient = useGraphClient();

    const [
        getParentLocation,
        getParentLocationData,
        getParentLocationLoading,
        getParentLocationErrors,
    ] = useQuery(queries.getParentLocation);
    const address = {
        country: "",
        googlePlacesId: null,
        latitude: null,
        line1: "",
        line2: "",
        line3: "",
        longitude: null,
        postalCode: "",
    };

    useEffect(() => {
        getParentLocation({ locationId: parentLocationId });
    }, [parentLocationId]);

    useEffect(() => {
        if (getParentLocationData) {
            let clonedLocation = _.cloneDeep(getParentLocationData);
            setLocationDetails(_.cloneDeep(getParentLocationData));
            if (!getParentLocationData.address) {
                setLocationDetails(() => ({
                    ...clonedLocation,
                    address: address,
                }));
            }
            setDataLoaded(true);
        }
    }, [getParentLocationData]);

    useEffect(() => {
        if (checkBlur) {
            setShowErrorStreetState(
                checkStreetAddress(locationDetails.address.line1)
            );
        }
    }, [locationDetails.address]);

    useEffect(() => {
        if (getParentLocationData) {
            setShowErrorState(false);
            setShowErrorStreetState(false);
            checkEnableButton();
            if (locationDetails.name !== getParentLocationData.name) {
                setShowErrorState(
                    checkUniqueParentName(
                        listParentLocations,
                        locationDetails.name
                    )
                );
            }
        }
    }, [locationDetails]);

    const setParentLocationDetails = (e, key, key2) => {
        let locationCopy = { ...locationDetails };
        if (key2) {
            locationCopy[key][key2] = e;
            setLocationDetails({
                ...locationCopy,
            });
        } else {
            locationCopy[key] = e;
            setLocationDetails({
                ...locationCopy,
            });
        }
    };

    const onUpdateParentLocation = async () => {
        try {
            const result = await graphClient.graphql({
                query: mutations.updateParentLocation,
                variables: {
                    input: {
                        locationId: locationDetails.locationId,
                        name: locationDetails.name,
                        address: {
                            line1: locationDetails.address.line1,
                            line2: locationDetails.address.line2,
                            line3: locationDetails.address.line3,
                            postalCode: locationDetails.address.postalCode,
                            country: locationDetails.address.country,
                        },
                    },
                },
            });
            onReturnToMain();
            toastSuccess("The parent location was successfully updated");
        } catch (error) {
            toastError("The location could not be updated");
            console.error("Error updating scanning location", error);
        }
    };

    const checkEnableButton = () => {
        if (
            getParentLocationData.name !== locationDetails.name ||
            !getParentLocationData.address
        ) {
            setEnableButton(true);
            return;
        }
        if (
            getParentLocationData.name !== locationDetails.name ||
            getParentLocationData.address.line1 !==
                locationDetails.address.line1 ||
            getParentLocationData.address.line2 !==
                locationDetails.address.line2 ||
            getParentLocationData.address.line3 !==
                locationDetails.address.line3 ||
            getParentLocationData.address.postalCode !==
                locationDetails.address.postalCode ||
            getParentLocationData.address.country !==
                locationDetails.address.country
        ) {
            setEnableButton(true);
            return;
        } else {
            setEnableButton(false);
        }
    };

    const handleBlur = () => {
        setCheckBlur(true);
        setShowErrorStreetState(
            checkStreetAddress(locationDetails.address.line1)
        );
    };

    const showBlocker = () => {
        if (enableButton && !showErrorState && !showErrorStreetState) {
            return true;
        } else return false;
    };

    return (
        <>
            {dataLoaded && (
                <Overlay
                    onClick={() => onClick(showBlocker())}
                    onClickBack={() => onTriggerBack(showBlocker())}
                    showLeft={true}
                    width={width}
                    onClickedOutside={closeEditParent}
                >
                    <>
                        <HeaderDiv>
                            <ParentLocationText
                                type="body"
                                size="medium"
                                color={colors.EXHAUST}
                            >
                                {getParentLocationData.name}
                            </ParentLocationText>
                            <ParentLocationText
                                type="header"
                                size="large"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                Parent location details
                            </ParentLocationText>
                        </HeaderDiv>
                        <div
                            style={{
                                position: "relative",
                                height: "65%",
                                overflowY: "scroll",
                                paddingBottom: spacing.large,
                            }}
                        >
                            <LocationInputForm
                                infoText="Parent Location Name"
                                width="medium"
                                value={locationDetails.name}
                                onChange={(e) =>
                                    setParentLocationDetails(
                                        e.target.value,
                                        "name"
                                    )
                                }
                                maxLength={150}
                                error={
                                    "That name already exists. Please enter a unique parent location name. "
                                }
                                errorStyle={{
                                    color: colors.ASH,
                                }}
                                hasError={showErrorState ? true : false}
                            ></LocationInputForm>
                            <LocationInputForm
                                width="medium"
                                infoText="Street Address"
                                infoStyle={{ color: colors.ASH }}
                                error={
                                    "That street address is invalid. Make sure it is entered correctly and try again. "
                                }
                                hasError={showErrorStreetState ? true : false}
                                value={locationDetails.address.line1}
                                onBlur={handleBlur}
                                onChange={(e) =>
                                    setParentLocationDetails(
                                        e.target.value,
                                        "address",
                                        "line1"
                                    )
                                }
                            ></LocationInputForm>
                            <LocationInputForm
                                width="medium"
                                infoText="Floor, suite, etc. (optional)"
                                infoStyle={{ color: colors.ASH }}
                                value={locationDetails.address.line2}
                                onChange={(e) =>
                                    setParentLocationDetails(
                                        e.target.value,
                                        "address",
                                        "line2"
                                    )
                                }
                            ></LocationInputForm>
                            <LocationInputForm
                                width="medium"
                                subHintText="Abbreviate the state or province (eg. 'Brooklyn, NY')"
                                subHintStyle={{ color: colors.EXHAUST }}
                                hintText="City, State or Province"
                                hintStyle={{ color: colors.ASH }}
                                value={locationDetails.address.line3}
                                onChange={(e) =>
                                    setParentLocationDetails(
                                        e.target.value,
                                        "address",
                                        "line3"
                                    )
                                }
                            ></LocationInputForm>
                            <LocationInputForm
                                width="medium"
                                infoText="ZIP or Postal Code"
                                infoStyle={{ color: colors.ASH }}
                                value={locationDetails.address.postalCode}
                                onChange={(e) =>
                                    setParentLocationDetails(
                                        e.target.value,
                                        "address",
                                        "postalCode"
                                    )
                                }
                            ></LocationInputForm>
                            <DropDownDiv>
                                <Select
                                    styles={{
                                        option: (styles, state) => ({
                                            ...styles,
                                        }),
                                        placeholder: (defaultStyles) => {
                                            return {
                                                ...defaultStyles,
                                                color: colors.DEEP_BLUE_SEA,
                                            };
                                        },
                                        indicatorSeparator: (
                                            baseStyles,
                                            state
                                        ) => ({
                                            ...baseStyles,
                                            backgroundColor: "transparent",
                                            color: colors.DEEP_BLUE_SEA,
                                            cursor: "pointer",
                                        }),
                                        container: (baseStyles, state) => ({
                                            //tried replacing with spacing themes, but only takes numbers
                                            ...baseStyles,
                                            fontFamily: "poppins",
                                            fontStyle: "normal",
                                            letterSpacing: "0.13px",
                                            fontSize: 13,
                                            fontWeight: 400,
                                            color: colors.DEEP_BLUE_SEA,
                                            width: 444,
                                            height: 48,
                                            cursor: "pointer",
                                            borderRadius: "4px",
                                        }),
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: colors.BLANK_SLATE,
                                            height: 48,
                                            border: "0.5px solid var(--greys-marine-layer, #c2cfe0)",
                                            zIndex: 10,
                                            display: "flex",
                                            color: colors.DEEP_BLUE_SEA,
                                            cursor: "pointer",
                                        }),
                                        dropdownIndicator: (
                                            baseStyles,
                                            state
                                        ) => ({
                                            ...baseStyles,
                                            svg: {
                                                cursor: "pointer",
                                                transform: state.selectProps
                                                    .menuIsOpen
                                                    ? "rotate(180deg)"
                                                    : null,
                                            },
                                        }),
                                    }}
                                    placeholder={
                                        locationDetails.address.country
                                    }
                                    options={COUNTRY_OPTIONS}
                                    isSearchable={false}
                                    onChange={(e) =>
                                        setParentLocationDetails(
                                            e.value,
                                            "address",
                                            "country"
                                        )
                                    }
                                    value={locationDetails.address.country}
                                    isClearable={false}
                                />
                            </DropDownDiv>
                            <CountryText
                                type="label"
                                size="medium"
                                color={colors.ASH}
                            >
                                Country
                            </CountryText>
                        </div>
                        <ButtonDiv height={"true"}>
                            <ButtonWrap>
                                <NextButton
                                    type="primary"
                                    onClick={onUpdateParentLocation}
                                    label={
                                        <Text
                                            type="label"
                                            size="large"
                                            color={colors.BLANK_SLATE}
                                        >
                                            {"Save Changes"}
                                        </Text>
                                    }
                                    status={
                                        enableButton &&
                                        !showErrorState &&
                                        !showErrorStreetState
                                            ? "default"
                                            : "disabled"
                                    }
                                ></NextButton>
                            </ButtonWrap>
                        </ButtonDiv>
                    </>
                </Overlay>
            )}
        </>
    );
}
