//styles
import styled from "../../../styling/styled-components";

const ChartContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: 0px ${({ theme }) => theme.spacing.large}px;
    padding-top: ${({ theme }) => theme.spacing.large}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-bottom: ${({ theme }) => theme.spacing.medium}px;
    width: 100%;
`;

export default function AssetContainer({ children }) {
    return <ChartContainer>{children}</ChartContainer>;
}
