import React, { useState } from "react";
import DeprecatedText from "./DeprecatedText";
import styled, { useTheme } from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";

type Props = {
    error: string;
    hintText: string;
    hintStyle: Record<string, unknown>;
    onBlur?: () => void;
    onFocus?: () => void;
    style?: React.CSSProperties;
    rightIcon: React.ReactNode;
    type: string;
    width: number;
};

const Container = styled.div``;

const WrapperTextInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    ${(props) =>
        props.status === "active" &&
        `border-bottom-color: ${props.theme.colors.WAVE_STORM};`};
    ${(props) =>
        props.hasError && `border-bottom-color: ${props.theme.colors.RED_3};`};
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const StyledTextInput = styled.input`
    flex: 1;
    ${({ theme }) => theme.textStyles["body"]["large"]};
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding: ${({ theme }) => theme.spacing.xsmall}px;
    outline-style: none;
    border: none;
    background: none;
`;

const Hint = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    font-family: poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    ${(props) => props.hasError && `color: ${props.theme.colors.RED_3};`};
    margin-top: 3px;
    ${(props) => props.hintStyle};
`;

const ErrorMessage = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
    color: ${({ theme }) => theme.colors.RED_3};
    ${(props) => props.hasError && `color: ${props.theme.colors.RED_3};`};
`;

export default function TextInput({
    error,
    hintText = "",
    hintStyle = {},
    onBlur,
    onFocus,
    rightIcon = null,
    style,
    width = 302,
    ...props
}: Props) {
    const [status, setStatus] = useState<string>("default");
    const { colors, textStyles } = useTheme();

    const underlineColor =
        status === "active" ? colors.WAVE_STORM : colors.MARINE_LAYER;

    const onBlurTextInput = () => {
        setStatus("default");
        onBlur && onBlur();
    };

    const onFocusTextInput = () => {
        setStatus("active");
        onFocus && onFocus();
    };

    return (
        <Container style={style} width={width}>
            <WrapperTextInput status={status} hasError={!!error}>
                <StyledTextInput
                    type="body"
                    size="large"
                    onBlur={onBlurTextInput}
                    onFocus={onFocusTextInput}
                    placeholderTextColor={colors.SUMMER_STORM}
                    underlineColorAndroid={underlineColor}
                    {...props}
                />
                {rightIcon}
            </WrapperTextInput>
            <Hint hasError={!!error} hintStyle={hintStyle} size="large">
                {hintText}
            </Hint>
            {error ? (
                <ErrorMessage type="label" size="medium">
                    {error}
                </ErrorMessage>
            ) : null}
        </Container>
    );
}
