import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import config from "../configs.json";

const app = require("../../package.json");

// RUM source maps upload (see scripts/upload_sourcemaps.js) must match version here
// and uploads do not update if version does not change which would be a problem
// for staging -> production promotion scheme without a manufactured version diff here
const VERSION =
    config.environment == "production" ? app.version : `${app.version}-rc`;

const RUMconfig = {
    applicationId: config.datadogConfig.applicationId,
    clientToken: config.datadogConfig.clientToken,
    env: config.environment,
    site: "datadoghq.com",
    service: "platformdash",
    version: VERSION,
    sampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: "mask-user-input",
};

const logsConfig = {
    clientToken: config.datadogConfig.clientToken,
    site: "datadoghq.com",
    env: config.environment,
    service: "platformdash",
    version: VERSION,
    forwardConsoleLogs: ["info", "warn", "error"],
    forwardErrorsToLogs: true,
    sampleRate: 100,
};

const DatadogAnalyticsReporter = {
    init: () => {
        datadogRum.init(RUMconfig);
        datadogRum.startSessionReplayRecording();
        datadogLogs.init(logsConfig);
    },
    setUser: (userId: string) => datadogRum.setUser({ id: userId }),
    clearUser: () => datadogRum.clearUser(),
    setOrganization: (campusSlug: string) =>
        datadogRum.setGlobalContextProperty("organization", {
            campusSlug,
        }),
};

export default DatadogAnalyticsReporter;
