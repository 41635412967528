//styles
import styled, { useTheme } from "../../styling/styled-components";

//components
import Text from "../common/Text";

//images
import { ReactComponent as Logo } from "../../assets/vectors/StreamLineFullLogo.svg";
import Button from "../common/Button";

//utils
import { LEARN_MORE_URL } from "./constants";

const Box = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.large}px;
    background-color: #fff;
    padding: 36px 36px 48px 36px;
    margin-top: ${({ theme }) => theme.spacing.xlarge}px;
    margin-right: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const LogoWrapper = styled.div``;

const BodyWrapper = styled.div``;

const BodyText = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.small}px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

const HeaderText = styled(Text)`
    font-family: Lato;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -2.52px;
`;

const BetaText = styled(Text)`
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.18px;
`;

const ButtonsWrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing.xlarge}px;
`;
const StyledButton = styled(Button)``;

type Props = {
    className?: string;
};

export default function LearnMore({ className }: Props) {
    const theme = useTheme();

    return (
        <Box className={className}>
            <LogoWrapper>
                <Logo />
            </LogoWrapper>
            <BodyWrapper>
                <HeaderWrapper>
                    <HeaderText color={theme.colors.DEEP_BLUE_SEA}>
                        StreamLine
                    </HeaderText>
                    <BetaText color={theme.colors.DEEP_BLUE_SEA}>Beta</BetaText>
                </HeaderWrapper>
                <BodyText
                    type="body"
                    size="large"
                    color={theme.colors.DEEP_BLUE_SEA}
                >
                    We're excited to expand Topanga's data-driven solutions to
                    help kitchen teams like yours reduce waste and save money!
                    Our latest innovation, StreamLine, uses advanced computer
                    vision to track food waste, offering the same reliable
                    results and seamless experience as ReusePass.
                </BodyText>
                <BodyText
                    type="body"
                    size="large"
                    color={theme.colors.DEEP_BLUE_SEA}
                >
                    The StreamLine Beta program is now live with select
                    partners, and we're giving our existing clients priority
                    access for Spring '25 launches. Thank you for helping us
                    revolutionize kitchen operations!
                </BodyText>
                <BodyText
                    type="body"
                    size="large"
                    color={theme.colors.DEEP_BLUE_SEA}
                >
                    Reach out to your Topanga client success team or schedule a
                    demo using the link below to see how it works.
                </BodyText>
                <ButtonsWrapper>
                    <StyledButton
                        type="primary"
                        label="Learn more"
                        onClick={() => window.open(LEARN_MORE_URL)}
                    />
                </ButtonsWrapper>
            </BodyWrapper>
        </Box>
    );
}
