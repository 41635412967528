//style
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import LinkText from "../../common/LinkText";
import InstantForgivenessCard from "./InstantForgivenessCard";
import ReturnReceivedCard from "./ReturnReceivedCard";
import EmailMessage from "../../common/EmailMessage";

//images
import { ForgivenHeart } from "../../common/IconWithShadow";

//constants
import { FORGIVENESS_POLICY } from "../../../constants/forgiveness";

//react
import { useEffect, useState } from "react";

//api
import useGraphClient from "../../../hooks/useGraphClient";
import * as queries from "../../../graphql/queries";

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

const BodyWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.small}px;
    align-self: stretch;
    ${({ theme: { isTablet } }) => (isTablet ? `width: 100%` : `width: 75%`)}
`;

const BodyText = styled(Text)`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const FootText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
`;

const ForgivenessCardWrapper = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing.xlarge}px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.large}px;
    align-self: stretch;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: ${({ theme }) => theme.shadowStyle.containerDropShadow};
    border-bottom: ${({ theme }) => theme.borderStyle.smallDivider};
    ${({ theme: { isTablet } }) => (isTablet ? `width: 100%` : `width: 75%`)}
`;

const DivderLineDiv = styled.div`
    height: 12px;
    width: 100%;
    border-bottom: ${({ theme }) => theme.borderStyle.smallDivider};
`;

export default function ForgivenessPolicy() {
    const { colors } = useTheme();

    const [forgivenessSettingsChange, setForgivenessSettingsChange] =
        useState(false);
    const [organizationData, setOrganizationData] = useState({});

    const graphClient = useGraphClient();

    useEffect(() => {
        getOrganizationData();
    }, [forgivenessSettingsChange]);

    const forgivenessSettingsChanged = () => {
        setForgivenessSettingsChange(!forgivenessSettingsChange);
    };

    const getOrganizationData = async (): null => {
        try {
            const results = await graphClient.graphql({
                query: queries.getOrganization,
            });
            const data = results.data.getOrganization;
            setOrganizationData(data);
        } catch (err) {
            console.error(
                "Error getting organization in forgiveness tooltip",
                err
            );
        }
    };

    return (
        <>
            <HeaderWrapper>
                <ForgivenHeart />
                <Text type="header" size="small" color={colors.DEEP_BLUE_SEA}>
                    {FORGIVENESS_POLICY.HEADER}
                </Text>
            </HeaderWrapper>
            <BodyWrapper>
                <BodyText
                    type="body"
                    size="medium"
                    color={colors.DEEP_BLUE_SEA}
                >
                    {FORGIVENESS_POLICY.FORGIVENESS_DETAILS}
                </BodyText>
                <BodyText
                    type="body"
                    size="medium"
                    color={colors.DEEP_BLUE_SEA}
                >
                    <b>{FORGIVENESS_POLICY.FORGIVENESS_FINED}</b>{" "}
                    {FORGIVENESS_POLICY.REFUND_RENTAL}
                </BodyText>
                <BodyText
                    type="body"
                    size="medium"
                    color={colors.DEEP_BLUE_SEA}
                >
                    {FORGIVENESS_POLICY.NEED_HELP}{" "}
                    <LinkText
                        type="new_link"
                        size="medium"
                        href="https://www.topanga.io/reusepass-academy-setup/designing-your-reusepass-program"
                    >
                        ReusePass Academy
                    </LinkText>{" "}
                    {FORGIVENESS_POLICY.CLIENT_SUCCESS}
                </BodyText>
            </BodyWrapper>
            <ForgivenessCardWrapper>
                <InstantForgivenessCard
                    instantForgivenessOn={
                        organizationData.instantForgivenessEnabled
                    }
                    organizationId={organizationData.organizationId}
                    forgivenessSettingsChanged={forgivenessSettingsChanged}
                />
                <DivderLineDiv />
                <ReturnReceivedCard
                    returnReceivedForgivenessOn={
                        organizationData.returnReceivedForgivenessEnabled
                    }
                    organizationId={organizationData.organizationId}
                    forgivenessSettingsChanged={forgivenessSettingsChanged}
                />
            </ForgivenessCardWrapper>
            <FootText type="body" size="medium" color={colors.DEEP_BLUE_SEA}>
                {FORGIVENESS_POLICY.FORGIVENESS_POLICY_TECH_SUPPORT}
                <EmailMessage
                    email="techsupport@topanga.io"
                    subject={`Rental Forgiveness Policy`}
                    body=""
                >
                    {"techsupport@topanga.io"}
                </EmailMessage>
            </FootText>
        </>
    );
}
