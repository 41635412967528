import React, { useEffect, useState } from "react";
import useGraphClient from "../../hooks/useGraphClient";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import DeprecatedText from "../common/DeprecatedText";
import Button from "../common/Button";
import ScreenView from "../common/ScreenView";
import styled from "styled-components/macro";
import { isPasswordInvalid } from "../../utils/validation";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { findTextStyles } from "../../styling/styled-components";
import { ScanAppUserSettings } from "../../API";
import { API_ERROR } from "../../constants/errors";
import { UPDATE_SCAN_APP_USER_PASSWORD } from "../../constants/scanapp";
import BackHeader from "../common/BackHeader";
import ConfirmationModal from "../common/ConfirmationModal";
import FormTextField from "../common/FormTextField";
import { toastSuccess, toastError } from "../../utils/toasts";

const StyledScreenView = styled(ScreenView)``;

const StyledSaveButton = styled(Button)``;

const HeaderWrapper = styled.div`
    padding-top: 0px;
    padding-bottom: ${({ theme }) => theme.spacing.xlarge}px;
`;

const Section = styled.div`
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xxlarge}px;
    margin: 3px;
`;

const CheckboxSection = styled(Section)`
    margin-top: ${({ theme }) => theme.spacing.small}px;
    padding: ${({ theme }) => theme.spacing.medium}px
        ${({ theme }) => theme.spacing.xlarge}px;
`;

const StyledTextInput = styled(FormTextField)`
    flex: 1;
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const LogoutWrapper = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
`;

const CheckWrapper = styled.div``;

const CharcoalText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const LogoutPromptText = styled(CharcoalText)`
    padding-left: ${({ theme }) => theme.spacing.small}px;
`;

const PromptText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ASH};
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const ContentWrapper = styled.div`
    width: 610px;
    margin: 0 auto;
`;

const LabelText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

const HintText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const ErrorText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.RED_2};
`;

export default function UpdateScanAppUserPassword() {
    const [password, setPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [logUsersOut, setLogUsersOut] = useState<boolean>(false);
    const [getUserLoading, setGetUserLoading] = useState<boolean>(false);
    const [user, setUser] = useState<ScanAppUserSettings>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const navigate = useNavigate();
    const { userId } = useParams();

    const graphClient = useGraphClient();

    const getUser = async (userId: string): null => {
        setGetUserLoading(true);
        try {
            const results = await graphClient.graphql({
                query: queries.getScanAppUser,
                variables: {
                    userId,
                },
            });

            const user = results?.data?.getScanAppUser;
            setUser(user);
            setGetUserLoading(false);
        } catch (err) {
            setGetUserLoading(false);
            console.error("Error getting ScanApp user", err);
            toastError(API_ERROR);
        }
    };

    useEffect(() => {
        if (userId) {
            getUser(userId);
        }
    }, [userId]);

    const handleSave = async (): null => {
        setSaveLoading(true);
        try {
            await graphClient.graphql({
                query: mutations.updateScanAppGroupUserPassword,
                variables: {
                    input: {
                        userId,
                        password,
                        globalSignOut: logUsersOut,
                    },
                },
            });
            toastSuccess(UPDATE_SCAN_APP_USER_PASSWORD.SAVE_MESSAGE);
            setSaveLoading(false);
            navigate("/dashboard/program-settings/scan-app");
        } catch (err) {
            setSaveLoading(false);
            if ("InvalidPasswordException" == err.errors[0].errorType) {
                toastError(UPDATE_SCAN_APP_USER_PASSWORD.PASSWORD_ERROR);
                setPasswordError(UPDATE_SCAN_APP_USER_PASSWORD.PASSWORD_ERROR);
            } else {
                console.error("Error updating ScanApp user password", err);
                toastError(API_ERROR);
            }
        }
    };

    const handleCancel = (): null => {
        resetForm();
        navigate("/dashboard/program-settings/scan-app");
    };

    const resetForm = (): null => {
        setPasswordError("");
        setPassword("");
        setLogUsersOut(false);
    };

    const handlePasswordChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setPassword(text);
        setPasswordError("");
    };

    const allowPasswordToSave = () => {
        return !isPasswordInvalid(password) && password.length >= 8;
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleBlur = () => {
        if (isPasswordInvalid(password) || password.length < 8) {
            setPasswordError(UPDATE_SCAN_APP_USER_PASSWORD.PASSWORD_ERROR);
        }
    };

    return (
        <StyledScreenView
            shouldShowCounter={false}
            title={
                <BackHeader
                    onClick={password.length ? openModal : handleCancel}
                    label={UPDATE_SCAN_APP_USER_PASSWORD.CANCEL}
                />
            }
            headerRight={
                <StyledSaveButton
                    type={"primary"}
                    status={allowPasswordToSave() ? "default" : "disabled"}
                    label={UPDATE_SCAN_APP_USER_PASSWORD.SAVE}
                    onClick={handleSave}
                    isLoading={saveLoading}
                />
            }
        >
            <ConfirmationModal
                isOpen={isModalOpen}
                title={UPDATE_SCAN_APP_USER_PASSWORD.CANCEL_TITLE}
                subtitle={UPDATE_SCAN_APP_USER_PASSWORD.CANCEL_SUBTITLE}
                onConfirm={handleCancel}
                onCancel={closeModal}
            />
            <ContentWrapper>
                <HeaderWrapper>
                    <CharcoalText type="header" size="large">
                        {UPDATE_SCAN_APP_USER_PASSWORD.HEADER}
                    </CharcoalText>
                    <CharcoalText type="header" size="medium">
                        {UPDATE_SCAN_APP_USER_PASSWORD.USERNAME(user?.username)}
                    </CharcoalText>
                </HeaderWrapper>
                <PromptText type="header" size="small">
                    {UPDATE_SCAN_APP_USER_PASSWORD.PROMPT}
                </PromptText>
                <Section>
                    <StyledTextInput
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder={
                            UPDATE_SCAN_APP_USER_PASSWORD.PASSWORD_PLACEHOLDER
                        }
                        hasError={!!passwordError}
                        onBlur={handleBlur}
                        maxLength={40}
                        secureTextEntry={false}
                        autoCorrect={false}
                        autoCapitalize="none"
                        returnKeyType="done"
                        hintText={UPDATE_SCAN_APP_USER_PASSWORD.PASSWORD}
                        error={passwordError}
                        infoText={UPDATE_SCAN_APP_USER_PASSWORD.PASSWORD_HELP}
                        showCounter={false}
                    />
                </Section>
                <CheckboxSection>
                    <LogoutWrapper>
                        <CheckWrapper>
                            <Checkbox
                                checked={logUsersOut}
                                onChange={() => setLogUsersOut(!logUsersOut)}
                            />
                        </CheckWrapper>
                        <LogoutPromptText type="label" size="medium">
                            {UPDATE_SCAN_APP_USER_PASSWORD.LOGOUT_HELP}
                        </LogoutPromptText>
                    </LogoutWrapper>
                </CheckboxSection>
            </ContentWrapper>
        </StyledScreenView>
    );
}
