//react
import React from "react";

//component
import DeprecatedText from "../common/DeprecatedText";
import HighlightHover from "./HighlightHover";

//images
import { ReactComponent as BackChevron } from "../../assets/vectors/BackChevron.svg";

//style
import styled from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";

const Container = styled.div`
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    display: flex;
`;

const ChevronWrapper = styled.div`
    margin-left: -6px;
`;

const LabelText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.WAVE_STORM};
    padding-left: ${({ theme }) => theme.spacing.medium}px;
    margin-top: -4px;
`;

type Props = {
    onClick: () => void;
    label: string;
};

export default function BackHeader({ onClick, label, ...props }: Props) {
    return (
        <Container onClick={onClick} {...props}>
            <ChevronWrapper>
                <HighlightHover>
                    <BackChevron />
                </HighlightHover>
            </ChevronWrapper>
            <LabelText type="label" size="large">
                {label}
            </LabelText>
        </Container>
    );
}
