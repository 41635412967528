//components
import RentalShimmerCard from "./common/RentalShimmerCard";

//styles
import styled from "../../../styling/styled-components";

//images
import { ReactComponent as RentalProfile } from "../../../assets/vectors/RentalProfile.svg";
import { ReactComponent as RightArrow } from "../../../assets/vectors/RightArrow.svg";

const Details = styled.div``;

const ProfileBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    padding-left: ${({ theme }) => theme.spacing.small}px;
    padding-top: ${({ theme }) => theme.spacing.small}px;
    padding-right: ${({ theme }) => theme.spacing.yuge}px;
    margin-right: ${({ theme }) => theme.spacing.yuge}px;
`;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const ArrowWrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing.small}px;
`;

export default function ConsumerProfileHeader() {
    return (
        <>
            <Details>
                <ProfileContainer>
                    <RentalProfile />
                    <ProfileBox>
                        <RentalShimmerCard></RentalShimmerCard>
                    </ProfileBox>
                    <ArrowWrapper>
                        <RightArrow></RightArrow>
                    </ArrowWrapper>
                </ProfileContainer>
            </Details>
        </>
    );
}
