import React, { useContext } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { ReactComponent as Logo } from "../../assets/vectors/Logo.svg";
import DeprecatedText from "../common/DeprecatedText";
import styled, { css, useTheme } from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";
import GlobalContext from "../../contexts/GlobalContext";
import "@fontsource/lato";

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
}

const StyledSidebarHeader = styled.div`
    height: 64px;
    min-height: 64px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding: 0 20px;
    > div {
        width: 100%;
        overflow: hidden;
    }
`;

const TopangaText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    font-family: Lato;
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

const StyledText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
`;

const StyledLogo = styled.div<{ rtl?: boolean }>`
    width: 35px;
    min-width: 35px;
    height: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: white;
    font-size: 24px;
    font-weight: 700;
    background-color: #009fdb;
    background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
    ${({ rtl }) =>
        rtl
            ? `
      margin-left: 10px;
      margin-right: 4px;
      `
            : `
      margin-right: 10px;
      margin-left: 4px;
      `}
`;

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
    children,
    ...rest
}) => {
    const context = useContext(GlobalContext);
    const { organizationName } = context.globalSelections;
    const { rtl, collapseSidebar } = useProSidebar();
    return (
        <StyledSidebarHeader {...rest}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Logo
                    onClick={() => collapseSidebar()}
                    style={{ width: 40, minWidth: 40, cursor: "pointer" }}
                />
                <div style={{ marginLeft: 10 }}>
                    <TopangaText type="header" size="xlarge">
                        Topanga.io
                    </TopangaText>
                </div>
            </div>
        </StyledSidebarHeader>
    );
};
