// react
import React, { useState, useEffect } from "react";

// style
import styled, { useTheme } from "../../../styling/styled-components";

// API
import { RentalStepStatus } from "../../../API";

// components
import Card from "../../common/card/Card";
import Text from "../../common/Text";
import CardHeader from "./common/CardHeader";
import CardSMS from "./common/CardSMS";
import { RightHalfCard } from "../../common/card/RightHalfCard";

// utils
import {
    colorChange,
    fontChange,
    fontChangeDate,
    removeDuplicateIds,
    findTriggerTime,
} from "./utils/helpers";
import { checkBorderColor } from "../../../utils/rentalOverlay/checkBorderColor";

//constant
import { RentalPhase } from "../../../API";
import { EXPIRED_CARD_STRINGS } from "../../../constants/rentalOverlay/rentalCards";

const DescriptionText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.large}px;
`;

//TODO: refactor FinedCard to reduce props TEC-1993
export default function FinedCard({
    rentalConfigStep,
    finedConfig,
    finedNotification,
    loop,
    notifications,
    notificationConfig,
    lastPhase,
    triggerAt,
}) {
    const { colors } = useTheme();
    const [triggerTime, setTriggerTime] = useState();
    const [sentBool, setSentBool] = useState(false);

    useEffect(() => {
        setTriggerTime(
            findTriggerTime(
                notifications,
                RentalPhase.Fined,
                RentalPhase.Failed,
                RentalPhase.Expired
            )
        );
        statusBoolNotifications();
    }, [notifications, triggerTime]);

    const checkDescription = (item) => {
        if (item.description !== "") {
            return item.description;
        }
    };

    const statusBool = () => {
        return (
            rentalConfigStep.status !== RentalStepStatus.Skipped &&
            rentalConfigStep.status !== RentalStepStatus.Pending
        );
    };

    const statusBoolNotifications = () => {
        notifications.map((item) => {
            if (
                (item.rentalPhase == RentalPhase.Fined ||
                    item.rentalPhase == RentalPhase.Expired ||
                    item.rentalPhase == RentalPhase.Failed) &&
                item.status == "NOTIFICATION_SENT"
            ) {
                setSentBool(true);
            }
        });
    };

    const SMSMessage = () => {
        return removeDuplicateIds(notificationConfig).map((item, index) => {
            if (
                item.rentalPhase == RentalPhase.Failed ||
                item.rentalPhase == RentalPhase.Fined ||
                item.rentalPhase == RentalPhase.Expired
            ) {
                return (
                    <div key={`${index}.fined`}>
                        <CardSMS
                            sent={sentBool}
                            name={item.name}
                            headerColor={fontChange(statusBool(), colors)}
                            dateColor={fontChangeDate(statusBool(), colors)}
                            date={triggerTime}
                            description={checkDescription(item)}
                            paddingTop="28px"
                        ></CardSMS>
                    </div>
                );
            } else {
                return;
            }
        });
    };

    return (
        <Card
            backgroundColor={colorChange(statusBool(), colors)}
            borderColor={checkBorderColor(
                rentalConfigStep.rentalPhase,
                lastPhase
            )}
        >
            <CardHeader
                header={EXPIRED_CARD_STRINGS.HEADER}
                date={triggerAt}
                colorHeader={fontChange(statusBool(), colors)}
                colorDate={fontChangeDate(statusBool(), colors)}
            ></CardHeader>
            <RightHalfCard>
                <DescriptionText
                    type="body"
                    size="medium"
                    color={fontChange(statusBool(), colors)}
                >
                    {EXPIRED_CARD_STRINGS.DESCRIPTION}
                </DescriptionText>
                {notifications.length == 0 ? <></> : <SMSMessage></SMSMessage>}
            </RightHalfCard>
        </Card>
    );
}
