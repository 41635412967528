// external
import React, { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";

// style
import styled, { useTheme } from "../../styling/styled-components";

//images
import {
    BlueDottedCircle,
    GreenCheck,
    GreyX,
    RedHourglass,
    ForgivenHeart,
    GreyWhiteX,
} from "../common/IconWithShadow";

// graphql
import useGraphClient from "../../hooks/useGraphClient";
import queryString from "query-string";
import { searchRentalsActivity } from "../../graphql/customQueries/searchRentalsActivity";

// components
import LinkText from "../common/LinkText";
import Text from "../common/Text";
import SectionTitle from "../common/overlay/SectionTitle";

// utils
import { isEmpty, convertDate, prettifyTime } from "../../utils/helpers";
import { deletePropFromSearchParams } from "../../utils/activity/deletePropFromSearchParams";

//constants
import { CONSUMER_OVERLAY } from "../../constants/consumers";
import { RentalStatus } from "../../API";

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const RentalBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const StatusText = styled(Text)`
    position: relative;
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const CountText = styled(Text)`
    margin-left: auto;
`;

const LastUpdatedText = styled(Text)`
    margin-left: auto;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    padding-right: ${({ theme }) => theme.spacing.medium}px;
`;

const Wrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.huge}px;
`;

const RentalStatusActivityDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #f5f6f8;
    border-radius: 4px;
    width: 410px;
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding: 16px ${({ theme }) => theme.spacing.medium}px;
    margin-top: ${({ theme }) => theme.spacing.small}px;
`;

export default function RentalActivity({ consumer, consumerUpdatedAt }) {
    const [dataLoading, setDataLoading] = useState(false);
    const [canceledCount, setCanceledCount] = useState(0);
    const [expiredCount, setExpiredCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [progressCount, setProgressCount] = useState(0);
    const [refundCount, setRefundCount] = useState(0);
    const [forgivenCount, setForgivenCount] = useState(0);
    const [failedCount, setFailedCount] = useState(0);

    const { colors } = useTheme();
    const dates = [convertDate(new Date()), convertDate(consumer.createdAt)];

    const statusDetails = [
        { Icon: BlueDottedCircle, label: "In-progress", count: progressCount },
        { Icon: GreenCheck, label: "Completed", count: completedCount },
        { Icon: ForgivenHeart, label: "Forgiven", count: forgivenCount },
        {
            Icon: RedHourglass,
            label: "Failed",
            count: expiredCount + failedCount,
        },
        { Icon: GreyX, label: "Canceled", count: canceledCount },
        {
            Icon: GreyWhiteX,
            label: "Canceled (with refund)",
            count: refundCount,
        },
    ];

    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const graphClient = useGraphClient();

    useEffect(() => {
        if (isEmpty(consumer)) {
            return;
        } else {
            setProgressCount(0);
            setCompletedCount(0);
            setForgivenCount(0);
            setExpiredCount(0);
            setFailedCount(0);
            setCanceledCount(0);
            setRefundCount(0);
            loadDataActivity();
        }
    }, [consumer.consumerId]);

    const loadDataActivity = async () => {
        setDataLoading(true);
        try {
            const results = await graphClient.graphql({
                query: searchRentalsActivity,
                variables: {
                    fromTimestamp: new Date(dates[1]).toISOString(),
                    toTimestamp: new Date(dates[0]).toISOString(),
                    consumerId: consumer.consumerId,
                },
            });

            const consumerHitAggregationsByStatus =
                results.data.searchRentals.hitAggregations;

            if (consumerHitAggregationsByStatus) {
                consumerHitAggregationsByStatus.map((item, index) => {
                    if (item.key == RentalStatus.Canceled) {
                        setCanceledCount(item.docCount);
                    } else if (item.key == RentalStatus.Expired) {
                        setExpiredCount(item.docCount);
                    } else if (item.key == RentalStatus.Failed) {
                        setFailedCount(item.docCount);
                    } else if (item.key == RentalStatus.Completed) {
                        setCompletedCount(item.docCount);
                    } else if (item.key == RentalStatus.InProgress) {
                        setProgressCount(item.docCount);
                    } else if (item.key == RentalStatus.CanceledWithRefund) {
                        setRefundCount(item.docCount);
                    } else if (item.key == RentalStatus.Forgiven) {
                        setForgivenCount(item.docCount);
                    }
                });
            }
            setDataLoading(false);
        } catch (error) {
            console.error("Error searching rental activity", error);
            setDataLoading(false);
        }
    };

    const handleLinkNavigation = () => {
        const updatedSearchParams = deletePropFromSearchParams(
            queryParams,
            searchParams
        );
        setSearchParams(updatedSearchParams);
        window.location.href =
            "/dashboard/activity/rentals/" + `?prop=${searchParams}`;
    };

    return (
        <RentalBox>
            <Header>
                <SectionTitle color={colors.ASH}>
                    {CONSUMER_OVERLAY.RENTAL_ACTIVITY_HEADER}
                </SectionTitle>
                <Wrapper>
                    <LinkText
                        type="new_link"
                        size="small"
                        onClick={handleLinkNavigation}
                    >
                        {CONSUMER_OVERLAY.SEE_RENTALS_LINK}
                    </LinkText>
                </Wrapper>
            </Header>
            {statusDetails.map((status, index) => {
                return (
                    <RentalStatusActivityDiv key={`${index}.status`}>
                        <status.Icon />
                        <StatusText
                            type="header"
                            size="little"
                            color={colors.DEEP_BLUE_SEA}
                        >
                            {status.label}
                        </StatusText>
                        <CountText
                            type="header"
                            size="medium"
                            color={colors.DEEP_BLUE_SEA}
                        >
                            {status.count}
                        </CountText>
                    </RentalStatusActivityDiv>
                );
            })}
            <LastUpdatedText type="label" size="small" color={colors.EXHAUST}>
                {CONSUMER_OVERLAY.LAST_UPDATED(prettifyTime(consumerUpdatedAt))}
            </LastUpdatedText>
        </RentalBox>
    );
}
