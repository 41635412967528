//components
import SideLine from "../../common/SideLine";
import RentalLoadingSubSection from "./RentalLoadingSubSection";
import ConsumerProfileHeader from "./ConsumerProfileHeader";
import StatusDetailsCard from "./StatusDetailsCard";

//styles
import styled from "../../../styling/styled-components";

const OverviewBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    width: 440px;
    padding-top: ${({ theme }) => theme.spacing.huge}px;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

export default function RentalDetailsLoading({ rental }) {
    const subSectionDetails = [
        {
            title: "Asset Type",
            description: "—",
        },
        {
            title: "Date Received",
            description: "—",
        },

        {
            title: "Return Criteria",
            description: "—",
        },
    ];

    return (
        <OverviewBox>
            {rental ? (
                <>
                    <SideLine />
                    <ConsumerProfileHeader></ConsumerProfileHeader>
                    <SideLine />
                    <RentalLoadingSubSection
                        sectionDetails={subSectionDetails}
                    ></RentalLoadingSubSection>
                    <StatusDetailsCard rental={rental}></StatusDetailsCard>
                    <SideLine />
                </>
            ) : (
                <></>
            )}
        </OverviewBox>
    );
}
