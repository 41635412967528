//react
import { useEffect, useState } from "react";

//style
import styled, { useTheme } from "../../styling/styled-components";

//components
import Text from "../common/Text";
import { LocationInputForm } from "./common/LocationInputField";
import { ButtonDiv } from "./common/ButtonDiv";
import { OverlayWrapper } from "./common/OverlayWrapper";
import { NextButton } from "./common/NextButton";
import Select from "react-select";

//utils
import { checkUniqueParentName } from "../../utils/locations/checkUniqueParentName";
import { checkStreetAddress } from "../../utils/locations/checkStreetAddress";

const COUNTRY_OPTIONS = [
    { value: "USA", label: "United States of America" },
    { value: "Canada", label: "Canada" },
];

const DropDownDiv = styled.div`
    display: flex;
    height: ${({ theme }) => theme.spacing.xxlarge}px;
    padding: ${({ theme }) => theme.spacing.small}px;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
`;

const CountryDiv = styled.div`
    height: ${({ theme }) => theme.spacing.yuge}px;
    padding-top: ${({ theme }) => theme.spacing.yuge}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const CountryText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const Wrapper = styled.div`
    width: 336px;
    padding-left: ${({ theme }) => theme.spacing.huge}px;
    margin-left: ${({ theme }) => theme.spacing.xxlarge}px;
    &:hover {
        color: ${({ theme }) => theme.colors.RED_3};
    }
`;

export default function AddLocationStepOne({
    clickContinueButton,
    addressDetails,
    setParentLocationDetails,
    listLocationsData,
}) {
    const { colors, spacing } = useTheme();
    const [showErrorState, setShowErrorState] = useState(false);
    const [showErrorStreetState, setShowErrorStreetState] = useState(false);
    const [checkBlur, setCheckBlur] = useState(false);

    useEffect(() => {
        setShowErrorState(
            checkUniqueParentName(listLocationsData, addressDetails.parentName)
        );
    }, [addressDetails.parentName]);

    useEffect(() => {
        if (checkBlur) {
            setShowErrorStreetState(
                checkStreetAddress(addressDetails.streetAddress)
            );
        }
    }, [addressDetails.streetAddress]);

    const handleBlur = () => {
        setCheckBlur(true);
        setShowErrorStreetState(
            checkStreetAddress(addressDetails.streetAddress)
        );
    };

    return (
        <>
            <div
                style={{
                    position: "relative",
                    height: 440, //scroll not working with % on the new overlay component.
                    overflowY: "scroll",
                }}
            >
                <OverlayWrapper>
                    <LocationInputForm
                        infoText="Parent Location Name"
                        width="medium"
                        infoStyle={{
                            color: colors.ASH,
                        }}
                        value={addressDetails.parentName}
                        onChange={(e) =>
                            setParentLocationDetails(
                                e.target.value,
                                "parentName"
                            )
                        }
                        error={
                            "That name already exists. Please enter a unique parent location name. "
                        }
                        errorStyle={{
                            color: colors.ASH,
                        }}
                        hasError={showErrorState ? true : false}
                        maxLength={150}
                    ></LocationInputForm>
                    <LocationInputForm
                        width="medium"
                        infoText="Street Address"
                        onBlur={handleBlur}
                        infoStyle={{
                            color: colors.ASH,
                            paddingTop: spacing.tiny,
                        }}
                        value={addressDetails.streetAddress}
                        onChange={(e) =>
                            setParentLocationDetails(
                                e.target.value,
                                "streetAddress"
                            )
                        }
                        error={
                            "That street address is invalid. Make sure it is entered correctly and try again. "
                        }
                        hasError={showErrorStreetState ? true : false}
                    ></LocationInputForm>
                    <LocationInputForm
                        width="medium"
                        infoText="Floor, suite, etc. (optional)"
                        infoStyle={{ color: colors.ASH }}
                        value={addressDetails.streetAddress2}
                        onChange={(e) =>
                            setParentLocationDetails(
                                e.target.value,
                                "streetAddress2"
                            )
                        }
                    ></LocationInputForm>
                    <LocationInputForm
                        width="medium"
                        hintText="City, State or Province"
                        subHintText="Abbreviate the state or province (eg. 'Brooklyn, NY')"
                        subHintStyle={{ color: colors.EXHAUST }}
                        hintStyle={{ color: colors.ASH }}
                        value={addressDetails.state}
                        onChange={(e) =>
                            setParentLocationDetails(e.target.value, "state")
                        }
                    ></LocationInputForm>
                    <LocationInputForm
                        width="medium"
                        infoText="ZIP or Postal Code"
                        infoStyle={{ color: colors.ASH }}
                        value={addressDetails.postalCode}
                        onChange={(e) =>
                            setParentLocationDetails(
                                e.target.value,
                                "postalCode"
                            )
                        }
                    ></LocationInputForm>
                    <DropDownDiv>
                        <Select
                            styles={{
                                option: (styles, state) => ({
                                    ...styles,
                                }),
                                placeholder: (defaultStyles) => {
                                    return {
                                        ...defaultStyles,
                                        color: colors.DEEP_BLUE_SEA,
                                    };
                                },
                                indicatorSeparator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: "transparent",
                                    color: colors.DEEP_BLUE_SEA,
                                    cursor: "pointer",
                                }),
                                container: (baseStyles, state) => ({
                                    //tried replacing with spacing themes, but only takes numbers
                                    ...baseStyles,
                                    fontFamily: "poppins",
                                    fontStyle: "normal",
                                    letterSpacing: "0.13px",
                                    fontSize: 13,
                                    fontWeight: 400,
                                    color: colors.DEEP_BLUE_SEA,
                                    width: 444,
                                    height: 48,
                                    cursor: "pointer",
                                    borderRadius: "4px",
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: colors.BLANK_SLATE,
                                    height: 48,
                                    border: "0.5px solid var(--greys-marine-layer, #c2cfe0)",
                                    zIndex: 10,
                                    display: "flex",
                                    color: colors.DEEP_BLUE_SEA,
                                    cursor: "pointer",
                                }),
                                dropdownIndicator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    svg: {
                                        cursor: "pointer",
                                        transform: state.selectProps.menuIsOpen
                                            ? "rotate(180deg)"
                                            : null,
                                    },
                                }),
                            }}
                            placeholder={addressDetails.country}
                            options={COUNTRY_OPTIONS}
                            isSearchable={false}
                            onChange={(e) =>
                                setParentLocationDetails(e.value, "country")
                            }
                            value={addressDetails.country}
                            isClearable={false}
                        />
                    </DropDownDiv>
                    <CountryText type="label" size="medium" color={colors.ASH}>
                        Country
                    </CountryText>
                    <CountryDiv></CountryDiv>
                </OverlayWrapper>
            </div>
            <ButtonDiv height={"true"}>
                <Wrapper>
                    <NextButton
                        type="primary"
                        onClick={clickContinueButton}
                        label={
                            <Text
                                type="label"
                                size="large"
                                color={colors.BLANK_SLATE}
                            >
                                {"Continue"}
                            </Text>
                        }
                        status={
                            addressDetails.parentName &&
                            !showErrorStreetState &&
                            !showErrorState
                                ? "default"
                                : "disabled"
                        }
                    ></NextButton>
                </Wrapper>
            </ButtonDiv>
        </>
    );
}
