//react
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

//components
import AssetCard from "./AssetCard";
import ScreenView from "../common/ScreenView";
import LocationsContext from "../../contexts/LocationsContext";
import ClipLoader from "react-spinners/ClipLoader";
import AssetsContext from "../../contexts/AssetsContext";
import HighlightHover from "../common/HighlightHover";

//styles
import styled, { useTheme } from "styled-components/macro";

//apis
import * as queries from "../../graphql/queries";
import useGraphClient from "../../hooks/useGraphClient";

//constants
import { ASSET_TYPES_LIST } from "../../constants/assets";
import LinkText from "../common/LinkText";

const DesktopWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const AssetTypesListWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Footer = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.yuge}px;
`;

const LoadMore = styled.div`
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.BLUE};
    padding-vertical: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    margin: ${({ theme }) => theme.spacing.xlarge}px 0;
    align-self: center;
`;

const LoadMoreText = styled.div`
    color: ${({ theme }) => theme.colors.BLUE};
`;

const NewAssetWrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: ${({ theme }) => theme.spacing.medium}px
        ${({ theme }) => theme.spacing.large}px;
    margin-top: ${({ theme }) => theme.spacing.small}px;
`;

const PlusIcon = styled.div`
    color: ${({ theme }) => theme.colors.BLUE};
`;

export default function AssetTypes() {
    const [selectedPaginationToken, setSelectedPaginationToken] = useState();
    const [listLoading, setListLoading] = useState(false);
    const navigate = useNavigate();
    const { colors } = useTheme();
    const context = useContext(LocationsContext);
    const { locationId } = context.selections;
    const { selections, updateSelections } = useContext(AssetsContext);

    const graphClient = useGraphClient();

    const loadAssetTypes = async (thisToken?: string) => {
        setListLoading(true);

        try {
            const results = await graphClient.graphql({
                query: queries.listAssetTypes,
            });

            updateSelections({ assets: results.data.listAssetTypes.results });

            setListLoading(false);
        } catch (err) {
            console.error("Error listing AssetTypes", err);
            setListLoading(false);
        }
    };

    return (
        <>
            {
                <ScreenView
                    screenName="ASSET_TYPES"
                    counter={`${selections.assets.length}${
                        selectedPaginationToken ? "+" : ""
                    }`}
                >
                    {
                        <DesktopWrapper>
                            <NewAssetWrapper onClick={() => navigate("new")}>
                                <HighlightHover>
                                    <PlusIcon type="other" size="large">
                                        +
                                    </PlusIcon>
                                </HighlightHover>
                                <LinkText
                                    type="new_link"
                                    size="large"
                                    color={colors.WAVE_STORM}
                                >
                                    {ASSET_TYPES_LIST.ADD_ASSET_TYPE}
                                </LinkText>
                            </NewAssetWrapper>
                            <AssetTypesListWrapper>
                                {selections.assets.map((item) => {
                                    return (
                                        <AssetCard
                                            assetData={item}
                                            key={item.assetTypeId}
                                            loadAssetTypes={loadAssetTypes}
                                        />
                                    );
                                })}
                                <Footer>
                                    {selectedPaginationToken ? (
                                        <LoadMore
                                            onClick={() =>
                                                loadAssetTypes(
                                                    selectedPaginationToken
                                                )
                                            }
                                        >
                                            {listLoading ? (
                                                <ClipLoader
                                                    color={colors.BLUE}
                                                    size={10}
                                                    loading
                                                />
                                            ) : (
                                                <LoadMoreText
                                                    type="body"
                                                    size="large"
                                                >
                                                    {ASSET_TYPES_LIST.LOAD_MORE}
                                                </LoadMoreText>
                                            )}
                                        </LoadMore>
                                    ) : null}
                                </Footer>
                            </AssetTypesListWrapper>
                        </DesktopWrapper>
                    }
                </ScreenView>
            }
        </>
    );
}
