//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import DaysLabelText from "./DaysLabelText";
import GrayCircle from "../../common/GrayCircle";

//constants
import { CYCLE_STRINGS } from "../../../constants/cyclerate";

const DaysText = styled(Text)`
    padding-top: 8px;
    padding-left: 1px;
`;

const CycleRateWrapper = styled.div`
    text-align: center;
    padding-left: ${({ theme }) => theme.spacing.large}px;
`;

const DaysTextWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.large}px;
    margin-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

export default function CycleRateDuration({ cycleRateData }: Props) {
    const { colors } = useTheme();
    let loopDuration = cycleRateData
        ? cycleRateData.isAvailable
            ? Math.round(cycleRateData.loopDuration)
            : "—"
        : "";
    let loopDurationUnit = cycleRateData
        ? cycleRateData.isAvailable
            ? cycleRateData.loopDurationUnit.toUpperCase()
            : "Days"
        : "";

    return (
        <CycleRateWrapper>
            <DaysTextWrapper>
                <GrayCircle height={40} width={40}>
                    <DaysText
                        type="header"
                        size="medium"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {loopDuration}
                    </DaysText>
                </GrayCircle>
            </DaysTextWrapper>
            <DaysLabelText>{loopDurationUnit}</DaysLabelText>
            <Text type="header" size="small" color={colors.BLACK}>
                {CYCLE_STRINGS.DAYS_RETURN}
            </Text>
        </CycleRateWrapper>
    );
}
