import { COLORS } from "../../../styling/colors";

export const bluePointStyles = () => {
    return {
        id: "1",
        pointStyle: "circle",
        fill: "start",
        backgroundColor: "transparent",
        pointBackgroundColor: COLORS.BLANK_SLATE,
        pointHoverBackgroundColor: COLORS.DEEP_BLUE_SEA,
        borderColor: COLORS.DEEP_BLUE_SEA,
    };
};
