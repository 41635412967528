//style
import styled from "../../../styling/styled-components";

//component
import Text from "../../common/Text";

const HeaderText = styled(Text)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
    position: relative;
    width: 200px;
`;

export default function Header({ children, color, ...props }: Props) {
    return (
        <HeaderText type="header" size="large" color={color}>
            {children}
        </HeaderText>
    );
}
