export const CONSUMER_DETAIL = {
    BACK: "Back to all consumers",
    HEADER: "Consumer Details",
    CONSUMER_ID: (consumerId: string) => `Consumer ID: ${consumerId}`,
};

export const CONSUMER_GLANCE = {
    PROFILE: "Profile",
    NAME: (name?: string) => `Name: ${name || ""}`,
    NUMBER: (number?: string) => `Number: ${number || ""}`,
    EXTERNALID: "External ID",
};

export const CONSUMER_LIST = {
    ERROR: "Error loading data. Please try again.",
    NO_DATA: "No consumers found...",
    LOAD_MORE: "Load more...",
    NAME: "Name",
    PHONE_NUMBER: "Phone Number",
    EXTERNAL_ID: "External ID",
    EMAIL_ADDRESS: "Email Address",
    CONSUMER_ID: "Consumer ID",
    CREATED_AT: "Created",
};

export const CONSUMER_OVERLAY = {
    RENTAL_ACTIVITY_HEADER: " RENTAL ACTIVITY BY STATUS",
    SEE_RENTALS_LINK: "See all rentals",
    LAST_UPDATED: (time: string) => ` Last updated ${time}`,
};
