export const RENTAL_VISUAL = {
    HEADER: "BREAKDOWN BY CURRENT PHASE",
    FORGIVEN_POLICY: "View & edit policy",
    INSTANT_RECEIVED_ON:
        "Instant Forgiveness and Return Received Forgiveness are turned on.",
    INSTANT_ON_RECEIVED_OFF:
        "Instant Forgiveness is on. Return Received Forgiveness is off.",
    INSTANT_OFF_RECEIVED_ON:
        "Return Received Forgiveness is on. Instant Forgiveness is off. ",
    INSTANT_OFF_RECEIVED_OFF:
        "Instant Forgiveness and Return Received Forgiveness are turned off.",
};
