import { StatWindow } from "../../../API";

export const DATE_RANGE_ITEMS = [
    {
        value: "-7",
        label: "Last 7 days",
        selected: false,
    },
    {
        value: "-14",
        label: "Last 14 days",
        selected: false,
    },
    {
        value: "-30",
        label: "Last 30 days",
        selected: true,
    },
    {
        value: "-90",
        label: "Last 3 months",
        selected: false,
    },
    {
        value: "-180",
        label: "Last 6 months",
        selected: false,
    },
];

export const STAT_WINDOW_ITEMS = [
    {
        value: "-7",
        label: StatWindow.D_7,
    },
    {
        value: "-14",
        label: StatWindow.D_14,
    },
    {
        value: "-30",
        label: StatWindow.D_30,
    },
    {
        value: "-90",
        label: StatWindow.M_3,
    },
    {
        value: "-180",
        label: StatWindow.M_6,
    },
];
