import { useState, useEffect } from "react";

//style
import styled, { useTheme } from "../../../styling/styled-components";

//apis
import * as queries from "../../../graphql/queries";
import useGraphClient from "../../../hooks/useGraphClient";
import { graphErrorDetails } from "../../../utils/helpers";
import { getCompleteRentalStepConfig } from "../../../graphql/customQueries/getRentalConfig";

//components
import Text from "../../common/Text";
import InitiatedCard from "./InitiatedCard";
import DueOnCard from "./DueOnCard";
import LatePendingCard from "./LatePendingCard";
import LateCard from "./LateCard";
import AboutToExpireCard from "./AboutToExpireCard";
import FinedCard from "./FinedCard";
import CompleteCard from "./CompleteCard";
import CanceledRefundCard from "./CanceledRefundCard";
import FailedCard from "./FailedCard";
import CanceledCard from "./CanceledCard";
import ShimmerCard from "./ShimmerCard";
import ProgressBar from "./ProgressBar";
import Loadable from "../../common/Loadable";
import FinedEligibleCard from "./FinedEligibleCard";
import Displayable from "../../common/Displayable";
import FinedManualCard from "./FinedManualCard";
import FinedAutomaticCard from "./FinedAutomaticCard";
import FineErrorCard from "./FineErrorCard";
import ForgivenCard from "./ForgivenCard";

//enums
import {
    RentalStatus,
    RentalPhase,
    Rental,
    ForgivenessReason,
    ChargeStatus,
} from "../../../API";

const TimelineBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    margin-left: ${({ theme }) => theme.spacing.xmlarge}px;
    overflow-x: hidden;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const CardTimelineWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: ${({ theme }) => theme.spacing.small}px;
`;

const RentalEligibleFineCard = styled.div`
    padding-left: ${({ theme }) => theme.spacing.medium}px;
`;

type Props = {
    rental: Rental;
    onClickDisplay: () => void;
};

export default function RentalCard({ rental, onClickDisplay }: Props) {
    const { colors } = useTheme();
    const [loop, setLoop] = useState({});
    const [finedNotification, setFinedNotification] = useState([]);
    const [latePendingNotfication, setLatePendingNotification] = useState([]);
    const [lateNotification, setLateNotification] = useState([]);
    const [finePendingNotification, setFinePendingNotification] = useState([]);
    const [finedConfig, setFinedConfig] = useState({});
    const [latePendingConfig, setLatePendingConfig] = useState({});
    const [lateConfig, setLateConfig] = useState({});
    const [finePendingConfig, setFinePendingConfig] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [notificationConfig, setNotificationConfig] = useState([]);
    const [rentalStepList, setRentalStepList] = useState([]);
    const [rentalStepCheck, setRentalStepCheck] = useState(false);
    const [rentalStepCache, setRentalStepCache] = useState({});
    const [lastPhase, setLastPhase] = useState(rental?.lastPhase);
    const [chargeDetails, setChargeDetails] = useState({});
    let finedPhase = "";

    const graphClient = useGraphClient();

    useEffect(() => {
        setNotifications([]);
        setNotificationConfig([]);
        setFilteredData([]);
        setRentalStepList([]);
        setChargeDetails({});
        if (rental && notifications) {
            loadLoop();
            setLastPhase(rental?.lastPhase);
        }
    }, [rental.rentalId]);

    useEffect(() => {
        if (rental && notifications) {
            setRentalStepCheck(false);
            loadData();
        }
    }, [rental.rentalId, notifications]);

    useEffect(() => {
        if (rental) {
            if (rental.loopId !== chargeDetails?.loopId) {
                getChargeOnLoop();
            }
        }
    }, [rental.rentalId, rental.loopId]);

    const loadLoop = async () => {
        try {
            const result = await graphClient.graphql({
                query: queries.getLoop,
                variables: {
                    loopId: rental.loopId,
                },
            });
            const loop = result.data.getLoop;
            setLoop(loop);
            setTimeout(() => {
                setIsLoading(false);
            }, 1500);
        } catch (err) {
            console.error("Error getting loop", err);
        }
    };

    const getChargeOnLoop = async () => {
        try {
            const results = await graphClient.graphql({
                query: queries.getChargeByLoop,
                variables: {
                    loopId: rental.loopId,
                },
            });
            if (results) {
                setChargeDetails(results.data.getChargeByLoop);
            }
        } catch (error) {
            const [getChargeErrorType, _getChargeErrorMessage] =
                graphErrorDetails(error?.errors);
            console.error("Error getting charge", getChargeErrorType);
        }
    };

    const returnUpdatedAtTimestamp = () => {
        if (rental.status == RentalStatus.Canceled) {
            let canceledMetadata = rental.metadata;
            let canceledTimestamp = "";
            canceledMetadata.map((item, index) => {
                if (item.key == "canceledAt") {
                    canceledTimestamp = item.value;
                }
            });
            if (!canceledTimestamp) {
                return rental.updatedAt;
            } else {
                return canceledTimestamp;
            }
        } else {
            return rental.updatedAt;
        }
    };

    const loadData = async () => {
        let customCard = {
            rentalPhase: `${rental.lastPhase}`,
            status: rental.status,
            triggerAt: returnUpdatedAtTimestamp(),
            rentalStepConfigType: "PhaseChangeRentalStepConfig",
            forgivenessDetails: rental.forgivenessDetails,
        };
        let customExpiredCard = {
            rentalPhase: `${rental.lastPhase}`,
            status: RentalStatus.Expired,
            triggerAt: returnUpdatedAtTimestamp(),
            rentalStepConfigType: "PhaseChangeRentalStepConfig",
            forgivenessDetails: rental.forgivenessDetails,
        };

        const checkCanceledFinedPhasesComboRental =
            (customExpiredCard.rentalPhase == RentalPhase.FineError ||
                customExpiredCard.rentalPhase == RentalPhase.FinedManual ||
                customExpiredCard.rentalPhase == RentalPhase.FinedAutomatic ||
                customExpiredCard.rentalPhase == RentalPhase.Expired) &&
            rental.status == RentalStatus.Canceled;
        const checkCanceledWithRefundFinedPhasesComboRental =
            (customExpiredCard.rentalPhase == RentalPhase.FineError ||
                customExpiredCard.rentalPhase == RentalPhase.FinedManual ||
                customExpiredCard.rentalPhase == RentalPhase.FinedAutomatic ||
                customExpiredCard.rentalPhase == RentalPhase.Expired) &&
            rental.status == RentalStatus.CanceledWithRefund;

        setIsLoading(true);

        try {
            if (rentalStepList.length == 0) {
                setRentalStepCheck(false);
                const results = await graphClient.graphql({
                    query: queries.listRentalStepsForRental,
                    variables: {
                        rentalId: rental.rentalId,
                    },
                });

                const rentalStep = results.data.listRentalStepsForRental;
                rentalStep.push(customCard);
                setRentalStepList(rentalStep);

                //add conditional to check when Fined Manual/Fine Error have a status of Canceled or Canceled with Refund
                //needs to add an additional custom card in the rental steps to show the combination Fined Manual/Fine Error/Fined Automatic + Canceled/Canceled with Refund
                if (
                    checkCanceledFinedPhasesComboRental ||
                    checkCanceledWithRefundFinedPhasesComboRental
                ) {
                    rentalStep.push(customExpiredCard);
                }
                if (
                    rentalStep.length > 1 &&
                    !rentalStepCheck &&
                    notifications.length == 0
                ) {
                    rentalStep.map((item, index) => {
                        if (
                            item.rentalPhase == "FINED_CUSTOM" ||
                            item.rentalPhase == "FAILED_CUSTOM"
                        ) {
                            const failedOrFinedRentalStep = rentalStep.find(
                                (obj) =>
                                    obj.rentalPhase == RentalPhase.Fined ||
                                    obj.rentalPhase == RentalPhase.Failed
                            );
                            item.triggerAt = failedOrFinedRentalStep.triggerAt;
                        }
                        if (
                            item.status == RentalStatus.Failed ||
                            item.status == RentalStatus.Expired
                        ) {
                            const findExpiredPhase = rentalStep.find(
                                (obj) =>
                                    obj["rentalPhase"] == RentalPhase.Expired ||
                                    obj["rentalPhase"] == RentalPhase.Failed ||
                                    obj["rentalPhase"] == RentalPhase.Fined
                            );
                            item.triggerAt = findExpiredPhase.triggerAt;
                        }
                        if (item) {
                            loadRentalStep(item);
                        }
                        if (
                            item.rentalStepConfigType ==
                                "NotificationRentalStepConfig" &&
                            !notifications.some(
                                (notification) =>
                                    notification.rentalPhase == item.rentalPhase
                            )
                        ) {
                            notifications.push(item);
                        }
                    });

                    notifications.map((item, index) => {
                        if (item) {
                            loadNotifications(item);
                        } else {
                            return;
                        }
                    });

                    const filteredAndSorted = rentalStep
                        .filter((event) => event.triggerAt)
                        .filter(
                            (item, index) =>
                                item.rentalStepConfigType ==
                                "PhaseChangeRentalStepConfig"
                        )
                        .sort(
                            (a, b) =>
                                new Date(a.triggerAt) - new Date(b.triggerAt)
                        );
                    if (rental.status == RentalStatus.InProgress) {
                        const filteredArray = filteredAndSorted.filter(
                            (item, index, array) => {
                                return (
                                    index === 0 ||
                                    item.rentalPhase !==
                                        array[index - 1].rentalPhase
                                );
                            }
                        );
                        setFilteredData(filteredArray);
                    } else {
                        setFilteredData(filteredAndSorted);
                    }
                }
            }
        } catch (error) {
            console.error("Error in listing rental steps for rental", error);
        }
    };

    const loadRentalStep = async (item) => {
        try {
            const rentalCache = rentalStepCache[item.rentalStepConfigId];

            if (rentalCache || !item.rentalStepConfigId) {
                return;
            } else if (!rentalCache && item.rentalStepConfigId) {
                const results = await graphClient.graphql({
                    query: getCompleteRentalStepConfig,
                    variables: {
                        id: item.rentalStepConfigId,
                    },
                });

                setRentalStepCache((prevCache) => ({
                    ...prevCache,
                    [item.rentalStepConfigId]: rentalStepConfig,
                }));

                setRentalStepCheck(true);

                let rentalStepConfig = results.data.getRentalStepConfig;
                if (
                    rentalStepConfig.rentalPhase == RentalPhase.Fined ||
                    rentalStepConfig.rentalPhase == RentalPhase.Failed ||
                    rentalStepConfig.rentalPhase == RentalPhase.Expired
                ) {
                    setFinedConfig(rentalStepConfig);
                    return;
                }
                if (
                    rentalStepConfig.rentalPhase == RentalPhase.Late &&
                    rentalStepConfig.__typename == "PhaseChangeRentalStepConfig"
                ) {
                    setLateConfig(rentalStepConfig);
                    return;
                }
                if (
                    rentalStepConfig.rentalPhase == RentalPhase.LatePending &&
                    rentalStepConfig.__typename == "PhaseChangeRentalStepConfig"
                ) {
                    setLatePendingConfig(rentalStepConfig);
                    return;
                }
                if (
                    (rentalStepConfig.rentalPhase == RentalPhase.FinePending &&
                        rentalStepConfig.__typename ==
                            "PhaseChangeRentalStepConfig") ||
                    (rentalStepConfig.rentalPhase ==
                        RentalPhase.FailedPending &&
                        rentalStepConfig.__typename ==
                            "PhaseChangeRentalStepConfig") ||
                    (rentalStepConfig.rentalPhase ==
                        RentalPhase.AboutToExpire &&
                        rentalStepConfig.__typename ==
                            "PhaseChangeRentalStepConfig")
                ) {
                    setFinePendingConfig(rentalStepConfig);
                    return;
                }
            }
        } catch (error) {
            console.error("Error when fetching rental step configs", error);
        }
    };

    const loadNotifications = async (item) => {
        try {
            const results = await graphClient.graphql({
                query: queries.getRentalStepConfig,
                variables: {
                    id: item.rentalStepConfigId,
                },
            });
            let rentalConfigNotification = results.data.getRentalStepConfig;
            notificationConfig.push(rentalConfigNotification);
        } catch (error) {
            console.error("Error in loading rental step configs", error);
        }
    };

    const ShimmerLoadingCards = () => {
        return (
            <div>
                <ShimmerCard />
                <ShimmerCard />
                <ShimmerCard />
                <ShimmerCard />
                <ShimmerCard />
                <ShimmerCard />
            </div>
        );
    };

    const TimelineComponent = ({ item, showRedPhaseDot }) => {
        const timelineRentalDetails = {
            lastPhase: rental?.lastPhase,
            status: rental?.status,
            forgivenessReason: rental?.forgivenessDetails?.reason,
        };
        const rentalStepDetails = {
            rentalPhase: item?.rentalPhase,
            status: item?.status,
        };

        return (
            <Loadable dataLoaded={!isLoading}>
                <ProgressBar
                    rentalStep={rentalStepDetails}
                    rental={timelineRentalDetails}
                    showRedPhaseDot={showRedPhaseDot}
                ></ProgressBar>
            </Loadable>
        );
    };

    const InitiatedCardComponent = () => {
        const item = {
            status: "CHECK_FAILED",
            rentalPhase: RentalPhase.Initiated,
        };
        return (
            <Loadable dataLoaded={!isLoading} LoadingComponent={ShimmerCard}>
                <CardTimelineWrapper>
                    <TimelineComponent item={item} />
                    <InitiatedCard
                        onClickDisplay={onClickDisplay}
                        rental={rental}
                        loop={loop}
                    ></InitiatedCard>
                </CardTimelineWrapper>
            </Loadable>
        );
    };

    //TODO: Update string to release date TEC-1914
    const createdAtDate = new Date(rental.createdAt);
    const releaseDate = new Date("2024-08-07T00:00:00.000Z");

    const displayFineEligibleCard = (item) => {
        if (
            displayFinedCard(item) ||
            item.rentalPhase == RentalPhase.FinedManual ||
            item.rentalPhase == RentalPhase.FinedAutomatic
        ) {
            return false;
        }

        if (item.rentalPhase == RentalPhase.FineError) {
            return true;
        }
        if (chargeDetails) {
            if (
                chargeDetails.chargeStatus == ChargeStatus.Pending ||
                chargeDetails.chargeStatus == ChargeStatus.Failed
            ) {
                if (createdAtDate > releaseDate) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };

    const displayFinedCard = (item) => {
        finedPhase = "";
        if (
            item.rentalPhase == RentalPhase.FinedManual ||
            item.rentalPhase == RentalPhase.FinedAutomatic
        ) {
            return true;
        }
        if (chargeDetails) {
            if (
                chargeDetails.chargeStatus == "PENDING" ||
                chargeDetails.chargeStatus == "FAILED"
            ) {
                if (createdAtDate < releaseDate) {
                    finedPhase = RentalPhase.FinedManual;
                    return true;
                } else {
                    return false;
                }
            }
            if (chargeDetails.chargeStatus == "SUCCESS") {
                if (createdAtDate < releaseDate) {
                    finedPhase = RentalPhase.FinedAutomatic;
                    return true;
                } else {
                    return false;
                }
            }
        }
    };

    return (
        <TimelineBox>
            <Text type="header" size="little" color={colors.ASH}>
                TIMELINE
            </Text>
            <Wrapper>
                <div>
                    <InitiatedCardComponent />
                    <Loadable
                        dataLoaded={!isLoading}
                        LoadingComponent={ShimmerLoadingCards}
                    >
                        {filteredData.map((item, index) => {
                            if (item.status == RentalStatus.Completed) {
                                return (
                                    <CardTimelineWrapper
                                        key={`${index}.complete`}
                                    >
                                        <TimelineComponent item={item} />
                                        <CompleteCard
                                            rental={rental}
                                            triggerAt={item.triggerAt}
                                        ></CompleteCard>
                                    </CardTimelineWrapper>
                                );
                            }
                            if (item.status == RentalStatus.Canceled) {
                                return (
                                    <CardTimelineWrapper
                                        key={`${index}.canceled`}
                                    >
                                        <TimelineComponent item={item} />
                                        <CanceledCard
                                            rental={rental}
                                            triggerAt={item.triggerAt}
                                        ></CanceledCard>
                                    </CardTimelineWrapper>
                                );
                            }
                            if (
                                item.status == RentalStatus.CanceledWithRefund
                            ) {
                                return (
                                    <CardTimelineWrapper
                                        key={`${index}.canceledRefund`}
                                    >
                                        <TimelineComponent item={item} />
                                        <CanceledRefundCard
                                            rental={rental}
                                            triggerAt={item.triggerAt}
                                        ></CanceledRefundCard>
                                    </CardTimelineWrapper>
                                );
                            }
                            if (item.status == RentalStatus.Forgiven) {
                                const failedCard = {
                                    rentalPhase: RentalPhase.Expired,
                                    status: RentalStatus.Failed,
                                };
                                const fineErrorCard = {
                                    rentalPhase: RentalPhase.FineError,
                                    status: RentalStatus.Expired,
                                };
                                return (
                                    <div key={`${index}.forgiven`}>
                                        <Displayable
                                            displayPrimaryComponent={
                                                item.forgivenessDetails
                                                    .reason ==
                                                ForgivenessReason.ReturnReceivedForgiveness
                                            }
                                        >
                                            <CardTimelineWrapper>
                                                <TimelineComponent
                                                    item={failedCard}
                                                    showRedPhaseDot={true}
                                                />
                                                <FailedCard
                                                    triggerAt={item.triggerAt}
                                                ></FailedCard>
                                            </CardTimelineWrapper>
                                        </Displayable>
                                        <Displayable
                                            displayPrimaryComponent={
                                                item.rentalPhase ==
                                                RentalPhase.FineError
                                            }
                                        >
                                            <CardTimelineWrapper>
                                                <TimelineComponent
                                                    item={fineErrorCard}
                                                    showRedPhaseDot={true}
                                                />
                                                <FineErrorCard
                                                    rental={rental}
                                                    chargeDetails={
                                                        chargeDetails
                                                    }
                                                />
                                            </CardTimelineWrapper>
                                        </Displayable>
                                        <CardTimelineWrapper>
                                            <TimelineComponent item={item} />
                                            <ForgivenCard
                                                forgivenessDetails={
                                                    item.forgivenessDetails
                                                }
                                            />
                                        </CardTimelineWrapper>
                                    </div>
                                );
                            }
                            if (
                                item.status == RentalStatus.Expired ||
                                item.status == RentalStatus.Failed
                            ) {
                                const canceledFinedCombo =
                                    rental.status == RentalStatus.Canceled ||
                                    rental.status ==
                                        RentalStatus.CanceledWithRefund;

                                return (
                                    <div key={`${index}.expired`}>
                                        <CardTimelineWrapper>
                                            <TimelineComponent item={item} />
                                            <FailedCard
                                                triggerAt={item.triggerAt}
                                            ></FailedCard>
                                        </CardTimelineWrapper>
                                        <Displayable
                                            displayPrimaryComponent={
                                                item?.rentalPhase ==
                                                RentalPhase.FineError
                                            }
                                        >
                                            <CardTimelineWrapper>
                                                <TimelineComponent
                                                    item={item}
                                                />
                                                <FineErrorCard
                                                    rental={rental}
                                                    chargeDetails={
                                                        chargeDetails
                                                    }
                                                />
                                            </CardTimelineWrapper>
                                        </Displayable>
                                        <Displayable
                                            displayPrimaryComponent={displayFineEligibleCard(
                                                item
                                            )}
                                        >
                                            <RentalEligibleFineCard
                                                key={`${index}.expired`}
                                            >
                                                <CardTimelineWrapper>
                                                    <FinedEligibleCard
                                                        rental={rental}
                                                        triggerAt={
                                                            item.triggerAt
                                                        }
                                                    ></FinedEligibleCard>
                                                </CardTimelineWrapper>
                                            </RentalEligibleFineCard>
                                        </Displayable>
                                        <Displayable
                                            displayPrimaryComponent={
                                                (displayFinedCard(item) &&
                                                    finedPhase ==
                                                        RentalPhase.FinedManual) ||
                                                (displayFinedCard(item) &&
                                                    lastPhase ==
                                                        RentalPhase.FinedManual)
                                            }
                                        >
                                            <CardTimelineWrapper>
                                                <TimelineComponent
                                                    item={item}
                                                    showRedPhaseDot={
                                                        !canceledFinedCombo
                                                    }
                                                />
                                                <FinedManualCard
                                                    chargeDetails={
                                                        chargeDetails
                                                    }
                                                    rentalConfigStep={item}
                                                    createdBeforeRelease={
                                                        createdAtDate <
                                                        releaseDate
                                                    }
                                                ></FinedManualCard>
                                            </CardTimelineWrapper>
                                        </Displayable>
                                        <Displayable
                                            displayPrimaryComponent={
                                                (displayFinedCard(item) &&
                                                    finedPhase ==
                                                        RentalPhase.FinedAutomatic) ||
                                                (createdAtDate < releaseDate &&
                                                    chargeDetails?.chargeStatus ==
                                                        "SUCCESS") ||
                                                lastPhase ==
                                                    RentalPhase.FinedAutomatic
                                            }
                                        >
                                            <CardTimelineWrapper>
                                                <TimelineComponent
                                                    item={item}
                                                    showRedPhaseDot={
                                                        !canceledFinedCombo
                                                    }
                                                />
                                                <FinedAutomaticCard
                                                    chargeDetails={
                                                        chargeDetails
                                                    }
                                                    rentalConfigStep={item}
                                                    createdBeforeRelease={
                                                        createdAtDate <
                                                        releaseDate
                                                    }
                                                ></FinedAutomaticCard>
                                            </CardTimelineWrapper>
                                        </Displayable>
                                    </div>
                                );
                            }

                            if (item.rentalPhase == RentalPhase.LatePending) {
                                return (
                                    <>
                                        <CardTimelineWrapper
                                            key={`${index}.dueOn`}
                                        >
                                            <TimelineComponent item={item} />
                                            <DueOnCard
                                                rentalConfigStep={item}
                                                latePendingConfig={
                                                    latePendingConfig
                                                }
                                                lastPhase={lastPhase}
                                            ></DueOnCard>
                                        </CardTimelineWrapper>
                                        <CardTimelineWrapper
                                            key={`${index}.latePending`}
                                        >
                                            <TimelineComponent item={item} />
                                            <LatePendingCard
                                                rentalConfigStep={item}
                                                latePendingNotfication={
                                                    latePendingNotfication
                                                }
                                                latePendingConfig={
                                                    latePendingConfig
                                                }
                                                notificationConfig={
                                                    notificationConfig
                                                }
                                                lastPhase={lastPhase}
                                            ></LatePendingCard>
                                        </CardTimelineWrapper>
                                    </>
                                );
                            }
                            if (item.rentalPhase == RentalPhase.LatePending) {
                                return (
                                    <>
                                        <CardTimelineWrapper
                                            key={`${index}.dueOn`}
                                        >
                                            <TimelineComponent item={item} />
                                            <DueOnCard
                                                rentalConfigStep={item}
                                                latePendingConfig={
                                                    latePendingConfig
                                                }
                                                lastPhase={lastPhase}
                                            ></DueOnCard>
                                        </CardTimelineWrapper>
                                        <CardTimelineWrapper
                                            key={`${index}.latePending`}
                                        >
                                            <TimelineComponent item={item} />
                                            <LatePendingCard
                                                rentalConfigStep={item}
                                                latePendingNotfication={
                                                    latePendingNotfication
                                                }
                                                latePendingConfig={
                                                    latePendingConfig
                                                }
                                                notificationConfig={
                                                    notificationConfig
                                                }
                                                lastPhase={lastPhase}
                                            ></LatePendingCard>
                                        </CardTimelineWrapper>
                                    </>
                                );
                            } else if (item.rentalPhase == RentalPhase.Late) {
                                return (
                                    <CardTimelineWrapper key={`${index}.late`}>
                                        <TimelineComponent item={item} />
                                        <LateCard
                                            rentalConfigStep={item}
                                            lateConfig={lateConfig}
                                            notifications={notifications}
                                            lateNotification={lateNotification}
                                            notificationConfig={
                                                notificationConfig
                                            }
                                            lastPhase={lastPhase}
                                        ></LateCard>
                                    </CardTimelineWrapper>
                                );
                            } else if (
                                item.rentalPhase == RentalPhase.FailedPending ||
                                item.rentalPhase == RentalPhase.FinePending ||
                                item.rentalPhase == RentalPhase.AboutToExpire
                            ) {
                                return (
                                    <CardTimelineWrapper
                                        key={`${index}.failedPending`}
                                    >
                                        <TimelineComponent item={item} />
                                        <AboutToExpireCard
                                            rentalConfigStep={item}
                                            finePendingConfig={
                                                finePendingConfig
                                            }
                                            lastPhase={lastPhase}
                                        ></AboutToExpireCard>
                                    </CardTimelineWrapper>
                                );
                            } else if (
                                item.rentalPhase == RentalPhase.Failed ||
                                item.rentalPhase == RentalPhase.Fined ||
                                item.rentalPhase == RentalPhase.Expired
                            ) {
                                return (
                                    <CardTimelineWrapper
                                        key={`${index}.failed`}
                                    >
                                        <TimelineComponent item={item} />
                                        <FinedCard
                                            rentalConfigStep={item}
                                            finedConfig={finedConfig}
                                            notifications={notifications}
                                            finedNotification={
                                                finedNotification
                                            }
                                            notificationConfig={
                                                notificationConfig
                                            }
                                            loop={loop}
                                            lastPhase={lastPhase}
                                            triggerAt={item.triggerAt}
                                        ></FinedCard>
                                    </CardTimelineWrapper>
                                );
                            }
                        })}
                    </Loadable>
                </div>
            </Wrapper>
        </TimelineBox>
    );
}
