//utils
import { differenceInHours, parseISO } from "date-fns";

export const checkWithin24Hours = (dateCreated) => {
    const currentDate = new Date();
    const createdAt = dateCreated;

    const hoursDifference = differenceInHours(currentDate, parseISO(createdAt));

    if (hoursDifference < 24) {
        return true;
    } else {
        return false;
    }
};
