//react
import { useState } from "react";

//components
import Text from "../common/Text";
import { LineDiv } from "./common/LineDiv";
import { NextButton } from "./common/NextButton";
import { ButtonDiv } from "./common/ButtonDiv";
import { OverlayWrapper } from "./common/OverlayWrapper";
import Button from "../common/Button";
import { ClipLoader } from "react-spinners";
import AddLocationStepTwoPartB from "./AddLocationStepTwoPartB";

//style
import styled, { useTheme } from "../../styling/styled-components";

const ButtonText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const HeaderText = styled(Text)`
    align-self: stretch;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const AddedScanningLocationDiv = styled.div`
    display: flex;
    height: ${({ theme }) => theme.spacing.xxlarge}px;
    padding-top: ${({ theme }) => theme.spacing.small}px;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    gap: ${({ theme }) => theme.spacing.small}px;
    align-self: stretch;
`;

const SkipStep = styled(Text)`
    position: absolute;
    left: 45%;
    bottom: ${({ theme }) => theme.spacing.xsmall}%;
    margin: auto;
    &:hover {
        cursor: pointer;
    }
`;

const AddTagButton = styled(Button)`
    display: flex;
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.medium}px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 0;
    height: 44px;
    border: 1px solid ${(props) => props.theme.colors.BLANK_SLATE};
`;

const AddButton = styled.div`
    width: ${({ theme }) => theme.spacing.large}%;
    display: flex;
    justify-content: flex-start;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
`;

export default function AddLocationStepTwo({
    clickContinue,
    onClickAddScanLocation,
    showAddButton,
    handleUsernameChange,
    scanningLocationName,
    addAndSaveScan,
    scanningLocations,
    locationPrefix,
    setNewMetaData,
    newMetaData,
    loading,
    parentLocationId,
}) {
    const { colors, spacing } = useTheme();
    const [showContinueButton, setShowContinueButton] = useState(false);
    const [showTags, setShowTags] = useState(false);
    const [isLabelHovered, setIsLabelHovered] = useState(false);
    const [isScanningHovered, setIsScanningHovered] = useState(false);

    const toggleLabelHover = () => {
        setIsLabelHovered(!isLabelHovered);
    };

    const toggleScanningHover = () => {
        setIsScanningHovered(!isScanningHovered);
    };

    const onClickContinue = () => {
        clickContinue();
        setShowTags(false);
    };

    return (
        <>
            {showAddButton ? (
                <>
                    <div
                        style={{
                            position: "relative",
                            height: 440, //scroll not working with % on the new overlay component.
                            overflowY: "scroll",
                        }}
                    >
                        <OverlayWrapper>
                            <HeaderText
                                type="label"
                                size="small"
                                color={colors.ASH}
                            >
                                {`SCANNING LOCATIONS (${scanningLocations.length})`}
                            </HeaderText>
                            <LineDiv width={84}></LineDiv>
                            {scanningLocations.length > 0 && !loading ? (
                                scanningLocations.map((item, index) => (
                                    <>
                                        <AddedScanningLocationDiv>
                                            <Text
                                                type="label"
                                                size="medium"
                                                color={colors.DEEP_BLUE_SEA}
                                            >
                                                {item.fullName}
                                            </Text>
                                        </AddedScanningLocationDiv>
                                        <LineDiv width={84}></LineDiv>
                                    </>
                                ))
                            ) : loading ? (
                                <div style={{ paddingLeft: spacing.yuge }}>
                                    <ClipLoader loading />
                                </div>
                            ) : (
                                <></>
                            )}
                            {scanningLocations.length > 0 ? (
                                <AddButton
                                    onMouseEnter={toggleScanningHover}
                                    onMouseLeave={toggleScanningHover}
                                >
                                    <AddTagButton
                                        type="secondary"
                                        onClick={onClickAddScanLocation}
                                        style={{ border: "0px" }}
                                        label={
                                            <Text
                                                type="label"
                                                size="medium"
                                                color={
                                                    !isScanningHovered
                                                        ? colors.WAVE_STORM
                                                        : colors.PLASTIC_BOTTLE
                                                }
                                            >
                                                {
                                                    "+ Add another scanning location"
                                                }
                                            </Text>
                                        }
                                    ></AddTagButton>
                                </AddButton>
                            ) : (
                                <AddButton
                                    onMouseEnter={toggleLabelHover}
                                    onMouseLeave={toggleLabelHover}
                                >
                                    <NextButton
                                        type="secondary"
                                        onClick={onClickAddScanLocation}
                                        label={
                                            <ButtonText
                                                type="label"
                                                size="medium"
                                                color={
                                                    !isLabelHovered
                                                        ? colors.WAVE_STORM
                                                        : colors.PLASTIC_BOTTLE
                                                }
                                            >
                                                {"+ Add scanning location"}
                                            </ButtonText>
                                        }
                                    ></NextButton>
                                </AddButton>
                            )}

                            <LineDiv width={84}></LineDiv>
                        </OverlayWrapper>
                    </div>
                    {showContinueButton || scanningLocations.length > 0 ? (
                        <ButtonDiv height={"true"}>
                            <NextButton
                                type="primary"
                                onClick={onClickContinue}
                                label={
                                    <ButtonText
                                        type="label"
                                        size="large"
                                        color={colors.BLANK_SLATE}
                                    >
                                        {"Continue"}
                                    </ButtonText>
                                }
                            ></NextButton>
                        </ButtonDiv>
                    ) : (
                        clickContinue && (
                            <SkipStep
                                type="link"
                                size="medium"
                                color={colors.WAVE_STORM}
                                onClick={clickContinue}
                            >
                                {"Skip this step"}
                            </SkipStep>
                        )
                    )}
                </>
            ) : (
                !showAddButton && (
                    <>
                        <AddLocationStepTwoPartB
                            addAndSaveScan={addAndSaveScan}
                            handleUsernameChange={handleUsernameChange}
                            scanningLocationName={scanningLocationName}
                            locationPrefix={locationPrefix}
                            parentLocationId={parentLocationId}
                            setNewMetaData={setNewMetaData}
                            newMetaData={newMetaData}
                        ></AddLocationStepTwoPartB>
                    </>
                )
            )}
        </>
    );
}
