//components
import Dialog from "@mui/material/Dialog";
import RoundIconButton from "./RoundIconButton";
import Text from "./Text";

//styles
import styled, { css, useTheme } from "styled-components/macro";

const Wrapper = styled.div`
    ${(props) =>
        props.wrapperStyle
            ? props.wrapperStyle
            : `display: flex;
        align-items: center;
        justify-content: center`}
`;

const DialogWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    margin-vertical: ${({ theme }) => theme.spacing.large}px;
    margin-horizontal: ${({ theme }) => theme.spacing.small}px;

    > * {
        flex-shrink: 0;
        flex-grow: 1;
    }

    ${({ theme: { isMobile, isTablet } }) =>
        (isMobile || isTablet) &&
        css`
            margin: 0px;
            padding-horizontal: ${({ theme }) => theme.spacing.medium}px;
        `}

    ${(props) => props.dialogStyle};
`;

const CloseButton = styled(RoundIconButton)`
    position: absolute;
    right: ${({ theme }) => theme.spacing.small}px;
    top: ${({ theme }) => theme.spacing.small}px;
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            width: 70px;
            height: 70px;
            border-radius: 35px;
        `}
`;

const ModalTitle = styled(Text)``;

type Props = {
    children?: JSX.Element | JSX.Element[];
    className: string;
    dialogProps: Record<string, unknown>;
    dialogStyle: Record<string, unknown>;
    isOpen: boolean;
    onEnter: () => void;
    onExit: () => void;
    overlayClickExits: () => void;
    overlayColor: string;
    overlayStyle: Record<string, unknown>;
    shouldShowClosebutton: boolean;
    title: string;
    titleId: number | string;
    wrapperStyle: Record<string, unknown>;
};

export default function BasicModal({
    children,
    className,
    dialogProps,
    dialogStyle,
    isOpen,
    onExit,
    shouldShowClosebutton,
    title,
    titleId,
    wrapperStyle,
    height = "30%",
}: Props) {
    const { colors, spacing, isDesktop } = useTheme();

    return (
        <>
            <Dialog
                data-override-outside-click={true}
                className={className}
                open={isOpen}
                fullWidth
                PaperProps={{
                    style: {
                        backgroundColor: colors.DEEP_BLUE_SEA,
                        boxShadow: "none",
                        maxWidth:
                            wrapperStyle || !isDesktop
                                ? "45%"
                                : `${spacing.xlarge}%`,
                        height: height,
                    },
                }}
                onClose={onExit}
                BackdropProps={{
                    style: {
                        backgroundColor: colors.ASPHALT,
                        opacity: 0.65,
                        boxShadow: "none",
                    },
                }}
            >
                <Wrapper className={className} wrapperStyle={wrapperStyle}>
                    {isOpen ? (
                        <DialogWrapper
                            aria-label={title}
                            aria-labelledby={titleId}
                            dialogStyle={dialogStyle}
                            role="dialog"
                            {...dialogProps}
                        >
                            {shouldShowClosebutton ? (
                                <CloseButton
                                    iconName="close"
                                    onClick={onExit}
                                />
                            ) : null}
                            <ModalTitle type="header">{title}</ModalTitle>
                            {children}
                        </DialogWrapper>
                    ) : null}
                </Wrapper>
            </Dialog>
        </>
    );
}

BasicModal.defaultProps = { shouldShowClosebutton: true };
