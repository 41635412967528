//components
import BasicModal from "./BasicModal";
import Button from "./Button";
import Text from "./Text";

//style
import styled, { css } from "styled-components/macro";
import { useTheme } from "../../styling/styled-components";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin: 0 ${({ theme }) => theme.spacing.xlarge}px;
`;

const ButtonDecisionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

const SubTitle = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.medium}px;
    ${(props) => (props.width ? `width: 180%` : `width: 100%`)};
`;

const StyledButton = styled(Button)`
    width: 160px;
`;

const YesButton = styled(StyledButton)`
    background: ${({ theme }) => theme.colors.BLANK_SLATE};
    margin-left: ${({ theme }) => theme.spacing.small}px;
    div {
        color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    }
    ${(props) =>
        props.width
            ? `width: ${props.theme.spacing.xxlarge}%`
            : `width: ${props.theme.spacing.small}%`};
`;

const dialogStyle = css`
    align-self: center;
    max-width: 400px;
    max-height: 220px;
    justify-content: end;
    padding: ${({ theme }) => theme.spacing.xlarge}px;
    background-color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const NoButton = styled(StyledButton)`
    border: 1px solid ${({ theme }) => theme.colors.BLANK_SLATE};
    background: transparent;
    margin-right: ${({ theme }) => theme.spacing.small}px;
    margin-left: ${({ theme }) => theme.spacing.large}px;
    &:hover {
        div {
            color: ${(props) => props.theme.colors.hover};
        }
    }
    div {
        color: ${({ theme }) => theme.colors.BLANK_SLATE};
    }
`;

function ButtonText({ color, blocker, children }) {
    return (
        <Text
            type={blocker ? "label" : "label"}
            size={blocker ? "large" : "medium"}
            color={color}
        >
            {children}
        </Text>
    );
}

function Title({ color, blocker, children }) {
    return (
        <Text type="header" size={blocker ? "xlarge" : "large"} color={color}>
            {children}
        </Text>
    );
}

type Props = {
    isOpen: boolean;
    title: string;
    subtitle: string;
    onCancel: () => void;
    onConfirm: () => void;
    confirmationLoading: boolean;
};

export default function ConfirmationModal({
    isOpen,
    title,
    subtitle,
    onCancel,
    onConfirm,
    confirmationLoading,
    buttonCancelText,
    buttonConfirmText,
    wrapperStyle,
    height,
}: Props) {
    const { colors, isDesktop } = useTheme();

    return (
        <BasicModal
            isOpen={isOpen}
            title={null}
            dialogStyle={dialogStyle}
            onExit={onCancel}
            shouldShowClosebutton={false}
            wrapperStyle={wrapperStyle}
            height={height}
        >
            <Wrapper>
                <HeaderWrapper>
                    <Title
                        color={colors.BLANK_SLATE}
                        blocker={wrapperStyle ? true : false}
                    >
                        {title}
                    </Title>
                    <SubTitle
                        type="body"
                        size="medium"
                        color={wrapperStyle ? colors.BLANK_SLATE : ""}
                        width={isDesktop ? true : false}
                    >
                        {subtitle}
                    </SubTitle>
                </HeaderWrapper>
                <ButtonDecisionWrapper>
                    <NoButton
                        type="secondary"
                        label={
                            <ButtonText color={colors.BLANK_SLATE}>
                                {buttonCancelText ? buttonCancelText : "No"}
                            </ButtonText>
                        }
                        onClick={onCancel}
                    />
                    <YesButton
                        label={
                            <ButtonText
                                color={colors.DEEP_BLUE_SEA}
                                blocker={wrapperStyle ? true : false}
                            >
                                {buttonConfirmText ? buttonConfirmText : "Yes"}
                            </ButtonText>
                        }
                        onClick={onConfirm}
                        isLoading={confirmationLoading}
                        width={wrapperStyle ? true : false}
                    />
                </ButtonDecisionWrapper>
            </Wrapper>
        </BasicModal>
    );
}

ConfirmationModal.defaultProps = {
    confirmationLoading: false,
};
