//react
import React, { useState, useEffect } from "react";

//utils
import { sub, parseISO, format } from "date-fns";
import _ from "lodash";
import { localizeISOStringDate } from "../../../utils/helpers";

//styles
import styled, { css } from "../../../styling/styled-components";

//component
import DoubleLineChart from "../../dashboard/checkoutreturns/DoubleLineChart";

//apis
import { LoopStatus } from "../../../API";
import * as queries from "../../../graphql/queries";
import { useQuery } from "../../../graphql/hooks/query";

//constants
import { CHECKOUTS_RETURN_SUMMARY } from "../../../constants/dashboard";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    ${({ theme: { isMobile, isTablet } }) =>
        (isMobile || isTablet) &&
        css`
            flex-direction: column-reverse;
        `}
`;

const Chart = styled.div`
    flex: 4;
    padding: ${({ theme }) => theme.isDesktop && theme.spacing.large}px 0px;
    padding-right: ${({ theme }) => theme.spacing.medium}px;
    width: 75%;
    height: 380px;
    ${({ theme: { isMobile, isTablet } }) =>
        (isMobile || isTablet) &&
        css`
            flex: 1;
            flex-basis: auto;
            width: 100%;
            min-height: 250px;
        `}
`;

type FilterOptions = {
    locationId: string | null;
    assetTypeId: string;
    dateRange: string[];
};

type Props = {
    filterOptions: FilterOptions;
    handleLoopPercentageStats?: (
        closedLoopsPercentage: number,
        openedLoopsPercentage: number
    ) => number;
};

export default function LoopActivity({
    filterOptions,
    handleLoopPercentageStats,
}: Props) {
    const [splitDate, setSplitDate] = useState();
    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [
        queryLoopCounts,
        queryLoopCountsData,
        queryLoopCountsLoading,
        queryLoopCountsErrors,
    ] = useQuery(queries.queryLoopCounts);

    useEffect(() => {
        load();
    }, [
        filterOptions?.window,
        filterOptions.parentLocationIds,
        filterOptions.assetTypeId,
    ]);

    const load = () => {
        if (filterOptions?.window && filterOptions.window !== null) {
            if (filterOptions?.window[1] && filterOptions?.window[0]) {
                // want to pull 2x the range for comparisons
                const doubleSelectedFloor = sub(filterOptions?.window[1], {
                    days: Math.abs(filterOptions?.window[2]),
                });

                // this date splits the old and new dates converted into UTC
                const split = new Date(
                    filterOptions?.window[1].getTime() +
                        filterOptions?.window[1].getTimezoneOffset() * 6000
                );

                console.log(split, "logging split date to check PH timezone");
                if (split) {
                    // Parse the date string into a Date object
                    const formattedDate = format(split, "yyyy-MM-dd");
                    setSplitDate(formattedDate);
                }

                //convert the UTC datetimes recieved to local
                const fromTimestamp = new Date(
                    localizeISOStringDate(doubleSelectedFloor)
                ).toISOString();
                // we want all Loops until the end of the local day supplied
                const toTimestamp = new Date(
                    new Date(
                        localizeISOStringDate(filterOptions?.window[0])
                    ).setUTCHours(23, 59, 59)
                ).toISOString();

                queryLoopCounts({
                    fromTimestamp: fromTimestamp,
                    toTimestamp: toTimestamp,
                    timezone: userTimezone,
                    timeGranularity: "day",
                    locationId: filterOptions.parentLocationIds
                        ? filterOptions.parentLocationIds[0]
                        : null,
                    assetTypeId:
                        filterOptions.assetTypeId == "ALL"
                            ? null
                            : filterOptions.assetTypeId,
                    consumerId: null,
                });
            }
        }
    };

    useEffect(() => {
        updateChartData();
    }, [
        queryLoopCountsData,
        filterOptions?.window,
        filterOptions.parentLocationIds,
        filterOptions.assetTypeId,
    ]);

    const findCurrentCountArray = (parsedData, loopStatus) => {
        return parsedData
            .filter(
                (obj) =>
                    obj.loopStatus == loopStatus && obj.timestamp >= splitDate
            )
            .map((obj) => {
                if (obj.count == null) {
                    return 0;
                } else {
                    return obj.count;
                }
            });
    };

    const findPreviousCountArray = (parsedData, loopStatus) => {
        return parsedData
            .filter(
                (result) =>
                    result.loopStatus == loopStatus &&
                    result.timestamp < splitDate
            )
            .map((obj) => {
                if (obj.count == null) {
                    return 0;
                } else {
                    return obj.count;
                }
            });
    };

    const updateChartData = () => {
        if (queryLoopCountsData) {
            const parsedLoopCountData = queryLoopCountsData?.results.map(
                (result) => {
                    return {
                        ...result,
                    };
                }
            );

            const uniqueTimestamps = new Set(
                parsedLoopCountData
                    .filter((obj) => obj.timestamp >= splitDate)
                    .map((obj) => format(parseISO(obj.timestamp), "MM/dd"))
            );
            const labelsArray = [...uniqueTimestamps];

            const currentWindowLoopsOpened = findCurrentCountArray(
                parsedLoopCountData,
                LoopStatus.Open
            );

            const currentWindowLoopsClosed = findCurrentCountArray(
                parsedLoopCountData,
                LoopStatus.Closed
            );

            const previousWindowOpenLoops = findPreviousCountArray(
                parsedLoopCountData,
                LoopStatus.Open
            );
            const previousWindowClosedLoops = findPreviousCountArray(
                parsedLoopCountData,
                LoopStatus.Closed
            );

            const openedPercentage = calculatePercentDifference(
                currentWindowLoopsOpened,
                previousWindowOpenLoops
            );

            const closedPercentage = calculatePercentDifference(
                currentWindowLoopsClosed,
                previousWindowClosedLoops
            );

            handleLoopPercentageStats({
                closedLoopsPercentage: closedPercentage,
                openedLoopsPercentage: openedPercentage,
            });

            setLabels(labelsArray);
            setDatasets([
                {
                    label: CHECKOUTS_RETURN_SUMMARY.OPEN_LOOPS_LABEL,
                    data: currentWindowLoopsOpened,
                },
                {
                    label: CHECKOUTS_RETURN_SUMMARY.CLOSED_LOOPS_LABEL,
                    data: currentWindowLoopsClosed,
                },
            ]);
        }
    };

    const calculatePercentDifference = (
        currentCountArray,
        previousCountArray
    ) => {
        const totalCurrent = currentCountArray.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
        const totalPrevious = previousCountArray.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
        const countDifference = totalCurrent - totalPrevious;
        return Math.round((countDifference / totalPrevious) * 100);
    };

    const toolTipText = (xAxisValue, optionLabel, value) => {
        let barOption = "";
        if (optionLabel == "Returns (loops closed)") {
            barOption = "returns";
        } else {
            barOption = "check-outs";
        }

        return `${xAxisValue} ${barOption}: ${value}`;
    };

    const barChartSettings = {
        toolTipText: toolTipText,
    };

    return (
        <Container>
            <Body>
                <Chart>
                    <DoubleLineChart
                        datasets={datasets}
                        labels={labels}
                        settings={barChartSettings}
                    />
                </Chart>
            </Body>
        </Container>
    );
}
