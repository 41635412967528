export type SearchTag = {
    value: string;
    label: string;
};

export const searchTags: SearchTag[] = [
    { label: "PhoneNumber", value: "consumer.phoneNumber" },
    { label: "ConsumerName", value: "consumer.name" },
    {
        label: "ConsumerID",
        value: "consumer.consumerId",
    },
    { label: "RentedAssetType", value: "rentedAsset.assetType.name" },
    { label: "ReceivedAssetType", value: "receivedAsset.assetType.name" },
    { label: "CanceledReason", value: "metadata.canceledReason" },
    { label: "RentalType", value: "rentalConfig.name" },
    { label: "RentedAssetId", value: "rentedAsset.assetId" },
    { label: "ReceivedAssetId", value: "receivedAsset.assetId" },
    { label: "CanceledBy", value: "metadata.canceledBy" },
    { label: "RentalTypeVersion", value: "rentalConfig.version" },
];
