//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import Loadable from "../../common/Loadable";
import ShimmerCard from "../../common/ShimmerCard";
import LinkText from "../../common/LinkText";

//images
import { ReactComponent as StatusLine } from "../../../assets/vectors/StatusLine.svg";

//constants
import { REUSE_RATE_SUMMARY } from "../../../constants/dashboard";

type Props = {
    reuseData: ReuseRateData;
};

type ReuseRateData = {
    isAvailable: boolean;
    mostUsedAssetId?: string;
    avgUsesPerAsset?: number;
    mostUsedAssetCount?: number;
};

const StatsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.tiny}px;
    flex: 1 0 0;
`;

const StatsText = styled(Text)`
    padding: ${({ theme }) => theme.spacing.tiny}px;
    padding-right: ${({ theme }) => theme.spacing.medium}px;
`;

const LineDiv = styled.div`
    padding-left: ${({ theme }) => theme.spacing.xmlarge}px;
    padding-right: ${({ theme }) => theme.spacing.medium}px;
`;

export default function ReuseStats({ reuseData }: Props) {
    const { colors } = useTheme();
    const avgUsesPerAsset = reuseData
        ? reuseData.isAvailable
            ? reuseData.avgUsesPerAsset
            : "—"
        : "—";
    const mostUsedAssetCount = reuseData
        ? reuseData.isAvailable
            ? reuseData.mostUsedAssetCount
            : "—"
        : "—";

    const handleLinkNavigation = () => {
        if (reuseData?.isAvailable) {
            window.location.href =
                "/dashboard/activity/assetevents/" +
                `?prop=${reuseData.mostUsedAssetId}`;
        } else {
            return;
        }
    };

    const StyledShimmerCardDescription = () => {
        return <ShimmerCard height={30} width={103} />;
    };

    const StyledShimmerCardLoops = () => {
        return <ShimmerCard height={21} width={180} />;
    };

    const LoopText = ({ children }) => {
        return (
            <Loadable
                dataLoaded={reuseData}
                LoadingComponent={StyledShimmerCardLoops}
            >
                <StatsText type="header" size="xlarge" color={colors.BLACK}>
                    {children}
                </StatsText>
            </Loadable>
        );
    };

    const LowDataText = (description) => {
        return (
            <Text type="header" size="smallest" color={colors.BLACK}>
                {description}
            </Text>
        );
    };

    const LoopStats = ({ loops, description }) => {
        return (
            <div>
                <Loadable
                    dataLoaded={reuseData}
                    LoadingComponent={StyledShimmerCardDescription}
                >
                    <StatsText
                        type="header"
                        size="smallest"
                        color={colors.BLACK}
                    >
                        {description}
                    </StatsText>
                </Loadable>
                <LoopText> {`${loops} loops`} </LoopText>
            </div>
        );
    };

    const AssetStats = ({ loops, description }) => {
        return (
            <div>
                <Loadable
                    dataLoaded={reuseData}
                    LoadingComponent={StyledShimmerCardDescription}
                >
                    <Loadable
                        dataLoaded={reuseData?.isAvailable}
                        LoadingComponent={() => LowDataText(description)}
                    >
                        <LinkText
                            type="header"
                            size="smallest"
                            onClick={handleLinkNavigation}
                        >
                            {description}
                        </LinkText>
                    </Loadable>
                </Loadable>
                <LoopText> {`${loops} loops`} </LoopText>
            </div>
        );
    };

    return (
        <StatsWrapper>
            <LoopStats
                loops={avgUsesPerAsset}
                description={REUSE_RATE_SUMMARY.AVERAGE_USES}
            />
            <LineDiv>
                <StatusLine />
            </LineDiv>
            <AssetStats
                loops={mostUsedAssetCount}
                description={REUSE_RATE_SUMMARY.MOST_USED}
            />
        </StatsWrapper>
    );
}
