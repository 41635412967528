//style
import { useTheme } from "../../../../styling/styled-components";

//utils
import { getFullFormattedTime } from "../../../../utils/helpers";

//components
import Text from "../../../common/Text";
import { LeftHalfCard } from "../../../common/card/LeftHalfCard";

//TEC-2009 Refactor to reduce # of props

export default function CardHeader({
    header,
    subheader = "",
    date,
    colorHeader,
    colorDate,
}) {
    const { colors } = useTheme();

    return (
        <LeftHalfCard>
            <Text
                type="header"
                size="medium"
                color={!colorHeader ? colors.ROCK_BOTTOM : colorHeader}
            >
                {header}
            </Text>
            <Text
                type="header"
                size="medium"
                color={!colorHeader ? colors.ROCK_BOTTOM : colorHeader}
            >
                {subheader}
            </Text>
            <Text
                type="body"
                size="tiny"
                color={!colorDate ? colors.ASH : colorDate}
            >
                {getFullFormattedTime(date)}
            </Text>
        </LeftHalfCard>
    );
}
