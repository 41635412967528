import styled from "../../../styling/styled-components";

const LeftHalfTextDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
    white-space: nowrap;
`;

export function LeftHalfCard({ children }) {
    return <LeftHalfTextDiv>{children}</LeftHalfTextDiv>;
}
