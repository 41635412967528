//components
import Filter, { FilterOption } from "../../common/Filter";

const STRINGS = {
    LABEL: "Donations",
};

type Props = {
    onClear: () => void; // how to handle when the clear CTA is hit
    onSave: (options: FilterOption[]) => void; // how to handle the selected FilterOptions when save CTA is hit
    isDonationSelected: boolean | null;
    className?: string;
};

export default function DonationsFilter({
    onClear,
    onSave,
    isDonationSelected,
    className,
}: Props) {
    const options: FilterOption[] = [
        {
            label: "Donated",
            value: "YES",
            selected: isDonationSelected === true,
        },
        {
            label: "Not donated (-)",
            value: "NO",
            selected: isDonationSelected === false,
        },
    ];

    return (
        <Filter
            className={className}
            buttonLabel={STRINGS.LABEL}
            options={options}
            onClear={onClear}
            onSave={(options: FilterOption[]) => onSave(options)}
        />
    );
}
