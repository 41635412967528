//styles
import styled from "../../../styling/styled-components";

//images
import { ReactComponent as CycleArrowRight } from "../../../assets/vectors/CycleArrowRight.svg";
import { ReactComponent as CycleArrowLeft } from "../../../assets/vectors/CycleArrowLeft.svg";

//components
import ShelfDuration from "./ShelfDuration";

const Body = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`;

const LeftCycleArrowWrapper = styled.div`
    transform: rotate(180deg);
    margin-left: ${({ theme }) => theme.spacing.medium}px;
    position: absolute;
    top: 60%;
`;

const RightCycleArrowWrapper = styled.div`
    transform: rotate(180deg);
    margin-right: ${({ theme }) => theme.spacing.medium}px;
    position: absolute;
    top: 60%;
    right: 0px;
`;

export default function ShelfTime({ cycleRateData }: Props) {
    return (
        <Body>
            <LeftCycleArrowWrapper>
                <CycleArrowLeft></CycleArrowLeft>
            </LeftCycleArrowWrapper>
            <ShelfDuration cycleRateData={cycleRateData}></ShelfDuration>
            <RightCycleArrowWrapper>
                <CycleArrowRight></CycleArrowRight>
            </RightCycleArrowWrapper>
        </Body>
    );
}
