import React, { useContext } from "react";
import styled from "styled-components/macro";
import DeprecatedText from "../common/DeprecatedText";
import { findTextStyles } from "../../styling/styled-components";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Flask } from "../../assets/vectors/Flask.svg";
import { ReactComponent as Logo } from "../../assets/vectors/Logo.svg";
import GlobalContext, {
    emptyGlobalSelection,
} from "../../contexts/GlobalContext";
import { signOut } from "aws-amplify/auth";

const MobileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 93vh;
    padding: 20px;
`;

const TopangaText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
`;

const UserWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 ${({ theme }) => theme.spacing.medium}px;
    margin: 0 auto;
    text-align: center;
`;

const UserEmail = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
`;

const UserOrg = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
    margin: 0 auto;
`;

const SignOutLabel = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.WAVE_STORM};
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const MobileTitle = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const MobileSubtitle = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    margin-top: ${({ theme }) => theme.spacing.small}px;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

export const MOBILE_SCREEN = {
    SIGN_OUT: "Sign Out",
    TITLE: "The mobile dashboard is in the works",
    SUBTITLE:
        "Our team is working on bringing the Loop Dashboard to your phone. For now, please use a tablet or computer to access your dashboard. Stay tuned for updates!",
};

export default function MobileScreen() {
    const context = useContext(GlobalContext);
    const { organizationName, username } = context.globalSelections;
    const navigate = useNavigate();

    const handleSignOut = async () => {
        await signOut();
        context.updateSelections(emptyGlobalSelection);
        navigate("/login");
    };

    return (
        <MobileContainer>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Logo style={{ width: 40, minWidth: 40, cursor: "pointer" }} />
                <div style={{ marginLeft: 10 }}>
                    <TopangaText type="header" size="medium">
                        Topanga.io
                    </TopangaText>
                </div>
            </div>
            <div style={{ textAlign: "center" }}>
                <Flask />
                <MobileTitle type="header" size="smallest">
                    {MOBILE_SCREEN.TITLE}
                </MobileTitle>
                <MobileSubtitle type="body" size="tiny">
                    {MOBILE_SCREEN.SUBTITLE}
                </MobileSubtitle>
            </div>
            {
                <UserWrapper>
                    <UserOrg type="label" size="medium">
                        {organizationName || null}
                    </UserOrg>
                    <UserEmail type="body" size="medium">
                        {username || null}
                    </UserEmail>
                    <SignOutLabel
                        onClick={handleSignOut}
                        type="link"
                        size="small"
                    >
                        {MOBILE_SCREEN.SIGN_OUT}
                    </SignOutLabel>
                </UserWrapper>
            }
        </MobileContainer>
    );
}
