import styled, { css } from "../../../styling/styled-components";

const StyledFlyoutForm = styled.form`
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: 0px 4px 10px rgba(22, 35, 80, 0.27);
    display: flex;
    padding: ${({ theme }) => theme.spacing.large}px;
    position: absolute;
    width: auto;
    z-index: 20000;
    ${({ alignRight }) =>
        !alignRight
            ? css``
            : css`
                  right: 0px;
              `};
`;

export default function FlyoutForm({ onSubmit, alignRight = false, children }) {
    return (
        <StyledFlyoutForm onSubmit={onSubmit} alignRight={alignRight}>
            {children}
        </StyledFlyoutForm>
    );
}
