//style
import styled from "../../../styling/styled-components";

//components
import FormTextField from "../../common/FormTextField";

const InputFormDiv = styled(FormTextField)`
    display: flex;
    padding: ${({ theme }) => theme.spacing.small}px;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
`;

const InputStyle = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing.medium}px;
    padding: ${({ theme }) => theme.spacing.small}px;
    margin-left: ${({ theme }) => theme.spacing.xmlarge}px;
    align-items: flex-start;
    align-self: stretch;
`;
export function LocationInputForm({
    children,
    infoText,
    width,
    infoStyle,
    maxLength,
    onChange,
    hintText,
    defaultValue,
    defaultOn,
    placeholder,
    value,
    hintStyle,
    error,
    hasError,
    errorStyle,
    subHintText,
    subHintStyle,
    onBlur,
}) {
    return (
        <InputStyle>
            <InputFormDiv
                subHintText={subHintText}
                subHintStyle={subHintStyle}
                infoText={infoText}
                width={width}
                infoStyle={infoStyle}
                maxLength={maxLength}
                onChange={onChange}
                status="location"
                hintText={hintText}
                hintStyle={hintStyle}
                defaultValue={defaultValue}
                defaultOn={defaultOn}
                placeholder={placeholder}
                value={value}
                error={error}
                hasError={hasError}
                errorStyle={errorStyle}
                onBlur={onBlur}
            >
                {children}
            </InputFormDiv>
        </InputStyle>
    );
}
