//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import TextWrapper from "./TextWrapper";
import Loadable from "../../common/Loadable";
import StatsLoadingCard from "../assetusage/StatsLoadingCard";

//constants
import { ACTIVE_CONSUMERS } from "../../../constants/dashboard";

type Props = {
    date: string;
    count: string;
};

const NetChangeWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

export default function GreatestNetIncrease({ date, count }: Props) {
    const { colors } = useTheme();

    return (
        <>
            <TextWrapper>
                <Text size="smallest" type="header" color={colors.BLACK}>
                    {ACTIVE_CONSUMERS.DATE_INCREASE}
                </Text>
                <NetChangeWrapper>
                    <Loadable
                        dataLoaded={date}
                        LoadingComponent={StatsLoadingCard}
                    >
                        <Text size="xlarge" type="header" color={colors.BLACK}>
                            {date}
                        </Text>
                        <Text size="large" type="label" color={colors.GREEN_2}>
                            {count}
                        </Text>
                    </Loadable>
                </NetChangeWrapper>
            </TextWrapper>
        </>
    );
}
