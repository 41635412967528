/**
 * Do not edit directly
 * Generated on Fri, 22 Mar 2024 17:10:58 GMT
 */

export const annotationsNotes = "#885af9";
export const uiColorsGreen4 = "#ddfde4";
export const uiColorsSeaglass = "#e5efff";
export const uiColorsPlasticBottle = "#6096fb";
export const uiColorsWavestorm = "#1745cf";
export const uiColorsRockBottom = "#1d2130";
export const uiColorsYellow1 = "#796515";
export const uiColorsYellow2 = "#b88d0b";
export const uiColorsYellow3 = "#f4b925";
export const uiColorsYellow4 = "#f9e7b8";
export const uiColorsRed1 = "#732626";
export const uiColorsRed2 = "#b82e2e";
export const uiColorsRed3 = "#e54d4d";
export const uiColorsRed4 = "#fdd9d8";
export const uiColorsGreen1 = "#295732";
export const uiColorsGreen2 = "#2e8540";
export const uiColorsGreen3 = "#4dcb7b";
export const greysExhaust = "#6a6f7c";
export const greysBlankSlate = "#ffffff";
export const greysAsh = "#36393f";
export const greysAsphalt = "#d6d8dc";
export const greysSummerStorm = "#90a0b7";
export const greysMarineLayer = "#c2cfe0";
export const greysSmog = "#f5f6f8";
export const greysOnyx = "#000000";
export const brandDeepBlueSea = "#162350";
export const brandFoam = "#fbfef5";
export const brandLimeSqueeze = "#e2f89c";
export const brandSprout = "#80b57b";
export const brandFroth = "#f7f3e8";
export const buttonDropShadow = "0 4px 10px 0 #16235045";
export const containerDropShadow = "0 6px 18px 0 #0000000f";
export const fontFamiliesPoppins = "Poppins";
export const lineHeights0 = "AUTO";
export const fontWeightsPoppins0 = 400;
export const fontWeightsPoppins1 = 500;
export const fontWeightsPoppins2 = 600;
export const fontSize0 = "10px";
export const fontSize1 = "11px";
export const fontSize2 = "12px";
export const fontSize3 = "13px";
export const fontSize4 = "14px";
export const fontSize5 = "15px";
export const fontSize6 = "18px";
export const fontSize7 = "20px";
export const fontSize8 = "26px";
export const letterSpacing0 = "0.01em";
export const paragraphSpacing0 = 0;
export const dashboardTypeStylesBodyLarge13 = "400 13px/AUTO Poppins";
export const dashboardTypeStylesBodyMedium12 = "400 12px/AUTO Poppins";
export const dashboardTypeStylesBodySmall11 = "400 11px/AUTO Poppins";
export const dashboardTypeStylesBodyTiny10 = "400 10px/AUTO Poppins";
export const dashboardTypeStylesHeaderExtraLarge20 = "500 20px/AUTO Poppins";
export const dashboardTypeStylesHeaderLarge18 = "500 18px/AUTO Poppins";
export const dashboardTypeStylesHeaderMedium15 = "500 15px/AUTO Poppins";
export const dashboardTypeStylesHeaderSmall14 = "500 14px/AUTO Poppins";
export const dashboardTypeStylesHeaderSmallest13 = "500 13px/AUTO Poppins";
export const dashboardTypeStylesLabelLarge13SemiBold = "600 13px/AUTO Poppins";
export const dashboardTypeStylesLabelMedium12 = "500 12px/AUTO Poppins";
export const dashboardTypeStylesLabelSmall11 = "500 11px/AUTO Poppins";
export const dashboardTypeStylesLinkLarge13 = "600 13px/AUTO Poppins";
export const dashboardTypeStylesLinkMedium12 = "600 12px/AUTO Poppins";
export const dashboardTypeStylesLinkSmall11 = "600 11px/AUTO Poppins";
export const dashboardTypeStylesHeroStat26 = "500 26px/AUTO Poppins";
export const textCaseNone = "none";
export const textDecorationNone = "none";
export const textDecorationUnderline = "underline";
export const paragraphIndent0 = "0px";
export const overlayDropShadow = "-10px 0 16px 0 #0000000d"; // Use for all page overlays and modals
export const small = "0.5px";
export const divider = "0.5px solid #c2cfe0";
export const graphHoverExcluded = 0.2; // On hover, display all excluded items at 20% opacity.
export const dataVizOceanSEdge = "#5f7492"; // data vis
export const dataVizStarryNight = "#0d2673";
export const dataVizMountainHaze = "#8092c6";
export const dataVizPebble = "#a3a3a3";
export const dataVizSpringMist = "#adc3eb";
export const dataVizHoverDeepBlueSea = "#16235033";
export const dataVizHoverSummerStorm = "#90a0b733";
export const dataVizHoverPlasticBottle = "#6096fb33";
export const dataVizHoverOceanSEdge = "#5f749233";
export const dataVizHoverSeaglass = "#e5efff33";
export const dataVizHoverYellow2 = "#b88d0b33";
export const dataVizHoverYellow3 = "#f4b92533";
export const dataVizHoverSprout = "#80b57b33";
export const dataVizHoverGreen2 = "#2e854033";
export const dataVizHoverGreen1 = "#29573233";
export const keyContrastStroke = "0.5px solid #6a6f7c";
