//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import LinkText from "../../common/LinkText";

//constants
import { LOST_ASSETS_SUMMARY } from "../../../constants/dashboard";

const LearnMoreText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

const Wrapper = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.xlarge}px;
    border-bottom: ${({ theme }) => theme.borderStyle.smallDivider};
`;

export default function LearnMore({ link }) {
    const { colors } = useTheme();

    return (
        <Wrapper>
            <LearnMoreText type="body" size="medium" color={colors.EXHAUST}>
                {LOST_ASSETS_SUMMARY.LEARN_MORE}{" "}
                <LinkText
                    type="new_link"
                    size="small"
                    color={colors.EXHAUST}
                    href={link}
                >
                    {"ReusePass Academy"}
                </LinkText>
            </LearnMoreText>
        </Wrapper>
    );
}
