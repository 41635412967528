// external
import { useSearchParams, useLocation } from "react-router-dom";

// style
import styled, { useTheme } from "../../styling/styled-components";

// graphql
import queryString from "query-string";

// components
import Text from "../common/Text";
import LinkText from "../common/LinkText";
import SectionTitle from "../common/overlay/SectionTitle";

// utils
import { prettifyTime } from "../../utils/helpers";
import { deletePropFromSearchParams } from "../../utils/activity/deletePropFromSearchParams";

const OpenStatus = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 440px;
    height: ${({ theme }) => theme.spacing.xlarge}px;
    border-top: 0.5px solid #c2cfe0;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: 18px 0px 0px;
    margin-top: 4px;
`;

const ClosedStatus = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 440px;
    height: 30px;
    border-top: 0.5px solid #c2cfe0;
    border-bottom: 0.5px solid #c2cfe0;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: 18px 0px 18px;
    margin-top: ${({ theme }) => theme.spacing.medium}px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing.tiny}px;
`;

const RentalBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px;
    padding-top: 40px;
    width: 440px;
`;

const CountText = styled(Text)`
    margin-left: auto;
    padding-right: ${({ theme }) => theme.spacing.xsmall}px;
`;

const UpdateText = styled(Text)`
    margin-left: auto;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const ClosedContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-start;
    position: relative;
    text-align: center;
    width: 82px;
`;

const OpenContainer = styled.div`
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-start;
    position: relative;
    text-align: center;
    width: 82px;
`;

const LinkWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.huge}px;
    margin-left: ${({ theme }) => theme.spacing.xmlarge}px;
`;

export default function AssetActivity({ consumerStats }) {
    const { colors } = useTheme();
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const handleLinkNavigation = () => {
        const updatedSearchParams = deletePropFromSearchParams(
            queryParams,
            searchParams
        );
        setSearchParams(updatedSearchParams);
        window.location.href =
            "/dashboard/activity/assetevents/" + `?prop=${searchParams}`;
    };

    return (
        <RentalBox>
            <Header>
                <SectionTitle color={colors.ASH}>ASSET ACTIVITY</SectionTitle>
                <LinkWrapper>
                    <LinkText
                        type="new_link"
                        size="small"
                        onClick={handleLinkNavigation}
                    >
                        See all asset events
                    </LinkText>
                </LinkWrapper>
            </Header>

            <OpenStatus>
                <OpenContainer>
                    <Text
                        type="label"
                        size="small"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {"LOOPS OPENED"}
                    </Text>
                </OpenContainer>
                <CountText
                    type="header"
                    size="medium"
                    color={colors.DEEP_BLUE_SEA}
                >
                    {consumerStats.totalOpenLoops}
                </CountText>
            </OpenStatus>

            <ClosedStatus>
                <ClosedContainer>
                    <Text type="label" size="small" color={colors.BLANK_SLATE}>
                        {"LOOPS CLOSED"}
                    </Text>
                </ClosedContainer>
                <CountText
                    type="header"
                    size="medium"
                    color={colors.DEEP_BLUE_SEA}
                >
                    {consumerStats.totalClosedLoops}
                </CountText>
            </ClosedStatus>
            <UpdateText type="label" size="small" color={colors.EXHAUST}>
                Last updated {prettifyTime(consumerStats.updatedAt)} PST
            </UpdateText>
        </RentalBox>
    );
}
