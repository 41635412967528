//components
import Text from "../../common/Text";

//api
import { ImageObservation } from "../../../API";

//styling
import styled from "../../../styling/styled-components";

const STRINGS = {
    IMAGES: (count: number) => `IMAGES (${count})`,
};

const ImagesWrapper = styled.div``;

const ImagesText = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
    margin: 0px 0px 6px 0px;
`;

const StyledImage = styled.img`
    width: 444px;
    height: 335px;
    flex-shrink: 0;
    border-radius: 4px;
    cursor: pointer;
    padding: 0px 0px 30px 0px;
`;

type Props = {
    images: ImageObservation[];
    observationId: string;
    className?: string;
};

export default function ObservationImages({
    className,
    images,
    observationId,
}: Props) {
    // FIXIT: remove when [FTK-87] is released
    const imagesDeduped = [
        ...new Map(images.map((image) => [image.key, image])).values(),
    ];

    return (
        <ImagesWrapper className={className}>
            <ImagesText type="label" size="small">
                {STRINGS.IMAGES(images?.length || "-")}
            </ImagesText>
            {(imagesDeduped || []).map((image: ImageObservation) => (
                <StyledImage
                    src={image.url}
                    key={`${image.key}-${new Date()}`}
                />
            ))}
        </ImagesWrapper>
    );
}
