import React from "react";

export default function SideLine() {
    return (
        <hr
            style={{
                position: "relative",
                top: -8,
                left: 0,
                right: 0,
                zIndex: 0,
                border: "none",
                borderTop: "0.5px solid #C2CFE0",
                margin: "1rem 0",
                width: "100%",
            }}
        />
    );
}
