import React, { MouseEventHandler } from "react";
import { TEXT_STYLES } from "../../styling";
import styled, { useTheme } from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";

interface TextButtonProps {
    text: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
    changeColor: string;
    changeBorder: string;
}

export default function TextButton({
    text,
    onClick,
    changeColor,
    changeBorder,
}: TextButtonProps) {
    return (
        <button
            style={{
                fontFamily: "poppins",
                fontSize: "15px",
                fontWeight: 500,
                background: "none",
                zIndex: 100,
                border: "none",
                padding: "0",
                cursor: "pointer",
                fontStyle: "normal",
                color: changeColor,
                marginRight: "24px",
                letterSpacing: "0.01em",
                lineHeight: "36px",
                borderBottom: changeBorder,
            }}
            onClick={onClick}
        >
            {text}
        </button>
    );
}
