import React, { useState, useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ChartArea, ChartData } from "chart.js";
import { useTheme } from "styled-components/macro";
import { prettifyNumber } from "../../../utils/helpers";

type Props = {
    data: number[];
    labels: string[];
    yStepSize?: number[];
    formatYLabel?: (val: number) => string;
    xAxisTitle?: string;
    yAxisTitle?: string;
    maintainAspectRatio?: boolean;
    responsive?: boolean;
};

export default function Component({
    labels,
    data,
    yStepSize,
    formatYLabel,
    xAxisTitle,
    yAxisTitle,
    maintainAspectRatio,
    responsive,
}: Props) {
    const chartRef = useRef<ChartJS>(null);

    const [chartData, setChartData] = useState<ChartData<"line">>({
        datasets: [],
    });
    const [options, setOptions] = useState({});

    const { colors } = useTheme();
    const dataSeries = {
        labels,
        datasets: [
            {
                id: "1",
                label: "",
                data,
                borderColor: colors.PLASTIC_BOTTLE,
                fill: "start",
            },
        ],
    };

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        const chartData = {
            ...dataSeries,
            datasets: dataSeries.datasets.map((dataset) => ({
                ...dataset,
                backgroundColor: createGradient(chart.ctx, chart.chartArea),
            })),
        };

        setChartData(chartData);

        setOptions({
            responsive,
            maintainAspectRatio,
            elements: {
                line: {
                    tension: 0.4,
                },
            },
            scales: {
                y: {
                    ticks: {
                        stepSize: yStepSize,
                        callback: (val, index) => formatYLabel(val),
                    },
                    title: {
                        display: !!yAxisTitle,
                        text: yAxisTitle,
                        padding: 12,
                        font: { size: 10 },
                    },
                },
                x: {
                    title: {
                        display: !!xAxisTitle,
                        text: xAxisTitle,
                        padding: 12,
                        font: { size: 10 },
                    },
                },
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (tooltipItem, _) =>
                            formatYLabel(tooltipItem.raw),
                    },
                },

                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
                filler: {
                    propagate: false,
                },
            },
        });
        chart.resize();
    }, [data, labels]);

    const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea) => {
        const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

        gradient.addColorStop(1, colors.PLASTIC_BOTTLE);
        gradient.addColorStop(0, "transparent");

        return gradient;
    };

    return <Line ref={chartRef} options={options} data={chartData} />;
}

Component.defaultProps = {
    formatYLabel: (val) => prettifyNumber(val),
    maintainAspectRatio: true,
    responsive: true,
};
