import React, { useState, useContext } from "react";
import { signIn, confirmSignIn } from "aws-amplify/auth";
import CreateNewPasswordContent from "./CreateNewPasswordContent";
import Button from "../common/Button";
import DeprecatedText from "../common/DeprecatedText";
import TextInput from "../common/TextInput";
import GlobalContext from "../../contexts/GlobalContext";
import { useTogglePasswordVisibility } from "../../hooks/useTogglePasswordVisibility";
import { ERROR_MESSAGES, ERROR_TYPES } from "../../constants/errors";
import { SIGNIN } from "../../constants/signin";
import { validatePassword } from "../../utils/validation";
import { Link, useSearchParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styled, { css, useTheme } from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";
import { ROUTES } from "../routes/routes";

const hintStyle = css`
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
`;

const mobileHintStyle = css`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
`;

const Container = styled.div`
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const NewPasswordContainer = styled.div`
    width: 302px;
`;

const SigninContainer = styled(NewPasswordContainer)``;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const PasswordTextInput = styled(TextInput)`
    margin-top: ${({ theme }) => theme.spacing.xxlarge}px;
    padding: ${({ theme }) => theme.spacing.xsmall}px 0;

    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            margin-top: 40px;
        `}
`;

const SignInButton = styled(Button)`
    align-self: center;
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
    margin-top: ${({ theme }) => theme.spacing.huge}px;
`;

const Title = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const PromptText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    margin-bottom: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const LinkText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    text-align: center;
    font-weight: 500;
    font-size: 13px;
`;

const StyledVisibilityIcon = styled(VisibilityIcon)`
    size: ${({ theme }) => theme.spacing.medium}px;
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
    cursor: pointer;
    margin-top: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const StyledVisibilityOffIcon = styled(VisibilityOffIcon)`
    size: ${({ theme }) => theme.spacing.medium}px;
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
    cursor: pointer;
    margin-top: ${({ theme }) => theme.spacing.xxlarge}px;
`;

export default function SignIn({}) {
    let [searchParams, setSearchParams] = useSearchParams();
    const username: string = searchParams.get("username") || "";
    const prompt: string = searchParams.get("prompt") || "";
    const { colors, isMobile } = useTheme();
    const { passwordVisibility, rightIcon, handlePasswordVisibility } =
        useTogglePasswordVisibility();
    const [updatedUsername, setUpdatedUsername] = useState<string>(username);
    const [updatedUsernameError, setUpdatedUsernameError] =
        useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [signInLoading, setSignInLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const [promptNewPassword, setPromptNewPassword] = useState<boolean>(false);

    const [newPasswordLoading, setNewPasswordLoading] =
        useState<boolean>(false);
    const [newPasswordError, setNewPasswordError] = useState<string>("");

    const [user, setUser] = useState<Record<string, unknown>>({});
    const [isSigninButtonDisabled, setIsSigninButtonDisabled] =
        useState<boolean>(!username);

    const context = useContext(GlobalContext);

    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setUpdatedUsernameError(null);
        setUpdatedUsername(text);
    };

    const validateText = () => {
        if (!updatedUsername) {
            setIsSigninButtonDisabled(true);
        } else if (!isValidEmail(updatedUsername)) {
            setUpdatedUsernameError(ERROR_MESSAGES.INVALID_EMAIL);
            setIsSigninButtonDisabled(true);
        } else {
            setUpdatedUsernameError(null);
            setIsSigninButtonDisabled(false);
        }
    };

    const handlePasswordChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setPasswordError(null);
        setPassword(text);
        if (text.split("").length >= 5 && !updatedUsernameError) {
            setIsSigninButtonDisabled(false);
        } else {
            setIsSigninButtonDisabled(true);
        }
    };

    const handleLoginPress = async () => {
        setSignInLoading(true);
        try {
            const user = await signIn({
                username: updatedUsername.trim().toLowerCase(),
                password,
            });

            if (
                user.nextStep.signInStep ===
                "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
            ) {
                setUser(user);
                setPromptNewPassword(true);
                setSignInLoading(false);
            } else {
                context.updateSelections({
                    isAuthenticated: true,
                });
            }
        } catch (err) {
            if (err.name === ERROR_TYPES.USER_NOT_FOUND_TYPE) {
                setUpdatedUsernameError(ERROR_MESSAGES.USER_NOT_FOUND);
            } else if (err.name === ERROR_TYPES.PASSWORD_NOT_RECOGNIZED_TYPE) {
                if (err.message === ERROR_TYPES.TEMP_PASSWORD_EXPIRED_TYPE) {
                    setPasswordError(ERROR_MESSAGES.TEMP_PASSWORD_EXPIRED);
                } else {
                    setPasswordError(ERROR_MESSAGES.PASSWORD_NOT_RECOGNIZED);
                }
                setPassword("");
            } else {
                setError(err.message);
            }
        } finally {
            setSignInLoading(false);
            setIsSigninButtonDisabled(true);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleNewPasswordSubmitPress = async (newPassword: string) => {
        setNewPasswordLoading(true);
        if (validatePassword(newPassword)) {
            try {
                await confirmSignIn({ challengeResponse: newPassword });
                setNewPasswordLoading(false);
                setPromptNewPassword(false);
                setPassword("");
                context.updateSelections({
                    isAuthenticated: true,
                });
            } catch (err) {
                setNewPasswordError(err.message);
                throw err;
            }
        } else {
            setNewPasswordError(true);
        }
        setNewPasswordLoading(false);
    };

    const navigateToSignin = () => {
        const params = new URLSearchParams({
            username: updatedUsername,
        });
        return "/?" + params;
    };

    const EyeIcon =
        rightIcon === "eye" ? (
            <StyledVisibilityIcon onClick={handlePasswordVisibility} />
        ) : (
            <StyledVisibilityOffIcon onClick={handlePasswordVisibility} />
        );

    return (
        <Container>
            {promptNewPassword ? (
                <NewPasswordContainer>
                    <Title
                        type="header"
                        size={isMobile ? "smallest" : "medium"}
                    >
                        {SIGNIN.CREATE_NEW_PASSWORD_TITLE}
                    </Title>
                    <PromptText
                        type="header"
                        size={isMobile ? "large" : "xlarge"}
                    >
                        {error || prompt || SIGNIN.CREATE_NEW_PASSWORD_SUBTITLE}
                    </PromptText>
                    <CreateNewPasswordContent
                        onChangePassword={handleNewPasswordSubmitPress}
                        styleContext="createNewPassword"
                        username={username}
                    />
                    <Link
                        onClick={() => setPromptNewPassword(false)}
                        to={navigateToSignin()}
                    >
                        <LinkText
                            type="label"
                            size={isMobile ? "small" : "medium"}
                        >
                            {SIGNIN.RETURN}
                        </LinkText>
                    </Link>
                </NewPasswordContainer>
            ) : (
                <SigninContainer>
                    <Title
                        type="header"
                        size={isMobile ? "smallest" : "medium"}
                    >
                        {SIGNIN.TITLE}
                    </Title>
                    <PromptText
                        type="header"
                        size={isMobile ? "large" : "xlarge"}
                    >
                        {error || prompt || SIGNIN.SIGN_IN_TITLE}
                    </PromptText>
                    <StyledForm onSubmit={handleSubmit}>
                        <TextInput
                            value={updatedUsername}
                            onChange={handleEmailChange}
                            onBlur={validateText}
                            hintText={SIGNIN.USERNAME_LABEL}
                            hintStyle={isMobile ? mobileHintStyle : hintStyle}
                            error={updatedUsernameError}
                            secureTextEntry={false}
                            autoCorrect="false"
                            autoCapitalize="none"
                            returnKeyType="done"
                        />
                        <PasswordTextInput
                            value={password}
                            type={rightIcon === "eye" ? "password" : "text"}
                            onChange={handlePasswordChange}
                            hintText={SIGNIN.PASSWORD_LABEL}
                            hintStyle={isMobile ? mobileHintStyle : hintStyle}
                            error={passwordError}
                            secureTextEntry={passwordVisibility}
                            autoCorrect="false"
                            autoCapitalize="none"
                            returnKeyType="done"
                            rightIcon={EyeIcon}
                        />
                        <SignInButton
                            label={SIGNIN.SIGN_IN_BUTTON}
                            type="primary"
                            status={
                                isSigninButtonDisabled ? "disabled" : "default"
                            }
                            onClick={handleLoginPress}
                            isLoading={signInLoading}
                        />
                        <Link to={ROUTES.FORGOT_PASSWORD + navigateToSignin()}>
                            <LinkText
                                type="link"
                                size={isMobile ? "small" : "medium"}
                            >
                                {SIGNIN.FORGOT_PASSWORD}
                            </LinkText>
                        </Link>
                    </StyledForm>
                </SigninContainer>
            )}
        </Container>
    );
}
