//react
import { useEffect } from "react";

//components
import UpdatedLineChart from "./daystoreturn/LineChart";
import "chart.js/auto";
import ErrorBlock from "./common/ErrorBlock";
import Errorable from "../common/Errorable";
import Loadable from "../common/Loadable";
import ChartSpinner from "./common/ChartSpinner";
import Footer from "./common/Footer";
import ChartHeader from "./common/ChartHeader";

//styles
import styled from "styled-components/macro";
import { useTheme } from "../../styling/styled-components";

//utils
import { checkLastUpdatedDate } from "../../utils/chart/checkLastUpdatedDate";

//apis
import * as queries from "../../graphql/queries";
import { useQuery } from "../../graphql/hooks/query";

//constants
import { DAYS_TO_RETURN_SUMMARY } from "../../constants/dashboard";

const Container = styled.div``;

const Chart = styled.div`
    cursor: pointer;
    padding-top: ${({ theme }) => theme.spacing.large}px;
    z-index: -1;
    height: 450px;
`;

const FooterWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
`;

type Props = {
    assetTypeId: string;
};

export default function DaysToReturnChart({ asset }: Props) {
    const [
        queryLoopDurations,
        queryLoopDurationsData,
        queryLoopDurationsLoading,
        queryLoopDurationsErrors,
    ] = useQuery(queries.getStatLoopDurations);
    const assetLabel = asset.name == "All asset types" ? "" : asset.name;

    const { colors } = useTheme();

    useEffect(() => {
        loadQueryLoopDurations();
    }, [asset]);

    const loadQueryLoopDurations = () => {
        queryLoopDurations({
            assetTypeId: asset.assetTypeId,
        });
    };

    queryLoopDurationsErrors && console.error(queryLoopDurationsErrors);

    const labels = queryLoopDurationsData?.loopDurations
        .slice(0, 15)
        .map((item, index) =>
            item.days == 0 ? "Checkout" : "Day" + " " + item.days
        );

    const data = queryLoopDurationsData?.loopDurations.map(
        (result) => result.percentReturned
    );

    const lastUpdated = queryLoopDurationsData?.lastUpdated;

    const LineChart = () => {
        return (
            <Errorable
                error={queryLoopDurationsErrors}
                ErrorComponent={ErrorBlock}
            >
                <Chart>
                    <UpdatedLineChart
                        labels={labels}
                        data={queryLoopDurationsData.isAvailable ? data : []}
                    />
                </Chart>
            </Errorable>
        );
    };

    return (
        <Container>
            <ChartHeader
                header={DAYS_TO_RETURN_SUMMARY.HEADER(assetLabel)}
                subheader={DAYS_TO_RETURN_SUMMARY.SUBHEADER}
            />
            <Loadable
                dataLoaded={queryLoopDurationsData}
                LoadingComponent={ChartSpinner}
            >
                <LineChart />
            </Loadable>
            <FooterWrapper>
                <Footer
                    loading={queryLoopDurationsLoading}
                    lastUpdated={checkLastUpdatedDate(lastUpdated)}
                ></Footer>
            </FooterWrapper>
        </Container>
    );
}

DaysToReturnChart.defaultProps = { hideTitle: false };
