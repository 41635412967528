import { FilteredAggregations } from "../../components/rentalsVisual/common/RentalVisualTypes";

export const totalUpdatedPhaseCounts = (
    filteredAggregations: FilteredAggregations[],
    rentalPhaseKeys: string[]
) => {
    const sum =
        filteredAggregations?.reduce((acc, obj) => {
            const subtotalSum = obj.subtotals?.reduce((subtotalAcc, sub) => {
                if (rentalPhaseKeys.includes(sub.key)) {
                    return subtotalAcc + sub.docCount;
                }
                return subtotalAcc;
            }, 0);
            return acc + subtotalSum;
        }, 0) || 0;

    return sum;
};
