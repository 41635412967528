//react
import { useEffect, useRef } from "react";

//components
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ChartData } from "chart.js";

//styles
import { useTheme } from "../../../styling/styled-components";

//style
import { axisTitles } from "../sharedLineChartElements/axisTitles";
import { lightBlueTooltipStyles } from "../sharedLineChartElements/lightBlueTooltipStyles";
import { hoverOptions } from "../sharedLineChartElements/hoverOptions";
import { legendOptions } from "../sharedLineChartElements/legendOptions";
import { titleOptions } from "../sharedLineChartElements/titleOptions";

type Props = {
    datasets: ChartData<"bar">;
    labels: string[];
    settings: ChartSettings;
};

type ChartSettings = {
    toolTipText?: (
        xAxisValue: string,
        optionLabel: string,
        value: number
    ) => string;
};

export default function DoubleLineChart({ labels, datasets, settings }: Props) {
    const chartRef = useRef<ChartJS>(null);
    const chart = chartRef.current;
    const { colors } = useTheme();

    let firstOptionData = datasets[0]?.data;
    let secondOptionData = datasets[1]?.data;

    let firstOptionLabel = datasets[0]?.label;
    let secondOptionLabel = datasets[1]?.label;

    let dataSeries = {
        labels: labels,
        datasets: [
            {
                borderColor: colors.MOUNTAIN_HAZE,
                backgroundColor: colors.MOUNTAIN_HAZE,
                data: firstOptionData,
                label: firstOptionLabel,
            },
            {
                borderColor: colors.DEEP_BLUE_SEA,
                backgroundColor: colors.DEEP_BLUE_SEA,
                data: secondOptionData,
                label: secondOptionLabel,
            },
        ],
    };

    const onResize = () => {
        if (!chart) {
            return;
        }
        chart.resize();
    };

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 2,
            },
        },
        onHover: (events, chartElements) => {
            if (chartRef.current) {
                chartRef.current.data.datasets.forEach(
                    (dataset, datasetIndex) => {
                        const bars =
                            chartRef.current.getDatasetMeta(datasetIndex).data;
                        bars.forEach((bar, index) => {
                            const hoverOff = () => {
                                chartRef.current
                                    .getDatasetMeta(datasetIndex)
                                    .controller.removeHoverStyle(bar);
                            };
                            const hoverOn = () => {
                                chartRef.current
                                    .getDatasetMeta(datasetIndex)
                                    .controller.setHoverStyle(bar);
                            };
                            const removeHover =
                                !chartElements || !chartElements[0];
                            if (datasets.length <= 1) {
                                settings.onHover(!removeHover);
                            } else {
                                if (
                                    removeHover ||
                                    chartElements[0].index == index
                                ) {
                                    hoverOff();
                                    return;
                                } else {
                                    hoverOn();
                                    return;
                                }
                            }
                        });
                    }
                );
            }
        },
        scales: {
            x: {
                title: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                title: {
                    ...axisTitles("Total number of assets"),
                },
                grid: {
                    display: true,
                    drawBorder: false,
                    borderDash: [2, 2],
                    drawOnChartArea: true,
                    drawTicks: false,
                },
                min: 0,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: (tooltipItem, _) => {
                        return "";
                    },
                    label: (tooltipItem) => {
                        let xAxisValue = tooltipItem.label;
                        let optionLabel = tooltipItem.dataset.label;

                        return settings.toolTipText(
                            xAxisValue,
                            optionLabel,
                            tooltipItem.raw
                        );
                    },
                    labelTextColor: function () {
                        return colors.ONYX;
                    },
                },
                bodyFont: {
                    size: 11,
                    weight: 500,
                    style: "normal",
                    lineHeight: "normal",
                },
                ...lightBlueTooltipStyles(),
            },
            hover: hoverOptions,
            legend: legendOptions,
            title: titleOptions,
            filler: {
                propagate: false,
            },
        },
    };

    return (
        <Line
            ref={chartRef}
            datasetIdKey="id"
            options={options}
            data={dataSeries}
        />
    );
}
