import { RentalPhase, RentalStatus } from "../../API";

export const checkBorderColor = (currentPhase, lastPhase) => {
    //border prop doesn't work with the themes so directly using the colors here
    if (RentalStatus.Completed == currentPhase) {
        return "1px solid var(--ui-colors-green-1, #295732)";
    }
    if (
        RentalStatus.Canceled == currentPhase ||
        RentalStatus.CanceledWithRefund == currentPhase
    ) {
        return "1px solid var(--greys-ash, #36393F)";
    }
    if (currentPhase == lastPhase) {
        return "1px solid var(--greys-ash, #1745cf)";
    } else {
        return `1px solid var(--greys-marine-layer, #c2cfe0)`;
    }
};
