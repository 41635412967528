//react
import { useEffect, useRef } from "react";

//components
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ChartData } from "chart.js";

//styles
import { useTheme } from "../../../styling/styled-components";

type Props = {
    datasets: ChartData<"bar">;
    labels: string[];
    settings: ChartSettings;
};

type ChartSettings = {
    maintainAspectRatio?: boolean;
    responsive?: boolean;
    onHover?: (hover: boolean) => void;
    showLegend?: boolean;
    xAxisLabel?: string;
    yAxisLabel?: string;
    toolTipText?: (
        xAxisValue: string,
        optionLabel: string,
        value: number
    ) => string;
    toolTipBackgroundColor?: string;
    toolTipEnabled?: boolean;
};

export default function BarChart({ labels, datasets, settings }: Props) {
    const chartRef = useRef<ChartJS>(null);
    const chart = chartRef.current;
    const dataSeries = {
        labels,
        datasets,
    };

    const onResize = () => {
        if (!chart) {
            return;
        }
        chart.resize();
    };

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const { colors, spacing } = useTheme();

    const options = {
        responsive: settings.responsive,
        maintainAspectRatio: settings.maintainAspectRatio,
        onHover: (events, chartElements) => {
            if (chartRef.current) {
                chartRef.current.data.datasets.forEach(
                    (dataset, datasetIndex) => {
                        const bars =
                            chartRef.current.getDatasetMeta(datasetIndex).data;
                        bars.forEach((bar, index) => {
                            const hoverOff = () => {
                                chartRef.current
                                    .getDatasetMeta(datasetIndex)
                                    .controller.removeHoverStyle(bar);
                            };
                            const hoverOn = () => {
                                chartRef.current
                                    .getDatasetMeta(datasetIndex)
                                    .controller.setHoverStyle(bar);
                            };
                            const removeHover =
                                !chartElements || !chartElements[0];
                            if (datasets.length <= 1) {
                                settings.onHover(!removeHover);
                            } else {
                                if (
                                    removeHover ||
                                    chartElements[0].index == index
                                ) {
                                    hoverOff();
                                    return;
                                } else {
                                    hoverOn();
                                    return;
                                }
                            }
                        });
                    }
                );
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: settings.xAxisLabel,
                    color: colors.ROCK_BOTTOM,
                    font: {
                        family: "poppins",
                        size: 11,
                        weight: 500,
                    },
                    padding: spacing.medium,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                title: {
                    display: true,
                    text: settings.yAxisLabel,
                    color: colors.ROCK_BOTTOM,
                    font: {
                        family: "poppins",
                        size: 11,
                        weight: 500,
                    },
                    padding: spacing.medium,
                },
                grid: {
                    display: true,
                    drawBorder: false,
                    borderDash: [2, 2],
                    drawOnChartArea: true,
                    drawTicks: false,
                },
                ticks: {
                    precision: 0,
                },
            },
        },
        plugins: {
            tooltip: {
                enabled: settings.toolTipEnabled,
                events: ["click"],
                callbacks: {
                    title: (tooltipItem, _) => {
                        return "";
                    },
                    label: (tooltipItem) => {
                        let xAxisValue = tooltipItem.label;
                        let optionLabel = tooltipItem.dataset.label;

                        return settings.toolTipText(
                            xAxisValue,
                            optionLabel,
                            tooltipItem.raw
                        );
                    },
                    labelTextColor: function () {
                        return colors.ONYX;
                    },
                },
                bodyFont: {
                    size: 11,
                    weight: 500,
                    style: "normal",
                    lineHeight: "normal",
                },
                usePointStyle: true,
                backgroundColor: settings.toolTipBackgroundColor,
                displayColors: false,
                cornerRadius: spacing.small,
                padding: spacing.small,
                caretSize: spacing.xsmall,
                pointer: "cursor",
            },
            legend: {
                display: settings.showLegend,
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    color: colors.ROCK_BOTTOM,
                    font: {
                        family: "poppins",
                        lineHeight: 16.5,
                        size: 11,
                        weight: 500,
                    },
                },
            },
            title: {
                display: false,
            },
            filler: {
                propagate: false,
            },
        },
    };

    return (
        <Bar
            ref={chartRef}
            datasetIdKey="id"
            options={options}
            data={dataSeries}
        />
    );
}
