//style
import styled, { useTheme } from "../../../../styling/styled-components";

//components
import Text from "../../../common/Text";
import { Switch } from "@mui/material";
import Loadable from "../../../common/Loadable";
import ShimmerCard from "../../../common/ShimmerCard";
import ToggleShimmerCard from "../../../common/ToggleShimmerCard";

//react
import { useState, useEffect } from "react";

const ToggleSection = styled.div`
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

type Props = {
    headerText: string;
    toggleOnChange: () => void;
    initialState: boolean;
};

export default function ForgivenessToggle({
    headerText,
    toggleOnChange,
    initialState,
}: Props) {
    const { colors } = useTheme();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 1500);
    }, []);

    function StyledShimmerCard() {
        return <ShimmerCard width={220} height={20} />;
    }

    function StyledToggleShimmerCard() {
        return <ToggleShimmerCard width={47} height={28} />;
    }

    return (
        <ToggleSection>
            <Loadable dataLoaded={loaded} LoadingComponent={StyledShimmerCard}>
                <Text
                    type="header"
                    size="smallest"
                    color={colors.DEEP_BLUE_SEA}
                >
                    {headerText}
                </Text>
            </Loadable>
            <Loadable
                dataLoaded={loaded}
                LoadingComponent={StyledToggleShimmerCard}
            >
                <Switch
                    checked={initialState}
                    onChange={() => toggleOnChange()}
                />
            </Loadable>
        </ToggleSection>
    );
}
