//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import ShimmerCard from "../../common/ShimmerCard";
import Loadable from "../../common/Loadable";
import VerticalLine from "../../common/VerticalLine";

//constants
import { CHECKOUTS_RETURN_SUMMARY } from "../../../constants/dashboard";

//utils
import { checkStatWindowValue } from "../../../utils/chart/checkStatWindowValue";

const StatsWrapper = styled.div`
    display: inline-flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.medium}px;
    padding-left: ${({ theme }) => theme.spacing.large}px;
`;

const StatsHeader = styled(Text)`
    padding: ${({ theme }) => theme.spacing.tiny}px;
`;

const PecentageWrapper = styled.div`
    display: flex;
    padding-left: ${({ theme }) => theme.spacing.tiny}px;
`;

const LineDiv = styled.div`
    padding-right: ${({ theme }) => theme.spacing.huge}px;
`;

type Props = {
    assetTypeId: string;
    dateRange: string[];
};

export default function CheckoutReturnStats({ data, loopStats }: Props) {
    const { colors } = useTheme();
    const totalCountOpen = data ? data.totalOpenedForWindow : "";
    const totalCountClosed = data ? data.totalClosedForWindow : "";
    let dateWindowLabel = checkStatWindowValue(
        data?.statWindow,
        data?.statWindow[0]
    );

    const TotalAverageStats = ({ totalCount, percentage, header }) => {
        const percentageGreaterThan100 =
            percentage >= 100 ? `>+100%` : `+${percentage}%`;
        const percentageGreaterThanNegative100 =
            percentage <= -100 ? `>-100%` : `${percentage}%`;
        const percentageSign = percentage
            ? percentage > 0
                ? percentageGreaterThan100
                : percentageGreaterThanNegative100
            : "-";
        const statColor = percentage
            ? percentage > 0
                ? colors.GREEN_2
                : colors.RED_2
            : colors.DEEP_BLUE_SEA;

        return (
            <div>
                <Loadable
                    dataLoaded={data}
                    LoadingComponent={StyledShimmerCard}
                >
                    <StatsHeader
                        type="label"
                        size="medium"
                        color={colors.BLACK}
                    >
                        {header}
                    </StatsHeader>
                </Loadable>
                <Loadable
                    dataLoaded={data}
                    LoadingComponent={StyledShimmerCard}
                >
                    <StatsHeader
                        type="header"
                        size="xlarge"
                        color={colors.BLACK}
                    >
                        {totalCount.toLocaleString()}
                    </StatsHeader>
                </Loadable>
                <Loadable
                    dataLoaded={data}
                    LoadingComponent={StyledShimmerCard}
                >
                    <PecentageWrapper>
                        <StatsHeader
                            type="header"
                            size="small"
                            color={statColor}
                        >
                            {percentageSign}
                        </StatsHeader>
                        <StatsHeader
                            type="body"
                            size="large"
                            color={colors.BLACK}
                        >
                            {CHECKOUTS_RETURN_SUMMARY.PERCENTAGE_LABEL(
                                dateWindowLabel
                            )}
                        </StatsHeader>
                    </PecentageWrapper>
                </Loadable>
            </div>
        );
    };

    const StyledShimmerCard = (width, height) => {
        return <ShimmerCard width={81} height={30} />;
    };

    return (
        <StatsWrapper>
            <TotalAverageStats
                totalCount={totalCountOpen}
                percentage={loopStats.openedLoopsPercentage}
                header={CHECKOUTS_RETURN_SUMMARY.TOTAL_CHECKOUT_LABEL(
                    dateWindowLabel
                )}
            />
            <VerticalLine height={"50%"} />
            <TotalAverageStats
                totalCount={totalCountClosed}
                percentage={loopStats.closedLoopsPercentage}
                header={CHECKOUTS_RETURN_SUMMARY.TOTAL_RETURNS_LABEL(
                    dateWindowLabel
                )}
            />
            <LineDiv />
            <VerticalLine />
        </StatsWrapper>
    );
}
