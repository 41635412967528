import React from "react";
import { ReactComponent as Close } from "../../assets/vectors/Close.svg";
import { ReactComponent as LeftChevron } from "../../assets/vectors/LeftChevron.svg";
import styled from "styled-components/macro";
type Props = {
    iconName: "close" | "back";
    onClick: () => void;
};

const Round = styled.div`
    background-color: ${({ theme }) => theme.colors.LIME_GREEN};
    width: 40px;
    height: 40px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export default function RoundIconButton({
    iconName,
    onClick,
    ...props
}: Props) {
    const picker = (name: string) => {
        if (name === "close") return <Close />;
        if (name === "back") return <LeftChevron />;
    };

    return (
        <Round {...props} style={[props.style]}>
            {picker(iconName)}
        </Round>
    );
}

RoundIconButton.defaultProps = {
    displayName: "RoundIconButton",
};
