import React from "react";

export default function NewLine() {
    return (
        <hr
            style={{
                position: "relative",
                zIndex: 1,
                border: "none",
                borderTop: "2px solid #D6D8DC",
                width: "100%",
            }}
        />
    );
}
