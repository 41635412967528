import React, { useState, useEffect } from "react";
import useGraphClient from "../../hooks/useGraphClient";
import * as queries from "../../graphql/queries";
import styled, { css } from "styled-components/macro";
import DeprecatedText from "../common/DeprecatedText";
import { prettifyPhoneNumber } from "../../utils/helpers";
import { findTextStyles } from "../../styling/styled-components";
import { CONSUMER_GLANCE } from "../../constants/consumers";

const Container = styled.div`
    flex-direction: row;
    display: flex;
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            flex-direction: column;
        `}
`;

const CharcoalText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const GreyText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ASPHALT};
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const Divider = styled.div`
    padding: 0 ${({ theme }) => theme.spacing.small}px;
`;

const Item = styled.div`
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding: ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.large}px;
    border-radius: 4px;
    flex: 1;
`;

export default function Component(props) {
    const [isLoading, setLoading] = useState(true);
    const [consumer, setConsumer] = useState({});

    const graphClient = useGraphClient();

    useEffect(() => {
        load();
    }, [props.consumerId]);

    const load = () => {
        setLoading(true);
        graphClient
            .graphql({
                query: queries.getConsumer,
                variables: {
                    consumerId: props.consumerId,
                },
            })
            .then((result) => {
                setConsumer(result.data.getConsumer);
                setLoading(false);
            })
            .catch((err) => {
                console.error("Error getting consumer", err);
                setLoading(false);
            });
    };

    if (isLoading || !consumer) return null;

    return (
        <Container>
            <Item>
                <GreyText type="label" size="small">
                    {CONSUMER_GLANCE.EXTERNALID}
                </GreyText>
                <CharcoalText type="body" size="medium">
                    {consumer.externalId}
                </CharcoalText>
            </Item>
            <Divider />
            <Item>
                <GreyText type="label" size="small">
                    {CONSUMER_GLANCE.PROFILE}
                </GreyText>
                <CharcoalText type="body" size="medium">
                    {CONSUMER_GLANCE.NAME(consumer.name)}
                </CharcoalText>
                <CharcoalText type="body" size="medium">
                    {CONSUMER_GLANCE.NUMBER(
                        prettifyPhoneNumber(consumer.phoneNumber)
                    )}
                </CharcoalText>
            </Item>
        </Container>
    );
}
