//components
import Text from "../../common/Text";

//styles
import styled, { useTheme } from "../../../styling/styled-components";

const HeaderText = styled(Text)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

export default function ChartHeader({ header, subheader }) {
    const { colors } = useTheme();

    return (
        <>
            <HeaderText type="header" size="medium" color={colors.BLACK}>
                {header}
            </HeaderText>
            <Text type="body" size="medium" color={colors.EXHAUST}>
                {subheader}
            </Text>
        </>
    );
}
