//components
import ForgivenessDetailsCard from "./common/ForgivenessCardDetails";
import ForgivenessToggle from "./common/ForgivenessToggle";

//constants
import { FORGIVENESS_POLICY } from "../../../constants/forgiveness";

//apis
import * as mutations from "../../../graphql/mutations";
import useGraphClient from "../../../hooks/useGraphClient";

//react
import { useState, useEffect } from "react";

type Props = {
    returnReceivedForgivenessOn: boolean;
    organizationId: string;
    forgivenessSettingsChanged: () => void;
};

export default function ReturnReceivedCard({
    returnReceivedForgivenessOn,
    organizationId,
    forgivenessSettingsChanged,
}: Props) {
    const headerText = returnReceivedForgivenessOn
        ? FORGIVENESS_POLICY.RETURN_RECEIVED_FORGIVEN_ON
        : FORGIVENESS_POLICY.RETURN_RECEIVED_FORGIVEN_OFF;

    const [
        returnReceivedForgivenessToggleSetting,
        setReturnReceivedForgivenessToggleSetting,
    ] = useState(returnReceivedForgivenessOn);

    const graphClient = useGraphClient();

    useEffect(() => {
        setReturnReceivedForgivenessToggleSetting(returnReceivedForgivenessOn);
    }, [headerText]);

    const toggleReturnReceivedForgivenessSetting = async () => {
        try {
            const result = await graphClient.graphql({
                query: mutations.updateOrganizationReturnReceivedForgivenessEnabled,
                variables: {
                    input: {
                        organizationId: organizationId,
                        returnReceivedForgivenessEnabled:
                            !returnReceivedForgivenessToggleSetting,
                    },
                },
            });
            setReturnReceivedForgivenessToggleSetting(
                result?.returnReceivedForgivenessEnabled
            );
            forgivenessSettingsChanged();
        } catch (err) {
            console.error(
                "Error updating Return Received Forgiveness Setting",
                err
            );
        }
    };

    return (
        <ForgivenessDetailsCard
            forgivenessDescription={
                FORGIVENESS_POLICY.RETURN_RECEIVED_FORGIVENESS_DETAILS
            }
            forgivenessExample={FORGIVENESS_POLICY.RETURN_RECEIVED_EXAMPLE_CASE}
        >
            <ForgivenessToggle
                headerText={headerText}
                toggleOnChange={toggleReturnReceivedForgivenessSetting}
                initialState={returnReceivedForgivenessToggleSetting}
            />
        </ForgivenessDetailsCard>
    );
}
