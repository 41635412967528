import styled, {
    useTheme,
    findTextStyles,
} from "../../../../styling/styled-components";
import FormTextField from "../../../common/FormTextField";

const InputDiv = styled.div`
    width: 528px;
    padding-top: 30px;
`;

const OtherInputText = styled(FormTextField)`
    flex: 1;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.13px;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

export default function OtherInput({ otherReason, setOtherReason }) {
    const handleAssetNameChange = (event) => {
        const text = event.target.value;
        setOtherReason(text);
    };

    return (
        <InputDiv>
            <OtherInputText
                width="large"
                maxLength={150}
                value={otherReason}
                hintText="Reason description"
                onChange={handleAssetNameChange}
                secureTextEntry={false}
                autoCorrect={false}
                autoCapitalize="none"
                returnKeyType="done"
            ></OtherInputText>
        </InputDiv>
    );
}
