//style
import styled from "../../../styling/styled-components";

//components
import Text from "../../common/Text";

const StepNumberText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

export function LocationText({ children, type, size, color }) {
    return (
        <StepNumberText type={type} size={size} color={color}>
            {children}
        </StepNumberText>
    );
}
