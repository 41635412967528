import { generateClient } from "aws-amplify/api";
import { GraphQLResult } from "aws-amplify/api";
import React from "react";
import { graphErrorDetails } from "../../utils/helpers";

const getDataObject = (data: GraphQLResult) =>
    data ? Object.values(data.data)[0] : data; // this is to get to the meaty data and avoid having to destructure for each query based on its name

export const useQuery = (
    query: string
): [
    any | null,
    any | null,
    boolean,
    object[] | boolean | null,
    object[] | object | null
] => {
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState<object | null>(null);
    const [data, setData] = React.useState<object | null>(null);

    const graphClient = generateClient();

    const queryCallback = React.useCallback(
        (variables: any) => {
            const load = async () => {
                setLoading(true);
                try {
                    const results = await graphClient.graphql({
                        query,
                        variables,
                    });

                    setData(results);
                    setErrors(null);
                } catch (error: any) {
                    const [_errorType, errorMessage] = graphErrorDetails(
                        error?.errors
                    );
                    if (errorMessage === "Network Error") {
                        console.warn(
                            "Network Error for useQuery request.",
                            error
                        );
                        setErrors(error);
                    } else {
                        setErrors(error);
                    }
                } finally {
                    setLoading(false);
                }
            };
            load();
        },
        [query]
    );

    return [queryCallback, getDataObject(data), loading, errors, setData];
};
