//react
import React, { useEffect } from "react";

//components
import ErrorBlock from "./common/ErrorBlock";
import RateChart from "./returnrate/RateChart";
import RateLine from "./returnrate/RateLine";
import Errorable from "../common/Errorable";
import Footer from "./common/Footer";
import ChartHeader from "./common/ChartHeader";
import Container from "./common/Container";

//styles
import styled from "../../styling/styled-components";

//utils
import { useReturnRateStatistics } from "../../hooks/useReturnRateStatistics";
import { checkLastUpdatedDate } from "../../utils/chart/checkLastUpdatedDate";

//constants
import { RETURN_RATE_SUMMARY } from "../../constants/dashboard";

const Header = styled.div`
    flex: 1;
`;

const Body = styled.div`
    padding: ${({ theme }) => theme.spacing.large}px 0px;
    flex: 4;
    display: flex;
    ${({ theme }) =>
        theme.isTablet ? "flex-direction: row;" : "flex-direction: column;"}
`;

const AllTimeWrapper = styled.div``;

export default function ReturnRateSummary({ asset }) {
    const [
        queryReturnRates,
        queryReturnRatesData,
        lastUpdated,
        isAvailable,
        queryReturnRatesLoading,
        queryReturnRatesErrors,
    ] = useReturnRateStatistics();
    const assetLabel = asset.name == "All asset types" ? "" : asset.name;

    useEffect(() => {
        queryReturnRates(asset);
    }, [asset]);

    const allTimeResult = queryReturnRatesData.find(
        (result) => result.key == "ALL_TIME"
    );

    const day30Result = queryReturnRatesData.find(
        (result) => result.key == "D_30"
    );

    const day60Result = queryReturnRatesData.find(
        (result) => result.key == "D_60"
    );

    const day90Result = queryReturnRatesData.find(
        (result) => result.key == "D_90"
    );

    const ReturnRateChart = () => {
        return (
            <Errorable
                error={queryReturnRatesErrors}
                ErrorComponent={ErrorBlock}
            >
                <Body>
                    <AllTimeWrapper>
                        <RateChart
                            item={allTimeResult}
                            isShown={isAvailable}
                            loading={queryReturnRatesLoading}
                        ></RateChart>
                    </AllTimeWrapper>
                    <div>
                        <RateLine item={day30Result}></RateLine>

                        <RateLine item={day60Result}></RateLine>

                        <RateLine item={day90Result}></RateLine>
                    </div>
                </Body>
            </Errorable>
        );
    };

    return (
        <Container>
            <Header>
                <ChartHeader
                    header={RETURN_RATE_SUMMARY.HEADER(assetLabel)}
                    subheader={RETURN_RATE_SUMMARY.SUBHEADER}
                ></ChartHeader>
            </Header>
            <ReturnRateChart />
            <Footer
                loading={queryReturnRatesLoading}
                lastUpdated={checkLastUpdatedDate(lastUpdated)}
            ></Footer>
        </Container>
    );
}
