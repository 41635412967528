//react
import React from "react";

//images
import { ReactComponent as Delete } from "../../assets/vectors/Delete.svg";

//components
import Text from "../common/Text";
import FormTextField from "../common/FormTextField";

//style
import styled, {
    useTheme,
    findTextStyles,
} from "../../styling/styled-components";

//constants
import { METADATA_FORM } from "../../constants/assets";

export const TAG_FORM = {
    DELETE_METADATA: "Delete",
    ERROR_MISSING_KEY: "Please enter a key to create the tag.",
    ERROR_MISSING_VALUE: "Please enter a value to create the tag.",
    LABEL_METADATA_KEY: "Key",
    LABEL_METADATA_VALUE: "Value",
    PLACEHOLDER_METADATA_KEY: "eg. Manager",
    PLACEHOLDER_METADATA_VALUE: "eg.manager.1@example.com",
};

const NewMetaDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: ${({ theme }) => theme.spacing.medium}px
        ${({ theme }) => theme.spacing.large}px;
    padding-bottom: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const AnimatedNewMetaDataWrapper = styled.div`
    gap: ${({ theme }) => theme.spacing.xxlarge}px;
`;
const DeleteMetaDataWrapper = styled.div`
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    padding-left: ${({ theme }) => theme.spacing.yuge}px;
`;

const DeleteLabel = styled(Text)`
    margin-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const TagDiv = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing.medium}px
        ${({ theme }) => theme.spacing.large}px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.small}px;
    align-self: stretch;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.SMOG};
    margin-left: ${({ theme }) => theme.spacing.small}px;
`;

const InputFormDiv = styled(FormTextField)`
    display: flex;
    padding: ${({ theme }) => theme.spacing.small}px;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    border-radius: 4px;
`;

const InputStyle = styled.div`
    display: flex;
    justify-content: center;
`;

const TagDetails = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const InfoDiv = styled.div`
    display: flex;
`;

const ErrorMessage = styled(Text)`
    position: relative;
    margin: 0px;
`;

type Props = {
    callbackUpdateNewMetadata: () => void;
    newMetaData: Record<string, unknown>;
    shouldShowMetadataErrors?: boolean;
    toggleMetadataSection: () => void;
};

export default function Tag({
    callbackUpdateNewMetadata,
    newMetaData,
    toggleMetadataSection,
    shouldShowMetadataErrors,
    setShowTags,
}: Props) {
    const { colors, spacing } = useTheme();

    const emptyMetadata = {
        key: "",
        value: "",
        isNew: true,
    };

    const deleteMetadata = (index) => {
        if (newMetaData?.length >= 1) {
            const updatedMetadata = [...newMetaData];
            updatedMetadata.splice(index, 1);
            callbackUpdateNewMetadata(updatedMetadata);
        } else {
            setShowTags(false);
            toggleMetadataSection();
            callbackUpdateNewMetadata([emptyMetadata]);
        }
    };
    const renderNewMetadataRow = (el, index) => {
        const missingKey = !newMetaData[index]["key"];
        const missingValue = !newMetaData[index]["value"];

        const handleMetadataKeyChange = (
            event: React.ChangeEvent<HTMLInputElement>
        ) => {
            const text = event.target.value;
            const updatedMetadataArr = [...newMetaData];
            updatedMetadataArr[index]["key"] = text;
            callbackUpdateNewMetadata(updatedMetadataArr);
        };

        const handleMetadataValueChange = (
            event: React.ChangeEvent<HTMLInputElement>
        ) => {
            const text = event.target.value;
            const updatedMetadataArr = [...newMetaData];
            updatedMetadataArr[index]["value"] = text;
            callbackUpdateNewMetadata(updatedMetadataArr);
        };
        return (
            <>
                <div
                    key={`metadata_row__${index}`}
                    style={{ paddingBottom: spacing.small }}
                >
                    <InfoDiv></InfoDiv>
                    <TagDiv>
                        <InfoDiv>
                            <TagDetails
                                type="label"
                                size="small"
                                color={colors.ASH}
                            >
                                {"Tag details"}
                            </TagDetails>
                            <DeleteMetaDataWrapper>
                                <div
                                    style={{
                                        display: "flex",
                                        cursor: "pointer",
                                        width: 60,
                                    }}
                                    onClick={() => deleteMetadata(index)}
                                >
                                    <Delete />
                                    <DeleteLabel
                                        onClick={deleteMetadata}
                                        type="other"
                                        size="medium"
                                        color={colors.WAVE_STORM}
                                    >
                                        {METADATA_FORM.DELETE_METADATA}
                                    </DeleteLabel>
                                </div>
                            </DeleteMetaDataWrapper>
                        </InfoDiv>
                        <InputStyle>
                            <InputFormDiv
                                value={newMetaData[index]["key"]}
                                onChange={handleMetadataKeyChange}
                                placeholder={TAG_FORM.PLACEHOLDER_METADATA_KEY}
                                secureTextEntry={false}
                                autoCapitalize="none"
                                returnKeyType="done"
                                hintText={TAG_FORM.LABEL_METADATA_KEY}
                                error={TAG_FORM.ERROR_MISSING_KEY}
                                hasError={
                                    shouldShowMetadataErrors && missingKey
                                        ? true
                                        : false
                                }
                                width="short"
                            />
                        </InputStyle>
                        <InputStyle>
                            <InputFormDiv
                                value={newMetaData[index]["value"]}
                                onChange={handleMetadataValueChange}
                                keyboardType="numeric"
                                placeholder={
                                    TAG_FORM.PLACEHOLDER_METADATA_VALUE
                                }
                                secureTextEntry={false}
                                autoCapitalize="none"
                                returnKeyType="done"
                                hintText={TAG_FORM.LABEL_METADATA_VALUE}
                                error={TAG_FORM.ERROR_MISSING_VALUE}
                                hasError={
                                    shouldShowMetadataErrors && missingValue
                                        ? true
                                        : false
                                }
                                width="short"
                            />
                        </InputStyle>
                    </TagDiv>
                </div>
            </>
        );
    };

    return (
        <NewMetaDataWrapper>
            <AnimatedNewMetaDataWrapper>
                {newMetaData.map((el, index) => {
                    return renderNewMetadataRow(el, index);
                })}
            </AnimatedNewMetaDataWrapper>
        </NewMetaDataWrapper>
    );
}
