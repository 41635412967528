import React, { ReactNode } from "react";

//components
import ConditionalRenderable from "./ConditionalRenderable";

type Props = {
    error: boolean;
    ErrorComponent: React.ComponentType;
    children: ReactNode;
};

export default function Errorable({ error, ErrorComponent, children }: Props) {
    return (
        <ConditionalRenderable
            condition={!error}
            AlternateComponent={ErrorComponent}
            children={children}
        />
    );
}
