//react
import React, { useState, useRef, useEffect } from "react";

//components
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";

//style
import { useTheme } from "../../../styling/styled-components";

//custom chart elements
import { lineAndPointRadiusStyles } from "../sharedLineChartElements/lineAndPointRadiusStyles";
import { axisTicksExhaustStyles } from "../sharedLineChartElements/axisTicksExhaustStyles";
import { bluePointStyles } from "../sharedLineChartElements/bluePointStyles";
import { axisTitles } from "../sharedLineChartElements/axisTitles";
import { darkBlueTooltipStyles } from "../sharedLineChartElements/darkBlueTooltipStyles";
import { hoverOptions } from "../sharedLineChartElements/hoverOptions";
import { legendOptions } from "../sharedLineChartElements/legendOptions";
import { titleOptions } from "../sharedLineChartElements/titleOptions";

//utils
import { escapeHandleKeyDown } from "../../../utils/chart/escapeHandleKeyDown";
import { showTooltip } from "../../../utils/chart/showTooltip";
import { hideTooltip } from "../../../utils/chart/hideTooltip";
import { titleCallback } from "../../../utils/chart/titleCallback";

//constants
import { DAYS_TO_RETURN_SUMMARY } from "../../../constants/dashboard";

type Props = {
    data: number[];
    labels: string[];
};

export default function Component({ labels, data }: Props) {
    const chartRef = useRef<ChartJS>(null);
    let hideFirstPoint = true;

    const [options, setOptions] = useState({});

    const formatYLabel = (val) => {
        return `${(val * 100).toFixed(0)}%`;
    };

    const { colors, spacing } = useTheme();
    const dataSeries = {
        labels,
        datasets: [
            {
                label: "",
                data,
                ...bluePointStyles(),
            },
        ],
    };

    useEffect(() => {
        let tooltip = chartRef.current ? chartRef.current.tooltip : null;

        document.addEventListener("keydown", (event) =>
            escapeHandleKeyDown(event.key, chartRef.current, tooltip)
        );
        return () => {
            document.removeEventListener("keydown", (event) =>
                escapeHandleKeyDown(event.key, chartRef.current, tooltip)
            );
        };
    }, []);

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        setOptions({
            responsive: true,
            maintainAspectRatio: false,
            elements: lineAndPointRadiusStyles(hideFirstPoint),
            scales: {
                y: {
                    ticks: {
                        callback: (val, index) => formatYLabel(val),
                        ...axisTicksExhaustStyles(),
                    },
                    title: {
                        ...axisTitles(DAYS_TO_RETURN_SUMMARY.Y_AXIS),
                    },
                },
                x: {
                    ticks: axisTicksExhaustStyles(),
                    title: {
                        ...axisTitles(DAYS_TO_RETURN_SUMMARY.X_AXIS),
                    },
                },
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => titleCallback(),
                        label: (tooltipItem, data) => {
                            if (tooltipItem.raw)
                                return [
                                    `${formatYLabel(
                                        tooltipItem.raw
                                    )} of assets`,
                                    `returned by ${tooltipItem.label}`,
                                ];
                        },
                        //hides the tooltip of the first data point on graph
                        filter: function (tooltipItem) {
                            return tooltipItem.dataIndex !== 0;
                        },
                    },
                    ...darkBlueTooltipStyles(),
                },
                hover: hoverOptions,
                legend: legendOptions,
                title: titleOptions,
                filler: {
                    propagate: false,
                },
            },
            onClick: (event, chartElements) => {
                let clickedOutsidePoint =
                    !chartElements || chartElements.length == 0;
                if (clickedOutsidePoint) {
                    hideTooltip(chart);
                }
                if (!clickedOutsidePoint) {
                    showTooltip(chart);
                }
            },
        });
        chart.resize();
    }, [data, labels]);

    return (
        <>
            <Line
                ref={chartRef}
                options={options}
                data={dataSeries}
                onClick={options.onClick}
            />
        </>
    );
}
