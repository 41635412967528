import { FilteredAggregations } from "../../components/rentalsVisual/common/RentalVisualTypes";

export const showSelectedStatusDetailsPhaseView = (
    filteredAggregations: FilteredAggregations[],
    visualWidth: number
) => {
    if (filteredAggregations) {
        filteredAggregations[0].displayStatus = true;
        filteredAggregations[0].displayPercentage = false;
        filteredAggregations[0].totalVisualWidth = visualWidth;
    }
};
