//components
import ForgivenessDetailsCard from "./common/ForgivenessCardDetails";
import ForgivenessToggle from "./common/ForgivenessToggle";

//constants
import { FORGIVENESS_POLICY } from "../../../constants/forgiveness";

//apis
import * as mutations from "../../../graphql/mutations";
import useGraphClient from "../../../hooks/useGraphClient";

//react
import { useState, useEffect } from "react";

type Props = {
    instantForgivenessOn: boolean;
    organizationId: string;
    forgivenessSettingsChanged: () => void;
};

export default function InstantForgivenessCard({
    instantForgivenessOn,
    organizationId,
    forgivenessSettingsChanged,
}: Props) {
    const headerText = instantForgivenessOn
        ? FORGIVENESS_POLICY.INSTANT_FORGIVEN_ON
        : FORGIVENESS_POLICY.INSTANT_FORGIVEN_OFF;

    const [
        instantForgivenessToggleSetting,
        setInstantForgivenessToggleSetting,
    ] = useState(instantForgivenessOn);

    useEffect(() => {
        setInstantForgivenessToggleSetting(instantForgivenessOn);
    }, [headerText]);

    const graphClient = useGraphClient();

    const toggleInstantForgivenessSetting = async () => {
        try {
            const result = await graphClient.graphql({
                query: mutations.updateOrganizationInstantForgivenessEnabled,
                variables: {
                    input: {
                        organizationId: organizationId,
                        instantForgivenessEnabled:
                            !instantForgivenessToggleSetting,
                    },
                },
            });
            setInstantForgivenessToggleSetting(
                result?.instantForgivenessEnabled
            );
            forgivenessSettingsChanged();
        } catch (err) {
            console.error("Error updating Instant Forgiveness Setting", err);
        }
    };

    return (
        <ForgivenessDetailsCard
            forgivenessDescription={
                FORGIVENESS_POLICY.INSTANT_FORGIVENESS_DETAILS
            }
            forgivenessExample={
                FORGIVENESS_POLICY.INSTANT_FORGIVENESS_EXAMPLE_CASE
            }
        >
            <ForgivenessToggle
                headerText={headerText}
                toggleOnChange={toggleInstantForgivenessSetting}
                initialState={instantForgivenessToggleSetting}
            />
        </ForgivenessDetailsCard>
    );
}
