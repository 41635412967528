//api
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { ReturnRate, StatWindow } from "../API";

//react
import React from "react";

//utils
import { graphErrorDetails } from "../utils/helpers";
import { generateClient } from "aws-amplify/api";

const STRINGS = {
    RATE: (days: string) => `Last month (${days} days)`,
    PLURAL_RATE: (days: string, month: string) =>
        `Last ${month} months (${days} days)`,
    ALL_TIME: "All-time",
};

export type ReturnRateResult = {
    percentage: number;
    rateText: string;
    key: string;
    loopsClosed: number;
    loopsOpened: number;
};

const checkReturnWindowDescription = (range) => {
    if (range == StatWindow.AllTime) {
        return STRINGS.ALL_TIME;
    }
    if (range == StatWindow.D_30) {
        return STRINGS.RATE("30");
    }
    if (range == StatWindow.D_60) {
        return STRINGS.PLURAL_RATE("60", "2");
    }
    if (range == StatWindow.D_90) {
        return STRINGS.PLURAL_RATE("90", "3");
    }
};

const formatRateData = (results: ReturnRate): ReturnRateResult[] => {
    let ReturnRateResult = [];
    results.map((item, index) => {
        ReturnRateResult.push({
            percentage: item.returnRate,
            loopsClosed: item.loopsClosed,
            loopsOpened: item.loopsOpened,
            rateText: checkReturnWindowDescription(item.window),
            key: item.window,
        });
    });
    return ReturnRateResult;
};

export const useReturnRateStatistics = (): [
    any | null,
    ReturnRate[] | null,
    string | null,
    boolean,
    object[] | null
] => {
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState<object | null>(null);
    const [data, setData] = React.useState<ReturnRate[]>([]);
    const [lastUpdated, setLastUpdated] = React.useState<string>(null);
    const [isAvailable, setIsAvailable] = React.useState<boolean>(false);

    const graphClient = generateClient();

    const queryCallback = React.useCallback((asset?: string) => {
        const load = async () => {
            setLoading(true);
            try {
                const results = await graphClient.graphql({
                    query: queries.getStatReturnRate,
                    variables: {
                        assetTypeId: asset ? asset.assetTypeId : null,
                    },
                });
                if (results.data.getStatReturnRate.isAvailable) {
                    setData(
                        formatRateData(
                            results.data.getStatReturnRate.returnRates
                        )
                    );
                }
                setLastUpdated(results.data.getStatReturnRate?.lastUpdated);
                setIsAvailable(results.data.getStatReturnRate.isAvailable);
            } catch (error) {
                const [getErrorType, _getErrorMessage] = graphErrorDetails(
                    error?.errors
                );

                //logging to monitor an error we've been seeing on load
                console.error("Error in useReturnRateStatistics", error);
                // when we have not yet calculated return rate for a supplied assetTypeId
                if (getErrorType == "object-does-not-exist") {
                    console.error(
                        "Error in useReturnRateStatistics where object doesn't exist",
                        error
                    );
                    setErrors(error);
                    return;
                }
            } finally {
                setLoading(false);
            }
        };
        load();
    }, []);

    return [queryCallback, data, lastUpdated, isAvailable, loading, errors];
};
