//utils
import { getShortFormattedTime } from "../../../../utils/helpers";

//component
import { SMSDiv } from "../../../common/card/SMSDiv";
import { ScheduledTimeText } from "../../../common/card/ScheduledTimeText";
import { SMSText } from "../../../common/card/SMSText";
import Displayable from "../../../common/Displayable";

//images
import { ReactComponent as SMS } from "../../../../assets/vectors/SMS.svg";
import { ReactComponent as SMSSent } from "../../../../assets/vectors/SMSSent.svg";

//style
import styled from "../../../../styling/styled-components";

const SMSMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const SMSIconWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

export default function CardSMS({
    showDetails,
    sent,
    name,
    date,
    description,
    headerColor,
    dateColor,
    paddingTop = "116px",
}) {
    const SentSMSIcon = () => {
        return (
            <SMSIconWrapper>
                <SMSSent />
            </SMSIconWrapper>
        );
    };
    return (
        <>
            <SMSDiv>
                <Displayable
                    displayPrimaryComponent={!sent}
                    SecondaryComponent={SentSMSIcon}
                >
                    <SMSIconWrapper>
                        <SMS />
                    </SMSIconWrapper>
                </Displayable>
                <SMSMessageWrapper>
                    <SMSText type="body" size="medium" color={headerColor}>
                        {`SMS: ${name}`}
                    </SMSText>
                    <ScheduledTimeText
                        type="body"
                        size="tiny"
                        color={dateColor}
                    >
                        Scheduled to send {getShortFormattedTime(date)}
                    </ScheduledTimeText>
                </SMSMessageWrapper>
            </SMSDiv>
        </>
    );
}
