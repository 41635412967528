import styled from "../../../styling/styled-components";

const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.tiny}px;
`;

export default function TextWrapper({ children }) {
    return <TextDiv>{children}</TextDiv>;
}
