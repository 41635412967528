// external
import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";

// style
import styled from "../../styling/styled-components";

// graphql
import useGraphClient from "../../hooks/useGraphClient";
import * as queries from "../../graphql/queries";

// utils
import { isEmpty } from "../../utils/helpers";

const GreyBox = styled.div`
    position: absolute;
    width: 444px;
    height: 100px;
    top: 120px;
    background-color: ${({ theme }) => theme.colors.SMOG};
`;

const CodeQR = styled.div`
    box-sizing: border-box;
    position: absolute;
    width: 70.62px;
    height: 64px;
    left: 190px;
    top: 15px;
`;

export default function ConsumerQR({ consumer }) {
    const [data, setData] = useState("");

    const graphClient = useGraphClient();

    useEffect(() => {
        if (!isEmpty(consumer)) {
            loadQR();
        }
    }, [consumer]);

    const loadQR = () => {
        graphClient
            .graphql({
                query: queries.consumerQRData,
                variables: {
                    consumerId: consumer.consumerId,
                },
            })
            .then((result) => {
                setData(result.data.consumerQRData.data);
            })
            .catch((err) => {
                console.error("Error getting consumer", err);
            });
    };

    return (
        <GreyBox>
            <CodeQR>
                <QRCode
                    style={{
                        width: "70.62px",
                        height: "64px",
                        border: "1px solid #000000",
                        padding: 2,
                    }}
                    value={data}
                ></QRCode>
            </CodeQR>
        </GreyBox>
    );
}
