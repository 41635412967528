//react
import { useEffect } from "react";

//styles
import styled, { useTheme } from "../../styling/styled-components";

//components
import Footer from "./common/Footer";
import MissedScanStats from "./missedscan/MissedScanStats";
import SubheaderDetails from "./missedscan/SubheaderDetails";
import StatsDescription from "./missedscan/StatsDescription";
import VerticalLine from "../common/VerticalLine";
import ErrorBlock from "./common/ErrorBlock";
import Errorable from "../common/Errorable";
import Container from "./common/Container";
import Text from "../common/Text";

//apis
import { useQuery } from "../../graphql/hooks/query";
import * as queries from "../../graphql/queries";

//constants
import {
    MISSED_SCAN_SUMMARY,
    DASHBOARD_OVERVIEW,
} from "../../constants/dashboard";

//utils
import { checkLastUpdatedDate } from "../../utils/chart/checkLastUpdatedDate";

const Wrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.medium}px;
    align-self: stretch;
    align-items: ${({ theme }) => (theme.isTablet ? "" : "center")};' }
    height: ${({ theme }) =>
        theme.isTablet ? "fit-content" : "max-content"};' }
`;

const StatsDiv = styled.div`
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const StatusLineDiv = styled.div`
    height: 80px;
    ${(props) =>
        props.error &&
        `height: 140px; padding-top:${({ theme }) => theme.spacing.xsmall}px;`}
    @media screen and (max-width: 1024px) {
        ${(props) =>
            props.error
                ? `height: 140px; padding-top:${({ theme }) =>
                      theme.spacing.xsmall}px;`
                : `      height: 90px;`}
    }
    @media screen and (max-width: 870px) {
        ${(props) =>
            props.error
                ? `height: 140px; padding-top:${({ theme }) =>
                      theme.spacing.xsmall}px;`
                : `      height: 96px;`}
    }
    @media screen and (max-width: 840px) {
        ${(props) =>
            props.error
                ? `height: 140px; padding-top:${({ theme }) =>
                      theme.spacing.xsmall}px;`
                : `      height: 110px;`}
    }
`;

export default function MissedScanSummary({ asset }: Props) {
    const { colors } = useTheme();
    const [
        queryStatMissedScans,
        queryStatMissedScansData,
        queryStatMissedScansLoading,
        queryStatMissedScansErrors,
    ] = useQuery(queries.getStatMissedScans);
    const assetLabel = asset.name == "All asset types" ? "" : asset.name;

    //stat variables
    let lastUpdated = queryStatMissedScansData?.lastUpdated;
    let previousPercentageMissed = queryStatMissedScansData?.isAvailable
        ? queryStatMissedScansData?.previousPercentMissed
        : "";
    let previousComparisonWindow = queryStatMissedScansData?.isAvailable
        ? queryStatMissedScansData?.previousComparisonWindow
        : "";
    let missedScanRate = queryStatMissedScansData
        ? queryStatMissedScansData?.isAvailable
            ? Math.round(queryStatMissedScansData?.percentMissed)
            : "—"
        : "—";
    let comparisonStatDescription = queryStatMissedScansData
        ? queryStatMissedScansData?.isAvailable
            ? MISSED_SCAN_SUMMARY.PREVIOUS_STAT(
                  previousComparisonWindow,
                  previousPercentageMissed
              )
            : DASHBOARD_OVERVIEW.LOW_DATA
        : "";

    useEffect(() => {
        queryStatMissedScans({ assetTypeId: asset.assetTypeId });
    }, [asset]);

    const Divider = () => {
        return (
            <StatusLineDiv error={queryStatMissedScansErrors}>
                <VerticalLine />
            </StatusLineDiv>
        );
    };

    const ErrorComponent = () => {
        return <ErrorBlock width={150} height={92} />;
    };

    const StatsCard = () => {
        return (
            <Errorable
                error={queryStatMissedScansErrors}
                ErrorComponent={ErrorComponent}
            >
                <StatsDiv>
                    <Text type="header" size="medium" color={colors.BLACK}>
                        {MISSED_SCAN_SUMMARY.SUBHEADER}
                    </Text>
                    <MissedScanStats missedScanRate={missedScanRate} />
                    <SubheaderDetails
                        statDescription={comparisonStatDescription}
                        assetLabel={assetLabel}
                    />
                </StatsDiv>
            </Errorable>
        );
    };

    return (
        <Container>
            <Wrapper>
                <StatsCard />
                <Divider />
                <StatsDescription missedScanRate={missedScanRate} />
            </Wrapper>
            <StatsDiv>
                <Footer
                    loading={queryStatMissedScansLoading}
                    lastUpdated={checkLastUpdatedDate(lastUpdated)}
                />
            </StatsDiv>
        </Container>
    );
}
