//style
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Card from "../../common/card/Card";
import CardHeader from "./common/CardHeader";
import CardDetails from "./common/CardDetails";
import Text from "../../common/Text";

//utils
import { colorChange, fontChange, fontChangeDate } from "./utils/helpers";
import { checkBorderColor } from "../../../utils/rentalOverlay/checkBorderColor";

const DescriptionText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xlarge}px;
`;

export default function LatePendingCard({
    rentalConfigStep,
    latePendingConfig,
    lastPhase,
}) {
    const { colors } = useTheme();

    const statusBool = () => {
        return (
            rentalConfigStep.status !== "SKIPPED" &&
            rentalConfigStep.status !== "PENDING"
        );
    };

    const checkDescription = (item) => {
        if (item.description !== "") {
            return item.description;
        }
    };

    return (
        <Card
            backgroundColor={colorChange(statusBool(), colors)}
            borderColor={checkBorderColor(
                rentalConfigStep.rentalPhase,
                lastPhase
            )}
        >
            <CardHeader
                header="Late pending"
                date={rentalConfigStep.triggerAt}
                colorHeader={fontChange(statusBool(), colors)}
                colorDate={fontChangeDate(statusBool(), colors)}
            ></CardHeader>
            <CardDetails
                width="200px"
                color={fontChange(statusBool(), colors)}
                paddingLeft="18px"
                asset={checkDescription(latePendingConfig)}
            ></CardDetails>
        </Card>
    );
}
