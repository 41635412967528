//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";

//constants
import { CYCLE_STRINGS } from "../../../constants/cyclerate";

const LoopSubText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const ReturnWrapper = styled.div`
    text-align: center;
    position: relative;
    top: ${({ theme }) => -theme.spacing.tiny}%;
    left: 86%;
    width: 100px;
`;
export default function AssetReturned({}) {
    const { colors } = useTheme();

    return (
        <ReturnWrapper>
            <Text type="header" size="small" color={colors.BLACK}>
                {CYCLE_STRINGS.ASSET_RETURN_LABEL}
            </Text>
            <LoopSubText type="label" size="small" color={colors.EXHAUST}>
                {CYCLE_STRINGS.LOOP_CLOSED}
            </LoopSubText>
        </ReturnWrapper>
    );
}
