export const ADD_DESCRIPTION = {
    STEP_ONE_INSTRUCTION: "Enter the parent location details",
    STEP_ONE_DESCRIPTION:
        "Parent locations are physical places, such as a dining hall or student center. Enter a name that your organization and consumers will recognize.",
    STEP_TWO_INSTRUCTION: "Add scanning locations",
    STEP_TWO_DESCRIPTION:
        "Scanning locations are specific areas within a parent location where assets will be scanned-out (such as cash registers or retail shops) or scanned-in (such a dishwashers). Scanning locations may have multiple ScanApp devices.",
    STEP_TWO_SUB_DESCRIPTION:
        "Once a scanning location is added to a parent location, it may not be moved. You can always add more scanning locations later.",
    STEP_TWO_NAME_LOCATION:
        "Enter the name of the scanning location. Example names include, “Sally’s Salads,” “Dishwasher,” or “Cashier.",
    STEP_TWO_ENTER_SCAN_LOCATION: "Enter the scanning location details",
    STEP_THREE_INSTRUCTION: "Review and save",
    STEP_THREE_DESCRIPTION:
        "Make sure everything looks good before you save the location.",
};

export const emptyMetadata = {
    key: "",
    value: "",
    isNew: true,
};

export const LOCATION_STEP_2_STRINGS = {
    SAVE_NAME: "Save scanning name",
    SAVE_SCANNING: "Save scanning location",
    ADD_TAG: "+ Add tag (optional)",
    ERROR_NAME_EXISTS:
        "That name already exists. Please enter a unique scanning location name.",
    ERROR_UNIQUE_SCAN_VS_PARENT_NAME:
        "The scanning and parent location names must be different.",
};
