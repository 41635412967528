//react
import React, { useContext, useState, useEffect } from "react";

//components
import ActiveConsumers from "./ActiveConsumers";
import ReturnRateSummary from "./ReturnRateSummary";
import DaysToReturnChart from "./DaysToReturnChart";
import ScreenView from "../common/ScreenView";
import CycleRateSummary from "./CycleRateSummary";
import CheckoutReturnsSummary from "./CheckoutReturnsSummary";
import MissedScanSummary from "./MissedScanSummary";
import OverviewHeader from "./common/OverviewHeader";
import AssetFiltersSummary from "./AssetFiltersSummary";
import AssetUsageSummary from "./AssetUsageSummary";
import ReuseRateSummary from "./ReuseRateSummary";
import LostRetiredAssetsSummary from "./LostRetiredAssetsSummary";

//context
import LocationsContext from "../../contexts/LocationsContext";
import GlobalContext from "../../contexts/GlobalContext";

//styles
import styled from "styled-components/macro";

//constants
import { DASHBOARD_OVERVIEW } from "../../constants/dashboard";

const StyledScreenView = styled(ScreenView)``;

const Top = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: row;
    @media (max-width: ${({ theme }) => theme.breakpoints.xlarge}px) {
        flex-direction: column;
    }
`;

const ChartContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: 0px ${({ theme }) => theme.spacing.large}px;
    padding-top: ${({ theme }) => theme.spacing.large}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const CycleRateWrapper = styled(ChartContainer)`
    flex: 9;
`;

const ChartWrapper = styled(ChartContainer)`
    flex: 4;
`;
const ReuseRateWrapper = styled(ChartContainer)`
    flex: 8;
    @media (max-width: ${({ theme }) => theme.breakpoints.large}px);
`;

const ReturnRateWrapper = styled(ChartContainer)`
    flex: 3;
    @media (min-width: ${({ theme }) => theme.breakpoints.xlarge}px) {
        margin-right: ${({ theme }) => theme.spacing.medium}px;
    }
`;

const AssetAndReuseWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing.medium}px;
    @media (max-width: ${({ theme }) => theme.breakpoints.xlarge}px) {
        flex-direction: column;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.large}px;
`;

export default function Dashboard() {
    const [loading, setLoading] = useState(false);
    const [assetSelected, setAssetSelected] = useState({
        assetTypeId: "ALL",
        name: "All asset types",
    });
    const context = useContext(LocationsContext);
    const { organizationName } = useContext(GlobalContext).globalSelections;
    const { locationId } = context.selections;

    const OVERVIEW_TEXT = {
        title: organizationName
            ? DASHBOARD_OVERVIEW.WELCOME_MESSAGE(organizationName)
            : DASHBOARD_OVERVIEW.WELCOME_DEFAULT,
        subtitle: DASHBOARD_OVERVIEW.CHECK_DATE_MESSAGE,
    };

    const handleAssetTypes = (asset) => {
        setAssetSelected(asset);
    };

    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            setLoading(true);
        }, 50);
        return () => clearTimeout(loadingTimeout);
    }, []);

    return (
        <StyledScreenView shouldShowCounter={false}>
            <HeaderWrapper>
                {loading && <OverviewHeader headerText={OVERVIEW_TEXT} />}
                <AssetFiltersSummary onClick={handleAssetTypes} />
                <Top>
                    <ReturnRateWrapper>
                        <ReturnRateSummary asset={assetSelected} />
                    </ReturnRateWrapper>
                    <CycleRateWrapper>
                        <CycleRateSummary asset={assetSelected} />
                    </CycleRateWrapper>
                </Top>
            </HeaderWrapper>
            <ChartContainer>
                <MissedScanSummary asset={assetSelected} />
            </ChartContainer>
            <ChartContainer>
                <CheckoutReturnsSummary asset={assetSelected} />
            </ChartContainer>
            <AssetAndReuseWrapper>
                <ChartWrapper>
                    <AssetUsageSummary asset={assetSelected} />
                </ChartWrapper>
                <ReuseRateWrapper>
                    <ReuseRateSummary asset={assetSelected} />
                </ReuseRateWrapper>
            </AssetAndReuseWrapper>
            <LostRetiredAssetsSummary asset={assetSelected} />
            <ChartContainer>
                <ActiveConsumers locationId={locationId || ""} />
            </ChartContainer>
            <ChartContainer>
                <DaysToReturnChart asset={assetSelected} />
            </ChartContainer>
        </StyledScreenView>
    );
}
