//styles
import styled, { useTheme } from "styled-components/macro";

//components
import Select from "react-select";

//images
import { ReactComponent as DropdownX } from "../../../../assets/vectors/DropdownX.svg";

const CANCEL_OPTIONS = [
    { value: "Food theft", label: "Food theft" },
    { value: "Missed return scan", label: "Missed return scan" },
    { value: "Program confusion", label: "Program confusion" },
    { value: "Extension request", label: "Extension request" },
    { value: "Container discrepancy", label: "Container discrepancy" },
    { value: "Other", label: "Other" },
];

export default function CancelRentalDropdown({
    onChange,
    disabled,
    cancelReason,
}) {
    const { colors } = useTheme();

    const RemoveSelection = () => {
        return (
            <DropdownX
                onClick={() => onChange("")}
                style={{ position: "absolute", top: 220, left: 500 }}
            ></DropdownX>
        );
    };

    return (
        <>
            <Select
                styles={{
                    option: (styles, state) => ({
                        ...styles,
                        cursor: "pointer",
                        backgroundColor: state.isSelected ? "white" : "white",
                        color: colors.EXHAUST,
                        "&:hover": {
                            ...styles,
                            color: colors.EXHAUST,
                        },
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "transparent",
                        color: colors.EXHAUST,
                        cursor: "pointer",
                    }),
                    container: (baseStyles, state) => ({
                        ...baseStyles,
                        fontFamily: "poppins",
                        fontStyle: "normal",
                        letterSpacing: "0.13px",
                        fontSize: 13,
                        fontWeight: 400,
                        color: colors.EXHAUST,
                        width: 514,
                        cursor: "pointer",
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: colors.BLANK_SLATE,
                        border: "0.5px solid var(--greys-marine-layer, #c2cfe0",
                        zIndex: 10,
                        display: "flex",
                        color: colors.EXHAUST,
                        cursor: "pointer",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        svg: {
                            cursor: "pointer",
                            transform: state.selectProps.menuIsOpen
                                ? "rotate(180deg)"
                                : null,
                        },
                    }),
                }}
                placeholder="Select a reason"
                options={CANCEL_OPTIONS}
                isSearchable={false}
                onChange={(item) => onChange(item)}
                value={cancelReason}
                isClearable={false}
            />
            {disabled ? <></> : <RemoveSelection></RemoveSelection>}
        </>
    );
}
