//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import Loadable from "../../common/Loadable";
import ShimmerCard from "../../common/ShimmerCard";

//constants
import { MISSED_SCAN_SUMMARY } from "../../../constants/dashboard";

//utils
import { checkColorCardVariation } from "../../../utils/chart/checkColorCardVariation";

const PercentageWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
    width: 214px;
`;

const HighlightDiv = styled.div`
    display: flex;
    padding: 0px ${({ theme }) => theme.spacing.xsmall}px;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    background-color: ${(props) => props.color || "transparent"};
    border-radius: 4px;
`;

export default function MissedScanStats({ missedScanRate }: Props) {
    const { colors } = useTheme();
    const showScanRate =
        missedScanRate == "—"
            ? "—"
            : MISSED_SCAN_SUMMARY.SCAN_STAT(missedScanRate);
    const loadMissedScanRate = missedScanRate || missedScanRate >= 0;

    const checkPercentageAndSetCardDetails = () => {
        if (missedScanRate == "—") {
            return {
                label: "",
                statColor: colors.GREEN_1,
                highlightColor: colors.SMOG,
            };
        }
        let cardColor = checkColorCardVariation(missedScanRate);
        if (cardColor == "GREEN") {
            return {
                label: "GREAT",
                statColor: colors.GREEN_1,
                highlightColor: colors.GREEN_4,
            };
        }
        if (cardColor == "YELLOW") {
            return {
                label: "ROOM FOR IMPROVEMENT",
                statColor: colors.YELLOW_1,
                highlightColor: colors.YELLOW_4,
            };
        }
        if (cardColor == "RED") {
            return {
                label: "NEEDS ATTENTION",
                statColor: colors.RED_2,
                highlightColor: colors.RED_4,
            };
        }
    };

    const cardDetails = checkPercentageAndSetCardDetails();

    const StyledShimmerCard = () => {
        return <ShimmerCard height={30} width={54} />;
    };

    return (
        <PercentageWrapper>
            <Loadable
                dataLoaded={loadMissedScanRate}
                LoadingComponent={<StyledShimmerCard />}
            >
                <HighlightDiv color={cardDetails?.highlightColor}>
                    <Text type="header" size="xlarge" color={colors.BLACK}>
                        {showScanRate}
                    </Text>
                </HighlightDiv>
            </Loadable>
            <Loadable dataLoaded={cardDetails}>
                <Text type="label" size="small" color={cardDetails?.statColor}>
                    {cardDetails?.label}
                </Text>
            </Loadable>
        </PercentageWrapper>
    );
}
