/*
 * Format counts by adding commas.
 *
 */
export default function formatCount(
    count: number,
    compact: boolean = false
): string {
    return compact
        ? Intl.NumberFormat("en", {
              notation: "compact",
              maximumFractionDigits: 1,
          }).format(count)
        : Intl.NumberFormat("en").format(count);
}
