//react

//style
// DO NOT REMOVE THE IMPORT BELOW. We keep trying to replace it with our locally built one and
// it breaks a ton of stuff, somehow even stuff not in this component. We've been
// trying to look into it, but it continues to be hard to pin down.
import styled, { useTheme, css } from "styled-components/macro";

// components
import Button from "./Button";
import Text from "./Text";

//images
import { ReactComponent as FilterDownCaret } from "../../assets/vectors/FilterDownCaret.svg";
import { ReactComponent as FilterUpCaret } from "../../assets/vectors/FilterUpCaret.svg";

const StyledButton = styled(Button)`
    ${({ theme, isOpen }) =>
        !isOpen &&
        css`
            background-color: #fff;
            border: 1px solid transparent;
            &:hover {
                background-color: ${theme.colors.SEAGLASS};
            }
        `};
    border: 1px solid transparent;
    box-shadow: 0px 0px;
    margin-bottom: ${({ theme }) => theme.spacing.tiny}px;
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    min-width: 0;
`;

const LabelContainer = styled.div`
    align-items: center;
    display: flex;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

const ButtonLabel = styled(Text)`
    margin-left: ${({ theme }) => theme.spacing.xsmall}px;
    white-space: nowrap;
`;

type Props = {
    isOpen?: boolean;
    onClick: () => void;
    children: JSX.Element[] | JSX.Element;
};

export default function FilterButton({ onClick, isOpen, children }: Props) {
    const { colors } = useTheme();
    const labelColor = isOpen ? colors.BLANK_SLATE : colors.WAVE_STORM;
    const buttonType = isOpen ? "primary" : "secondary";
    const Icon = isOpen ? FilterUpCaret : FilterDownCaret;

    return (
        <StyledButton isOpen={isOpen} onClick={onClick} type={buttonType}>
            <LabelContainer>
                <ButtonLabel type="body" size="medium" color={labelColor}>
                    {children}
                </ButtonLabel>
                <Icon />
            </LabelContainer>
        </StyledButton>
    );
}
