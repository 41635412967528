//style
import { useTheme } from "../../../styling/styled-components";

//components
import Card from "../../common/card/Card";
import CardHeader from "./common/CardHeader";
import FinedAlert from "./FinedAlert";

//utils
import { checkBorderColor } from "../../../utils/rentalOverlay/checkBorderColor";

//constants
import { FINE_ERROR_STRINGS } from "../../../constants/rentalOverlay/rentalCards";

export default function FineErrorCard({ rental, chargeDetails }) {
    const { colors } = useTheme();

    return (
        <Card
            backgroundColor={colors.SEAGLASS}
            borderColor={checkBorderColor(rental.rentalPhase, rental.lastPhase)}
        >
            <CardHeader
                header={FINE_ERROR_STRINGS.HEADER}
                date={chargeDetails?.updatedAt ? chargeDetails.updatedAt : ""}
                colorHeader={colors.ROCK_BOTTOM}
                colorDate={colors.ASH}
            />
            <FinedAlert
                asset={FINE_ERROR_STRINGS.FAILED_TEXT}
                subText={FINE_ERROR_STRINGS.FAILED_REASON(
                    chargeDetails?.failedReason
                        ? chargeDetails.failedReason?.message
                        : ""
                )}
            />
        </Card>
    );
}
