//style
import styled, { useTheme } from "../../styling/styled-components";

//components
import {
    RentalStatusWrapper,
    StyleIconDiv,
    StatusText,
} from "./common/SharedRentalVisual";
import StatusIcon from "../overlay/rental/common/RentalStatusIcon";
import ForgivenPolicyLink from "./common/ForgivenPolicyLink";
import Displayable from "../common/Displayable";
import RentalStatusIconSmall from "../overlay/rental/common/RentalStatusIconSmall";

//react
import { useEffect, useState } from "react";

//utils
import { filterDisplay } from "./utils/helpers";

//constants
import { RentalStatus } from "../../API";

//types
import { FilteredAggregations, StatusItem } from "./common/RentalVisualTypes";

const StatusWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    padding-left: ${({ theme }) => theme.spacing.small}px;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.tiny}px;
    height: 36px;
    @media screen and (max-width: 1023px) {
        padding: 0px;
        position: relative;
        left: ${({ theme }) => -theme.spacing.small}px;
    }
`;

const TextWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

type Props = {
    filteredAggregations: FilteredAggregations[];
    statusFilters: StatusItem[];
    statusItem: StatusItem;
};
export default function StatusIconRentalVis({
    statusItem,
    filteredAggregations,
    statusFilters,
}: Props) {
    const { colors, isDesktop, isTablet } = useTheme();
    const [statusFilteredShown, setStatusFilteredShown] = useState(true);

    useEffect(() => {
        rentalStatusDisplay();
        checkStatusIcon();
    }, [statusItem, statusFilters, filteredAggregations]);

    const rentalStatusDisplay = () => {
        setStatusFilteredShown(statusItem?.displayStatus);
    };

    const checkStatusIcon = () => {
        let statusLabel = statusFilters.find(
            (element) => element.selected == true
        );
        if (statusLabel) {
            return statusLabel.value;
        } else {
            return "";
        }
    };

    const checkStatusLabel = () => {
        let statusLabel = statusFilters.find(
            (element) => element.selected == true
        );
        if (statusLabel) {
            return statusLabel.label;
        } else {
            return "";
        }
    };

    const showStatus = (key) => {
        let color = "";
        let opacity = "";

        switch (key) {
            case RentalStatus.Completed:
                color = colors.GREEN_2;
                opacity = filterDisplay(statusFilteredShown, "100%", "20%");
                break;
            case RentalStatus.Expired:
                color = colors.RED_2;
                opacity = filterDisplay(statusFilteredShown, "100%", "20%");
                break;
            case RentalStatus.Failed:
                color = colors.RED_2;
                opacity = filterDisplay(statusFilteredShown, "100%", "20%");
                break;
            case RentalStatus.InProgress:
                color = colors.WAVE_STORM;
                opacity = filterDisplay(statusFilteredShown, "100%", "20%");
                break;
            case RentalStatus.Forgiven:
                color = colors.YELLOW_1;
                opacity = filterDisplay(statusFilteredShown, "100%", "20%");
                break;
            default:
                color = colors.ASH;
                opacity = filterDisplay(statusFilteredShown, "100%", "20%");
                break;
        }

        return (
            <StatusWrapper>
                <StyleIconDiv
                    opacity={filterDisplay(statusFilteredShown, "100%", "20%")}
                >
                    <Displayable displayPrimaryComponent={isDesktop}>
                        <StatusIcon status={key} />
                    </Displayable>
                    <Displayable displayPrimaryComponent={!isDesktop}>
                        <RentalStatusIconSmall status={key} />
                    </Displayable>
                </StyleIconDiv>
                <TextWrapper>
                    <StatusText
                        color={color}
                        type="label"
                        size="small"
                        opacity={opacity}
                    >
                        {statusItem.label
                            ? statusItem.label
                            : checkStatusLabel()}
                    </StatusText>
                    <ForgivenPolicyLink status={statusItem.label} />
                </TextWrapper>
            </StatusWrapper>
        );
    };

    return (
        <RentalStatusWrapper>
            {showStatus(statusItem.key || checkStatusIcon())}
        </RentalStatusWrapper>
    );
}
