//utils
import { parseISO, format } from "date-fns";

export const CYCLE_STRINGS = {
    HEADER: (asset: string) =>
        asset ? `Average cycle rate (${asset})` : "Average cycle rate",
    SUBHEADER:
        "The average cycle rate shows how effectively you're cycling inventory, calculated by summing the days to return and shelf time. A loop opens when an asset is checked-out; a loop closes when that same asset is returned and scanned in. The cycle ends when the asset goes out on a new loop.",
    ASSET_CHECKOUT_LABEL: `Asset checked out`,
    ASSET_RETURN_LABEL: "Asset returned",
    SHELF_LABEL: "Shelf time",
    DAYS_RETURN: "Days to return",
    CYCLE_LABEL: "Cycle rate",
    LOOP_OPEN: "(Loop opened)",
    LOOP_CLOSED: "(Loop closed)",
    APPROXIMATE_LABEL: " (Approx.)",
    CYCLE_RATE_LABEL: "Cycle rate",
    LOW_DATA_MESSAGE: "There is not enough data.",
    CHECK_BACK_MESSAGE: "Check back soon.",
    LAST_UPDATED: (date: string) =>
        date
            ? `Last updated ${format(parseISO(date), "MM/dd/yy hh:mm:ss a")}`
            : "",
};
