//style
import styled from "../../../styling/styled-components";

const FinedWrapperDiv = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing.xsmall}px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
    padding-left: ${({ theme }) => theme.spacing.medium}px;
`;

export default function FinedWrapper({ children }) {
    return <FinedWrapperDiv>{children}</FinedWrapperDiv>;
}
