export const escapeHandleKeyDown = (key, chartRef, tooltip) => {
    if (!tooltip || !key || !chartRef) {
        return;
    }
    if (key === "Escape") {
        tooltip._active = [];
        tooltip.update();
        chartRef.draw();
    }
};
