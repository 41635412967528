import React, { useState, useEffect } from "react";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import Button from "../common/Button";
import CreateNewPasswordContent from "./CreateNewPasswordContent";
import DeprecatedText from "../common/DeprecatedText";
import TextInput from "../common/TextInput";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import {
    ERROR_MESSAGES,
    ERROR_TYPES,
    CODE_ERRORS,
} from "../../constants/errors";
import { FORGOT_PASSWORD } from "../../constants/signin";
import styled, { css, useTheme } from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";
import VerificationInput from "react-verification-input";

const CODE_LENGTH = 6;

const hintStyle = css`
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
`;

const mobileHintStyle = css`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 320px;
`;

const StepTwoContainer = styled(Container)`
    gap: ${({ theme }) => theme.spacing.huge}px;
`;

const StepTwoWrapper = styled.div``;

const Step = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const StepInstruction = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
    margin-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const Title = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const PromptText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    margin-bottom: ${({ theme }) => theme.spacing.xxlarge}px;
    ${(props) => props.hasError && `color: ${props.theme.colors.RED_3};`};
`;

const ErrorPrompt = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    margin-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const ContinueButton = styled(Button)`
    align-self: center;
    margin-top: ${({ theme }) => theme.spacing.huge}px;
`;

const LinkText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    margin-top: ${({ theme }) => theme.spacing.small}px;
    color: ${({ theme }) => theme.colors.WAVE_STORM} !important;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
`;

const CTAText = styled(LinkText)`
    margin-top: 12px;
`;

const SuccessPrompt = styled(DeprecatedText)`
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.GREEN_2} !important;
`;

const Label = styled(DeprecatedText)`
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
    margin-bottom: ${({ theme }) => theme.spacing.tiny}px;
    color: ${({ hasError, theme }) =>
        hasError ? theme.colors.RED_3 : theme.colors.EXHAUST};
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            margin-top: ${({ theme }) => theme.spacing.small};
        `}
`;

const AttemptPrompt = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const StyledVerificationInput = styled.div`
    .character {
        background-color: white;
        border-radius: 1rem;
        line-height: 45px;
        color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
        border: 1px solid ${({ theme }) => theme.colors.MARINE_LAYER};
    }
`;

export default function ForgotPassword({}) {
    const { isMobile } = useTheme();
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const username: string = searchParams.get("username") || "";
    const resetStep: string = searchParams.get("resetStep") || "1";
    const [code, setCode] = useState<string>("");
    const [step, setStep] = useState<number>(parseInt(resetStep));
    const [codeLoading, setCodeLoading] = useState<boolean>();
    const [updatedUsername, setUpdatedUsername] = useState<string>(username);
    const [updatedUsernameError, setUpdatedUsernameError] =
        useState<string>("");
    const [newPasswordLoading, setNewPasswordLoading] =
        useState<boolean>(false);
    const [changePasswordError, setChangePasswordError] = useState<string>();

    const [isContinueButtonDisabled, setIsContinueButtonDisabled] =
        useState<boolean>(!username);
    const [shouldShowSuccessPrompt, setShouldShowSuccessPrompt] =
        useState<boolean>(false);
    const [shouldShowMaxCodePrompt, setShouldShowMaxCodePrompt] =
        useState<boolean>(false);
    const [errorCodeMessage, setErrorCodeMessage] = useState<string>("");

    const sendCode = async (
        force: boolean = false,
        onSuccess: () => void
    ): Promise<void> => {
        setCodeLoading(true);

        try {
            await resetPassword({
                username:
                    updatedUsername.trim().toLowerCase() ||
                    username.trim().toLowerCase(),
            });
            if (force) {
                setShouldShowSuccessPrompt(true);
                setTimeout(() => {
                    setShouldShowSuccessPrompt(false);
                }, 5000);
            }
            !!onSuccess && onSuccess();
        } catch (err) {
            //TODO: handle error
            console.error(err);
            if (err.code === ERROR_TYPES.MAX_CODE_RESEND_TYPE) {
                setShouldShowMaxCodePrompt(true);
            } else if (err.code === ERROR_TYPES.USER_NOT_FOUND_TYPE) {
                setUpdatedUsernameError(ERROR_MESSAGES.USER_NOT_FOUND);
            }
        } finally {
            setCodeLoading(false);
        }
    };

    const changePassword = async (newPassword: string): void => {
        setChangePasswordError(null);
        setNewPasswordLoading(true);
        try {
            await confirmResetPassword({
                username: updatedUsername.trim().toLowerCase(),
                confirmationCode: code,
                newPassword,
            });
        } catch (err) {
            //TODO: handle error
            setChangePasswordError(err.message);
            setErrorCodeMessage(ERROR_MESSAGES.CODE_REQUIREMENTS);
            throw err;
        } finally {
            setNewPasswordLoading(false);
        }
    };

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        if (!isValidEmail(text)) {
            setUpdatedUsernameError(ERROR_MESSAGES.INVALID_EMAIL);
            setIsContinueButtonDisabled(true);
        } else {
            setUpdatedUsernameError(null);
            setIsContinueButtonDisabled(false);
        }
        setUpdatedUsername(text);
    };

    const handleChangeCode = (code) => {
        setCode(code);
    };

    const handleContinue = () => {
        const onSuccess = () => {
            setStep(2);
        };
        if (!isValidEmail(updatedUsername.trim().toLowerCase())) {
            setUpdatedUsernameError(ERROR_MESSAGES.INVALID_EMAIL);
            setIsContinueButtonDisabled(true);
        } else {
            sendCode(false, onSuccess);
        }
    };

    const onBlurCodeInput = () => {
        if (!!code.split("").length && code.split("").length !== CODE_LENGTH) {
            setErrorCodeMessage(ERROR_MESSAGES.CODE_REQUIREMENTS);
        } else {
            setErrorCodeMessage(null);
        }
    };

    const onFocusCodeInput = () => {
        setErrorCodeMessage(null);
    };

    const returnPromptText = () => {
        if (!!shouldShowMaxCodePrompt) {
            return ERROR_MESSAGES.MAX_RETRY;
        } else {
            if (step === 1) {
                return FORGOT_PASSWORD.CONFIRM_EMAIL;
            } else {
                if (!!changePasswordError) {
                    return ERROR_MESSAGES.CORRECT_TITLE;
                }
                return FORGOT_PASSWORD.FOLLOW_STEPS;
            }
        }
    };

    const renderStepOne = () => {
        return (
            <Container>
                <TextInput
                    value={updatedUsername}
                    onChange={handleEmailChange}
                    error={updatedUsernameError}
                    hintText={FORGOT_PASSWORD.USERNAME}
                    hintStyle={isMobile ? mobileHintStyle : hintStyle}
                    secureTextEntry={false}
                    autoCorrect="false"
                    autoCapitalize="none"
                    returnKeyType="done"
                />
                <ContinueButton
                    label={
                        !!shouldShowMaxCodePrompt
                            ? FORGOT_PASSWORD.BACK_SIGN_IN
                            : FORGOT_PASSWORD.CONTINUE
                    }
                    type="primary"
                    status={isContinueButtonDisabled ? "disabled" : "default"}
                    onClick={
                        !!shouldShowMaxCodePrompt
                            ? () => navigate("..")
                            : handleContinue
                    }
                />
            </Container>
        );
    };

    const renderStepTwo = () => {
        return (
            <StepTwoContainer>
                <StepTwoWrapper>
                    <Step type="header" size="medium">
                        {FORGOT_PASSWORD.STEPONE}
                    </Step>
                    <StepInstruction
                        type="body"
                        size="large"
                    >{`${FORGOT_PASSWORD.CODE_PROMPT} ${updatedUsername}`}</StepInstruction>
                    <StyledVerificationInput>
                        <VerificationInput
                            onBlur={onBlurCodeInput}
                            onChange={handleChangeCode}
                            onFocus={onFocusCodeInput}
                            value={code}
                            validChars={"0-9"}
                            placeholder=""
                            classNames={{
                                character: "character",
                            }}
                        />
                    </StyledVerificationInput>
                    {FORGOT_PASSWORD.CODE ? (
                        <Label
                            hasError={
                                !!shouldShowMaxCodePrompt || !!errorCodeMessage
                            }
                        >
                            {FORGOT_PASSWORD.CODE}
                        </Label>
                    ) : null}
                    {!!shouldShowMaxCodePrompt ? (
                        <AttemptPrompt type="label" size="small">
                            {CODE_ERRORS.TRY_AGAIN_LATER}
                        </AttemptPrompt>
                    ) : null}
                    {!!errorCodeMessage ? (
                        <ErrorPrompt type="label" size="small">
                            {errorCodeMessage}
                        </ErrorPrompt>
                    ) : null}
                    {!(!!codeLoading || shouldShowMaxCodePrompt) && (
                        <LinkText
                            type="label"
                            size={isMobile ? "small" : "medium"}
                            onClick={() => {
                                sendCode(true);
                            }}
                        >
                            {FORGOT_PASSWORD.RESEND_CODE}
                        </LinkText>
                    )}
                    {shouldShowSuccessPrompt ? (
                        <SuccessPrompt>
                            {FORGOT_PASSWORD.SUCCESS_CODE_SENT}
                        </SuccessPrompt>
                    ) : null}
                    {shouldShowMaxCodePrompt ? (
                        <ErrorPrompt type="label" size="small">
                            {ERROR_MESSAGES.MAX_CODE_RESEND}
                        </ErrorPrompt>
                    ) : null}
                </StepTwoWrapper>
                <StepTwoWrapper>
                    <Step type="header" size="medium">
                        {FORGOT_PASSWORD.STEPTWO}
                    </Step>
                    <StepInstruction type="body" size="large">
                        {FORGOT_PASSWORD.NEW_PASSWORD_PROMPT}
                    </StepInstruction>
                    <CreateNewPasswordContent
                        continueButtonLabel={FORGOT_PASSWORD.CONFIRM}
                        onChangePassword={changePassword}
                        hasOtherError={
                            !!errorCodeMessage || !!shouldShowMaxCodePrompt
                        }
                        username={updatedUsername}
                    />
                </StepTwoWrapper>
            </StepTwoContainer>
        );
    };

    const renderContent = () => {
        if (step === 1) {
            return renderStepOne();
        }
        return renderStepTwo();
    };

    const renderCtaLabel = () => {
        if (step === 1) {
            return FORGOT_PASSWORD.RETURN;
        } else {
            return FORGOT_PASSWORD.BACK;
        }
    };

    const ctaLabel = (
        <CTAText type="link" size={"small"}>
            {renderCtaLabel()}
        </CTAText>
    );

    const passwordResetLink = () => {
        if (step === 1) {
            const params = new URLSearchParams({
                username: updatedUsername,
            });
            return <Link to={"/?" + params}>{ctaLabel}</Link>;
        } else {
            const params = new URLSearchParams({
                username: updatedUsername,
                resetStep: (step - 1).toString(),
            });
            return (
                <Link to={"/login/forgot-password/?" + params}>{ctaLabel}</Link>
            );
        }
    };

    return (
        <div>
            <Container>
                <Title type="header" size={isMobile ? "smallest" : "medium"}>
                    {FORGOT_PASSWORD.FORGOT_PASSWORD}
                </Title>
                <PromptText
                    type="header"
                    size={isMobile ? "large" : "xlarge"}
                    hasError={!!changePasswordError}
                >
                    {returnPromptText()}
                </PromptText>
                {renderContent()}
                {!shouldShowMaxCodePrompt ? passwordResetLink() : null}
            </Container>
        </div>
    );
}
