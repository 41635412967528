// style
import styled, { useTheme } from "../../../styling/styled-components";

// components
import {
    SubSectionHeader,
    SubSectionText,
} from "../../common/overlay/SubSection";

//utils
import { humanizeLower, prettifyDate } from "../../../utils/helpers";

// assets
import { ReactComponent as GreenCheckSmall } from "../../../assets/vectors/GreenCheckSmall.svg";

//utils
import { checkReturnReceivedStatusLabel } from "../../../utils/activity/checkReturnReceivedStatusLabel";
import { RETURN_RATE_CARDS } from "../../../constants/assets";

const Container = styled.div`
    justify-content: space-between;
    display: flex;
`;

const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.tiny}px;
`;

const SectionWrapper = styled.div`
    padding-right: ${({ theme }) => theme.spacing.medium}px;
`;

export default function ReturnReceivedDetails({ rental, returnCriteria }) {
    const { colors } = useTheme();

    let returnReceivedStatusConfirmed =
        checkReturnReceivedStatusLabel(
            rental.createdAt,
            rental.status,
            rental.resolvingLoop
        ) == RETURN_RATE_CARDS.RETURN_RECEIVED;

    const textColor =
        rental.resolvingLoop && returnReceivedStatusConfirmed
            ? colors.GREEN_2
            : colors.EXHAUST;

    const text = returnReceivedStatusConfirmed
        ? `Return received ${prettifyDate(rental.resolvingLoop.closedAt)}`
        : checkReturnReceivedStatusLabel(
              rental.createdAt,
              rental.status,
              rental.resolvingLoop
          );

    const Icon = () => {
        if (rental.resolvingLoop && returnReceivedStatusConfirmed) {
            return <GreenCheckSmall />;
        } else {
            return <></>;
        }
    };

    return (
        <div>
            <SubSectionHeader>Return Received</SubSectionHeader>
            <Container>
                <StatusContainer>
                    <Icon />
                    <SubSectionText color={textColor}>{text}</SubSectionText>
                </StatusContainer>
                <SectionWrapper>
                    <SubSectionHeader color={colors.DEEP_BLUE_SEA}>
                        {humanizeLower(returnCriteria)}
                    </SubSectionHeader>
                </SectionWrapper>
            </Container>
        </div>
    );
}
