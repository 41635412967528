import styled, { css } from "../../styling/styled-components";

const GrayCircleCss = styled.div`
    ${({ theme }) =>
        css`
            width: ${(props) => props.width}px;
            height: ${(props) => props.height}px;
            background-color: ${({ theme }) => theme.colors.SMOG};
            border-radius: 50%;
            align-items: center;
        `};
`;

export default function GrayCircle({ height, width, children }) {
    return (
        <GrayCircleCss height={height} width={width}>
            {children}
        </GrayCircleCss>
    );
}
