//enum
import { RentalPhase } from "../../API";

//utils
import { lowercaseAllButFirstLetter } from "./lowercaseAllButFirstLetter";

export const displayLastPhaseLabel = (phase: string) => {
    if (phase == RentalPhase.Failed || phase == RentalPhase.Fined) {
        return lowercaseAllButFirstLetter(RentalPhase.Expired);
    }
    if (
        phase == RentalPhase.FailedPending ||
        phase == RentalPhase.FinePending
    ) {
        return lowercaseAllButFirstLetter(RentalPhase.AboutToExpire);
    }
    if (phase == RentalPhase.FinedAutomatic) {
        return "Fined (automatic)";
    }
    if (phase == RentalPhase.FinedManual) {
        return "Fined (manual)";
    } else {
        return lowercaseAllButFirstLetter(phase);
    }
};
