//style
import styled, { useTheme } from "../../styling/styled-components";

//component
import { StackChartStatus } from "./common/SharedRentalVisual";
import Stack from "@mui/material/Stack";

//apis
import { RentalStatus } from "../../API";

//utils
import { isEmpty } from "../../utils/helpers";

const StackDiv = styled.div`
    flex: 1 0 0;
`;

export default function StackedBarChart({
    filteredAggregations,
    totalDocCount,
    visualWidth,
}) {
    const { colors } = useTheme();

    const sampleAggregationOrder = [
        RentalStatus.InProgress,
        RentalStatus.Completed,
        RentalStatus.Failed,
        RentalStatus.Expired,
        RentalStatus.Forgiven,
        RentalStatus.Canceled,
        RentalStatus.CanceledWithRefund,
    ];

    const orderStatus = (filteredAggregations) => {
        if (filteredAggregations) {
            filteredAggregations.sort((a, b) => {
                return (
                    sampleAggregationOrder.indexOf(a.key) -
                    sampleAggregationOrder.indexOf(b.key)
                );
            });
            return filteredAggregations;
        }
    };

    const orderedFilteredAggregations = orderStatus(filteredAggregations);

    const calculateWidth = (item, totalCount) => {
        return (item.docCount / totalCount) * (visualWidth - 40);
    };

    const displayStatusColor = (item) => {
        switch (item.key) {
            case RentalStatus.Forgiven:
                return colors.YELLOW_3;
            case RentalStatus.Completed:
                return colors.GREEN_3;
            case RentalStatus.Expired:
                return colors.RED_2;
            case RentalStatus.Failed:
                return colors.RED_2;
            case RentalStatus.InProgress:
                return colors.WAVE_STORM;
            case RentalStatus.CanceledWithRefund:
                return colors.ASPHALT;
            default:
                return colors.ASH;
        }
    };

    return (
        <StackDiv>
            <Stack spacing={2} direction="row">
                {!isEmpty(orderedFilteredAggregations) ? (
                    orderedFilteredAggregations.map((item, index) => {
                        return (
                            <StackChartStatus
                                key={index}
                                width={calculateWidth(item, totalDocCount)}
                                color={displayStatusColor(item)}
                            ></StackChartStatus>
                        );
                    })
                ) : (
                    <></>
                )}
            </Stack>
        </StackDiv>
    );
}
