//style
import styled from "../../../styling/styled-components";

//components
import Close from "../Close";

//utils
import useClickOutside from "../../../utils/useClickOutside";

//react
import { useRef } from "react";

const Container = styled.div`
    ${(props) => `width: ${props.width}`};
    ${(props) => `overflow-y: ${props.scroll}`};
    height: 100%;
    transition: width 1s;
    top: 0px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    z-index: 1;
    position: fixed;
    box-shadow: ${({ theme }) => theme.shadowStyle.overlayDropShadow};
`;

const ChildrenWrapper = styled.div`
    width: 500px;
    margin-bottom: ${({ theme }) => theme.spacing.large}px;
`;

export default function Overlay({
    children,
    width,
    onClick,
    showLeft,
    onClickBack,
    onClickedOutside,
    scroll,
    ...props
}: Props) {
    const overlayRef = useRef(null);

    useClickOutside(overlayRef, () => {
        onClickedOutside();
    });

    return (
        <div data-override-outside-click={true}>
            <Container width={width} scroll={scroll} ref={overlayRef}>
                <Close
                    label={"Close"}
                    onClick={onClick}
                    showLeftLabel={showLeft}
                    leftlabel={"Back"}
                    onClickLeft={onClickBack}
                />
                <ChildrenWrapper>{children}</ChildrenWrapper>
            </Container>
        </div>
    );
}
