export const addExtraEmptyMetaData = (newMetaData, setNewMetaData) => {
    const emptyMetadata = {
        key: "",
        value: "",
        isNew: true,
    };
    const updatedMetadataArr = [...newMetaData];
    updatedMetadataArr.push(emptyMetadata);
    setNewMetaData(updatedMetadataArr);
};
