import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import SignedOut from "../sign_in/SignedOut";
import useGraphClient from "../../hooks/useGraphClient";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import { useContext, useEffect, useState } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import GlobalContext, {
    emptyGlobalSelection,
    GlobalSelections,
} from "../../contexts/GlobalContext";
import LocationsContext, {
    emptyLocationsSelections,
    LocationsSelections,
} from "../../contexts/LocationsContext";
import AssetsContext, {
    emptyAssetSelections,
    AssetSelections,
} from "../../contexts/AssetsContext";
import * as queries from "../../graphql/queries";
import Dashboard from "../dashboard/Dashboard";
import LoopsList from "../loops/LoopList";
import ConsumerList from "../consumers/ConsumerList";
import ConsumerDetail from "../consumers/ConsumerDetail";
import LoopDetail from "../loops/LoopDetail";
import AssetTypes from "../assets/AssetTypes";
import AssetTypeDetail from "../assets/AssetTypeDetail";
import CreateAssetType from "../assets/CreateAssetType";
import EditAssetType from "../assets/EditAssetType";
import CreateScanAppAccount from "../scanapp/CreateScanAppAccount";
import UpdateScanAppUserSettings from "../scanapp/UpdateScanAppUserSettings";
import UpdateScanAppUserPassword from "../scanapp/UpdateScanAppUserPassword";
import ProtectedLayout from "./ProtectedLayout";
import PageNotFound from "../overlay/PageNotFound";
import ActivityRentals from "../activity/ActivityRentals";
import ProgramSettings from "../settings/ProgramSettings";
import StreamLineLanding from "../streamline/StreamLineLanding";

// utils
import DatadogAnalyticsReporter from "../../utils/datadogAnalyticsReporter";
import { randomToken } from "../../utils/helpers";

export enum ROUTES {
    FORGOT_PASSWORD = "forgot-password",
}

export default function Component() {
    const navigate = useNavigate();
    const context = useContext(GlobalContext);
    const { organizationName, username, isAuthenticated } =
        context.globalSelections;

    const graphClient = useGraphClient();

    const PublicRoute = ({ children }) => {
        async function checkAuthState() {
            try {
                await getCurrentUser();
                navigate("dashboard/overview");
            } catch (err) {}
        }
        useEffect(() => {
            checkAuthState();
        });
        return children;
    };

    const [sessionInitiated, setSessionInitiated] = useState(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [showInfo, setShowInfo] = useState<boolean>(true);
    const [globalSelections, setGlobalSelections] =
        useState<GlobalSelections>(emptyGlobalSelection);
    const [locationsSelections, setLocationsSelections] =
        useState<LocationsSelections>(emptyLocationsSelections);
    const [assetSelections, setAssetSelections] =
        useState<AssetSelections>(emptyAssetSelections);

    // Global context
    // TODO: Get rid of this function an use `setGlobalSelections` instead.
    // this was added a long time ago and I'm not sure it can be taken out just yet.
    const updateSelections = (selections: GlobalSelections) => {
        setGlobalSelections({ ...globalSelections, ...selections });
    };

    // Locations context
    const updateLocationsSelections = (selections: LocationsSelections) => {
        setLocationsSelections({ ...locationsSelections, ...selections });
    };

    // Assets context
    const updateAssetsSelections = (selections: AssetSelections) => {
        setAssetSelections({ ...assetSelections, ...selections });
    };

    useEffect(() => {
        if (!globalSelections.isAuthenticated) {
            setSessionInitiated(false);
        } else if (!sessionInitiated) {
            initiateSession();
        }
    }, [globalSelections?.isAuthenticated]);

    useEffect(() => {
        initiateSession();
    }, []);

    const initiateSession = async (): void => {
        try {
            if (!isLoading && !sessionInitiated) {
                // Will throw error if user is not authed
                await getCurrentUser();
                // If user is authed; get attributes
                const userAttributes = await fetchUserAttributes();

                try {
                    setLoading(true);
                    const [organizationResult, locationResults, assetResults] =
                        await Promise.all([
                            graphClient.graphql({
                                query: queries.getOrganization,
                            }),
                            graphClient.graphql({
                                query: queries.listLocations,
                            }),
                            graphClient.graphql({
                                query: queries.listAssetTypes,
                            }),
                        ]);

                    updateSelections({
                        username: userAttributes.email,
                        userAdmin: userAttributes.email.includes("@topanga.io"),
                        organizationName:
                            organizationResult.data.getOrganization.name,
                        slug: organizationResult.data.getOrganization.slug,
                        scanAppUsers:
                            organizationResult.data.getOrganization
                                .scanAppUsers,
                        scanAppUserLimit:
                            organizationResult.data.getOrganization
                                .scanAppUserLimit,
                    });

                    updateLocationsSelections({
                        locations:
                            locationResults.data?.listLocations?.results || [],
                        isInitialized: true,
                    });

                    updateAssetsSelections({
                        assets:
                            assetResults.data?.listAssetTypes?.results || [],
                        isInitialized: true,
                    });

                    DatadogAnalyticsReporter.setUser(userAttributes.email);
                    DatadogAnalyticsReporter.setOrganization(
                        organizationResult.data.getOrganization.slug
                    );

                    setSessionInitiated(true);
                    setLoading(false);
                } catch (err) {
                    console.error("Error initiating session", err);
                    setLoading(false);
                    setSessionInitiated(false);
                }
            }
        } catch (_err) {
            // user needs to authenticate
            setLoading(false);
            setSessionInitiated(false);
        }
    };

    return (
        <GlobalContext.Provider
            value={{
                globalSelections,
                updateSelections,
            }}
        >
            <LocationsContext.Provider
                value={{
                    selections: locationsSelections,
                    updateSelections: updateLocationsSelections,
                }}
            >
                <AssetsContext.Provider
                    value={{
                        selections: assetSelections,
                        updateSelections: updateAssetsSelections,
                    }}
                >
                    <ProSidebarProvider>
                        <Routes>
                            <Route
                                index
                                path="login/*"
                                element={
                                    <PublicRoute>
                                        <SignedOut />
                                    </PublicRoute>
                                }
                            />
                            <Route
                                path="/"
                                element={
                                    <PublicRoute>
                                        <Navigate to="/login" />
                                    </PublicRoute>
                                }
                            />
                            <Route
                                path="dashboard"
                                element={
                                    <ProtectedLayout
                                        dashboardInitiated={
                                            !!isAuthenticated && !isLoading
                                        }
                                    />
                                }
                            >
                                <Route
                                    index
                                    path="overview"
                                    element={<Dashboard />}
                                />
                                <Route path="assets">
                                    <Route index element={<AssetTypes />} />
                                    <Route path=":assetTypeId">
                                        <Route
                                            index
                                            element={<AssetTypeDetail />}
                                        />
                                        <Route
                                            path="edit"
                                            element={<EditAssetType />}
                                        />
                                    </Route>
                                    <Route
                                        path="new"
                                        element={<CreateAssetType />}
                                    />
                                </Route>
                                <Route path="loops">
                                    <Route index element={<LoopsList />} />
                                    <Route
                                        path=":loopId"
                                        element={<LoopDetail />}
                                    />
                                </Route>
                                <Route path="activity">
                                    <Route
                                        index
                                        element={
                                            <ActivityRentals showInfo={false} />
                                        }
                                    ></Route>
                                    <Route
                                        index
                                        path="rentals/"
                                        element={
                                            <ActivityRentals showInfo={false} />
                                        }
                                    ></Route>
                                    <Route
                                        index
                                        path="assetevents/"
                                        element={
                                            <ActivityRentals showInfo={true} />
                                        }
                                    ></Route>
                                </Route>
                                <Route path="consumers">
                                    <Route index element={<ConsumerList />} />
                                    <Route
                                        path=":consumerId"
                                        element={<ConsumerDetail />}
                                    />
                                </Route>

                                <Route
                                    path="program-settings/scan-app/create"
                                    element={<CreateScanAppAccount />}
                                />
                                <Route
                                    path="program-settings/scan-app/:userId/settings"
                                    element={<UpdateScanAppUserSettings />}
                                />
                                <Route
                                    path="program-settings/scan-app/:userId/password"
                                    element={<UpdateScanAppUserPassword />}
                                />
                                <Route
                                    path="program-settings"
                                    element={<ProgramSettings />}
                                >
                                    <Route
                                        path="locations"
                                        element={<ProgramSettings />}
                                    />
                                    <Route
                                        path="scan-app"
                                        element={<ProgramSettings index={3} />}
                                    ></Route>
                                    <Route
                                        path="rentals"
                                        element={<ProgramSettings />}
                                    ></Route>
                                </Route>
                                <Route
                                    path="streamline"
                                    element={<StreamLineLanding />}
                                >
                                    <Route
                                        index
                                        path="activity"
                                        element={<StreamLineLanding />}
                                    />
                                </Route>
                                <Route
                                    path="streamline"
                                    element={<StreamLineLanding />}
                                >
                                    <Route
                                        index
                                        path="activity"
                                        element={<StreamLineLanding />}
                                    />
                                </Route>
                            </Route>
                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                    </ProSidebarProvider>
                </AssetsContext.Provider>
            </LocationsContext.Provider>
        </GlobalContext.Provider>
    );
}
