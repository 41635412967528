import { getCurrentUser } from "aws-amplify/auth";
import { useMediaQuery } from "react-responsive";
import { useEffect, useRef } from "react";
import {
    Link,
    Navigate,
    useOutlet,
    useNavigate,
    useLocation,
} from "react-router-dom";
import { useProSidebar } from "react-pro-sidebar";

import ClipLoader from "react-spinners/ClipLoader";
import styled from "styled-components/macro";
import { BottleName } from "../../assets/vectors";
import NavSidebar from "../nav/Sidebar";
import TopBar from "../nav/TopBar";
import MobileScreen from "./MobileScreen";
import MobileSidebar from "../nav/MobileSidebar";

type Props = {
    dashboardInitiated: boolean;
};

const LoadingContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.SMOG};
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    img {
        padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    }
`;

const Container = styled.div`
    display: flex;
    height: 100vh;
`;

interface NonSideBarProps {
    collapsed: boolean;
    toggled: boolean;
}

const NonSideBarContainer = styled.div<NonSideBarProps>`
    height: 100vh;
    overflow-y: scroll;
    width: -webkit-fill-available;
    width: -moz-available;
    ${({ collapsed, toggled }) =>
        collapsed && toggled ? "position: absolute;" : ""}
`;

const ChildrenContainer = styled.div`
    height: -webkit-fill-available;
`;

export default function ProtectedLayout({ dashboardInitiated }: Props) {
    const outlet = useOutlet();
    const { pathname } = useLocation();
    const childrenContainerRef = useRef(null);
    const navigate = useNavigate();
    const { collapsed, toggled, broken } = useProSidebar();

    async function checkAuthState() {
        try {
            await getCurrentUser();
        } catch (err) {
            navigate("/login");
        }
    }
    useEffect(() => {
        checkAuthState();
    });

    useEffect(() => {
        childrenContainerRef.current?.scrollIntoView({
            behavior: "auto",
            block: "start",
        });
    }, [pathname]);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    if (isMobile) {
        return <MobileScreen />;
    }

    if (dashboardInitiated) {
        return (
            <LoadingContainer>
                <img src={BottleName} />
                <ClipLoader loading={true} />
            </LoadingContainer>
        );
    }

    return (
        <Container>
            <NavSidebar />
            {broken && <MobileSidebar />}
            <NonSideBarContainer toggled={toggled} collapsed={collapsed}>
                <ChildrenContainer ref={childrenContainerRef}>
                    {outlet}
                </ChildrenContainer>
            </NonSideBarContainer>
        </Container>
    );
}
