import styled from "../../../styling/styled-components";

const ModuleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export default function Container({ children }) {
    return <ModuleContainer>{children}</ModuleContainer>;
}
