//style
import styled, { useTheme } from "../../../styling/styled-components";

//component
import Text from "../../common/Text";
import SideLine from "../SideLine";

const Details = styled.div`
    margin-top: ${({ theme }) => -theme.spacing.xsmall}px;
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
`;

export default function SubSection({
    children,
    sectionDetails,
    ...props
}: Props) {
    return (
        <>
            {sectionDetails.map((item, index) => {
                return (
                    <div style={{ width: "100%" }} key={index}>
                        <Details>
                            <SubSectionHeader>{item.title}</SubSectionHeader>
                            <SubSectionText>{item.description}</SubSectionText>
                        </Details>
                        <SideLine />
                    </div>
                );
            })}
        </>
    );
}

export function SubSectionHeader({ children, color }: Props) {
    const { colors } = useTheme();

    const headerColor = color ? color : colors.EXHAUST;

    return (
        <Text type="body" size="medium" color={headerColor}>
            {children}
        </Text>
    );
}

export function SubSectionText({ children, color }: Props) {
    const { colors } = useTheme();

    const textColor = color ? color : colors.DEEP_BLUE_SEA;

    return (
        <Text type="other" size="medium" color={textColor}>
            {children}
        </Text>
    );
}

export function SubSectionGeneric({ subSectionComponents }: Props) {
    return (
        <>
            {subSectionComponents.map((component, index) => {
                return (
                    <div key={index}>
                        <Details>{component}</Details>
                        <SideLine />
                    </div>
                );
            })}
        </>
    );
}

export function BasicSubSectionSection({ title, description }: Props) {
    return (
        <>
            <SubSectionHeader>{title}</SubSectionHeader>
            <SubSectionText>{description}</SubSectionText>
        </>
    );
}
