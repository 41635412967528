//styles
import styled, { useTheme, css } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import BarChart from "../../common/charts/BarChart";

//utils
import { capitalizeFirstLetter } from "../../../utils/chart/capitalizeFirstLetter";

type Datasets = {
    backgroundColor: string;
    barPercentage: number;
    borderRadius: number;
    categoryPercentage: number;
    data: number[];
    hoverBackgroundColor: string;
    label: string;
    maxBarThickness: number;
};
export type Props = {
    chartTitle: string;
    labels: string[];
    datasets: Datasets;
};

const MiniChartTitle = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.large}px;
    margin-left: ${({ theme }) => theme.spacing.large}px;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const BarChartWrapper = styled.div`
    height: 180px;
    width: 444px;
    padding-right: ${({ theme }) => theme.spacing.large}px;
    cursor: pointer;
    ${({ theme: { isMobile, isTablet } }) =>
        (isMobile || isTablet) &&
        css`
            padding-top: 42px;
            width: 98%;
            margin-left: ${({ theme }) => -theme.spacing.medium}px;
        `}
`;

export default function MiniBarChart({ chartTitle, labels, datasets }: Props) {
    const { colors } = useTheme();

    const toolTipText = (xAxisValue, optionLabel, value) => {
        let barOption = "";
        if (optionLabel == "Avg. returns (loops closed)") {
            barOption = "returns";
        } else {
            barOption = "check-outs";
        }
        let capitalizedLabel = capitalizeFirstLetter(xAxisValue);
        return `${capitalizedLabel} avg. ${barOption}: ${value}`;
    };

    const barChartSettings = {
        maintainAspectRatio: false,
        responsive: true,
        showLegend: false,
        toolTipText: toolTipText,
        toolTipBackgroundColor: colors.MARINE_LAYER,
        toolTipEnabled: true,
        toolTipLabels: [],
    };

    return (
        <BarChartWrapper>
            <MiniChartTitle type="label" size="medium" color={colors.BLACK}>
                {chartTitle}
            </MiniChartTitle>
            <BarChart
                settings={barChartSettings}
                labels={labels}
                datasets={datasets}
            />
        </BarChartWrapper>
    );
}
