import styled from "../../../styling/styled-components";

const SMSTextDiv = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    padding-left: ${({ theme }) => theme.spacing.large}px;
`;

export function SMSDiv({ children }) {
    return <SMSTextDiv>{children}</SMSTextDiv>;
}
