// TODO: TEC-1978 combine code that this form and EditAssetType both use into one form
//react
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//components
import Button from "../common/Button";
import ConfirmationModal from "../common/ConfirmationModal";
import LocationDropdown from "../common/LocationDropdown";
import MetaDataForm from "./MetaDataForm";
import ScreenView from "../common/ScreenView";
import Text from "../common/Text";
import FormTextField from "../common/FormTextField";
import { toastError, toastSuccess } from "../../utils/toasts";
import HighlightHover from "../common/HighlightHover";

//api
import useGraphClient from "../../hooks/useGraphClient";
import * as mutations from "../../graphql/mutations";
import { CreateAssetTypeInput } from "../../API";
import { API_ERROR } from "../../constants/errors";

//images
import { ReactComponent as BackChevron } from "../../assets/vectors/BackChevron.svg";

//style
import styled, { useTheme } from "styled-components/macro";

//constants
import { CREATE_ASSET_TYPE } from "../../constants/assets";

//utils
import convertDollarStringToAssetValue from "./utils/convertDollarStringToAssetValue";

const max_interactions = 2;

const StyledScreenView = styled(ScreenView)`
    height: auto;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const CancelButton = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CancelButtonLabel = styled(Text)`
    margin-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const SaveButton = styled(Button)`
    margin: 0;
`;

const AssetTypeWrapper = styled(Wrapper)`
    margin-top: 28px;
`;

const AddMetaDataWrapper = styled(Wrapper)``;

const NewAssetLabel = styled(Text)``;

const NewAssetInstr = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const NewInventoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xxlarge}px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: ${({ theme }) => theme.spacing.xxlarge}px
        ${({ theme }) => theme.spacing.large}px;
    margin-top: ${({ theme }) => theme.spacing.medium}px;
`;

const NewAssetTypeWrapper = styled(NewInventoryWrapper)``;

const ContentRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 515px;
    margin: 0 auto;
`;

const AddNewMetaDataWrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-top: ${({ theme }) => theme.spacing.xlarge}px;
`;

const PlusIcon = styled(Text)``;

const NewAssetTypeLabel = styled(Text)`
    margin-left: ${({ theme }) => theme.spacing.large}px;
`;

const NewAssetTextField = styled(FormTextField)`
    flex: 1;
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

const NameTextField = styled(NewAssetTextField)`
    margin: 0 auto;
`;

const ContentWrapper = styled.div`
    width: 611px;
    margin: 0 auto;
`;

export default function CreateAssetTypeScreen() {
    const emptyMetadata = {
        key: "",
        value: "",
        isNew: true,
    };
    const { colors } = useTheme();
    const navigate = useNavigate();

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [newAssetName, setNewAssetName] = useState<string>("");
    const [newAssetDescr, setNewAssetDescr] = useState<string>("");
    const [newAssetExtId, setNewAssetExtId] = useState<number>("");
    const [newAssetValue, setNewAssetValue] = useState<string>("");
    const [newMetaData, setNewMetaData] = useState<array>([emptyMetadata]);
    const [shouldShowMetadataErrors, setShouldShowMetadataErrors] =
        useState<boolean>(false);
    const [isMetadataSectionOpen, setIsMetadataSectionOpen] =
        useState<boolean>(false);

    const [saveAssetTypeLoading, setSaveAssetTypeLoading] =
        useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [counterInteraction, setCounterInteraction] = useState<number>(0);

    const graphClient = useGraphClient();

    useEffect(() => {
        const formValidation = () => {
            if (!!newAssetName) {
                setIsFormValid(true);
            } else {
                setIsFormValid(false);
            }
        };
        formValidation();
    }, [newAssetName]);

    const toggleMetadataSection = () => {
        setIsMetadataSectionOpen(
            (isMetadataSectionOpen) => !isMetadataSectionOpen
        );
    };

    const resetForm = () => {
        toggleMetadataSection();
        setNewMetaData([emptyMetadata]);
        setNewAssetName("");
        setNewAssetDescr("");
        setNewAssetExtId("");
        setNewAssetValue("");
    };

    const cancelCreate = () => {
        resetForm();
        closeModal();
        navigate("/dashboard/assets");
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        if (counterInteraction > max_interactions) {
            openModal();
        } else {
            cancelCreate();
        }
    };

    const addExtraEmptyMetaData = () => {
        const updatedMetadataArr = [...newMetaData];
        updatedMetadataArr.push(emptyMetadata);
        setNewMetaData(updatedMetadataArr);
    };

    const handleAssetNameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setNewAssetName(text);
        setCounterInteraction((counterInteraction) => counterInteraction + 1);
    };

    const handleAssetTextChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setNewAssetDescr(text);
        setCounterInteraction((counterInteraction) => counterInteraction + 1);
    };

    const handleAssetExternalIDChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setNewAssetExtId(text);
        setCounterInteraction((counterInteraction) => counterInteraction + 1);
    };

    const handleAssetValueChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setNewAssetValue(text);
        setCounterInteraction((counterInteraction) => counterInteraction + 1);
    };

    const handleSaveAsset = async () => {
        const isMetadataValid = newMetaData.every((data) => {
            // no input okay, but if you enter one you need to enter both
            return !(!data.key && !!data.value) && !(!!data.key && !data.value);
        });

        if (!isMetadataValid) {
            console.log("setting metadata errors to true");
            setShouldShowMetadataErrors(true);
            toastError(CREATE_ASSET_TYPE.ERROR_TOAST_MESSAGE_INCOMPLETE);
        } else {
            setSaveAssetTypeLoading(true);

            const reformattedNewMetadata = newMetaData.map((md) => {
                return Object.keys(md)
                    .filter(
                        (key) => key.includes("key") || key.includes("value")
                    )
                    .reduce((cur, key) => {
                        return Object.assign(cur, { [key]: md[key] });
                    }, {});
            });

            const newAssetType: CreateAssetTypeInput = {
                name: newAssetName,
                externalId: newAssetExtId,
                description: newAssetDescr,
                metadata: reformattedNewMetadata,
                value: convertDollarStringToAssetValue(newAssetValue),
            };
            try {
                const { data: saveAssetTypeResponse } =
                    await graphClient.graphql({
                        query: mutations.createAssetType,
                        variables: {
                            input: newAssetType,
                        },
                    });
            } catch (error) {
                console.error(
                    "Error saving new AssetType",
                    JSON.stringify(error.message)
                );
                toastError(API_ERROR);
            } finally {
                setSaveAssetTypeLoading(false);
                resetForm();
                toastSuccess(CREATE_ASSET_TYPE.SUCCESS_MESSAGE);
                navigate("/dashboard/assets");
            }
        }
    };

    return (
        <StyledScreenView
            screenName="CREATE_ASSET_TYPE"
            shouldShowHeader={false}
            shouldShowCounter={false}
        >
            <Wrapper>
                <ConfirmationModal
                    isOpen={isModalOpen}
                    title={CREATE_ASSET_TYPE.CANCEL_TITLE}
                    subtitle={CREATE_ASSET_TYPE.CANCEL_SUBTITLE}
                    onConfirm={cancelCreate}
                    onCancel={closeModal}
                />
                <Header>
                    <CancelButton onClick={handleCancel}>
                        <HighlightHover>
                            <BackChevron />
                        </HighlightHover>
                        <CancelButtonLabel
                            type="other"
                            size="large"
                            color={colors.WAVE_STORM}
                        >
                            {CREATE_ASSET_TYPE.CANCEL_LABEL}
                        </CancelButtonLabel>
                    </CancelButton>
                    <SaveButton
                        label={CREATE_ASSET_TYPE.SAVE_LABEL}
                        status={
                            isFormValid || saveAssetTypeLoading
                                ? "default"
                                : "disabled"
                        }
                        isLoading={saveAssetTypeLoading}
                        onClick={handleSaveAsset}
                    />
                </Header>
                <ContentWrapper>
                    <AssetTypeWrapper>
                        <NewAssetLabel
                            type="header"
                            size="large"
                            color={colors.ASH}
                        >
                            {CREATE_ASSET_TYPE.CREATE_ASSET_TYPE}
                        </NewAssetLabel>
                        <NewAssetInstr
                            type="body"
                            size="medium"
                            color={colors.ROCK_BOTTOM}
                        >
                            {CREATE_ASSET_TYPE.CREATE_ASSET_TYPE_INSTR}
                        </NewAssetInstr>
                        <NewAssetTypeWrapper>
                            <ContentRow>
                                <NameTextField
                                    value={newAssetName}
                                    onChange={handleAssetNameChange}
                                    placeholder={
                                        CREATE_ASSET_TYPE.PLACEHOLDER_NAME
                                    }
                                    maxLength={
                                        CREATE_ASSET_TYPE.MAX_LENGTH_NAME
                                    }
                                    secureTextEntry={false}
                                    autoCorrect={false}
                                    autoCapitalize="none"
                                    returnKeyType="done"
                                    hintText={CREATE_ASSET_TYPE.LABEL_NAME}
                                />
                            </ContentRow>

                            <ContentRow>
                                <NewAssetTextField
                                    value={newAssetDescr}
                                    onChange={handleAssetTextChange}
                                    placeholder={
                                        CREATE_ASSET_TYPE.PLACEHOLDER_DESCR
                                    }
                                    secureTextEntry={false}
                                    autoCorrect={false}
                                    autoCapitalize="none"
                                    returnKeyType="done"
                                    hintText={CREATE_ASSET_TYPE.LABEL_DESCR}
                                />
                            </ContentRow>
                            <ContentRow>
                                <NewAssetTextField
                                    value={newAssetExtId}
                                    onChange={handleAssetExternalIDChange}
                                    keyboardType="numeric"
                                    placeholder={
                                        CREATE_ASSET_TYPE.PLACEHOLDER_EXTID
                                    }
                                    secureTextEntry={false}
                                    autoCorrect={false}
                                    autoCapitalize="none"
                                    returnKeyType="done"
                                    hintText={CREATE_ASSET_TYPE.LABEL_EXTID}
                                />
                            </ContentRow>
                            <ContentRow>
                                <NewAssetTextField
                                    value={newAssetValue}
                                    onChange={handleAssetValueChange}
                                    placeholder={
                                        CREATE_ASSET_TYPE.PLACEHOLDER_VALUE
                                    }
                                    secureTextEntry={false}
                                    autoCorrect={false}
                                    autoCapitalize="none"
                                    returnKeyType="done"
                                    hintText={CREATE_ASSET_TYPE.LABEL_VALUE}
                                />
                            </ContentRow>
                        </NewAssetTypeWrapper>
                        {isMetadataSectionOpen ? null : (
                            <AddNewMetaDataWrapper
                                onClick={toggleMetadataSection}
                            >
                                <PlusIcon
                                    type="other"
                                    size="large"
                                    color={colors.WAVE_STORM}
                                >
                                    +
                                </PlusIcon>
                                <NewAssetTypeLabel
                                    type="other"
                                    size="large"
                                    color={colors.WAVE_STORM}
                                >
                                    {CREATE_ASSET_TYPE.ADD_META_DATA}
                                </NewAssetTypeLabel>
                            </AddNewMetaDataWrapper>
                        )}
                    </AssetTypeWrapper>
                    {isMetadataSectionOpen ? (
                        <AddMetaDataWrapper>
                            <NewAssetLabel
                                type="header"
                                size="small"
                                color={colors.ASH}
                            >
                                {CREATE_ASSET_TYPE.CREATE_METADATA_TITLE}
                            </NewAssetLabel>
                            <NewAssetInstr
                                type="body"
                                size="medium"
                                color={colors.ROCK_BOTTOM}
                            >
                                {CREATE_ASSET_TYPE.CREATE_METADATA_INSTR}
                            </NewAssetInstr>
                            <MetaDataForm
                                callbackUpdateNewMetadata={(
                                    updatedMetadata
                                ) => {
                                    setNewMetaData(updatedMetadata);
                                    setCounterInteraction(
                                        (counterInteraction) =>
                                            counterInteraction + 1
                                    );
                                }}
                                newMetaData={newMetaData}
                                shouldShowMetadataErrors={
                                    shouldShowMetadataErrors
                                }
                                toggleMetadataSection={toggleMetadataSection}
                            />
                            <AddNewMetaDataWrapper
                                onClick={addExtraEmptyMetaData}
                            >
                                <PlusIcon
                                    type="other"
                                    size="large"
                                    color={colors.WAVE_STORM}
                                >
                                    +
                                </PlusIcon>
                                <NewAssetTypeLabel
                                    type="other"
                                    size="large"
                                    color={colors.WAVE_STORM}
                                >
                                    {CREATE_ASSET_TYPE.ADD_META_DATA}
                                </NewAssetTypeLabel>
                            </AddNewMetaDataWrapper>
                        </AddMetaDataWrapper>
                    ) : null}
                </ContentWrapper>
            </Wrapper>
        </StyledScreenView>
    );
}
