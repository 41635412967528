import React, { useState } from "react";
import { ReactComponent as Delete } from "../../assets/vectors/Delete.svg";
import DeprecatedText from "../common/DeprecatedText";
import TextInput from "../common/TextInput";
import styled, { useTheme } from "styled-components/macro";
import { findTextStyles } from "../../styling/styled-components";
import { METADATA_FORM } from "../../constants/assets";
import FormTextField from "../common/FormTextField";

const NewMetaDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    padding: ${({ theme }) => theme.spacing.xxlarge}px
        ${({ theme }) => theme.spacing.large}px;
    margin-top: ${({ theme }) => theme.spacing.medium}px;
`;

const AnimatedNewMetaDataWrapper = styled.div`
    gap: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const MetaDataContentRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 515px;
    margin: 0 auto;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const DeleteMetaDataWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    top: 24px;
`;

const DeleteLabel = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.WAVE_STORM};
    margin-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const NewAssetTextInput = styled(FormTextField)`
    flex: 1;
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const LabelText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    margin-top: 4px;
    color: ${({ isNotEmpty, theme }) =>
        isNotEmpty ? theme.colors.EXHAUST : theme.colors.DEEP_BLUE_SEA};
`;

const ErrorMessage = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.RED_3};
`;

type Props = {
    callbackUpdateNewMetadata: () => void;
    newMetaData: Record<string, unknown>;
    shouldShowMetadataErrors?: boolean;
    toggleMetadataSection: () => void;
};

export default function MetaDataForm({
    callbackUpdateNewMetadata,
    newMetaData,
    toggleMetadataSection,
    shouldShowMetadataErrors,
}: Props) {
    const emptyMetadata = {
        key: "",
        value: "",
        isNew: true,
    };
    const { spacing } = useTheme();

    const deleteMetadata = (nativeEvent) => {
        if (newMetaData.length > 1) {
            const updatedMetadata = [...newMetaData];
            updatedMetadata.pop();
            callbackUpdateNewMetadata(updatedMetadata);
        } else {
            toggleMetadataSection();
            callbackUpdateNewMetadata([emptyMetadata]);
        }
    };

    const renderNewMetadataRow = (el, index) => {
        const missingKey = !newMetaData[index]["key"];
        const missingValue = !newMetaData[index]["value"];

        const handleMetadataKeyChange = (
            event: React.ChangeEvent<HTMLInputElement>
        ) => {
            const text = event.target.value;
            const updatedMetadataArr = [...newMetaData];
            updatedMetadataArr[index]["key"] = text;
            callbackUpdateNewMetadata(updatedMetadataArr);
        };

        const handleMetadataValueChange = (
            event: React.ChangeEvent<HTMLInputElement>
        ) => {
            const text = event.target.value;
            const updatedMetadataArr = [...newMetaData];
            updatedMetadataArr[index]["value"] = text;
            callbackUpdateNewMetadata(updatedMetadataArr);
        };
        return (
            <div key={`metadata_row__${index}`}>
                <MetaDataContentRow>
                    <NewAssetTextInput
                        value={newMetaData[index]["key"]}
                        onChange={handleMetadataKeyChange}
                        placeholder={METADATA_FORM.PLACEHOLDER_METADATA_KEY}
                        secureTextEntry={false}
                        autoCorrect={false}
                        autoCapitalize="none"
                        returnKeyType="done"
                        hasError={shouldShowMetadataErrors && missingKey}
                        hintText={METADATA_FORM.LABEL_METADATA_KEY}
                        error={METADATA_FORM.ERROR_MISSING_KEY}
                        width="small"
                    />
                    <NewAssetTextInput
                        value={newMetaData[index]["value"]}
                        onChange={handleMetadataValueChange}
                        keyboardType="numeric"
                        placeholder={METADATA_FORM.PLACEHOLDER_METADATA_VALUE}
                        secureTextEntry={false}
                        autoCorrect={false}
                        autoCapitalize="none"
                        returnKeyType="done"
                        hasError={shouldShowMetadataErrors && missingValue}
                        hintText={METADATA_FORM.LABEL_METADATA_VALUE}
                        error={METADATA_FORM.ERROR_MISSING_VALUE}
                        width="small"
                    />
                </MetaDataContentRow>
            </div>
        );
    };

    return (
        <NewMetaDataWrapper>
            <AnimatedNewMetaDataWrapper>
                {newMetaData.map((el, index) => {
                    return renderNewMetadataRow(el, index);
                })}
            </AnimatedNewMetaDataWrapper>
            <DeleteMetaDataWrapper>
                <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={deleteMetadata}
                >
                    <Delete />
                    <DeleteLabel
                        onClick={deleteMetadata}
                        type="other"
                        size="medium"
                    >
                        {METADATA_FORM.DELETE_METADATA}
                    </DeleteLabel>
                </div>
            </DeleteMetaDataWrapper>
        </NewMetaDataWrapper>
    );
}
