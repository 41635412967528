// API
import { Location } from "../../API";

// utils
import { prettifyUID } from "../helpers";

export function getLocationDisplayText(location: Location): string {
    if (location.fullName) {
        return location.fullName;
    } else if (location?.address?.line1) {
        return `${location.address.line1}, ${location.address.line3}}`;
    }
    return prettifyUID(location.locationId);
}
