//react
import { useState, useEffect } from "react";

//styling
import { useTheme } from "../../styling/styled-components";

//components
import { RentalStatusWrapper, StatusText } from "./common/SharedRentalVisual";
import Loadable from "../common/Loadable";
import ShimmerCard from "../common/ShimmerCard";

//utils
import { filterDisplay } from "./utils/helpers";
import formatCount from "../../utils/formatCount";

//constants
import { RentalStatus } from "../../API";

//types
import { FilteredAggregations, StatusItem } from "./common/RentalVisualTypes";
import Displayable from "../common/Displayable";

type Props = {
    filteredAggregations: FilteredAggregations;
    totalDocCount: number;
    status: StatusItem;
};

export default function RentalStatusCard({
    status,
    totalDocCount,
    filteredAggregations,
}: Props) {
    const [percentage, setPercentage] = useState(0);
    const { colors, spacing, isTablet } = useTheme();
    const [statusFilteredShown, setStatusFilteredShown] = useState(true);
    const [count, setCount] = useState("-");
    const [dataLoaded, setDataLoaded] = useState(false);
    const formattedPercentageText = filterDisplay(
        statusFilteredShown,
        percentage >= 1 || percentage == 0
            ? `(${Math.round(percentage)}%)`
            : `(<${Math.round(percentage)}%)`,
        ""
    );
    const percentageDisplay = status.displayPercentage
        ? formattedPercentageText
        : "";

    useEffect(() => {
        calculateStatusCount();
        displaySelectedStatuses();
    }, [status, filteredAggregations, count, totalDocCount]);

    //handleEmptyPhaseCount and totalExpiredFailedRentalsPerPhase can be deleted once we remove the expired phase
    //TEC-1856 notion ticket
    const handleEmptyPhaseCount = (expired, failed) => {
        if (isNaN(expired) && isNaN(failed)) {
            return 0;
        } else if (isNaN(expired)) {
            return failed;
        } else if (isNaN(failed)) {
            return expired;
        } else {
            return expired + failed;
        }
    };

    const totalExpiredFailedRentalsPerPhase = () => {
        const expiredItem = filteredAggregations?.find(
            (status) => status["key"] == RentalStatus.Expired
        );
        const failedItem = filteredAggregations?.find(
            (status) => status["key"] == RentalStatus.Failed
        );
        let expiredAndFailedCount = handleEmptyPhaseCount(
            expiredItem?.docCount,
            failedItem?.docCount
        );
        return expiredAndFailedCount;
    };

    const displaySelectedStatuses = () => {
        setStatusFilteredShown(status.displayStatus);
    };

    const calculateStatusCount = () => {
        const statusSubTotals =
            (filteredAggregations &&
                filteredAggregations.find((sub) => sub.key == status.key)) ||
            {};
        if (
            status.key == RentalStatus.Failed ||
            status.key == RentalStatus.Expired
        ) {
            let totalCount = totalExpiredFailedRentalsPerPhase();
            setCount(totalCount || 0);
        } else {
            setCount(statusSubTotals?.docCount || 0);
        }
        let calculatePercentage = (count / totalDocCount) * 100;
        setPercentage(calculatePercentage || 0);
        setTimeout(() => {
            setDataLoaded(true);
        }, 1200);
    };

    const StyledShimmerCard = () => {
        return <ShimmerCard height={23} width={51} />;
    };

    return (
        <RentalStatusWrapper
            paddingLeft={isTablet ? spacing.tiny : spacing.medium}
        >
            <Loadable
                dataLoaded={dataLoaded}
                LoadingComponent={StyledShimmerCard}
            >
                <StatusText
                    type="header"
                    size={"xlarge"}
                    color={colors.DEEP_BLUE_SEA}
                    opacity={filterDisplay(statusFilteredShown, "100%", "20%")}
                >
                    {filterDisplay(
                        statusFilteredShown,
                        formatCount(count),
                        "—"
                    )}
                </StatusText>
                <Displayable displayPrimaryComponent={!isTablet}>
                    <StatusText
                        type="label"
                        size="small"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {percentageDisplay}
                    </StatusText>
                </Displayable>
            </Loadable>
        </RentalStatusWrapper>
    );
}
