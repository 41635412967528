export default function convertAssetValueToDollarString(
    assetChargeValue: number
): string {
    if (!assetChargeValue) {
        return "";
    }

    const dollars: number = parseInt(assetChargeValue / 100);
    const cents: number = assetChargeValue % 100;
    const centsPad: string = cents > 10 ? "" : "0";

    return `$${dollars}.${centsPad}${cents}`;
}
