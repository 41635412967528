import React, { useEffect, useState } from "react";
import { useTogglePasswordVisibility } from "../../hooks/useTogglePasswordVisibility";
import DeprecatedText from "../common/DeprecatedText";
import Button from "../common/Button";
import TextInput from "../common/TextInput";
import { ERROR_MESSAGES, ERROR_TYPES } from "../../constants/errors";
import { NEW_PASSWORD } from "../../constants/signin";
import { validatePassword } from "../../utils/validation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styled, { css, useTheme } from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { findTextStyles } from "../../styling/styled-components";

const hintStyle = css`
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const mobileHintStyle = css`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const NewPasswordConstraints = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
    margin-top: ${({ theme }) => theme.spacing.small}px;
    margin-bottom: ${({ theme }) => theme.spacing.xlarge}px;
    ${(props) => props.hasError && `color: ${props.theme.colors.RED_3};`};
`;

const ContinueButton = styled(Button)`
    align-self: center;
    margin-top: ${({ theme }) => theme.spacing.huge}px;
`;

const StyledVisibilityIcon = styled(VisibilityIcon)`
    size: ${({ theme }) => theme.spacing.medium}px;
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
    cursor: pointer;
    margin-top: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const StyledVisibilityOffIcon = styled(VisibilityOffIcon)`
    size: ${({ theme }) => theme.spacing.medium}px;
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
    cursor: pointer;
    margin-top: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const PasswordTextInput = styled(TextInput)`
    margin-top: ${({ theme }) => theme.spacing.xxlarge}px;
    padding: ${({ theme }) => theme.spacing.xsmall}px 0;

    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            margin-top: 40px;
        `}
`;

type Props = {
    continueButtonLabel: string;
    hasOtherError: boolean;
    onChangePassword: () => void;
    styleContext: string;
    username: string;
};

export default function CreateNewPasswordContent({
    continueButtonLabel = NEW_PASSWORD.CONTINUE,
    hasOtherError = false,
    onChangePassword,
    styleContext = "forgotPassword",
    username,
}: Props) {
    const { colors, isMobile } = useTheme();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfirmation, setNewPasswordconfirmation] =
        useState<string>("");
    const [newPasswordLoading, setNewPasswordLoading] =
        useState<boolean>(false);
    const [newPasswordError, setNewPasswordError] = useState<string>("");
    const [newPasswordConfirmationError, setNewPasswordConfirmationError] =
        useState<string>("");
    const [changePasswordError, setChangePasswordError] = useState<string>();

    const [isContinueButtonDisabled, setIsContinueButtonDisabled] =
        useState<boolean>(true);

    const { passwordVisibility, rightIcon, handlePasswordVisibility } =
        useTogglePasswordVisibility();

    useEffect(() => {
        if (
            !validatePassword(newPassword) ||
            newPasswordConfirmation !== newPassword ||
            hasOtherError
        ) {
            setIsContinueButtonDisabled(true);
        } else {
            setIsContinueButtonDisabled(false);
        }
    }, [hasOtherError, newPassword, newPasswordConfirmation]);

    const changePassword = async (): void => {
        setNewPasswordLoading(true);
        onChangePassword(newPassword)
            .then(function () {
                setNewPassword("");

                navigate("/", {
                    username: username,
                    prompt: "Your password has been updated. Please sign in.",
                });
            })
            .catch(function (rej) {
                setChangePasswordError(rej.message);
                setNewPasswordError(ERROR_MESSAGES.PASSWORD_CONSTRAINTS);
            })
            .finally(function () {
                setNewPasswordLoading(false);
            });
    };

    const handleContinue = (newPassword) => {
        changePassword(newPassword);
    };

    const onPasswordInputBlur = () => {
        if (!validatePassword(newPassword) && !!newPassword.length) {
            setNewPasswordError(ERROR_MESSAGES.PASSWORD_CONSTRAINTS);
        } else if (newPassword && newPasswordConfirmation !== newPassword) {
            setNewPasswordConfirmationError(
                ERROR_MESSAGES.PASSWORD_CONFIRMATION
            );
        } else {
            setNewPasswordError(null);
            setNewPasswordConfirmationError(null);
        }
    };

    const onPasswordConfirmationBlur = () => {
        if (!!newPasswordConfirmation.length) {
            if (newPasswordConfirmation !== newPassword) {
                setNewPasswordConfirmationError(
                    ERROR_MESSAGES.PASSWORD_CONFIRMATION
                );
            } else if (!validatePassword(newPasswordConfirmation)) {
                setNewPasswordConfirmationError(
                    ERROR_MESSAGES.PASSWORD_CONSTRAINTS
                );
            }
        } else {
            setNewPasswordConfirmationError(null);
        }
    };

    const handlePasswordChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setNewPasswordError(null);
        setNewPassword(text);
    };

    const handlePasswordConfirmationChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setNewPasswordConfirmationError(null);
        setNewPasswordconfirmation(text);
    };

    const EyeIcon =
        rightIcon === "eye" ? (
            <StyledVisibilityIcon onClick={handlePasswordVisibility} />
        ) : (
            <StyledVisibilityOffIcon onClick={handlePasswordVisibility} />
        );

    return (
        <Container>
            <PasswordTextInput
                key="password"
                type={rightIcon === "eye" ? "password" : "text"}
                value={newPassword}
                onChange={handlePasswordChange}
                hintText={NEW_PASSWORD.PASSWORD_HINT}
                hintStyle={isMobile ? mobileHintStyle : hintStyle}
                error={newPasswordError}
                secureTextEntry={passwordVisibility}
                autoCorrect="false"
                autoCapitalize="none"
                returnKeyType="done"
                rightIcon={EyeIcon}
                onBlur={onPasswordInputBlur}
            />
            <NewPasswordConstraints
                type="body"
                size="tiny"
                hasError={!!newPasswordError}
            >
                {NEW_PASSWORD.PASSWORD_CONSTRAINTS}
            </NewPasswordConstraints>
            <PasswordTextInput
                key="confirmationPassword"
                type={rightIcon === "eye" ? "password" : "text"}
                value={newPasswordConfirmation}
                onChange={handlePasswordConfirmationChange}
                hintText={
                    styleContext === "forgotPassword"
                        ? NEW_PASSWORD.RE_ENTER_PASSWORD
                        : NEW_PASSWORD.RESET_PASSWORD
                }
                hintStyle={isMobile ? mobileHintStyle : hintStyle}
                error={newPasswordConfirmationError}
                secureTextEntry={passwordVisibility}
                autoCorrect="false"
                autoCapitalize="none"
                returnKeyType="done"
                rightIcon={EyeIcon}
                onBlur={onPasswordConfirmationBlur}
            />
            <ContinueButton
                label={continueButtonLabel}
                type="primary"
                status={isContinueButtonDisabled ? "disabled" : "default"}
                onClick={() => handleContinue(newPassword)}
            />
        </Container>
    );
}
