import React, { useState, useEffect } from "react";
import useGraphClient from "../../hooks/useGraphClient";
import * as queries from "../../graphql/queries";
import DeprecatedText from "../common/DeprecatedText";
import ReturnRateCards from "./ReturnRateCards";
import AllTimeReturnRateCard from "./AllTimeReturnRateCard";
import LoopDurationCurve from "../common/charts/LoopDurationCurve";
import styled, { useTheme } from "styled-components/macro";
import AssetUtilizationCards from "./AssetUtilizationCards";
import ScreenView from "../common/ScreenView";
import { ReactComponent as Edit } from "../../assets/vectors/Edit.svg";
import { findTextStyles } from "../../styling/styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { ASSET_TYPE_DETAIL } from "../../constants/assets";
import BackHeader from "../common/BackHeader";

const StyledScreenView = styled(ScreenView)`
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

const LoadingContainer = styled.div`
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Header = styled.div`
    padding-top: ${({ theme }) => theme.spacing.large}px;
`;

const ReturnRateWrapper = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
`;

const StyledReturnRateCards = styled(ReturnRateCards)`
    height: 100%;
    flex: 25;
`;

const StyledAllTimeReturnRateCard = styled(AllTimeReturnRateCard)`
    height: 100%;
    flex: 12;
`;

const StyledLoopDurationCurve = styled(LoopDurationCurve)`
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding: ${({ theme }) => theme.spacing.medium}px;
    border-radius: 4px;
`;

const Divider = styled.div`
    flex: 1;
`;

const IdsWrapper = styled.div`
    padding: ${({ theme }) => theme.spacing.small}px 0;
    display: flex;
    flex-direction: row;
`;

const Id = styled.div`
    padding-right: ${({ theme }) => theme.spacing.medium}px;
`;

const AshText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ASH};
`;

const ExhaustText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

const SectionHeader = styled.div`
    margin-top: ${({ theme }) => theme.spacing.huge}px;
    margin-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const SectionDivider = styled.div`
    display: flex;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    margin: ${({ theme }) => theme.spacing.small}px 0;
`;

const OptionsWrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const IconWrapper = styled.div``;

const LabelText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.WAVE_STORM};
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const Body = styled.div``;

export default function AssetTypeDetail() {
    const { assetTypeId } = useParams();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [assetType, setAssetType] = useState({});
    const { colors } = useTheme();

    const graphClient = useGraphClient();

    useEffect(() => {
        if (assetTypeId) {
            loadAssetType();
        }
    }, []);

    const renderSectionHeader = (title: string, description: string) => {
        return (
            <SectionHeader>
                <AshText type="header" size="medium">
                    {title}
                </AshText>
                <SectionDivider />
                <ExhaustText type="body" size="tiny">
                    {description}
                </ExhaustText>
            </SectionHeader>
        );
    };

    const renderRightHeaderOptions = () => {
        return (
            <OptionsWrapper onClick={() => navigate("edit")}>
                <IconWrapper>
                    <Edit color={colors.WAVE_STORM} />
                </IconWrapper>
                <LabelText type="label" size="small">
                    {ASSET_TYPE_DETAIL.EDIT_OPTION}
                </LabelText>
            </OptionsWrapper>
        );
    };

    const loadAssetType = async () => {
        setLoading(true);
        try {
            const result = await graphClient.graphql({
                query: queries.getAssetType,
                variables: {
                    assetTypeId,
                },
            });
            setAssetType(result.data.getAssetType);
            setLoading(false);
        } catch (err) {
            console.error("Error getting AssetType", err);
            setLoading(false);
        }
    };

    return (
        <StyledScreenView
            shouldShowCounter={false}
            title={
                <BackHeader
                    label={"Back"}
                    onClick={() => navigate("/dashboard/assets")}
                />
            }
            headerRight={renderRightHeaderOptions()}
        >
            <Header>
                <AshText type="header" size="xlarge">
                    {assetType.name}
                </AshText>
                {assetType.description ? (
                    <AshText type="header" size="small">
                        {assetType.description}
                    </AshText>
                ) : null}
                <IdsWrapper>
                    <Id>
                        <ExhaustText type="body" size="medium">
                            {`ID: ${assetType.assetTypeId}`}
                        </ExhaustText>
                    </Id>
                    {assetType.externalId ? (
                        <Id>
                            <ExhaustText type="body" size="medium">
                                {`External ID: ${assetType.externalId}`}
                            </ExhaustText>
                        </Id>
                    ) : null}
                </IdsWrapper>
            </Header>
            <Body>
                {renderSectionHeader(
                    ASSET_TYPE_DETAIL.RETURN_RATE,
                    ASSET_TYPE_DETAIL.RETURN_RATE_DESCR
                )}
                <ReturnRateWrapper>
                    <StyledAllTimeReturnRateCard assetTypeId={assetTypeId} />
                    <StyledReturnRateCards assetTypeId={assetTypeId} />
                </ReturnRateWrapper>
                {renderSectionHeader(
                    ASSET_TYPE_DETAIL.LOOP_DURATIONS,
                    ASSET_TYPE_DETAIL.LOOP_DURATIONS_DESCR
                )}
                <StyledLoopDurationCurve assetTypeId={assetTypeId} hideTitle />
                {renderSectionHeader(
                    ASSET_TYPE_DETAIL.ASSET_UTILIZATION,
                    ASSET_TYPE_DETAIL.ASSET_UTILIZATION_DESCR
                )}
                <AssetUtilizationCards assetTypeId={assetTypeId} />
            </Body>
        </StyledScreenView>
    );
}
