//styles
import styled from "../../../styling/styled-components";

//components
import Loadable from "../../common/Loadable";
import SingleSelectLocationDropdown from "../../common/SingleSelectLocationDropdown";

//react
import { useState, useEffect } from "react";

//constants
import { CHECKOUTS_RETURN_SUMMARY } from "../../../constants/dashboard";

//apis
import { useQuery } from "../../../graphql/hooks/query";
import * as queries from "../../../graphql/queries";

const LocationsWrapper = styled.div``;

export default function LocationFilter({ handleChangeLocation }: Props) {
    const [locationItems, setLocationItems] = useState([]);
    const [
        listLocations,
        listLocationsData,
        listLocationsLoading,
        listLocationsErrors,
    ] = useQuery(queries.listParentLocations);

    useEffect(() => {
        listLocations();
    }, []);

    useEffect(() => {
        updateLocationData();
    }, [listLocationsData]);

    const updateLocationData = () => {
        if (listLocationsData) {
            const items = listLocationsData.results.map((location) => ({
                id: location.locationId,
                label: location.name,
                value: location.locationId,
            }));

            items.push({
                id: null,
                label: CHECKOUTS_RETURN_SUMMARY.ALL_LOCATIONS,
                value: "",
            });
            if (items) {
                items.sort((a, b) => a.label.localeCompare(b.label));
            }
            setLocationItems(items);
        }
    };

    return (
        <Loadable dataLoaded={locationItems.length > 0}>
            <LocationsWrapper>
                <SingleSelectLocationDropdown
                    processLocationChange={handleChangeLocation}
                    locationItems={locationItems}
                />
            </LocationsWrapper>
        </Loadable>
    );
}
