import React from "react";
import DeprecatedText from "../common/DeprecatedText";
import styled from "styled-components/macro";
import { AssetOperationType } from "../../API";
import { findTextStyles } from "../../styling/styled-components";

const assetTypeTitle = (assetOperationType: AssetOperationType) =>
    assetOperationType.charAt(0).toUpperCase() + assetOperationType.slice(1);

const BlueContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.WAVE_STORM};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-end;
`;

const GreenContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.GREEN_2};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-end;
`;

const LimeContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.GREEN_2};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-end;
`;

const RedContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.RED_2};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-end;
`;

const DeepBlueContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-end;
`;

const GreyContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.EXHAUST};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.medium}px;
    align-self: flex-end;
`;

const WhiteText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
`;

type Props = {
    assetOperationType: AssetOperationType;
};

export default function Component({ assetOperationType }: Props) {
    if (assetOperationType == AssetOperationType.Clean) {
        return (
            <BlueContainer>
                <WhiteText type="label" size="small">
                    {assetTypeTitle(assetOperationType)}
                </WhiteText>
            </BlueContainer>
        );
    }
    if (assetOperationType == AssetOperationType.Fill) {
        return (
            <DeepBlueContainer>
                <WhiteText type="label" size="small">
                    {assetTypeTitle(assetOperationType)}
                </WhiteText>
            </DeepBlueContainer>
        );
    }
    if (assetOperationType == AssetOperationType.Repair) {
        return (
            <RedContainer>
                <WhiteText type="label" size="small">
                    {assetTypeTitle(assetOperationType)}
                </WhiteText>
            </RedContainer>
        );
    }
    if (assetOperationType == AssetOperationType.Stock) {
        return (
            <LimeContainer>
                <WhiteText type="label" size="small">
                    {assetTypeTitle(assetOperationType)}
                </WhiteText>
            </LimeContainer>
        );
    }
    return (
        <GreyContainer>
            <WhiteText type="label" size="small">
                {assetTypeTitle(assetOperationType)}
            </WhiteText>
        </GreyContainer>
    );
}
